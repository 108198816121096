import {
  errorCodeEquals,
  isApi5GdprError,
  isApi5IpError,
  isApi5PersonalBlockedError,
  isApi6GdprError,
  isApi6IpError,
  isApi6PersonalBlockedError,
  trackBlockedCode,
} from 'api/index'
import {
  ADD_UNI_NOTICE_FROM_GRAPHQL_TO_REDUX,
  FETCH_UNI_NOTICE,
} from 'actions/uninotice/uniNoticeAction'
import { createUniNoticeChannel } from 'functions/comet'
import { notEmptyArray } from 'functions/array'
import { isNoticeNewMessage } from 'functions/isNoticeNewMessage'
import {
  SOCKET_RECEIVE_DATA,
  SocketContent,
  SocketData,
} from 'actions/socket/socketReceiveDataAction'
import { findProfileIdInChat } from 'components/page/ContactList/ContactList.function'
import { AsyncAction } from 'actions/actions.types'
import { ApiResult } from 'api/fetchApi'
import { Api6Error } from 'api/api.types'
import { FETCH_NOTICE_SHORTCUT } from 'actions/uninotice/noticeShortCutAction'
import { isNeedShowNewMessageShortCut } from 'functions/isNeedShowNewMessageShortCut'
import { DeprecatedApi6Error } from 'api/types'
import { AppStore } from 'common/store/store.types'
import { UniNotice } from 'api/uninotice/UniNotice'
import { ActionComet } from './messenger/ChatMessengerState'

export const isActionBlockedTrack = ({
  result,
  error,
}: ApiResult<Api6Error>) => {
  /** для совместимости со старым fetch  */
  const json = error || result
  return (
    errorCodeEquals(json, trackBlockedCode) ||
    errorCodeEquals(json, 'blocked_tracker')
  )
}

export const isActionBlockedIp = ({ result }: AsyncAction) => {
  return isApi5IpError(result) || isApi6IpError(result)
}

export const isActionBlockedGdpr = ({ result }: AsyncAction) => {
  return isApi5GdprError(result) || isApi6GdprError(result)
}

export const filterCometMessages = <T extends SocketContent>(
  channels: SocketData<T>[],
  selectedChannel: string,
  options?: {
    withContent: boolean
    actions: ActionComet[]
  }
): SocketData<T>[] => {
  if (options) {
    return channels.filter(
      (item) =>
        item.channel === selectedChannel &&
        (!options.withContent || item.content) &&
        (!options.actions.length || options.actions.includes(item.action))
    )
  }

  return channels.filter((item) => item.channel === selectedChannel)
}

export const isActionPersonalBlocked = ({ result }: AsyncAction) => {
  return (
    isApi5PersonalBlockedError(result) || isApi6PersonalBlockedError(result)
  )
}

export const isActionHasNotice = (
  action: AsyncAction<Api6Error & DeprecatedApi6Error>,
  store: AppStore
) => {
  const { result, type, ready, ignoreUniNotice, error } = action

  if (ready === true && ignoreUniNotice) {
    return false
  }

  /**
   * когда сами запрашиваем нотис с сервера
   */
  if (
    (type === FETCH_UNI_NOTICE || type === FETCH_NOTICE_SHORTCUT) &&
    ready === true
  ) {
    return true
  }

  if (type === ADD_UNI_NOTICE_FROM_GRAPHQL_TO_REDUX) {
    return true
  }

  /**
   * когда нотис в ответе на каком-либо из 200ых запросов
   */
  if (result && result.notice && result.notice.noticeId) {
    return true
  }

  /**
   * когда нотис в ответе на каком-либо из 400ых запросов
   * или пришла в api5. Запросы от апи5 всегда 200, но ошибка в поле будет
   */
  if (
    result &&
    result.internalError &&
    (result.internalError.notice || result.internalError.uniNotice)
  ) {
    return true
  }

  /**
   * Новые fetchApi возвращает ошибку не в result,
   * а как отдельное поле.
   * И в нем приходит notice
   */
  if (error && error.notice && error.notice.noticeId) {
    return true
  }

  /**
   * нотисы из комметы или веб-сокетов(нужно добавить при необходимости)
   * openedChatUserId id пользователя, с кем открыта переписка.
   */
  const {
    authorizationReducer: { profile: { id: profileId } = {} },
    chatMessengerReducer,
  } = store.getState()

  if (type === SOCKET_RECEIVE_DATA && profileId) {
    const openedChatUserId =
      chatMessengerReducer && chatMessengerReducer.selectedProfileId
        ? chatMessengerReducer.selectedProfileId
        : 0

    return isNeedShowNewMessageShortCut(
      profileId,
      openedChatUserId,
      (result as unknown) as SocketData<SocketContent>[]
    )
  }

  return false
}
