import cookie from 'js-cookie'

/**
 * Для целей разработчика, чтобы иметь возможность скрывать надоедливые элементы интерфейса:
 * попрошайка фото при регистрации новой анкеты,
 * баннер установи приложение, при эмуляции touch,
 * баннер про использование кук, который надо постоянно закрывать, чтобы не мешал
 * и т.д. и т.п.
 */
export const isPureDevMode = () => {
  return !process.env.production && Boolean(cookie.get('_DEV'))
}
