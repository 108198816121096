import { CustomMetaResult } from 'api/meta/metaDataApi'
import { mergeFullUrls } from 'functions/mergeAllUrls'
import { MetaDataState } from 'reducers/metaData/MetaDataState'

export const createCustomMetaData = (metaData: CustomMetaResult): void => {
  const { protocol, hostname } = location

  const metaDescription =
    document.querySelector('meta[name=\'description\']') ||
    document.createElement('meta')
  const metaRobots =
    document.querySelector('meta[name=\'robots\']') ||
    document.createElement('meta')
  const canonicalLink =
    document.querySelector('link[rel=\'canonical\']') ||
    document.createElement('link')

  metaDescription.setAttribute('name', 'description')
  if (metaData.description) {
    metaDescription.setAttribute('content', metaData.description)
  }

  metaRobots.setAttribute('name', 'robots')
  if (metaData.metaRobots) {
    metaRobots.setAttribute('content', metaData.metaRobots)
  }

  canonicalLink.setAttribute('rel', 'canonical')
  if (metaData.canonicalUrl) {
    canonicalLink.setAttribute(
      'href',
      mergeFullUrls(protocol, hostname, metaData.canonicalUrl)
    )
  }

  if (
    !metaDescription.parentElement &&
    metaDescription.getAttribute('content')
  ) {
    document.head.appendChild(metaDescription)
  }
  if (!metaRobots.parentElement && metaRobots.getAttribute('content')) {
    document.head.appendChild(metaRobots)
  }
  if (!canonicalLink.parentElement && canonicalLink.getAttribute('href')) {
    document.head.appendChild(canonicalLink)
  }
}

export const clearCustomMetaData = (metaData: MetaDataState) => {
  if (!metaData.metaRobots) {
    document.querySelector('meta[name=\'robots\']')?.remove()
  }
  if (!metaData.canonicalUrl) {
    document.querySelector('link[rel=\'canonical\']')?.remove()
  }

  return null
}

const createMeta = () => {
  const metaDescription = document.createElement('meta')
  metaDescription.setAttribute('name', 'description')

  return metaDescription
}

export const updateCommonMetaData = (description: string): void => {
  const meta =
    document.querySelector('meta[name=\'description\']') || createMeta()

  if (meta?.getAttribute('content') !== description) {
    meta.setAttribute('content', description)
  }
}
