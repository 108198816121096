import { AsyncAction } from 'actions/actions.types'
import { errorCodeEquals, isApi5CaptchaError } from 'api/index'
import { Api6Error } from 'api/api.types'

export const isActionCaptchaNeeded = ({
  result,
  error,
}: AsyncAction<unknown>) => {
  const json = error || result
  return (
    json &&
    (isApi5CaptchaError(json) || errorCodeEquals(json as Api6Error, 'captcha'))
  )
}
