import { useWatchProfileUnbanned } from 'hooks/useWatchProfileBanned'

/** Чтобы не оставлять разбаненного юзера на экране: "Вы заблокированы" */
/**
 * Не надо вещать hook в корень приложения,
 * в котором есть значение из store, которое может меняться(если только не задумано, чтобы обновить все приложения)
 * Надо выносить в отдельный компонент
 */
export const WatchProfileBanned = () => {
  useWatchProfileUnbanned()

  return null
}
