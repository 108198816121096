import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/photo'

/**
 * Удалять можно только по одной фотографии в профиле.
 * В Attach Album можно удалять массово, но там свой метод api
 * @param photosIds
 * @returns
 */

type Method = paths['/photos/deletePhoto']
type Body = Method['post']['parameters']['body']['body']
type Result = Method['post']['responses']['200'][]

export const deletePhotoApi = (photosIds: number[]) => {
  const photoId = photosIds[0]

  return postApi<Result, Body>('/photos/deletePhoto', {
    photoId,
  })
}
