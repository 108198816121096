import { ConvertedAlbum } from 'components/system/third-party/vk/api/api.types'

export const mergeClassMateAlbums = (
  albums: ClassmateAlbumExtended[],
  photos: ClassmateAlbumPhotos[]
): ConvertedAlbum[] => {
  const photoAlbums = albums
    .map<ConvertedAlbum>(({ aid, title }) => {
      return {
        id: Number(aid),
        header: title ? title : '',
        images: [],
      }
    })
    .sort((albumsA, albumB) => (albumsA.id > albumB.id ? 1 : -1))

  const photosArray = photos
    .reduce((acc, { photos }) => {
      return [...acc, ...photos]
    }, [])
    .map((photo) => {
      if (!Number(photo.album_id)) {
        return { ...photo, album_id: 1 }
      } else {
        return photo
      }
    })

  for (const album of photoAlbums) {
    for (const photo of photosArray) {
      if (
        photo.standard_width >= 600 &&
        photo.standard_height >= 800 &&
        album.id === Number(photo.album_id)
      ) {
        album.images.push({
          id: Number(photo.id),
          source: photo.pic_max,
          thumbnail: photo.pic190x190,
          stretchWidth: photo.standard_width < photo.standard_height,
        })
      }
    }
  }

  return photoAlbums.filter(({ images }) => images.length > 0)
}

export type ClassmateAlbumExtended = {
  aid: string | number
  album_type?: 'user'
  photos_count?: number
  title?: string
}

export type ClassmateAlbumPhotos = {
  hasMore: boolean
  totalCount: number
  anchor: string
  photos: ClassmatePhoto[]
}

/**
 * Там еще есть набор но нам он не интересен
  "pic50x50": "https://i.mycdn.me/i?r=BEGWFx0ahHjSmUS1F7-YtmG0ZFkLVZ3OJuW-gLrq6NX-sKch1IiZqji2if9kvENit1KqDhVvPKY-nIXjQHRTLa0f",
  "pic128x128": "https://i.mycdn.me/i?r=BEEEuDQaLb_rD7ssk-vmUggv6oN22DFZhfPp69U2_1SQ6N1qWxz7yMlfrRx_K0b_AoYdgoWOZArxJbj2Y8SGbX9b",
  "pic128max": "https://i.mycdn.me/i?r=BEGWFx0ahHjSmUS1F7-YtmG0n2yOYBg19RhMK6y9biSH7qch1IiZqji2if9kvENit1KSOlDXocPbPjlHwiSH_0qX",
  "pic180min": "https://i.mycdn.me/i?r=BEEEuDQaLb_rD7ssk-vmUggvfDdDKFiVCNLHvGTjSpn4Mt1qWxz7yMlfrRx_K0b_AoZIONKvjHY_C800HBXZ3hHe",
  "pic240min": "https://i.mycdn.me/i?r=BEEEuDQaLb_rD7ssk-vmUggvsv0gMTYjy-tucA3ZlpIY1d1qWxz7yMlfrRx_K0b_AoYYXjxX8DA5rB_BDnz-OHbY",
  "pic320min": "https://i.mycdn.me/i?r=BEEEuDQaLb_rD7ssk-vmUggvWHNC1cANFO4uATo0i1vWdt1qWxz7yMlfrRx_K0b_Aoberh4CtWzTnL2TjUsJ7Ahj",
  "pic190x190": "https://i.mycdn.me/i?r=BEEEuDQaLb_rD7ssk-vmUggv41NPWltCkIFvnb2Te97NgN1qWxz7yMlfrRx_K0b_AoaacUy4jq0mObHw3FPBXnZE",
  "pic640x480": "https://i.mycdn.me/i?r=BEGWFx0ahHjSmUS1F7-YtmG02zQQRSIb9h6cOX5-5eSL-ach1IiZqji2if9kvENit1KnBN2QATPla36pkszphHeG",
  "pic1024x768": "https://i.mycdn.me/i?r=BEEEuDQaLb_rD7ssk-vmUggvqG46nZB1Fj-8cF5kDCK67d1qWxz7yMlfrRx_K0b_Aob5VR1rjAJcaW7ycg4q0SHm",
 */

export type ClassmatePhoto = {
  type: 'user'
  id: string
  album_id?: string
  standard_width: number
  standard_height: number
  pic_max: string
  pic190x190: string
  text_detected: boolean
}

export type ConvertClassmateAlbum = {
  id: number
  header: string
  images: ConvertClassmatePhoto[]
}

export type ConvertClassmatePhoto = {
  id: number
  source: string
  thumbnail: string
  stretchWidth: boolean
}
