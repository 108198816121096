/**
 * Для авторизации по сикрету. Запрос: /authBySecret.
 *
 * Убрал параметр "o", т.е. его заменили на "so":
 * https://redmine.mamba.ru/issues/122079
 */

export const secretParam = 's'
export const uidParam = 'u'
export const secretOperationParam = 'so'
