import { SessionHandler } from '../sessionStorage'

/**
 * Сохраняя в сессионном хранилище историю:
 * 1. храним ее только для конкретной вкладки (не смешиваем с остальными)
 * 2. автоматически чистим при закрытии
 * @param state
 * @param partialState
 */
export const mutatePartialRootStateWithLocationsHistoryState: SessionHandler = (
  state,
  partialState
) => {
  if (state.locationsHistory) {
    partialState.locationsHistory = state.locationsHistory
  }
}
