import {
  VERIFY_CAPTCHA,
  CAPTCHA_SCRIPT_LOADED,
  FETCH_CAPTCHA_DATA,
  CAPTCHA_RESOLVED,
  UPDATE_FROM_URL_CAPTCHA,
  CaptchaTypes,
} from 'actions/captchaAction'
import { Reducer } from 'redux'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

export interface CaptchaReducerState {
  scriptLoading: boolean
  siteKeyLoading: boolean
  siteKey: string | null
  recaptchaResponse: string | null
  verifying: boolean
  fromUrl: string
}

const initialState: CaptchaReducerState = {
  scriptLoading: true,
  siteKeyLoading: true,
  siteKey: null,
  recaptchaResponse: null,
  verifying: false,
  fromUrl: '/',
}

export const captchaReducer: Reducer<CaptchaReducerState, CaptchaTypes> = (
  state = { ...initialState },
  action
) => {
  switch (action.type) {
    case CAPTCHA_SCRIPT_LOADED:
      return {
        ...state,
        scriptLoading: false,
      }

    case FETCH_CAPTCHA_DATA:
      return defaultPromiseReducer(
        state,
        action,
        () => null,
        (result) => {
          if (result) {
            return {
              siteKeyLoading: false,
              siteKey: result.recaptchaV2Key,
            }
          }

          return state
        }
      )

    case CAPTCHA_RESOLVED:
      return {
        ...state,
        recaptchaResponse: action.recaptchaResponse,
      }

    case VERIFY_CAPTCHA:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          verifying: true,
        }),
        () => ({
          verifying: false,
        }),
        () => ({
          verifying: false,
        })
      )

    case UPDATE_FROM_URL_CAPTCHA:
      return { ...state, fromUrl: action.url }

    default:
      return state
  }
}
