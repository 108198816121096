import { TimeRange } from 'common-constants/timeRange'

export const UPDATE_PUSH_NOTIFICATIONS_REQUEST_MODAL_HIDDEN_TILL_DATE = 'UPDATE_PUSH_NOTIFICATIONS_REQUEST_MODAL_HIDDEN_TILL_DATE' as const

export const updatePushNotificationsRequestModalHiddenTillDateAction = (
  dateInMs: number = Date.now() + 2 * TimeRange.hour
) => ({
  type: UPDATE_PUSH_NOTIFICATIONS_REQUEST_MODAL_HIDDEN_TILL_DATE,
  value: dateInMs,
})
