export const TOGGLE_DISPLAY_PRELOADER = 'TOGGLE_DISPLAY_PRELOADER'
export const UPDATE_IFRAME_CHAT = 'UPDATE_IFRAME_CHAT'

export const toggleDisplayPreloaderAction = flag => {
  return {
    type: TOGGLE_DISPLAY_PRELOADER,
    flag,
  }
}

export const updateIframeChatAction = isUpdateChat => {
  return {
    type: UPDATE_IFRAME_CHAT,
    isUpdateChat,
  }
}
