import { useMemo } from 'react'
import { useShallowEqualSelector } from './useShallowEqualSelector'
import { mambaFeaturesFlagsParsed } from 'api/api.constants'

export const useSupportLiveness = () => {
  const { features } = useShallowEqualSelector(
    ({ systemReducer: { features } }) => ({
      features,
    })
  )

  return useMemo(() => {
    return features?.[mambaFeaturesFlagsParsed.liveness] == 0 || false
  }, [features])
}
