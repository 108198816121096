import {
  birthdayPath,
  personHeightPath,
  datingGoalPath,
  enterNamePath,
  personWeightPath,
  yourGenderPath,
} from 'components/page/StepRegistration/StepRegistration.paths'

export const pathsOrder = [
  yourGenderPath,
  datingGoalPath,
  personHeightPath,
  personWeightPath,
  enterNamePath,
  birthdayPath,
]
