import React, { FC } from 'react'

export const Search3Svg: FC<{ size?: number }> = ({ size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
  >
    <path
      d="M12.158 12.784l6.06 6.06M13.987 7.492A6.494 6.494 0 0 1 1 7.492a6.494 6.494 0 0 1 12.987 0z"
      fill="none"
      fillRule="evenodd"
      stroke="#46AAE9"
      strokeWidth="1.5"
    />
  </svg>
)
