export const loginPath = '/login'
export const loginVariantsPath = '/login-variants'
export interface LoginVariantsParams {
  limit: string
}
/**
 * @deprecated path.ts
 */
export const registrationPath = '/register'
export const otherSocialNetworksPath = '/other-social'
export const appBoardingAgreementPath = '/app/boarding/agreement'
export const cityListPath = '/city-list'
export const shortcutListPath = '/shortcut-list'
export const aboutCompanyPath = '/about-company'
