export const CREATE_BASE_URL = 'CREATE_BASE_URL'

interface CreateBaseUrlAction {
  type: typeof CREATE_BASE_URL
  id: string
  baseUrl: string
  basePath: string
  secondary: boolean
}

export interface BaseUrlData {
  uid?: string // debug
  id: string | null
  baseUrl: string
  basePath: string
  secondary: boolean
}

export const createBaseUrlAction = ({
  uid,
  id,
  baseUrl,
  basePath,
  secondary,
}: BaseUrlData) => {
  return {
    type: CREATE_BASE_URL,
    id,
    uid,
    baseUrl,
    basePath,
    secondary,
  }
}

export type CreateBaseUrlTypes = CreateBaseUrlAction
