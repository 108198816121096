import { findCountryCode } from 'actions/profile/function/findCountryCode'
import { clearCounterDownAction } from 'actions/utility/counterDown/counterDownAction'
import { apiResultHasErrors } from 'api/function/apiResultHasErrors'
import {
  sendCodeSmsDeleteProfileApi,
  sendPhoneDeleteProfileApi,
} from 'api/profile/profileDeleteApi'
import { DeleteReasons } from 'components/page/DeleteProfile/DeleteProfile.constants'
import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { concatPhone } from 'components/page/RestorePassword/function/concatPhone'
import { Api6Error } from 'api/api.types'

export const SELECTED_REASON = 'SELECTED_REASON_DELETE'
export const CLEAR_SELECTED_REASON = 'CLEAR_SELECTED_REASON'
export const UPDATE_FORM_DELETE_PROFILE = 'UPDATE_FORM_DELETE_PROFILE'
export const DELETE_PROFILE_SEND_PHONE = 'DELETE_PROFILE_SEND_PHONE'

interface SelectedReasonAction {
  type: typeof SELECTED_REASON
  reasonSelected: DeleteReasons
}

export const selectedReasonAction = (reasonSelected: string) => ({
  type: SELECTED_REASON,
  reasonSelected,
})

interface ClearSelectedReasonAction {
  type: typeof CLEAR_SELECTED_REASON
}

export const clearSelectedReasonAction = () => ({
  type: CLEAR_SELECTED_REASON,
})

interface UpdateFormDeleteProfileAction {
  type: typeof UPDATE_FORM_DELETE_PROFILE
  name: string
  value: string
}

export const updateFormDeleteProfileAction = (name: string, value: string) => ({
  type: UPDATE_FORM_DELETE_PROFILE,
  name,
  value,
})

interface DeleteProfileSendPhoneAction extends AsyncAction {
  type: typeof DELETE_PROFILE_SEND_PHONE
}

export const deleteProfileSendPhoneAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const { countriesInfo, profileDelete } = getState()
  const { countriesList } = countriesInfo

  const {
    form: { countryCode, phoneNumber },
  } = profileDelete

  const foundCountry = findCountryCode(countriesList, countryCode)

  if (foundCountry && phoneNumber) {
    const { prefix } = foundCountry

    const { result: json } = ((await dispatch({
      type: DELETE_PROFILE_SEND_PHONE,
      promise: () =>
        sendPhoneDeleteProfileApi(concatPhone(prefix, phoneNumber)),
    })) as unknown) as { result: Api6Error }

    return !apiResultHasErrors(json)
  } else {
    console.error(`cannot find country with code ${countryCode}`)
    return Promise.resolve()
  }
}

export const DELETE_PROFILE_SEND_CODE_SMS = 'DELETE_PROFILE_SEND_CODE_SMS'

export interface DeleteProfileSendCodeSmsAction extends AsyncAction {
  type: typeof DELETE_PROFILE_SEND_CODE_SMS
}

export const deleteProfileSendCodeSmsAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const { countriesInfo, profileDelete } = getState()
  const { countriesList } = countriesInfo

  const {
    form: { countryCode, phoneNumber, verificationCode },
    reasonSelected,
  } = profileDelete

  const foundCountry = findCountryCode(countriesList, countryCode)

  if (foundCountry && reasonSelected && phoneNumber) {
    const { prefix } = foundCountry

    const { result: json } = ((await dispatch({
      type: DELETE_PROFILE_SEND_CODE_SMS,
      promise: () =>
        sendCodeSmsDeleteProfileApi(
          concatPhone(prefix, phoneNumber),
          verificationCode,
          reasonSelected
        ),
    })) as unknown) as { result: Api6Error }

    if (!apiResultHasErrors(json)) {
      dispatch(clearCounterDownAction())
    }
  } else {
    console.error(`cannot find country with code ${countryCode}`)
    return Promise.resolve
  }
}

export type SelectedReasonTypes =
  | SelectedReasonAction
  | ClearSelectedReasonAction
  | UpdateFormDeleteProfileAction
  | DeleteProfileSendPhoneAction
  | DeleteProfileSendCodeSmsAction
