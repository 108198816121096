import { AsyncAction, AsyncThunkActionVoid } from 'actions/actions.types'
import { paths as notifications_pushes } from 'api/generated/notifications_pushes'
import { fetchPushNotificationsApi } from 'api/push/fetchPushNotificationsApi'
import { createHeadersForNotifications } from 'api/push/functions'

export const FETCH_PUSH_SUBSCRIPTIONS = 'FETCH_PUSH_SUBSCRIPTIONS' as const

export interface FetchPushSubscriptionsIfNeededAction
  extends AsyncAction<
    notifications_pushes['/notifications/subscriptions']['get']['responses']['200']['schema']
  > {
  type: typeof FETCH_PUSH_SUBSCRIPTIONS
}

export const fetchPushSubscriptionsIfNeededAction = (): AsyncThunkActionVoid => (
  dispatch,
  getState
) => {
  const {
    systemReducer: { partnerId },
    settingsReducer: { pushSubscriptionGroups },
  } = getState()

  if (pushSubscriptionGroups?.length) {
    return
  }

  const action: FetchPushSubscriptionsIfNeededAction = {
    type: FETCH_PUSH_SUBSCRIPTIONS,
    promise: () =>
      fetchPushNotificationsApi(createHeadersForNotifications(partnerId)),
  }

  dispatch(action)
}
