import { PlainAction } from 'actions/actions.types'

export const TOGGLE_SETTINGS_INFO = 'TOGGLE_SETTINGS_INFO'

export interface ToggleSettingsInfoAction extends PlainAction {
  type: typeof TOGGLE_SETTINGS_INFO
  settingInfoId: number
}

/**
 * TODO перенести в экшены меню, либо в стейт
 */
export function toggleSettingsInfoAction(settingInfoId: string) {
  return {
    type: TOGGLE_SETTINGS_INFO,
    settingInfoId,
  }
}
