import { getKeyValue, setKeyValue } from 'functions/localStorage'

const facebookTokenLocalStorageKey = 'facebookToken'

export const saveFacebookTokenAndMambaUserIdToLocalStorage = (
  token: string,
  userId: string | number
) => {
  setKeyValue(facebookTokenLocalStorageKey, { userId, token })
}

export const getFacebookTokenAndMambaUserIdFromLocalStorage = ():
  | { token: string; userId: string | number }
  | undefined => {
  const json = getKeyValue(facebookTokenLocalStorageKey)

  if (!json) {
    return
  }

  try {
    return JSON.parse(json)
  } catch (error) {
    return
  }
}
