import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const useUserId = (): number => {
  const { id } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        profile: { id },
      },
    }) => ({
      id,
    })
  )

  return id!
}
