import {
  fetchPopularityStatisticApi,
  fetchPopularityStatusApi,
} from '../api/popularityApi'

export const FETCH_POPULARITY_STATUS = 'FETCH_POPULARITY_STATUS'
export const FETCH_POPULARITY_STATISTIC = 'FETCH_POPULARITY_STATISTIC'
export const CHANGE_FIELD_STATISTIC = 'CHANGE_FIELD_STATISTIC'

export function fetchPopularityStatusAction() {
  return {
    type: FETCH_POPULARITY_STATUS,
    promise: () => fetchPopularityStatusApi(),
  }
}

export function fetchPopularityStatisticAction() {
  return {
    type: FETCH_POPULARITY_STATISTIC,
    promise: () => fetchPopularityStatisticApi(),
  }
}

export function changeFieldStatisticAction() {
  return {
    type: CHANGE_FIELD_STATISTIC,
  }
}
