import React, { FC, SVGProps } from 'react'

export const LogoSvg: FC<SVGProps<SVGSVGElement & {}>> = (props) => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M86 43C86 19.2518 66.7482 0 43 0C19.2518 0 0 19.2518 0 43C0 66.7482 19.2518 86 43 86C66.7482 86 86 66.7482 86 43Z"
        fill="#FFC7E6"
      />
      <mask
        id="mask0_6178_21690"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="86"
        height="86"
      >
        <path
          d="M86 43C86 19.2518 66.7482 0 43 0C19.2518 0 0 19.2518 0 43C0 66.7482 19.2518 86 43 86C66.7482 86 86 66.7482 86 43Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6178_21690)">
        <path
          d="M-22.1083 -1.07062C-33.338 6.29137 -42.0694 16.254 -48.1131 28.3228C-65.0165 62.075 -60.8572 112.32 -31.3223 168.332C-31.0255 168.895 -30.7169 169.459 -30.4167 170.022L63.1694 58.2533C69.3854 8.47762 17.1471 -26.8043 -22.1083 -1.07062Z"
          fill="#FFC7E6"
        />
        <path
          d="M-9.13193 17.4293C-16.7514 24.5952 -22.1011 33.4187 -25.1042 43.4939C-33.5048 71.6713 -23.5182 109.651 6.71246 148.622C7.01601 149.012 7.32809 149.404 7.63676 149.795L64.4675 51.3572C62.2164 12.4054 17.4988 -7.62053 -9.13363 17.4276L-9.13193 17.4293Z"
          fill="#FFC7E6"
        />
        <path
          d="M-2.68916 33.3794C-7.28849 40.8114 -9.69985 49.0908 -9.94542 57.8734C-10.6327 82.4355 5.64316 110.932 38.4745 135.85C38.8036 136.101 39.1413 136.348 39.4739 136.597L64.0582 44.8479C53.8158 13.8924 13.3889 7.40018 -2.68916 33.3794Z"
          fill="#FB9BF9"
        />
        <path
          d="M12.5948 41.8641C10.2176 47.6981 9.54057 53.8646 10.5024 60.1181C13.1917 77.6116 28.7155 95.8008 55.8049 109.415C56.0761 109.551 56.3541 109.686 56.6286 109.82L62.6587 41.7618C51.2227 21.2925 20.9067 21.4681 12.5948 41.8641Z"
          fill="#FF8640"
        />
        <path
          d="M95.0116 43.4132C90.7636 29.3833 73.2258 28.5648 62.6817 40.3845C62.6817 40.3845 62.6168 40.4493 62.5214 40.5141C62.3696 40.6352 62.1905 40.6932 62.02 40.6745C61.8511 40.6932 61.6704 40.6352 61.5186 40.5141C61.4231 40.4493 61.3583 40.3845 61.3583 40.3845C50.792 28.546 33.2542 29.3322 29.0334 43.3553C27.826 47.3662 27.7374 51.4983 28.7128 55.5945C31.4414 67.0511 42.5125 78.2245 60.8041 85.6888C61.2202 85.8593 61.638 86.0265 62.0609 86.1919V86.1851L62.0643 86.1919C62.4872 86.0265 62.9051 85.861 63.3212 85.6905C81.5991 78.2586 92.648 67.1039 95.3544 55.6525C96.3231 51.558 96.2258 47.4259 95.0099 43.4115L95.0116 43.4132Z"
          fill="#EA4D0A"
        />
      </g>
    </svg>
  )
}
