import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const ShellLoadable = loadable(
  async () => (await import('./ShellPage')).ShellPage,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Shell page split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
