import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const AstroStarLoadable = loadable(() => import('./AstroStarModal'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('AstroStar modal split error', error)
    }
    return <DefaultLoader />
  })(defaultLoadableProps),
})
