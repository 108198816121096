import { Colors } from 'common-constants/colors'
import React, { SVGProps } from 'react'

export const DropDownSvg = ({
  className,
  color = Colors.link,
  size = 24,
  ...rest
}: SVGProps<SVGSVGElement> & {
  size?: number
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      {...rest}
    >
      <path
        d="M12 16L19.5 8.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M4.5 8.5L12 16"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  )
}
