export const TOGGLE_HIT_LIST_NEEDS_UPDATE = 'TOGGLE_HIT_LIST_NEEDS_UPDATE'

export interface ToggleHitListNeedsUpdateAction {
  type: typeof TOGGLE_HIT_LIST_NEEDS_UPDATE
  value: boolean
}

export const toggleHitListNeedsUpdateAction = (value: boolean) => ({
  type: TOGGLE_HIT_LIST_NEEDS_UPDATE,
  value,
})

export const TOGGLE_HIT_LIST_COUNTER_TOP_BAR_UPDATE =
  'TOGGLE_HIT_LIST_COUNTER_TOP_BAR_UPDATE'

export interface ToggleHitListCounterTopBarUpdateAction {
  type: typeof TOGGLE_HIT_LIST_COUNTER_TOP_BAR_UPDATE
  value: boolean
}

export const toggleHitListCounterTopBarUpdateAction = (value: boolean) => ({
  type: TOGGLE_HIT_LIST_COUNTER_TOP_BAR_UPDATE,
  value,
})
