import { fetchApi } from 'api/index'
import { UniNoticeId } from 'api/uninotice/uniNoticeApi'

export const ackClickApi = (noticeId: UniNoticeId) =>
  fetchApi(`/uni-notice/${noticeId}/ackClick`, {
    method: 'POST',
  })

export const ackViewApi = (noticeId: UniNoticeId) =>
  fetchApi(`/uni-notice/${noticeId}/ackView`, {
    method: 'POST',
  })
