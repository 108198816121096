import { VoidHandler } from 'common/types'
import { GeolocationPermissionSvg } from 'components/presentational/svg/GeolocationPermissionSvg'
import UniNoticeViewContent from 'components/uninotice/UniNoticeViewContent'
import React, { FC, ReactNode } from 'react'
import { GeoPermissionDeniedText } from './GeoPermissionDeniedText'
import { NotNowButton } from './NotNowButton'

export const GeoPermissionDeniedContent: FC<{
  title?: ReactNode | null
  onSecondaryButtonClick?: VoidHandler
}> = ({ title, onSecondaryButtonClick }) => {
  return (
    <UniNoticeViewContent
      title={title}
      imageComponent={<GeolocationPermissionSvg />}
      textComponent={<GeoPermissionDeniedText />}
      secondaryComponent={
        onSecondaryButtonClick && (
          <NotNowButton onClick={onSecondaryButtonClick} />
        )
      }
    />
  )
}
