import { AppRoute } from 'components/components.types'
import {
  createNoticeEmailsClientPath,
  createNoticeEmailsServerPath,
  createNoticeRecoveryPasswordsClientPath,
  createNoticeRecoveryPasswordsServerPath,
} from './function'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { noticePath, systemRedirectPath } from './paths'
import { NoticeEmails } from './constants'
import { InitialFetchParams } from 'server/InitialFetch'
import loadable from '@loadable/component'
import { withLoader } from 'components/system/loadable/withLoader'

export const restoreProfileServerPath = createNoticeEmailsServerPath(
  NoticeEmails.RestoreProfile
)

export const confirmEmailServerPath = createNoticeEmailsServerPath(
  NoticeEmails.ConfirmEmail
)

export const confirmProfileServerPath = createNoticeEmailsServerPath(
  NoticeEmails.ConfirmProfile
)

export const activateVipServerPath = createNoticeEmailsServerPath(
  NoticeEmails.ActivateVip
)

export interface AccountRemovalRouteParams {
  mailstat: string
  so: string
}

export const passwordRecoveryServerPath = createNoticeRecoveryPasswordsServerPath()

export interface NoticeEmailsParams extends InitialFetchParams {
  key: string
  login: string
  mailstat: string
  profileId: string
}

export const noticeEmailsRoute: AppRoute[] = [
  {
    path: mergeAllUrls(systemRedirectPath, noticePath),
    component: loadable(
      () => import('components/system/redirect/NoticeEmail/NoticeEmails'),
      withLoader
    ),
    exact: false,
  },
]

/**
 * Роуты не могут быть только для авторизованных, т.к. авторизация
 * может быть в самом урле.
 */
export const noticeEmailsRoutes: AppRoute[] = [
  {
    path: createNoticeEmailsClientPath(NoticeEmails.RestoreProfile),
    component: loadable(
      () =>
        import(
          'components/system/redirect/NoticeEmail/Emails/RestoreProfile/RestoreProfile'
        ),
      withLoader
    ),
    exact: true,
  },
  {
    path: createNoticeEmailsClientPath(NoticeEmails.ConfirmEmail),
    component: loadable(
      () =>
        import(
          'components/system/redirect/NoticeEmail/Emails/ConfirmEmail/ConfirmEmail'
        ),
      withLoader
    ),
    exact: true,
  },
  {
    path: createNoticeEmailsClientPath(NoticeEmails.ConfirmProfile),
    component: loadable(
      () =>
        import(
          'components/system/redirect/NoticeEmail/Emails/ConfirmProfile/ConfirmProfile'
        ),
      withLoader
    ),
    exact: true,
  },
  {
    path: createNoticeEmailsClientPath(NoticeEmails.ActivateVip),
    component: loadable(
      () =>
        import(
          'components/system/redirect/NoticeEmail/Emails/ActivateVip/ActivateVip'
        ),
      withLoader
    ),
    exact: true,
  },
  {
    path: createNoticeRecoveryPasswordsClientPath(),
    component: loadable(
      () =>
        import(
          'components/system/redirect/NoticeEmail/Emails/PasswordRecovery/PasswordRecovery'
        ),
      withLoader
    ),
    exact: true,
  },
]
