import { PhotoLineItem } from 'reducers/photoLine/photoLine.types'

export const multiply = (photos: PhotoLineItem[]) => {
  return photos.reduce<PhotoLineItem[]>((acc, photo) => {
    const amount = photo.amount!

    if (amount > 1) {
      const cloned = [] as PhotoLineItem[]

      for (let index = 0; index < amount; index += 1) {
        cloned.push({
          ...photo,
          key: `${photo.id}_${index}`,
          amount: 1,
        })
      }
      return [...acc, ...cloned]
    }

    photo.key = photo.id

    return [...acc, photo]
  }, [])
}
