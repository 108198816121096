import { useMemo } from 'react'
import { useShallowEqualSelector } from './useShallowEqualSelector'
import { hasEdnaSupport } from 'functions/edna/hasEdnaSupport'

export const useSupportEdna = () => {
  const { features } = useShallowEqualSelector(
    ({ systemReducer: { features } }) => ({
      features,
    })
  )

  return useMemo(() => {
    return hasEdnaSupport(features)
  }, [features])
}
