import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { setShouldAppReloadAction } from 'actions/system/checkBundleUpdateAction'
import { AsyncThunkAction } from 'actions/actions.types'
import { tryUpdatePwa } from 'functions/pwa/tryUpdatePwa'

export const installServiceWorkerAction = (): AsyncThunkAction => (
  dispatch
) => {
  if (isPwaSupported()) {
    window.addEventListener('load', async () => {
      navigator.serviceWorker.addEventListener('controllerchange', (event) => {
        console.info('Service worker changed: app should reload')
        dispatch(setShouldAppReloadAction(true))
      })

      tryUpdatePwa(() =>
        dispatch(setShouldAppReloadAction(true))
      ).catch((error) =>
        console.error('Service worker install attempt failed', error)
      )
    })
  }
}
