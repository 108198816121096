import { sendGeolocationAnalyticsThunkAction } from 'actions/geolocation/sendGeolocationAnalyticsAction'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useCallback } from 'react'
import {
  GeolocationAbTestPageName,
  GeolocationAbTestEventType,
} from './useGeolocationAbTestAnalytics.types'

export const useGeolocationAbTestAnalytics = (
  fromPageName: GeolocationAbTestPageName
) => {
  const dispatch = useAppDispatch()

  const handleEvent = useCallback(
    (type: GeolocationAbTestEventType) => {
      dispatch(sendGeolocationAnalyticsThunkAction(type, fromPageName))
    },
    [dispatch, fromPageName]
  )

  const handleGeoRequestShow = useCallback(() => {
    handleEvent('show')
  }, [handleEvent])

  const handleGeoRequestSuccess = useCallback(() => {
    handleEvent('success')
  }, [handleEvent])

  return {
    handleGeoRequestShow,
    handleGeoRequestSuccess,
  }
}
