import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'
import React from 'react'

export const HitListRedirectLoadable = loadable(
  () => import('./HitListRedirect'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Hit List Redirect error', error)
      }
      return <DefaultLoader />
    })(defaultLoadableProps),
  }
)
