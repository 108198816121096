import React, { FC } from 'react'

import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { Subscription } from './Subscription'

import { WebSocketStart } from 'components/system/WebSocketStart'

import { SubscriptionWithCursor } from 'components/layout/MambaLayout/SubscriptionWithCursor'

export const CometProvider: FC = () => {
  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  if (authorized) {
    return (
      <>
        <WebSocketStart />
        <Subscription />
        <SubscriptionWithCursor />
      </>
    )
  }

  return null
}
