import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { sendStatisticsAction } from 'actions/analytics/sendStatisticsAction'
import { createHeadersForNotifications } from 'api/push/functions'
import { unregisterPushConsumerApi } from 'api/push/unregisterPushConsumerApi'

export const UNREGISTER_PUSH_TOKEN = 'UNREGISTER_PUSH_TOKEN' as const

export interface UnregisterPushConsumerAction extends AsyncAction {
  type: typeof UNREGISTER_PUSH_TOKEN
}

export const unregisterPushConsumerAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const {
    pushNotifications: { pushNotificationsToken },
    systemReducer: { partnerId },
  } = getState()

  if (!pushNotificationsToken) {
    return
  }

  dispatch(sendStatisticsAction('open', 'notifications_off', 'notifications'))

  return dispatch<UnregisterPushConsumerAction>({
    type: UNREGISTER_PUSH_TOKEN,
    promise: () =>
      unregisterPushConsumerApi(
        pushNotificationsToken,
        createHeadersForNotifications(partnerId)
      ),
  })
}
