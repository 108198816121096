import { postApi } from 'api/fetchApi'
import { operations } from 'api/generated/auth'
import { NodeHeaders } from 'api/NodeHeaders'

type Operation = operations['auth_by_secret_authBySecret']
type Response = Operation['responses']['200']['schema']
type Body = Operation['parameters']['body']['body']

export const authorizeBySecretApi = (
  uid: string,
  secret: string,
  headers?: NodeHeaders
) => {
  return postApi<Response, Body>(
    '/authBySecret',
    {
      uid,
      secret,
    },
    { headers }
  )
}
