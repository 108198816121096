import { push } from 'functions/router'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { useShallowEqualSelector } from './useShallowEqualSelector'

/**
 * Решает следующую проблему:
 * сервер присылает нам html строкой, в которой ссылки типа:
 * <a href="https://www.mamba.ru/app/agreement">Соглашение</a>
 * делают серверные переходы.
 *
 * Этот хук навершивает свой обработчик onclick.
 */
export const useAddClientRoutingToServerHtml = () => {
  const dispatch = useDispatch()
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const jobDoneRef = useRef(false)
  const { hostname } = useShallowEqualSelector(
    ({ systemReducer: { hostname } }) => ({
      hostname,
    })
  )

  useEffect(() => {
    if (!ref || jobDoneRef.current) {
      return
    }

    iterateChildrenRecursively(ref.children, hostname, dispatch)

    jobDoneRef.current = true
  }, [dispatch, hostname, ref])

  return { setRef }
}

const setClickHandler = (
  element: HTMLAnchorElement,
  hostname: string,
  dispatch: Dispatch<any>
) => {
  const sameHostname =
    // Проверка на внешнюю ссылку
    element.hostname === hostname ||
    // В случае localhost
    element.hostname === window.location.hostname

  if (element.pathname && sameHostname) {
    element.onclick = (event) => {
      event.preventDefault()
      dispatch(push(`${element.pathname}${element.search}`))
    }
  }
}

const iterateChildrenRecursively = (
  children: HTMLCollection,
  hostname: string,
  dispatch: Dispatch<any>
) => {
  for (const child of children) {
    if (child instanceof HTMLAnchorElement) {
      setClickHandler(child, hostname, dispatch)
      continue
    }

    if (child.children) {
      iterateChildrenRecursively(child.children, hostname, dispatch)
    }
  }
}
