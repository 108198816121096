import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from '../../../presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export default loadable(() => import('./SupportFormIndex'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('form support split error', error)
    }
    return <DefaultLoader />
  })(defaultLoadableProps),
})
