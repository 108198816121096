import { InMemoryCache } from '@apollo/client/cache'

export function createInMemoryCache() {
  return new InMemoryCache({
    typePolicies: {
      /** RootQuery */
      Query: {
        fields: {
          user: {
            keyArgs: ['userId'],
          },
        },
      },
      My: {
        keyFields: [],
        fields: {
          vip: {
            merge: true,
          },
          photos: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
      MyGifts: {
        keyFields: [],
      },
      MyProfile: {
        keyFields: [],
      },
      MyVipInfo: {
        keyFields: [],
      },
      MyTravelAtlasVisitedCountries: {
        keyFields: [],
      },
      MyEncounters: {
        keyFields: [],
      },
      MyInterests: {
        keyFields: [],
        fields: {
          interests: {
            merge: false,
          },
          interestsCount: {
            merge: false,
          },
        },
      },
      PublicProfile: {
        keyFields: ['id'],
        fields: {
          relations: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          location: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          vip: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          verification: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          photos: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
          photo: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
      Comment: {
        keyFields: ['id'],
      },
      ParentComment: {
        keyFields: ['id'],
      },
      Photo: {
        keyFields: ['id'],
        fields: {
          urls: {
            merge: (existing, incoming) => {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
      Comments: {
        keyFields: [],
        fields: {
          count: {
            keyArgs: false,
            merge: (existing, incoming) => {
              return incoming
            },
          },
          countNew: {
            keyArgs: false,
            merge: (existing, incoming) => {
              return incoming
            },
          },
          comments: {
            keyArgs: false,
            merge: (existing, incoming, { args }) => {
              if ((existing && !args?.after) || !existing) {
                return incoming
              }
              if (existing && args?.after) {
                return {
                  ...existing,
                  pageInfo: incoming.pageInfo,
                  nodes: [...existing.nodes, ...incoming.nodes],
                }
              }
            },
          },
        },
      },
      HitList: {
        keyFields: [],
        fields: {
          newHitsCounters: {
            merge: (existing, incoming) => {
              return incoming
            },
          },
          hits: {
            keyArgs: ['group'],
            merge: (existing, incoming, options) => {
              if ((existing && !options?.variables?.cursor) || !existing) {
                return incoming
              }
              if (existing && options?.variables?.cursor) {
                return {
                  ...existing,
                  pageInfo: incoming.pageInfo,
                  edges: [...existing.edges, ...incoming.edges],
                }
              }
            },
          },
        },
      },
      Ui: {
        keyFields: false,
      },
    },
  })
}
