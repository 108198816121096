export const enterNamePath = 'enter-name'
export const yourGenderPath = 'enter-gender'
export const birthdayPath = 'enter-birthday'
export const selectTargetGenderPath = 'select-target-gender'
export const selectAgePath = 'select-age'
export const datingGoalPath = 'select-dating-purpose'
export const constitutionPath = 'select-constitution'
export const personHeightPath = 'person-height'
export const personWeightPath = 'person-weight'
export const finalStepPath = 'final-step'
export const ageRestrictionPath = 'age-restriction'

export const redirectPath = '/'

export const stepRegistrationInnerPaths = [
  enterNamePath,
  yourGenderPath,
  birthdayPath,
  selectTargetGenderPath,
  selectAgePath,
  datingGoalPath,
  constitutionPath,
  personHeightPath,
  personWeightPath,
  finalStepPath,
  ageRestrictionPath,
]

const stepRegistrationJoinedPaths = stepRegistrationInnerPaths.join('|')

export const stepRegistrationPath = '/step-registration'

export const stepRegistrationStepPath = `/:step(${stepRegistrationJoinedPaths})`
