export const sharedHost = 'social.wambacdn.net'
export const sharedFullHost = `https://${sharedHost}`
export const mambaPartnerId = 4745247

export enum VkRedirectPlace {
  authorization = 'authorization',
  photo = 'photo',
}

export const baseUrlLocalStorageKey = 'baseUrl'
