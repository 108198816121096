import { isLoveMailru, isMamba, isYonja } from 'common/constants'
import { createApi6Header } from 'functions/createApi6Header'

export const createHeadersForNotifications = (partnerId: number) => {
  return {
    'Mamba-Client': createApi6Header(getBrand(partnerId)),
  }
}

/**
 * Возвращает id бренда. Не путать с partnerId.
 * При включении поддержки пушей на других партнеров,
 * нужно узнавать у серверсайда id брендов для этих партнеров.
 *
 * Поддержка партнера определяется функцией isPushNotificationsEnabledForPartner
 */
const getBrand = (partnerId: number) => {
  if (isMamba(partnerId)) {
    return 'Mamba'
  }
  if (isLoveMailru(partnerId)) {
    return 'Mailru'
  }
  if (isYonja(partnerId)) {
    return 'Yonja'
  }

  console.error('Brand is not defined')
}
