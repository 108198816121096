import {
  CONVERT_DIAMONDS_TO_COIN,
  FETCH_CURRENT_DIAMONDS_AND_SETTINGS,
  FETCH_CURRENT_SETTINGS,
  UPDATE_ACTIVE_TARIFF,
} from 'actions/cashOutDiamonds/cashOutDiamondsAction'
import { defaultPromiseReducer } from 'reducers/isActionWithoutErrors'

export const COIN_ITEM_TARIFF = 0
export const CASH_ITEM_TARIFF = 1

export default function cashOutStreamReducer(
  state = {
    diamondsTotal: 0,
    diamondsAwaitApproval: 0,
    currentRequest: {
      moneyAmount: 0,
      diamondsAmount: 0,
      requestedAt: '',
    },
    settingsPersonalLoaded: false,
    settingsDiamondsLoaded: false,
    diamondToCoinDeposit: 0,
    diamondToCoinWithdrawalRate: 0,
    diamondToCashWithdrawalRate: 0,
    minMoneyWithdrawal: 0,
    tariffsList: [
      {
        coins: null,
        id: 'coin',
        chosen: true,
        price: '',
        profit: null,
        description: '',
        isInActive: false,
        rate: null,
      },
      {
        coins: null,
        id: 'cash',
        chosen: false,
        price: null,
        profit: null,
        description: '',
        isInActive: false,
        rate: null,
      },
    ],
  },
  action
) {
  const { type, result, indexTariff } = action
  switch (type) {
    case FETCH_CURRENT_DIAMONDS_AND_SETTINGS:
      return defaultPromiseReducer(
        state,
        action,
        () => null,
        () => {
          const {
            rates: {
              diamondToCoinWithdrawalRate,
              diamondToCashWithdrawalRate,
              lowerMoneyWithdrawThreshold,
            },
          } = result
          const coins = result.diamondsWithdrawableToCoins
          const diamondsWithdrawableToCash = result.diamondsWithdrawableToCash
          const current = result.currentWithdrawRequest
          return {
            currentRequest: current,
            diamondsTotal: result.diamondsTotal,
            diamondsAwaitApproval: result.diamondsAwaitApproval,
            settingsPersonalLoaded: true,
            diamondToCoinWithdrawalRate: diamondToCoinWithdrawalRate,
            diamondToCashWithdrawalRate: diamondToCashWithdrawalRate,
            currency: result.currency,
            minMoneyWithdrawal: lowerMoneyWithdrawThreshold,
            tariffsList: [
              {
                ...state.tariffsList[COIN_ITEM_TARIFF],
                coins: coins,
                isInActive: coins < diamondToCoinWithdrawalRate,
                rate: diamondToCoinWithdrawalRate,
              },
              {
                ...state.tariffsList[CASH_ITEM_TARIFF],
                coins: diamondsWithdrawableToCash,
                isInActive:
                  checkCurrentRequest(current) ||
                  diamondsWithdrawableToCash < diamondToCashWithdrawalRate,
                rate: diamondToCashWithdrawalRate,
              },
            ],
          }
        }
      )

    case CONVERT_DIAMONDS_TO_COIN:
      return {
        ...state,
        diamondsTotal: 0,
        diamondsAwaitApproval: 0,
        currentRequest: {
          moneyAmount: 0,
          diamondsAmount: 0,
          requestedAt: '',
        },
        diamondToCoinDeposit: 0,
        minMoneyWithdrawal: 0,
        tariffsList: [
          {
            coins: null,
            id: 'coin',
            chosen: true,
            price: null,
            profit: null,
            description: '',
            isInActive: false,
            rate: null,
          },
          {
            coins: null,
            id: 'cash',
            chosen: false,
            price: null,
            profit: null,
            description: '',
            isInActive: false,
            rate: null,
          },
        ],
        settingsPersonalLoaded: false,
      }

    case FETCH_CURRENT_SETTINGS:
      return defaultPromiseReducer(
        state,
        action,
        () => null,
        () => {
          const { rates } = result

          return {
            settingsDiamondsLoaded: true,
            diamondToCoinWithdrawalRate: rates.diamondToCoinWithdrawalRate,
            diamondToCashWithdrawalRate: rates.diamondToCashWithdrawalRate,
            currency: result.currency,
            minMoneyWithdrawal: rates.lowerMoneyWithdrawThreshold,
          }
        }
      )

    case UPDATE_ACTIVE_TARIFF:
      if (indexTariff === COIN_ITEM_TARIFF)
        return {
          ...state,
          tariffsList: updateChoseTariff(
            state.tariffsList,
            COIN_ITEM_TARIFF,
            CASH_ITEM_TARIFF
          ),
        }
      else {
        return {
          ...state,
          tariffsList: updateChoseTariff(
            state.tariffsList,
            CASH_ITEM_TARIFF,
            COIN_ITEM_TARIFF
          ),
        }
      }
    default:
      return state
  }
}

function updateChoseTariff(arrayTariff, chosingTariff, cancelTariff) {
  arrayTariff[chosingTariff].chosen = true
  arrayTariff[cancelTariff].chosen = false
  return [...arrayTariff]
}

function checkCurrentRequest(current) {
  if (current === undefined) {
    return false
  } else {
    return !!current.moneyAmount
  }
}
