import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const useLocale = (): string => {
  const { locale } = useShallowEqualSelector(
    ({ systemReducer: { locale } }) => ({
      locale,
    })
  )

  return locale
}
