export const redirectToFacebookOAuthPath = 'redirect-to-fb-oauth'
export const facebookPhotoUploadPath = 'fb-photo-upload'
export const facebookHostUrl = 'https://graph.facebook.com/'
export const noToken = 'no-token'

export const profileFacebookPhotoUploadPath = `profile/${facebookPhotoUploadPath}`

export enum FacebookQueryStringStateKeys {
  /** Нужен, чтобы вернуться на домент партнера после авторизации в фейсбуке */
  PartnerHost = 'partnerHost',
  /** Нужен, чтобы точно определить редирект с фейсбука */
  FromFacebook = 'fromFacebook',
  Location = 'location',
}

export interface FacebookQueryStringState {
  [FacebookQueryStringStateKeys.PartnerHost]: string
  [FacebookQueryStringStateKeys.FromFacebook]: string
  [FacebookQueryStringStateKeys.Location]: string
}
