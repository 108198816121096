import { socialCdnDomain } from 'common/constants'

export const wambaCdn = '//' + socialCdnDomain

export const paramsAlbumsClassMate = [
  'album.aid',
  'album.title',
  'album.photos_count',
]
export const paramsPhotosClassMate = [
  'photo.id',
  'photo.album_id',
  'photo.pic50x50',
  'photo.pic128x128',
  'photo.pic128max',
  'photo.pic180min',
  'photo.pic240min',
  'photo.pic320min',
  'photo.pic190x190',
  'photo.pic640x480',
  'photo.pic1024x768',
  'photo.standard_width',
  'photo.standard_height',
  'photo.pic_base',
  'photo.pic_max',
]
export const urlClassMateProxyPath = '/upload/odnoklassniki/proxy.phtml'
