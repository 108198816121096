import { Workbox } from 'workbox-window'
import { serviceWorkerPath } from 'common-constants/client-service.paths'

export const tryUpdatePwa = async (
  onFinished: () => void
): Promise<boolean> => {
  try {
    const wb = new Workbox(serviceWorkerPath)

    /** 3. Когда сервис воркер включился, сообщаем о готовности (например, перезагружаем страницу "с диска") */
    wb.addEventListener('controlling', onFinished)

    wb.addEventListener('waiting', (event) => {
      /** 2. Говорим сервис воркеру, что можно активироваться */
      wb.messageSkipWaiting()
    })

    const registration = await wb.register()

    /** 1. Начало установки новых ассетов и самого нового сервис воркера */
    await wb.update()

    /** Первая установка */
    wb.addEventListener('installed', () => {
      console.info('Try update PWA: installed')
      onFinished()
    })

    /** Шлем сразу, если уже ожидает */
    if (registration?.waiting) {
      wb.messageSkipWaiting()
      onFinished()
    }

    return true
  } catch (error) {
    console.error('Try update PWA: error', error)
  }

  return false
}
