import {
  AbTestActionTypes,
  FETCH_AB_TEST_GROUP,
  FETCH_AB_TEST_GROUP_LIST,
} from 'actions/abTestAction'
import { AbTestName } from 'api/abTestApi'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import {
  AbTestFeaturedPhotosHits,
  AbTestMessagesFromLiked,
  AbTestHideAdvBlock,
  AbTestNewContactsBlock,
  AbTestPhotoUploadGroups,
  AbTestPromoCodeReg,
  AbTestGroupНitHiddenData,
  AbTestGeolocationRequestGroup,
} from 'reducers/system/abTestReducer.constants'
import { Reducer } from 'redux'
import { reduceAbTestList } from './system.functions'
import { LOGOUT_ACTION } from 'actions/authorization/logoutAction'

export const abTestReducer: Reducer<AbTestState, AbTestActionTypes> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case FETCH_AB_TEST_GROUP:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          [action.testName]: undefined,
        }),
        (result) => {
          if (result) {
            return {
              [action.testName]: result.name,
            }
          }
          return state
        },
        () => ({
          [action.testName]: undefined,
        })
      )

    case FETCH_AB_TEST_GROUP_LIST:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (result.groups) {
          return reduceAbTestList(result.groups)
        }
        return state
      })

    case LOGOUT_ACTION: {
      return defaultPromiseReducer(state, action, undefined, () => {
        return INITIAL_STATE
      })
    }

    default:
      return state
  }
}

const INITIAL_STATE = {
  [AbTestName.FeaturedPhotosHits]: null as
    | null
    | AbTestFeaturedPhotosHits
    | undefined,
  [AbTestName.PhotoUpload]: null as null | AbTestPhotoUploadGroups | undefined,
  [AbTestName.PromoCodeReg]: null as null | AbTestPromoCodeReg | undefined,
  [AbTestName.NewContactsBlock]: null as
    | null
    | AbTestNewContactsBlock
    | undefined,
  [AbTestName.HideAdvBlock]: null as null | AbTestHideAdvBlock | undefined,
  [AbTestName.НitsHiddenData]: null as
    | null
    | AbTestGroupНitHiddenData
    | undefined,
  [AbTestName.BestOptionForRequestingLocation]: null as
    | null
    | AbTestGeolocationRequestGroup
    | undefined,
  [AbTestName.MessagesFromLiked]: null as
    | null
    | AbTestMessagesFromLiked
    | undefined,
} as const

export type AbTestState = typeof INITIAL_STATE
