import { AsyncThunkAction, PlainAction } from 'actions/actions.types'

export const RESET_ERROR = 'RESET_ERROR'

interface ResetErrorAction extends PlainAction {
  type: typeof RESET_ERROR
}

export const resetErrorAction = () => ({
  type: RESET_ERROR,
})

export const RESET_BLOCKED_TRACK = 'RESET_BLOCKED_TRACK'

interface ResetBlockedTrackAction extends PlainAction {
  type: typeof RESET_BLOCKED_TRACK
}

export const resetBlockedTrackAction = () => ({
  type: RESET_BLOCKED_TRACK,
})

export const RESET_BLOCKED_PERSONAL = 'RESET_BLOCKED_PERSONAL'

interface ResetBlockedPersonalAction extends PlainAction {
  type: typeof RESET_BLOCKED_PERSONAL
}

export const resetBlockedPersonalAction = () => ({
  type: RESET_BLOCKED_PERSONAL,
})

export const RESET_BLOCKED_PROFILE = 'RESET_BLOCKED_PROFILE'

interface ResetBLockedProfileAction extends PlainAction {
  type: typeof RESET_BLOCKED_PROFILE
}

export const resetBLockedProfileAction = () => ({
  type: RESET_BLOCKED_PROFILE,
})

export const RESET_FIRST_TIME_REAL = 'RESET_FIRST_TIME_REAL'

interface ResetFirstTimeRealAction extends PlainAction {
  type: typeof RESET_FIRST_TIME_REAL
}

export const resetFirstTimeRealAction = () => ({
  type: RESET_FIRST_TIME_REAL,
})

export const FORCE_CAPTCHA = 'FORCE_CAPTCHA'

interface ForceCaptchaAction extends PlainAction {
  type: typeof FORCE_CAPTCHA
}

export const forceCaptchaAction = () => ({
  type: FORCE_CAPTCHA,
})

export const RESET_PROFILE_ERROR_DATA = 'RESET_PROFILE_ERROR_DATA' as const

export const resetProfileErrorDataAction = () => ({
  type: RESET_PROFILE_ERROR_DATA,
})

export const SET_ERROR_FROM_GQL = 'SET_ERROR_FROM_GQL' as const

export const setErrorFromGQLAction = (value: string) => ({
  type: SET_ERROR_FROM_GQL,
  value,
})

export const RESET_REAL_STATUS = 'RESET_REAL_STATUS' as const

export const resetRealStatusAction = () => ({
  type: RESET_REAL_STATUS,
})

/**
 * Сбрасываем все ошибки, чтобы они не "заморозились" в шелл.
 */
export const resetErrorsAction = (): AsyncThunkAction => (dispatch) => {
  dispatch(resetBlockedTrackAction())
  dispatch(resetBlockedPersonalAction())
  dispatch(resetErrorAction())
  dispatch(resetRealStatusAction())
}

export type ErrorActionTypes =
  | ForceCaptchaAction
  | ResetFirstTimeRealAction
  | ResetErrorAction
  | ResetBlockedTrackAction
  | ResetBlockedPersonalAction
  | ResetBLockedProfileAction
  | ReturnType<typeof resetProfileErrorDataAction>
  | ReturnType<typeof resetRealStatusAction>
  | ReturnType<typeof setErrorFromGQLAction>
