import React, { FC } from 'react'
import styled from 'styled-components'

import MambaPreloaderWhiteSvgUrl from 'components/presentational/AnimatedWatch/pwa/MambaPreloaderWhiteSvg.svg'
import MambaPreloaderOrangeSvgUrl from 'components/presentational/AnimatedWatch/pwa/MambaPreloaderOrangeSvg.svg'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const mapFillToUrlPreloaderSvg: Record<string, string> = {
  '#ed6b0b': MambaPreloaderOrangeSvgUrl,
  white: MambaPreloaderWhiteSvgUrl,
  '#fff': MambaPreloaderWhiteSvgUrl,
  '#ffffff': MambaPreloaderWhiteSvgUrl,
}

export interface MambaPreloaderSvgProps {
  fill: string
  size: number
  className?: string
}
export const MambaPreloaderSvg: FC<MambaPreloaderSvgProps> = ({
  fill = '#ed6b0b',
  className,
  size,
}) => (
  <Wrapper>
    <img
      className={className}
      src={mapFillToUrlPreloaderSvg[fill]}
      width={size}
      height={size}
      alt={'Mamba Preloader'}
    />
  </Wrapper>
)
