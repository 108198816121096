import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/notifications_pushes'

type Method = paths['/notifications/registerPushConsumer']['post']

export const registerPushConsumerApi = (
  params: {
    token: string
    locale: string
    osName: string
  },
  headers
) => {
  const { token, osName } = params

  return postApi<void, Method['parameters']['body']['body']>(
    '/notifications/registerPushConsumer',
    {
      token,
      dev: false,
      isVoip: false,
      // Обсудили с Чижиковым, что можно пустую строку,
      // будет браться серверный язык
      language: '',
      // Обсудили с Ратушным, что подойдет просто название ОСи
      osVersion: osName,
    },
    { headers }
  )
}
