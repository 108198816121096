/**
 * Возвращает true для версии 15.4 и выше
 * @TODO Удалить этот код, если Apple пофиксит баг со скроллом в следующих версиях
 */
export const isCheesyDesktopSafariVersion = (version: string) => {
  if (!version || (version && isNaN(Number(version)))) {
    return
  }

  // На версии 15.4 перестал скроллиться контейнер со scroll({ behavior: 'smooth' })
  return Number(version) >= 15.4
}
