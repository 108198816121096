import { partnerDataApi, sendCubeStatApi } from 'api/system/systemApi'
import { resolveOsType } from 'functions/index'
import { push } from 'functions/router'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { ClientType, CommonInitialData } from 'common/types'
import {
  loginPath,
  registrationPath,
} from 'components/page/Boarding/boarding.paths'
import { definitions } from 'api/generated/uniweb'
import {
  AsyncAction,
  AsyncThunkAction,
  AsyncThunkAction2,
  PlainAction,
} from 'actions/actions.types'
import { StartScreen } from 'common/constantsStartScreen'
import { OsTypes } from 'common-constants/userAgent'
import { fetchLocaleApi } from 'api/system/fetchLocaleApi'
import { fakeAdsApi } from 'api/system/fakeAdsApi'
import { fullPageReload } from 'functions/fullPageReload'
import { NodeHeaders } from 'api/NodeHeaders'

export const CREATE_BASE_URL = 'CREATE_BASE_URL'

export const SWITCH_LANDING_TYPE = 'SWITCH_LANDING_TYPE'

interface SwitchLandingTypeAction {
  type: typeof SWITCH_LANDING_TYPE
  landingType: string
}

export const switchLandingTypeAction = (landingType: string) => ({
  type: SWITCH_LANDING_TYPE,
  landingType,
})

export interface ScreenData {
  startScreen: StartScreen | null
}

export const UPDATE_START_SCREEN = 'UPDATE_START_SCREEN'

interface UpdateStartScreenAction extends PlainAction {
  type: typeof UPDATE_START_SCREEN
  payload: StartScreen | null
}

export const updateStartScreenAction = (screenData: ScreenData | null) => ({
  type: UPDATE_START_SCREEN,
  payload: screenData === null ? null : screenData.startScreen,
})

export const SET_META = 'SET_META'

interface SetMetaDataAction extends PlainAction {
  type: typeof SET_META
  meta: definitions['PageMetaInfo']
}

export const setMetaDataAction = (meta: definitions['PageMetaInfo']) => ({
  type: SET_META,
  meta,
})

export const DISABLE_JAVASCRIPT = 'DISABLE_JAVASCRIPT'

interface DisableJavaScriptAction extends PlainAction {
  type: typeof DISABLE_JAVASCRIPT
}

export const disableJavaScriptAction = (
  force: boolean = false
): AsyncThunkAction2 => (dispatch, getState) => {
  const {
    authorizationReducer: { authorized },
  } = getState()

  if (force || !authorized) {
    return dispatch({
      type: DISABLE_JAVASCRIPT,
    })
  }
}

export const FETCH_LOCALE = 'FETCH_LOCALE'

interface FetchLocaleAction extends AsyncAction<definitions['Language']> {
  type: typeof FETCH_LOCALE
}

const localeAction = (): AsyncThunkAction2<
  ReturnType<typeof fetchLocaleApi>
> => async (dispatch) => {
  return dispatch({
    type: FETCH_LOCALE,
    promise: () => fetchLocaleApi(),
  })
}

export const fetchLocaleAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const {
    systemReducer: { locale },
  } = getState()

  const { result, error } = await dispatch<
    ReturnType<ReturnType<typeof localeAction>>
  >(localeAction())

  if (!error && result?.code !== locale) {
    fullPageReload()
  }

  return result
}

export const UPDATE_CLIENT = 'UPDATE_CLIENT'

interface UpdateClientAction {
  type: typeof UPDATE_CLIENT
  clientType: ClientType
}

export const updateClientAction = (clientType: ClientType) => ({
  type: UPDATE_CLIENT,
  clientType,
})

export const UPDATE_COMMON_DATA = 'UPDATE_COMMON_DATA' as const

interface UpdateCommonDataAction extends PlainAction {
  type: typeof UPDATE_COMMON_DATA
  common: {
    osType: OsTypes | null
    ios: boolean
    boarding: boolean
  } & CommonInitialData
}

export const updateCommonDataAction = ({
  userAgent,
  ...rest
}: CommonInitialData) => {
  const osType = resolveOsType(userAgent)

  return {
    type: UPDATE_COMMON_DATA,
    common: {
      ios: osType === 'ios',
      ...rest,
      boarding:
        [3, 81449119, 958134831, 963463432, 1374393173, 1374393182].indexOf(
          rest.partnerId
        ) > -1,
    },
  }
}

export const UPDATE_PARTNER = 'UPDATE_PARTNER'

interface UpdatePartnerAction {
  type: typeof UPDATE_PARTNER
  partner: definitions['PartnerShortInfo']
}

export const updatePartnerAction = (
  partner: definitions['PartnerShortInfo']
) => ({
  type: UPDATE_PARTNER,
  partner,
})

export const UPDATE_COOKIES_BANNER_RULES_BOTTOM =
  'UPDATE_COOKIES_BANNER_RULES_BOTTOM'

interface UpdateSystemCookiesBannersAction extends PlainAction {
  type: typeof UPDATE_COOKIES_BANNER_RULES_BOTTOM
}

export const updateSystemCookiesBannersAction = () => ({
  type: UPDATE_COOKIES_BANNER_RULES_BOTTOM,
})

export const UPDATE_COOKIE_MOBILE_APP_BANNER = 'UPDATE_COOKIE_MOBILE_APP_BANNER' as const

export const updateCookieMobileAppBannerAction = () => ({
  type: UPDATE_COOKIE_MOBILE_APP_BANNER,
})

export const FETCH_FAKE_ADS = 'FETCH_FAKE_ADS'

interface FetchFakeAdsAction extends AsyncAction {
  type: typeof FETCH_FAKE_ADS
}

export const fetchFakeAdsAction = (adsUrl: string): FetchFakeAdsAction => ({
  type: FETCH_FAKE_ADS,
  promise: () => fakeAdsApi(adsUrl),
  critical: false,
})

export const SET_SHOW_ADBLOCK_BANNER = 'SET_SHOW_ADBLOCK_BANNER'

interface SetShowAdblockBannerAction extends PlainAction {
  type: typeof SET_SHOW_ADBLOCK_BANNER
  value: boolean
}

export const setShowAdblockBannerAction = (value: boolean) => ({
  type: SET_SHOW_ADBLOCK_BANNER,
  value,
})

export const SEND_CUBESTAT = 'SEND_CUBESTAT'

export const sendCubeStatLandingAction = (
  testName: string,
  testGroup: string
) => ({
  type: SEND_CUBESTAT,
  promise: () => sendCubeStatApi(testName, testGroup),
})

export const SET_LANDING_PAGE = 'SET_LANDING_PAGE'

interface SetLandingPageAction {
  type: typeof SET_LANDING_PAGE
}

export const setLandingPageAction = () => ({
  type: SET_LANDING_PAGE,
})

export const SET_DEFAULT_PAGE = 'SET_DEFAULT_PAGE'

interface SetDefaultPageAction extends PlainAction {
  type: typeof SET_DEFAULT_PAGE
}

export const setDefaultPageAction = () => ({
  type: SET_DEFAULT_PAGE,
})

export const correctRedirectLoveMailRuAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const { authorizationReducer, systemReducer } = getState()

  const { needRegistrationLoveMailRu, loveMailruAuth } = authorizationReducer
  const { baseUrl } = systemReducer

  if (needRegistrationLoveMailRu) {
    dispatch(push(mergeAllUrls(baseUrl, loginPath)))
  }

  if (loveMailruAuth) {
    dispatch(push(mergeAllUrls(baseUrl, registrationPath)))
  }
}

export const FETCH_PARTNER_DATA = 'FETCH_PARTNER_DATA' as const

interface FetchPartnerDataAction
  extends AsyncAction<definitions['PartnerShortInfo']> {
  type: typeof FETCH_PARTNER_DATA
}

export const fetchPartnerDataAction = (
  headers?: NodeHeaders
): AsyncThunkAction => (dispatch, getState) => {
  const {
    systemReducer: { locale },
  } = getState()

  return dispatch({
    type: FETCH_PARTNER_DATA,
    promise: () => partnerDataApi(locale, headers),
  })
}

export const UPDATE_REFERRER = 'UPDATE_REFERRER' as const

export const updateReferrerAction = (referrer: string) => ({
  type: UPDATE_REFERRER,
  referrer,
})

export type SystemActionTypes =
  | UpdatePartnerAction
  | SetLandingPageAction
  | DisableJavaScriptAction
  | UpdateStartScreenAction
  | SetMetaDataAction
  | FetchLocaleAction
  | UpdateClientAction
  | UpdateCommonDataAction
  | SwitchLandingTypeAction
  | UpdateSystemCookiesBannersAction
  | SetShowAdblockBannerAction
  | FetchFakeAdsAction
  | SetDefaultPageAction
  | FetchPartnerDataAction
  | ReturnType<typeof updateReferrerAction>
