import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/phone_verification'

type Post = paths['/phone_verification/code/verify']['post']
type Response = Post['responses']['204']['schema']
type Body = Post['parameters']['body']['body']

export const phoneVerificationCodeVerifyApi = (code: string) => {
  return postApi<Response, Body>('/phone_verification/code/verify', {
    code,
  })
}
