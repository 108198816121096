import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/notifications_pushes'

type Method = paths['/notifications/unregisterPushConsumer']['post']

export const unregisterPushConsumerApi = (token: string, headers) => {
  return postApi<void, Method['parameters']['body']['body']>(
    '/notifications/unregisterPushConsumer',
    { token },
    { headers }
  )
}
