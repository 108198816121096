import { createContext } from 'react'

export interface UniNoticeContextValue {
  /** Текст может быть не безопасным, например XSS */
  notSafe: boolean
}

export const UniNoticeContext = createContext<UniNoticeContextValue>({
  notSafe: false,
})
