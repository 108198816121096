import { postApi } from 'api/fetchApi'
import { SearchRequest } from './search.types'
import { paths } from 'api/generated/search'

type Method = paths['/search/options']
type PostBody = Method['post']['parameters']['body']['body']
type Answer = Method['post']['responses']['204']

export const saveSearchSettingListApi = (form: SearchRequest) => {
  return postApi('/search/options', {
    request: {
      ...form,
    },
  })
}
