import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { SettingsForm } from 'reducers/settings/SettingsReducerState'
import { goBackAction } from 'actions/route/routeAction'
import { push } from 'functions/router'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { updateShellAction } from 'actions/pwa/updateShellAction'
import { fullPageReload } from 'functions/fullPageReload'
import { findUpdateApiMethod } from 'actions/findUpdateApiMethod'
import { hasServiceWorkerStatusActivated } from 'functions/pwa/hasServiceWorkerStatusActivated'

export const UPDATE_SETTING = 'UPDATE_SETTING'

export interface UpdateSettingAction extends AsyncAction {
  type: typeof UPDATE_SETTING
  field: keyof SettingsForm
  value: string
}

export const updateSettingAction = (
  field: keyof SettingsForm,
  returnUrl: string | null
): AsyncThunkAction => async (dispatch, getState) => {
  const { settingsReducer } = getState()
  if (!settingsReducer.lockUpdates[field]) {
    const value = settingsReducer.form[field]

    const { result: json } = ((await dispatch({
      type: UPDATE_SETTING,
      field,
      value,
      promise: () => findUpdateApiMethod(field, value as string),
    })) as unknown) as { result: unknown }

    if (!json) {
      return
    }

    // TODO вынести во View проверку на поле
    if (field === 'languageId') {
      dispatch(goBackAction())
      /**
       * Может быть такое что пользователь очень быстро
       * дошел до данного пункт
       */
      if (isPwaSupported() && hasServiceWorkerStatusActivated()) {
        dispatch(updateShellAction({ reload: true }))
      } else {
        setTimeout(fullPageReload, 100)
      }
      return
    }

    if (returnUrl) {
      dispatch(push(returnUrl))
      return
    }

    dispatch(goBackAction())
  }
}
