export const loginPath = 'login'
export const restorePasswordPath = 'restore-password'
export const restorePasswordEmailSuccessPath = '/restore-password/email-success'
export const restorePasswordPhoneSuccessPath = 'restore-password/phone-success'
export const restorePasswordEnterPhoneCodePath =
  'restore-password/enter-phone-code'
export const phoneResetSuccessPath = 'phone-success'
export const emailResetSuccessPath = 'email-success'

export const selectGenderPath = 'select-gender'
export const enterNamePath = 'enter-name'
export const enterBirthdayPath = 'enter-birthday'
export const selectDatingPurposePath = 'select-dating-purpose'
export const finalStepPath = 'final-step'
export const changeLanguagePath = 'change-language'
