import { definitions } from 'api/generated/helpdesk_edna'
import { ModerationStatus } from 'api/photo/photo.types'
import { Gender } from 'common-constants/api6.types'
import { GQLGender } from 'generated-graphql/graphql'

export interface UserState {
  /** @deprecated authorizationReducer.profile.id */
  userId: number | null
  smallPhoto: string | null
  photoStatus: ModerationStatus | null
  messagesCounter: number
  accountBalance: number
  vip: boolean
  login: string | null
  incognito: boolean
  notifications: number
  location: string
  details:
    | {
        meetingGoalsExtended: string[]
        interests: string[]
      }
    | undefined
  email: string
  place: number | null
  name: string | null
  albumId: number | null
  albumIdMain: number | null
  albumIdMainLoading: boolean
  albumIdMainLoaded: boolean
  profileInfoLoading: boolean
  hasDefaultPhoto: boolean | null
  gender: Gender | GQLGender | null
  smallPhotoMasked?: string
  activityDayCounter: number
  activityMonthCounter: number
  countryId: number
  promoMtsAvailable: boolean | null
  promoMtsStatusLoading: boolean
  promoMtsStatus: null | string
  promoMtsSendingStatus: boolean | null

  autoDetectEnabled: boolean

  supportHelpDeskEddy: boolean | null
  supportHelpDeskEddyUserId: string | null

  supportEdnaInformation: definitions['HelpdeskEdnaClientData'] | null
  newMessageSupportEdna: boolean
}

export const initialState: UserState = {
  userId: null,
  smallPhoto: null,
  messagesCounter: 0,
  accountBalance: 0,
  photoStatus: null,
  vip: false,
  login: null,
  incognito: false,
  hasDefaultPhoto: null,
  notifications: 0,
  location: '',
  details: {
    meetingGoalsExtended: [],
    interests: [],
  },
  email: '',
  place: null,
  name: null,
  albumId: null,
  albumIdMain: null,
  albumIdMainLoading: false,
  albumIdMainLoaded: false,
  profileInfoLoading: false,
  gender: null,
  activityDayCounter: 0,
  activityMonthCounter: 0,
  countryId: 0,

  promoMtsAvailable: null,
  promoMtsStatusLoading: true,
  promoMtsStatus: null,
  promoMtsSendingStatus: null,

  autoDetectEnabled: false,

  supportHelpDeskEddy: null,
  supportHelpDeskEddyUserId: null,

  supportEdnaInformation: null,
  newMessageSupportEdna: false,
}
