import { SocketReceiveDataAction } from 'actions/socket/socketReceiveDataAction'
import { PHONE_VERIFICATION_MOBILE_ID_SUCCESS_COMET_CHANNEL } from 'functions/comet'
import { SettingsReducerState } from './SettingsReducerState'

export const getSocketReceiveDataCaseHandler = (
  state: SettingsReducerState,
  action: SocketReceiveDataAction
): SettingsReducerState => {
  const channel = action.result?.[0]?.channel

  if (!channel) {
    return state
  }

  if (channel.startsWith(PHONE_VERIFICATION_MOBILE_ID_SUCCESS_COMET_CHANNEL)) {
    return {
      ...state,
      phoneVerificationMobileIdSuccess: true,
    }
  }

  return state
}
