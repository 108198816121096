import { ImageContainerComponent, Text, Title } from './UniNoticeView.styled'
import { ImageCenter } from './UniNoticeImage'
import React, {
  FC,
  isValidElement,
  ReactChildren,
  ReactNode,
  useContext,
} from 'react'
import { UniNoticeImages } from 'api/uninotice/uninotice.types'
import { UniNoticeContext } from 'components/uninotice/UniNotice.context'
import { useAddClientRoutingToServerHtml } from 'hooks/useAddClientRoutingToServerHtml'

const CommonText: FC<{
  noticeId: string
  text: ReactNode
  color: string
}> = ({ noticeId, text, color }) => {
  const { setRef } = useAddClientRoutingToServerHtml()
  const { notSafe } = useContext(UniNoticeContext)

  if (notSafe || isValidElement(text)) {
    return (
      <Text ref={setRef} data-name={`uninotice-text-${noticeId}`} color={color}>
        {text}
      </Text>
    )
  }

  return (
    <Text
      ref={setRef}
      data-name={`uninotice-text-${noticeId}`}
      dangerouslySetInnerHTML={{
        __html: text as string,
      }}
      color={color}
    />
  )
}

export const UniNoticeContentCommon: FC<{
  noticeId: string
  color?: string
  title?: ReactNode
  imageComponent?: ReactNode
  imageSource?: UniNoticeImages
  text?: ReactNode
  textComponent?: ReactNode
}> = ({
  title,
  text,
  textComponent,
  imageSource,
  imageComponent,
  noticeId,
  color = 'black',
}) => {
  return (
    <>
      <Title data-name={`uninotice-title-${noticeId}`} color={color}>
        {title}
      </Title>
      {imageSource && <ImageCenter image={imageSource} />}
      {imageComponent && (
        <ImageContainerComponent>{imageComponent}</ImageContainerComponent>
      )}
      <>
        {/* TODO точно может быть и текст и текст компонент? */}
        {text && <CommonText noticeId={noticeId} text={text} color={color} />}
        {textComponent && (
          <Text data-name={`uninotice-text-${noticeId}`} color={color}>
            {textComponent}
          </Text>
        )}
      </>
    </>
  )
}
