import { NumberRange } from 'reducers/registration/stepRegistrationReducer'
import { DatingGoals, Constitution, Gender } from 'common-constants/api6.types'
import { fetchSearchSettingsApi } from 'api/search/fetchSearchSettingsApi'
import { saveSearchSettingListApi } from 'api/search/saveSearchSettingListApi'
import { changeDatingProfileApi } from 'api/profile/changeDatingProfileApi'
import {
  FIELD_NAME_DATING_GOALS,
  FIELD_NAME_LOOK_FOR,
  FIELD_NAME_LOOK_FOR_AGE_RANGE,
} from 'components/page/DatingProfile/DatingProfile.constants'

const saveSearchSettingsApi = async (
  targetGender: Gender[] | Gender | null,
  age: NumberRange | null,
  datingGoal: DatingGoals | null,
  constitutions: Constitution[],
  personHeight: NumberRange | null,
  personWeights: NumberRange | null
) => {
  const { result: current } = await fetchSearchSettingsApi()
  if (targetGender) {
    current!.lookFor = targetGender
  }
  if (age) {
    current!.ageFrom = age.from!
    current!.ageTo = age.to!
  }
  current!.target = [datingGoal!]

  return saveSearchSettingListApi(current!)
}

export const saveStepRegistrationDataApi = (
  targetGender: Gender[] | Gender | null,
  age: NumberRange | null,
  datingGoal: DatingGoals | null,
  constitutions: Constitution[],
  personHeight: NumberRange | null,
  personWeight: NumberRange | null
) => {
  const promises = [
    saveSearchSettingsApi(
      targetGender,
      age,
      datingGoal,
      constitutions,
      personHeight,
      personWeight
    ),
    changeDatingProfileApi(FIELD_NAME_DATING_GOALS, datingGoal!),
    changeDatingProfileApi(FIELD_NAME_LOOK_FOR, targetGender as string),
  ]
  if (age) {
    promises.push(changeDatingProfileApi(FIELD_NAME_LOOK_FOR_AGE_RANGE, age))
  }
  return Promise.all(promises)
}
