import { details, features } from 'api/api.constants'
import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { postApi } from 'api/fetchApi'
import { paths as systemSettings } from 'api/generated/system_settings'
import { createApi6Header } from 'functions/createApi6Header'

type Response = systemSettings['/features']['post']['responses']['200']['schema']
type Body = systemSettings['/features']['post']['parameters']['body']['body']

export const fetchFeaturesApi = (headers?: Api6NodeHeaders) => {
  // Для данного запроса brand значения не имеет, поэтому передаем Mamba.
  // https://youtrack.mamba.ru/issue/M-5137#focus=Comments-4-34335.0-0
  const mambaClientHeader = createApi6Header('Mamba')

  return postApi<Response, Body>(
    '/features',
    {
      features,
      details,
    },
    {
      headers: ({
        ...headers,
        'Mamba-Client': process.env.browser
          ? mambaClientHeader
          : JSON.stringify(mambaClientHeader),
      } as unknown) as Api6NodeHeaders,
    }
  )
}
