import React, { FC } from 'react'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { useParams } from 'react-router-dom'
import { LocaleParams } from 'components/components.types'
import { mergeAllUrls } from 'functions/mergeAllUrls'

/**
 * Редирект с сохранением локали для неавторизованного.
 * @param uid
 * @param to
 * @param status
 * @constructor
 */
export const RedirectWithStatusAndLocale: FC<{
  uid: string
  to: string
  status: number
}> = ({ uid, to, status }) => {
  const { locale } = useParams<LocaleParams>()

  return (
    <RedirectWithStatus
      uid={uid}
      to={mergeAllUrls(locale, to)}
      status={status}
    />
  )
}
