export const UPDATE_FIELD_ACTION = 'UPDATE_FIELD_ACTION'

export interface UpdateFieldAction {
  type: typeof UPDATE_FIELD_ACTION
  name: string
  value: string
}

export const updateFieldAction = (name: string, value: string | boolean) => ({
  type: UPDATE_FIELD_ACTION,
  name,
  value,
})
