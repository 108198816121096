export const verifyByPhotoPath = 'check/photo'
export const verifyByCameraPath = 'camera/start'
export const verifyByEmailPath = 'check/email'
export const verifyByPhonePath = 'update/phone'
export const checkCodePath = 'check/code'
export const verifySuccessPath = 'check/success'
export const phonePath = 'phone'
export const verifyBySocialPath = 'check/social'
export const confirmExitPath = 'confirm-exit'

export interface ProviderParams {
  provider: string
}
