import React, { FC } from 'react'
import { ImageContainer, ImageInner, Image } from './UniNoticeView.styled'
import { UniNoticeImages } from 'api/uninotice/uninotice.types'

export const ImageCenter: FC<{
  image: UniNoticeImages
}> = ({ image: { svgUrl, pngUrl, jpgUrl, component } }) => {
  if (component) {
    return (
      <ImageContainer>
        <ImageInner>{component}</ImageInner>
      </ImageContainer>
    )
  } else if (svgUrl) {
    return (
      <ImageContainer>
        <ImageInner>
          <Image src={svgUrl} />
        </ImageInner>
      </ImageContainer>
    )
  } else if (pngUrl) {
    return (
      <ImageContainer>
        <ImageInner>
          <Image src={pngUrl} />
        </ImageInner>
      </ImageContainer>
    )
  } else if (jpgUrl) {
    return (
      <ImageContainer>
        <ImageInner>
          <Image src={jpgUrl} $borderRadius={'5px'} />
        </ImageInner>
      </ImageContainer>
    )
  } else {
    console.warn('UniNotice image format not found.')
    return null
  }
}
