import { STATE } from 'common/constants'
import { PartialRootState } from './store.types'

export const loadStateFromGivenStorage = (
  storage: Storage
): PartialRootState => {
  try {
    const serializedState = storage.getItem(STATE)
    if (serializedState) {
      return JSON.parse(serializedState)
    }
    return {}
  } catch (error) {
    console.error('Error reading state from storage')
    return {}
  }
}
