import { putApi } from 'api/fetchApi'
import { paths } from 'api/generated/appsflyer'

type Put = paths['/appsflyer/add-webid']['put']
type Response = Put['responses']['204']['schema']
type Body = Put['parameters']['body']['body']

export const addAppsFlyerWebIdApi = (webId: string) => {
  return putApi<Response, Body>('/appsflyer/add-webid', { webId })
}
