import { useShallowEqualSelector } from './useShallowEqualSelector'

/**
 * Возвращает true, если пользователь авторизован, реальный(верифицирован) и принял gdpr.
 * Это нужно, например, чтобы активировать ранее введенный промокод сразу после регистрации пользователя.
 */
export const useIsAuthUserReady = () => {
  const { authorized, real, gdprBlocked } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized, profile },
      errorReducer: { gdprBlocked },
    }) => ({
      authorized,
      real: profile['isReal'],
      gdprBlocked,
    })
  )

  return authorized && real && !gdprBlocked
}
