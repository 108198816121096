import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { mergeFullUrls } from 'functions/mergeAllUrls'
import { buildFullQuery } from 'functions/queryString'
import { useEffect } from 'react'
import { isLoveMailru, isWamba } from 'common/constants'
import { fullPageReload } from 'functions/fullPageReload'

/**
 * Редирект со старых доменов с префиком m.*
 * m.love.mail.ru -> love.mail.ru
 */
export const useRedirectFromOldMobileDomain = () => {
  const { url, partnerId, query, pathname } = useShallowEqualSelector(
    ({
      systemReducer: { partner, partnerId, query },
      router: {
        location: { pathname },
      },
    }) => ({ url: partner?.url, partnerId, query, pathname })
  )

  /**
   * url.web нельзя использовать
   * для mail.ru там www.love.mail.ru
   */

  const mainLoveMailRuUrl = mergeFullUrls(
    'https',
    'love.mail.ru',
    `${pathname}${buildFullQuery(query)}`
  )

  const mainWambaUrl = mergeFullUrls(
    'https',
    url?.web,
    `${pathname}${buildFullQuery(query)}`
  )

  useEffect(() => {
    if (
      process.env.production &&
      (window.location.hostname === 'm.love.mail.ru' ||
        window.location.hostname === 'm.wamba.com')
    ) {
      if (isWamba(partnerId)) {
        fullPageReload(mainWambaUrl)
      }

      if (isLoveMailru(partnerId)) {
        fullPageReload(mainLoveMailRuUrl)
      }
    }
  }, [partnerId, url])
}
