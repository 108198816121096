/**
 * @deprecated используем Сентри
 */
export function reportErrorApi(report) {
  const xmlHttpRequest = new XMLHttpRequest()
  xmlHttpRequest.open('POST', '/stat/js-error.phtml', true)
  xmlHttpRequest.setRequestHeader(
    'Content-type',
    'application/x-www-form-urlencoded'
  )
  xmlHttpRequest.setRequestHeader('Cache-Control', 'no-cache')
  xmlHttpRequest.send(report)
}
