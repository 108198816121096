import loadable from '@loadable/component'
import React from 'react'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'

export const LivenessRedirectLoadable = loadable(
  async () => (await import('./LivenessRedirect')).LivenessRedirect,
  {
    fallback: <DefaultLoader />,
  }
)
