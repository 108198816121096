import { AsyncAction } from 'actions/actions.types'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { fetchAllowedVerificationIfNeededAction } from 'actions/settingsAction'
import { clearCounterDownAction } from 'actions/utility/counterDown/counterDownAction'
import { phoneVerificationCodeVerifyApi } from 'api/phoneVerification/phoneVerificationCodeVerifyApi'
import { getPhoneVerificationSuccessPath } from 'components/page/Settings/Verification/functions'
import { replace } from 'functions/router'

export const VERIFY_CODE = 'VERIFY_CODE'

export interface VerifyCodeAction extends AsyncAction {
  type: typeof VERIFY_CODE
  promise: () => ReturnType<typeof phoneVerificationCodeVerifyApi>
}

export const verifyCodeAction = (code?: string) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    settingsReducer: {
      form: { verificationCode },
    },
    userReducer: { userId },
    errorReducer: { realStatusNeeded },
  } = getState()

  dispatch({
    type: VERIFY_CODE,
    promise: () =>
      phoneVerificationCodeVerifyApi(code || verificationCode).then((json) => {
        if (json.ok) {
          dispatch(fetchAllowedVerificationIfNeededAction(true))
          dispatch(clearCounterDownAction())
          dispatch(
            replace(getPhoneVerificationSuccessPath(userId!, realStatusNeeded))
          )
        }
        return json
      }),
  })
}
