import { DefaultTheme } from 'styled-components'
import { Colors } from 'common-constants/colors'

const CommonTheme = {
  $linkColor: Colors.link,
  $linkHoverColor: Colors.linkHover,
  $linkSelectedColor: Colors.linkSelected,
  partnerId: 0,
  javaScriptEnabled: true,
}

export const LtrTheme: DefaultTheme = {
  ...CommonTheme,
  rtl: false,
  marginRight: 'margin-right',
  marginLeft: 'margin-left',
  paddingLeft: 'padding-left',
  paddingRight: 'padding-right',
  textAlignLeft: 'left',
  textAlignRight: 'right',
  right: 'right',
  left: 'left',
  borderBottomLeftRadius: 'border-bottom-left-radius',
  borderTopLeftRadius: 'border-top-left-radius',
  borderTopRightRadius: 'border-top-right-radius',
  borderBottomRightRadius: 'border-bottom-right-radius',
  preserveAspectRatioX: 'xMin',
  borderLeft: 'border-left',
  borderRight: 'border-right',
  flexStart: 'flex-start',
  flexEnd: 'flex-end',
  gradientLeft: 'to left',
  gradientRight: 'to right',
  translateX: -1,
  scaleX: 1,
  multiplier: 1,
  rotate: 0,
}

export const RtlTheme: DefaultTheme = {
  ...CommonTheme,
  rtl: true,
  marginRight: 'margin-left',
  marginLeft: 'margin-right',
  paddingLeft: 'padding-right',
  paddingRight: 'padding-left',
  textAlignLeft: 'right',
  textAlignRight: 'left',
  borderBottomLeftRadius: 'border-bottom-right-radius',
  borderBottomRightRadius: 'border-bottom-left-radius',
  borderTopLeftRadius: 'border-top-right-radius',
  borderTopRightRadius: 'border-top-left-radius',
  preserveAspectRatioX: 'xMax',
  borderLeft: 'border-right',
  borderRight: 'border-left',
  right: 'left',
  left: 'right',
  flexStart: 'flex-end',
  flexEnd: 'flex-start',
  gradientLeft: 'to right',
  gradientRight: 'to left',
  translateX: +1,
  scaleX: -1,
  multiplier: -1,
  rotate: -90,
}
