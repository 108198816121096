import React, { FC, useEffect } from 'react'
import { Redirect } from 'react-router'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { fullPageReload } from 'functions/fullPageReload'
import { OnlyServerRedirect } from 'components/system/OnlyServerRedirect'
import { NotFoundPageLoadable } from 'components/page/NotFoundPage/NotFoundPageLoadable'
import { addQueryStringToUrl } from 'functions/addQueryStringToUrl'

export const Replace: FC<{
  to: string
  from?: string
  /** Любая информация, которая поможет найти где был зарендерен этот компонент. Для удобства дебаггинга */
  uid: string
  removeQuery?: boolean
  push?: boolean
}> = ({ to, from, uid, removeQuery, push = false }) => {
  const { search } = useShallowEqualSelector(
    ({
      router: {
        location: { search },
      },
    }) => ({
      search,
    })
  )

  const _to = addQueryStringToUrl(to, removeQuery ? '' : search)
  console.info('Redirect', uid, _to, push)

  /** Внешняя ссылка возможно только через релоад */
  const absolutePath = _to.startsWith('https://') || _to.startsWith('http://')

  useEffect(() => {
    if (absolutePath) {
      fullPageReload(_to)
    }
  }, [absolutePath, _to])

  /**
   * https://youtrack.mamba.ru/issue/M-2642
   * Сначала загружается history.js и делает decodeURI(location) и добавляет в store.
   * Если вдруг будет редирект, то history.js снова будет делать decodeURI(location) и может упасть
   * если url будет какой-то кривой (по типу /rating%25' AND 2*3*8=6*8 AND 'bDZ3'!='bDZ3%25).
   * Поэтому после первого прохода мы сами проверяем через decodeURI и если все плохо возвращаем 404.
   */
  try {
    decodeURI(_to)
  } catch (error) {
    console.error(
      `Some strange location: ${_to}. This is is likely caused by an invalid percent-encoding.`
    )
    return <NotFoundPageLoadable />
  }

  if (
    process.env.browser &&
    window.location.pathname === _to &&
    !window.location.search
  ) {
    console.warn('Duplicated redirect', window.location.pathname, _to)
    return null
  }

  if (absolutePath) {
    return <OnlyServerRedirect to={_to} />
  }

  return <Redirect from={from} to={_to} push={push} />
}
