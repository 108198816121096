import { DEFAULT_LOCALE } from 'common/constants'
import { fetchApi, fetchApi5 } from 'api/index'
import cookie from 'js-cookie'
import { ApiResult } from 'api/fetchApi'
import { buildQuery } from 'functions/queryString'

export const loginApi = async (
  login: string,
  password: string,
  computerLevel: boolean,
  partnerId: number,
  locale = DEFAULT_LOCALE,
  isBindingWithSocialNetworkAccount?: boolean
) => {
  await loginBuilderApi(locale)

  const { crossAuthTokenId } = await crossAuthApi(
    login,
    password,
    computerLevel,
    partnerId
  )

  const token = await tokenLoginApi(
    crossAuthTokenId,
    locale,
    isBindingWithSocialNetworkAccount
  )
  const startScreen = await fetchStartScreenApi()

  return {
    ...startScreen,
    ...token,
    startScreen: startScreen ? startScreen.startScreen : null,
  }
}

const loginBuilderApi = (locale: string) => {
  return fetchApi5(`/login/builder/?lang_id=${locale}`)
}

const tokenLoginApi = (
  token: string,
  locale: string,
  isBindingWithSocialNetworkAccount?: boolean
) => {
  const s_post = cookie.get('s_post')
  return fetchApi5(
    `/login/builder?crossAuthTokenId=${token}&lang_id=${locale}&reqType=json`,
    {
      body: JSON.stringify({
        crossAuthTokenId: token,
        s_post,
        isBindingWithSocialNetworkAccount,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )
}

const crossAuthApi = async (
  login: string,
  password: string,
  computerLevel: boolean,
  partnerId: number
) => {
  const s_post = cookie.get('s_post')
  const response = await fetch(
    `${process.env.AUTH_HOST}/token.json?partnerId=${partnerId}`,
    {
      body: buildQuery({
        login,
        password,
        s_post: s_post!,
        domain: '',
        level: computerLevel,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    }
  )
  return await response.json()
}

/**
 * TODO: Пока придется оставить старый запрос, так как обновленный
 * readyStatePromise в ошибках читает src/common/store/storeMiddleware.ts: 77 error: result.result.
 * А в ответе loginApi, еще помимо result, возвращается еще
 */
const fetchStartScreenApi = (): Promise<{ startScreen: string }> =>
  fetchApi('/start_screen')
