import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { fetchVerificationPhotoStatusApi } from 'api/settings/photoVerificationApi'

export const VERIFICATION_PHOTO_STATUS = 'VERIFICATION_PHOTO_STATUS' as const

export const fetchVerificationPhotoStatusIfNeededAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const { photoStatusLoading, photoStatus } = getState().settingsReducer
  if (!photoStatusLoading && photoStatus === null) {
    dispatch(fetchVerificationPhotoStatusPlainAction())
  }
}

export interface FetchVerificationPhotoStatusPlainAction
  extends AsyncAction<
    Awaited<ReturnType<typeof fetchVerificationPhotoStatusApi>>['result']
  > {
  type: typeof VERIFICATION_PHOTO_STATUS
}

export const fetchVerificationPhotoStatusPlainAction = (): FetchVerificationPhotoStatusPlainAction => ({
  type: VERIFICATION_PHOTO_STATUS,
  promise: () => fetchVerificationPhotoStatusApi(),
})
