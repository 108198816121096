import { userProfilePath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const photoViewerBasePath = '/photo'
export const photoViewerPhotoIdPath = '/:photoId(\\d+)'
export const photoViewerAttachPhotoPath = '/attach-photos/:photoId'
export const photoViewerAttachPath = 'attach-photos'
export const promoPath = '/:promo(promo)?'
export const promoValuePath = '/promo'

export interface PhotoViewerParams {
  userId: string
  photoId: string
  promo: string
}

export const photoViewerFullPath = mergeAllUrls(
  userProfilePath,
  photoViewerBasePath,
  photoViewerPhotoIdPath,
  promoPath
)

export const photoViewerModalPath = mergeAllUrls(
  photoViewerBasePath,
  photoViewerPhotoIdPath,
  promoPath
)

export const photoViewerModalAttachPath = mergeAllUrls(
  photoViewerBasePath,
  photoViewerAttachPath,
  photoViewerPhotoIdPath
)
