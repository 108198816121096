import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/coubstat'

type Post = paths['/cubstat/statisticsFromClient/authorized']['post']
type Response = Post['responses']['204']
type Body = Post['parameters']['body']['body']

export const sendStatisticsAuthorizedApi = (
  type: string,
  eventId: string,
  fromId: string
) => {
  return postApi<Response, Body>('/cubstat/statisticsFromClient/authorized', {
    type,
    eventId,
    fromId,
  })
}
