import { RestoreInitialStateAction } from 'actions/system/restoreInitialStateAction'
import { PartialRootState } from 'common/store/store.types'

export const initialStateReducer = <S>(
  state: S,
  action: RestoreInitialStateAction,
  reducerName: keyof PartialRootState
) => {
  if (action.stateName === reducerName) {
    return {
      ...state,
      ...action.initialState[reducerName],
    }
  }
  return state
}
