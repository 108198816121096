import {
  fetchSeoSnippetApi,
  fetchAdsLinksApi,
  resolveSeoLocationApi,
  fetchTopLocationLinksApi,
} from 'api/landing/seoApi'
import { ANY } from 'components/page/Landing/constants'
import { AsyncThunkAction } from 'actions/types'
import { AsyncAction } from 'actions/actions.types'
import { definitions } from 'api/generated/seo'
import { NodeHeaders } from 'api/NodeHeaders'

export const FETCH_SEO_SNIPPET = 'FETCH_SEO_SNIPPET'

interface FetchSeoSnippetAction extends AsyncAction<definitions['SeoTexts']> {
  type: typeof FETCH_SEO_SNIPPET
}

export const fetchSeoSnippetAction = (
  locationId: string,
  locationType: string,
  lookFor = ANY,
  headers?: NodeHeaders
): AsyncThunkAction => (dispatch, getState) =>
  dispatch({
    type: FETCH_SEO_SNIPPET,
    promise: () =>
      fetchSeoSnippetApi(
        locationId,
        locationType,
        lookFor,
        headers,
        getState().systemReducer.locale
      ),
  })

export const FETCH_ADS_LINKS = 'FETCH_ADS_LINKS'

interface FetchAdsLinksAction extends AsyncAction<definitions['SeoAdsLinks']> {
  type: typeof FETCH_ADS_LINKS
}

export const fetchAdsLinksAction = (
  locationId: string,
  locationType: string,
  lookFor = ANY,
  headers?: NodeHeaders
): AsyncThunkAction => (dispatch, getState) =>
  dispatch({
    type: FETCH_ADS_LINKS,
    promise: () =>
      fetchAdsLinksApi(
        locationId,
        locationType,
        lookFor,
        headers,
        getState().systemReducer.locale
      ),
  })

export const RESOLVE_SEO_LOCATION = 'RESOLVE_SEO_LOCATION'

interface ResolveSeoLocationAction
  extends AsyncAction<definitions['SeoLocationDto']> {
  type: typeof RESOLVE_SEO_LOCATION
}

export const resolveSeoLocationAction = (
  locationId: string,
  locationType: string,
  lookFor: string = ANY,
  headers?: NodeHeaders
): AsyncThunkAction => (dispatch, getState) =>
  dispatch({
    type: RESOLVE_SEO_LOCATION,
    promise: () =>
      resolveSeoLocationApi(
        locationId,
        locationType,
        lookFor,
        headers,
        getState().systemReducer.locale
      ),
  })

export const FETCH_TOP_LOCATION_LINKS = 'FETCH_TOP_LOCATION_LINKS'

interface FetchTopLocationLinksAction
  extends AsyncAction<definitions['SeoTopLocationsLinks']> {
  type: typeof FETCH_TOP_LOCATION_LINKS
}

export const fetchTopLocationLinksAction = (
  locationId: string,
  locationType: string,
  lookFor = ANY,
  limit: number,
  headers?: NodeHeaders
): AsyncThunkAction => (dispatch, getState) => {
  /** limit === 0 все регионы без лимита https://redmine.mamba.ru/issues/118406 */
  if (!getState().landingReducer.topLocationsLoaded || limit === 0) {
    return dispatch({
      type: FETCH_TOP_LOCATION_LINKS,
      promise: () =>
        fetchTopLocationLinksApi(
          locationId,
          locationType,
          lookFor,
          limit,
          headers,
          getState().systemReducer.locale
        ),
    })
  }
  return Promise.resolve()
}

export const LGBT_BLOCKED = 'LGBT_BLOCKED' as const

export const setLgbtBlockedAction = (value: boolean) => ({
  type: LGBT_BLOCKED,
  value,
})

export type SeoActionTypes =
  | FetchTopLocationLinksAction
  | FetchSeoSnippetAction
  | FetchAdsLinksAction
  | ResolveSeoLocationAction
  | ReturnType<typeof setLgbtBlockedAction>
