import { ResetRegistrationNeededAction } from 'actions/authorization/resetRegistrationNeededAction'
import { ErrorActionTypes } from 'actions/errorAction'
import {
  AcceptGdprAction,
  RemoveGdprAction,
  ResetGdprStatusAction,
} from 'actions/gdprAction'
import { DeleteAccountAction } from 'actions/settings/deleteAccountAction'
import { RemoveAccountAction } from 'actions/settings/removeAccountAction'
import { DeleteProfileSendCodeSmsAction } from 'actions/profile/profileDeleteAction'
import { VerifyCaptchaAction } from 'actions/captchaAction'
import { FetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import { UpdateMiniAction } from 'actions/system/updateMiniAction'
import { SocketReceiveDataAction } from 'actions/socket/socketReceiveDataAction'
import { LoginAction } from 'actions/authorization/loginAction'
import { LogoutAction } from 'actions/authorization/logoutAction'
import { RegistrationAction } from 'actions/authorization/registrationAction'
import { RestoreAccountAction } from 'actions/settings/restoreAccountAction'

export type AllErrorActionTypes =
  | ResetRegistrationNeededAction
  | ErrorActionTypes
  | LogoutAction
  | LoginAction
  | RemoveGdprAction
  | ResetGdprStatusAction
  | RestoreAccountAction
  | DeleteAccountAction
  | RemoveAccountAction
  | DeleteProfileSendCodeSmsAction
  | VerifyCaptchaAction
  | RegistrationAction
  | FetchMiniIfNeededAction
  | UpdateMiniAction
  | AcceptGdprAction
  | SocketReceiveDataAction

export enum ServerError {
  userBanned = 'user_banned',
}
