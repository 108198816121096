import React, {
  FC,
  FormEvent,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
} from 'react'
import {
  Dirty,
  InputElement,
  WrapperField,
} from 'components/presentational/input'
import {
  makeDirtyAction,
  toggleValidateOnSubmitAction,
} from 'actions/formAction'
import { FormContext } from 'components/presentational/form'
import { useDispatch } from 'react-redux'
import { useIsFormDirty } from 'components/presentational/Form/Form.hooks'
import { FormHandler } from 'components/presentational/Form/From.functions'

export const InputWrapper: FC<{
  name: string
  message: ReactNode
  refNode?: MutableRefObject<HTMLInputElement | null>
  withDomain?: boolean
  onInvalid?: FormHandler
}> = ({ message, refNode, onInvalid, ...rest }) => {
  const dispatch = useDispatch()
  const formId = useContext<string>(FormContext)
  const dirty = useIsFormDirty(rest.name)

  const handleMarkDirty = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      dispatch(makeDirtyAction(formId, rest.name))
    },
    [formId, rest.name]
  )

  const handleInvalid = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      if (!dirty) {
        // повторение логики оригинала, возможно проверка не нужна
        dispatch(toggleValidateOnSubmitAction(formId, false))
      }
      handleMarkDirty(event)
      if (onInvalid) {
        onInvalid(event)
      }
    },
    [formId, onInvalid, handleMarkDirty]
  )

  const field = (
    <WrapperField withDomain={rest.withDomain}>
      <InputElement
        onInvalid={handleInvalid}
        onBlur={handleMarkDirty}
        ref={refNode}
        {...rest}
      />
      {message}
    </WrapperField>
  )

  if (dirty) {
    return <Dirty>{field}</Dirty>
  }

  return field
}
