import { mergeAllUrls } from 'functions/mergeAllUrls'
import {
  PaymentType,
  StorefrontService,
} from 'components/storefront/StoreFront.constants'
import { storeFrontStartAutoStartSliderPath } from 'components/paths'

export const failedPath = 'failed'
export const successfulPath = 'successful'
export const orderPath = 'order'
export const receiptPath = 'receipt'
export const paymentFormPath = 'form'
export const paymentTypePath = 'type'
// Нужен для того, чтобы показать через роутинг завязанный на конкретный сервис
export const commonServicePath = 'common'
export const failedWithOrderIdPath = `/${orderPath}/:orderId/${failedPath}`
// TODO возможно надо упростить до урлов вида /order/:orderId/type/:paymentType/result/:result и делать их
// релативно, в следующих этапах рефакторинга
export interface PaymentTypeParams {
  paymentType: PaymentType
}
export const paymentTypeRelativePath = `/${paymentTypePath}/:paymentType`
export interface OrderParams {
  orderId: string
}
export const failedWithOrderIdAndPaymentTypePath = `/${orderPath}/:orderId/${failedPath}${paymentTypeRelativePath}`
export const successfulWithOrderIdPath = `/${orderPath}/:orderId/${successfulPath}${paymentTypeRelativePath}`
export const successfulReceiptPath = `/${receiptPath}/${successfulPath}${paymentTypeRelativePath}`
export const paymentOrderIdPath = `/${paymentFormPath}/${orderPath}/:orderId`
export type FullStoreFrontParams = OrderParams &
  PaymentTypeParams &
  ServiceTypeParams
export const storeFrontUserPath = 'user'
export const baseStoreFrontPath = '/app/storefront'
export const vipPresentPath = 'vip-present'
export const giftPresentPath = 'gift-present'
export const stopChatTextPathParameter = ':extendedText(true|false)'
export const stopChatTextPath = 'extended-text'
export const stopChatTextPathParameterMerge = mergeAllUrls(
  stopChatTextPath,
  'true'
)

export interface StoreFrontGiftsRouteParams {
  userId: string
  option?: typeof vipPresentPath | typeof giftPresentPath
}
export const storeFrontGiftsUserPath = `/user/:userId/:option(${vipPresentPath}|${giftPresentPath})/(${stopChatTextPath})?/${stopChatTextPathParameter}?`

export interface ServiceTypeParams {
  serviceType: StorefrontService
  slideName: string
  autoStartSlider: string
  userId: string
}
export const storeFrontPath = '/app/storefront/:serviceType'
/**
 * Нужен для отправки через SMS и почту.
 */
export const shortStoreFrontPart = '/p'
export const shortStoreFrontPath = `${shortStoreFrontPart}/:serviceType`
/**
 * Нужен для платежей OTP.
 */
export const otpStoreFrontPart = '/one-time-password'
export const otpStoreFrontPath = `${otpStoreFrontPart}/:serviceType`
export const storeFrontTopUpPath = '/app/storefront/topup'
export const storeFrontVipPath = '/app/storefront/vip'
export const storeFrontProfileUpPath = '/app/storefront/maketop'
export const storeFrontGiftsPath = '/app/storefront/gifts'
export const storeFrontPhotoLinePath = '/app/storefront/photoline'
export const storeFrontFeaturedPhotosPath = '/app/storefront/featured-photos'
export const storeFrontGiftToUserPath = mergeAllUrls(
  storeFrontGiftsPath,
  storeFrontUserPath
)

export const storeFrontVipSlideWithStopChatPath = `${storeFrontStartAutoStartSliderPath}/(${stopChatTextPath})?/${stopChatTextPathParameter}?`

export interface StoreFrontStopChatRouteParameters {
  extendedText: string
}
