import { AppDispatch, AsyncAction } from 'actions/actions.types'
import { fetchMetaDataApi, MetaApiResult } from 'api/system/fetchMetaDataApi'
import { ApiResult } from 'api/fetchApi'
import { AppGetState } from 'actions/actions.typesNext'

export const FETCH_META = 'FETCH_META'

export interface FetchMetaDataAction extends AsyncAction<MetaApiResult> {
  type: typeof FETCH_META
}

type Type = ApiResult<MetaApiResult>

export const fetchMetaDataAction = (path: string) => (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  if (!getState().systemReducer.metaLoading) {
    return dispatch({
      type: FETCH_META,
      promise: () => fetchMetaDataApi(path),
    })
  }
  return Promise.resolve()
}
