import { fetchQueryApi } from 'api/fetchApi'
import { UniNoticeParams } from 'actions/uninotice/uniNoticeAction'
import { createComplexParams } from 'functions/createComplexParams'
import { paths as uniNotice } from 'api/generated/uni_notice_index'

import { NodeHeaders } from 'api/NodeHeaders'

export const vipPresentByMeUniNoticeId = 'vip-presented-by-me'

export type UniNoticeId = string | typeof vipPresentByMeUniNoticeId

export function fetchUniNoticeApi(
  noticeId: UniNoticeId,
  params: UniNoticeParams = {},
  headers?: NodeHeaders
) {
  const dataParams = Object.keys(params).reduce((acc, key) => {
    if (typeof params[key] === 'object') {
      return createComplexParams(key, params[key])
    }
    acc[`data[${key}]`] = params[key]
    return acc
  }, {})

  return fetchQueryApi(
    `/uni-notice/${noticeId}` as keyof uniNotice,
    dataParams,
    {
      headers,
    }
  )
}
