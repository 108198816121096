import cookie from 'js-cookie'

import { fetchApi, fetchApi5 } from './index.ts'

export function registrationDataApi(locale) {
  return fetchApi5(`/registration/?lang_id=${locale}`)
}

export function logoutApi() {
  const s_post = cookie.get('s_post')
  return fetchApi5(`/logout?s_post=${s_post}`)
}

export function resetPasswordEmailApi(email) {
  return fetchApi('/password_reset/requestResetByEmail', {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  })
}

export function fetchPasswordCrossUserApi(email) {
  return fetchApi('/password_reset/requestResetByEmailCross', {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  })
}

export function resetPasswordEmailApi5(email) {
  const s_post = cookie.get('s_post')
  return fetchApi5('/profile/restore_password', {
    method: 'POST',
    body: JSON.stringify({
      secure: {
        s_post,
      },
      recovery: {
        id: email,
      },
    }),
  })
}

export function resetPasswordPhoneApi(phone, ignoreCrossAuth = true) {
  return fetchApi('/password_reset/requestResetBySms', {
    method: 'POST',
    body: JSON.stringify({
      phone,
      ignoreCrossAuth,
    }),
  })
}

export function verifySmsCodeApi(phone, code) {
  return fetchApi('/password_reset/verifySmsCode', {
    method: 'POST',
    body: JSON.stringify({
      phone,
      code,
    }),
  })
}

export const isAuthorizationMailruApi = (headers) =>
  fetchApi('/mailru/check_current_user', { headers })
