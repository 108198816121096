import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const LoginVariantsLoadable = loadable(
  async () => (await import('./LoginVariants')).LoginVariants,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Login variants split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
