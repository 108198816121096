import { AsyncAction } from 'actions/actions.types'

import { restoreAccountApi } from 'api/settings/restoreAccountApi'

export const RESTORE_ACCOUNT = 'RESTORE_ACCOUNT'

export interface RestoreAccountAction extends AsyncAction {
  type: typeof RESTORE_ACCOUNT
}

export const restoreAccountAction = () => ({
  type: RESTORE_ACCOUNT,
  promise: () => restoreAccountApi(),
})
