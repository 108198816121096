import { loadLocalStorageState } from './localStorage'
import { loadSessionStorageState } from './sessionStorage'
import { PartialRootState } from './store.types'

/**
 * Возвращает слитое воедино состояния из локального и сессионного хранилищей.
 * По задумке, данные должны храниться либо в одном, либо в другом хранилище.
 *
 * Нужно для промо, чтобы хранилось только в сессии.
 * И нужно для локальной истории для перезагрузок страницы.
 */
export const loadStorageState = (): PartialRootState => {
  const localStorageState = loadLocalStorageState()
  const sessionStorageState = loadSessionStorageState()

  // Пока что достаточно merge на верхнем уровне
  return {
    ...localStorageState,
    ...sessionStorageState,
  }
}
