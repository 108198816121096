import { loadStorageState } from 'common/store/loadStorageState'
import { useIsAuthUserReady } from 'hooks/useIsAuthUserReady'
import React, { FC, useEffect, useState } from 'react'
import { UseActivateVipBeelineSubscriptionLoadable } from './UseActivateVipBeelineSubscriptionLoadable'

/** Маунитит компонент, который активирует подписку после авторизации */
export const ActivateVipBeelineSubscription: FC = () => {
  const [mount, setMount] = useState(false)

  const authUserReady = useIsAuthUserReady()

  useEffect(() => {
    if (!authUserReady) {
      return
    }

    const storageState = loadStorageState()

    if (storageState.vipBeeline?.subscriptionId) {
      setMount(true)
    }
  }, [authUserReady])

  if (!mount) {
    return null
  }

  return <UseActivateVipBeelineSubscriptionLoadable />
}
