import { updatePushNotificationsSupportedAction } from 'actions/push/updatePushNotificationsSupportedAction'
import { firebaseConfig } from 'common-constants/firebaseConfig'
import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  isSupported as isFirebaseMessagingSupported,
  onMessage,
} from 'firebase/messaging'
import { isPushNotificationsEnabledForPartner } from 'functions/pushNotifications/isPushNotificationsEnabledForPartner'
import { useIsAuthUserReady } from 'hooks/useIsAuthUserReady'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

const initFirebase = async () => {
  const app = initializeApp(firebaseConfig)
  const messaging = getMessaging(app)

  onMessage(messaging, (payload) => {
    console.info('Foreground message received', payload)
  })
}

export const FirebaseCommunication: FC = () => {
  const dispatch = useDispatch()
  const ready = useIsAuthUserReady()
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  useEffect(() => {
    if (!ready) {
      return
    }

    ;(async () => {
      try {
        if (
          isPushNotificationsEnabledForPartner(partnerId) &&
          (await isFirebaseMessagingSupported())
        ) {
          initFirebase()
          dispatch(updatePushNotificationsSupportedAction(true))
          return
        }

        dispatch(updatePushNotificationsSupportedAction(false))
      } catch (error) {
        dispatch(updatePushNotificationsSupportedAction(false))
      }
    })()
  }, [dispatch, ready, partnerId])

  return null
}
