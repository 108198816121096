export function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export function concatLocationName(location) {
  return [location.cityName, location.regionName, location.countryName]
    .filter((name) => name)
    .join(', ')
}

export const mostExactLocationName = (location) => {
  return location.cityName || location.regionName || location.countryName
}

export function concatItem(array) {
  return array.filter((name) => name).join(', ')
}

export function addStyleFixedToBody() {
  document.body.style.position = 'fixed'
  document.body.style.width = '100%'
}

export function removeStyleFixedFromBody() {
  document.body.style.position = null
  document.body.style.width = null
}

export function addStyleOverflowToBody() {
  document.body.style.overflow = 'hidden'
}

export function removeStyleOverflowFromBody() {
  document.body.style.overflow = null
}

export const isArray = (element) => Array.isArray(element)

export function compareArrayElement(currentArray, defaultArray) {
  const sortArray = currentArray.sort()
  const defaultSortArray = defaultArray.sort()
  if (defaultArray.length === sortArray.length) {
    return defaultSortArray.every(
      (element, index) => element === sortArray[index]
    )
  } else {
    return false
  }
}

/**
 * @deprecated
 * Мне кажется это не общая функция и либо надо удалить, либо вынести отсюда.
 */
export function findGiftCategory(giftCategories, giftId) {
  return giftCategories.find((category) =>
    category.gifts.some((id) => id === giftId)
  )
}

export function getValueFromUrlQueryString(search, field) {
  const code = new URLSearchParams(search).get(field)
  if (code) {
    return code
  } else {
    /**
     * полифилл для тех браузеров которые не поддерживают URLSearchParams
     */
    const params = {}
    const query = search.substring(1)
    const vars = query.split('&')
    vars.forEach((item) => {
      const pair = item.split('=')
      params[pair[0]] = decodeURIComponent(pair[1])
    })
    return params[field]
  }
}
