// TODO отдельно заявкой удалим из function.js
const splitUrl = (url) => {
  return String(url)
    .split('/')
    .filter((part) => part)
}

// const cleanUrlRegExp = new RegExp(`[^A-z0-9/\\-:\+\(\)|\?\.*]`, 'g')
const cleanUrlRegExp = new RegExp('[^A-z0-9/\\-\\:]', 'g')

export const cleanUrl = (value) => value.replace(cleanUrlRegExp, '')

/**
 * Теперь нужно явно указывать строки в:
 * mergeAllUrls('/storefront/topup', '0')
 * Если сделать например 0:
 * mergeAllUrls('/storefront/topup', 0)
 * Получится '/storefront/topup' без 0
 * @param urls
 * @returns {string}
 */
export const mergeAllUrls = (...urls: (string | number)[]): string => {
  return (
    '/' +
    urls
      .filter((part) => part)
      .map(splitUrl)
      .reduce((acc, paths) => [...acc, ...paths], [])
      .join('/')
  )
}

export const mergeFullUrls = (protocol, hostname, ...urls) => {
  if (!process.env.production && hostname === 'localhost:8080') {
    protocol = 'http'
  }
  return protocol + '://' + hostname + mergeAllUrls(...urls)
}
