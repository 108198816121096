import {
  allCitiesPath,
  allRegionsPath,
  seoGeoLandingPath,
} from 'components/page/Landing/constants'
import { matchPath } from 'react-router-dom'
import { LandingParams } from 'components/page/Landing/Landing.types'
import { mergeAllUrls } from 'functions/mergeAllUrls'

const deprecatedCityPath =
  '/:locale?/landing/:locale/:country/:region/:city-:cityId(\\d+)/:gender(girls|guys|gays|lesbians)?'
const deprecatedRegionPath =
  '/:locale?/landing/:locale/:country/:region-:regionId(\\d+)/:gender(girls|guys|gays|lesbians)?'
const deprecatedCountryPath =
  '/:locale?/landing/:locale/:country-:countryId(\\d+)/:gender(girls|guys|gays|lesbians)?'
const deprecatedCountryAllCitiesPath = `/:locale?/landing/:locale/:country/:region-:regionId/${allCitiesPath}`
const deprecatedCountryAllRegionsPath = `/:locale?/landing/:locale/:country-:countryId/${allRegionsPath}`

export const createGenericSeoLandingRedirectUrl = (locationUrl) => {
  const countryAllCitiesMatch = matchPath<LandingParams>(locationUrl, {
    path: deprecatedCountryAllCitiesPath,
    exact: false,
    strict: false,
  })
  if (countryAllCitiesMatch) {
    const { locale, country, region, regionId } = countryAllCitiesMatch.params
    return mergeAllUrls(
      locale,
      seoGeoLandingPath,
      country,
      `${region}-${regionId}`,
      allCitiesPath
    )
  }
  const countryAllRegionsMatch = matchPath<LandingParams>(locationUrl, {
    path: deprecatedCountryAllRegionsPath,
    exact: false,
    strict: false,
  })
  if (countryAllRegionsMatch) {
    const { locale, country, countryId } = countryAllRegionsMatch.params
    return mergeAllUrls(
      locale,
      seoGeoLandingPath,
      `${country}-${countryId}`,
      allRegionsPath
    )
  }
  const cityMatch = matchPath<LandingParams>(locationUrl, {
    path: deprecatedCityPath,
    exact: false,
    strict: false,
  })
  if (cityMatch) {
    const {
      locale,
      country,
      region,
      city,
      cityId,
      gender = '',
    } = cityMatch.params
    return mergeAllUrls(
      locale,
      seoGeoLandingPath,
      country,
      region,
      `${city}-${cityId}`,
      gender
    )
  }
  const regionMatch = matchPath<LandingParams>(locationUrl, {
    path: deprecatedRegionPath,
    exact: false,
    strict: false,
  })
  if (regionMatch) {
    const {
      locale,
      country,
      region,
      regionId,
      gender = '',
    } = regionMatch.params
    return mergeAllUrls(
      locale,
      seoGeoLandingPath,
      country,
      `${region}-${regionId}`,
      gender
    )
  }
  const countryMatch = matchPath<LandingParams>(locationUrl, {
    path: deprecatedCountryPath,
    exact: false,
    strict: false,
  })
  if (countryMatch) {
    const { locale, country, countryId, gender = '' } = countryMatch.params
    return mergeAllUrls(locale, 'landing', `${country}-${countryId}`, gender)
  }
  console.warn(`Redirect to root from SEO landing "${locationUrl}".`)
  return '/'
}
