import { fetchQueryApi } from 'api/fetchApi'
import { paths, definitions, operations } from 'api/generated/seo'

type Method = paths['/seo/pages-meta']['get']
export type CustomMetaResult = definitions['SeoPageMeta']

export const fetchCustomMetaDataApi = (
  params: operations['seo_pages_meta_getSeoPageMeta']['parameters']['query']
) =>
  fetchQueryApi<CustomMetaResult, Method['parameters']['query']>(
    '/seo/pages-meta',
    {
      url: params.url,
    }
  )
