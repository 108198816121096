import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { CometMessage } from 'api/comet/comet.types'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import {
  closeSocketAction,
  closeWebSocketConnectionAction,
  newSocketAction,
  openWebSocketConnectionAction,
  shiftSocketMessageAction,
} from 'actions/webSocketAction'
import { addCountAction } from 'actions/clientStatisticAction'
import {
  SocketData,
  socketReceiveDataAction,
} from 'actions/socket/socketReceiveDataAction'
import { consoleError, consoleLog } from '../../consoleLog'
import { ClientService } from 'api/clientStatisticApi'
import { captureException } from '@sentry/browser'

export const WebSocketConnection = () => {
  const dispatch = useDispatch()
  const {
    urlWebSocket,
    messagesQueue,
    isWebSocketConnectionOpen,
  } = useShallowEqualSelector(
    ({
      webSocket: { urlWebSocket, messagesQueue, isWebSocketConnectionOpen },
    }) => ({
      urlWebSocket,
      messagesQueue,
      isWebSocketConnectionOpen,
    })
  )
  const socketRef = useRef<WebSocket>(null)
  const webSocketTimerRef = useRef<number>(0)

  const restoreSocket = useCallback(() => {
    const timerId = window.setTimeout(() => {
      consoleLog('Restart socket')
      setUpSocket()
      dispatch(newSocketAction())
    }, 1000)
    consoleLog('Restore socket', { timerId })
    webSocketTimerRef.current = timerId
  }, [dispatch])

  const setUpSocket = useCallback(() => {
    // TODO REMOVE
    // console.info('new WebSocket')
    const socket = new WebSocket(urlWebSocket)
    // @ts-ignore
    socketRef.current = socket
    socket.onopen = () => {
      dispatch(addCountAction(ClientService.websocket, 'subscribe'))
      dispatch(openWebSocketConnectionAction())
      socket.onmessage = (response) => {
        // TODO REMOVE
        // console.info('socket onmessage', response)
        const { params } = JSON.parse(response.data) as CometMessage
        if (params.length) {
          params.forEach((param) =>
            dispatch(
              socketReceiveDataAction(([param] as unknown) as SocketData[])
            )
          )
          dispatch(addCountAction(ClientService.websocket, 'get_message'))
        }
        // Ни в коем случае не добавлять сюда кастомные экшены!
        // Это ломает сплит
      }
      // Если что-то было в очереди до открытия, надо отправить
      dispatch(shiftSocketMessageAction(socket))
      dispatch(addCountAction(ClientService.websocket, 'connect'))
    }
    socket.onclose = (event) => {
      dispatch(closeWebSocketConnectionAction())
      consoleLog(event)
      consoleError(`WebSocket closed by service side with code: ${event.code}`)
      dispatch(addCountAction(ClientService.websocket, 'fail_connect'))
      restoreSocket()
    }
    socket.onerror = (event) => {
      /**
       * Информация в ошибке просто не отправляется:
       * https://stackoverflow.com/questions/18803971/websocket-onerror-how-to-read-error-description
       *
       * Чтобы убрать дубли из Сентри, отправлю текстом.
       */
      const errorMessage = `WebSocket error with code: ${event.type}, status: ${socket.readyState}`
      captureException(errorMessage)
      consoleError(errorMessage)
      consoleLog(event)
      socket.close()
      dispatch(addCountAction(ClientService.websocket, 'fail_connect'))
    }
  }, [dispatch, urlWebSocket])

  useEffect(() => setUpSocket(), [setUpSocket])

  useEffect(() => {
    const closeSocketBeforeUnload = (event: BeforeUnloadEvent) => {
      /**
       * Как минимум, в Chromium браузерах, при клике по элементу <a href="tel:+79998887766" />,
       * вызывается событие beforeunload, что похоже на баг в браузере.
       */
      // @ts-expect-error (Property 'activeElement' does not exist on type 'EventTarget'.) Неполный тип.
      if (event.target?.activeElement?.href?.startsWith('tel:')) {
        return
      }

      const socket = socketRef.current as WebSocket
      socket.onclose = (event) => {
        consoleLog(event)
        consoleError(`WebSocket manually closed: ${event.code}`)
      }
      socket.close()
    }
    const closeSocket = () => {
      const socket = socketRef.current as WebSocket
      dispatch(closeSocketAction())
      clearTimeout(webSocketTimerRef.current)
      consoleLog('Clear socket with timer', webSocketTimerRef.current)
      socket.onclose = () => {
        consoleLog('WebSocket close')
      }
      socket.close()
    }
    /**
     * Поменял beforeunload на unload, т.к. по клику на баннер
     * "скачать приложение" страница не закрывается, но событие beforeunload
     * происходит. Из-за этого нами закрывается сокет и, например, перестают
     * приходить сообщения:
     * https://youtrack.mamba.ru/issue/M-6602
     */
    window.addEventListener('unload', closeSocketBeforeUnload)
    return () => {
      closeSocket()
      window.removeEventListener('unload', closeSocketBeforeUnload)
    }
  }, [dispatch])

  useEffect(() => {
    const socket = socketRef.current as WebSocket
    if (socket.readyState === WebSocket.OPEN && isWebSocketConnectionOpen) {
      dispatch(shiftSocketMessageAction(socket))
    }
  }, [dispatch, isWebSocketConnectionOpen, messagesQueue])

  return null
}
