/**
 * Компонент используется для корректной работы логики 404-й без редиректа.
 * На сервере происходит один рендеринг.
 * @returns {null}
 * @constructor
 */
export const FoundComponent = () => {
  // const { path } = useRouteMatch()
  // const { pathname } = useLocation()
  // console.log('FoundComponent render', { path, pathname })
  return null
}
