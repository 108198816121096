import { GEOLOCATION_CUSTOM_ERROR } from 'actions/geolocation/setGeolocationErrorCodeAction'
import { GeolocationPermissionSvg } from 'components/presentational/svg/GeolocationPermissionSvg'
import UniNoticeViewContent from 'components/uninotice/UniNoticeViewContent'
import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { GeoPermissionDeniedText } from './GeoPermissionDeniedText'
import { GeoPermissionUnavailableText } from './GeoPermissionUnavailableText'
import { VoidHandler } from 'common/types'
import { NotNowButton } from './NotNowButton'

/**
 * Выводит текст ошибки при получении геолокации согласно:
 * https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
 */
export const GeoPermissionErrorContent: FC<{
  title?: ReactNode | null
  errorCode: number
  onSecondaryButtonClick?: VoidHandler
}> = ({ title, errorCode, onSecondaryButtonClick }) => {
  return (
    <UniNoticeViewContent
      title={title}
      imageComponent={<GeolocationPermissionSvg />}
      textComponent={(() => {
        switch (errorCode) {
          case 1: // PERMISSION_DENIED
            return <GeoPermissionDeniedText />
          case 2: // POSITION_UNAVAILABLE
            return <GeoPermissionUnavailableText />
          /** Ошибку по таймауту не показываем, т.к. особого смысла в этом не видно */
          // case 3: // TIMEOUT
          //   return (
          //     <FormattedMessage
          //       id="geolocation.timeout"
          //       defaultMessage="Информация о геолокации не была получена в разрешенное время."
          //     />
          //   )
          case GEOLOCATION_CUSTOM_ERROR:
            return (
              <FormattedMessage
                id="geolocation.error"
                defaultMessage="Ошибка."
              />
            )
        }
      })()}
      secondaryComponent={
        onSecondaryButtonClick && (
          <NotNowButton onClick={onSecondaryButtonClick} />
        )
      }
    />
  )
}
