import { Action } from 'redux'
import { AsyncThunkAction } from 'actions/actions.types'

export const SHOW_TOOLTIP = 'SHOW_TOOLTIP' as const
export const HIDE_TOOLTIPS = 'HIDE_TOOLTIPS' as const

export const showTooltipAction = (id) => ({
  type: SHOW_TOOLTIP,
  id,
})

export interface HideTooltipsAction extends Action {
  type: typeof HIDE_TOOLTIPS
}

export const hideTooltipsAction = (): AsyncThunkAction<void> => (
  dispatch,
  getState
) => {
  if (Object.keys(getState().tooltipReducer.tooltip).length) {
    return dispatch<HideTooltipsAction>({ type: HIDE_TOOLTIPS })
  }
}

export const toggleTooltipAction = (id) => (dispatch, getState) => {
  const { tooltipReducer } = getState()
  if (tooltipReducer.tooltip[id] === true) {
    dispatch(hideTooltipsAction())
  } else {
    dispatch(showTooltipAction(id))
  }
}
