import { VendorSocialList } from 'common-constants/vendorSocialList'
import { profilePath, okPhotoUploadPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const uploadPhotosPath = 'upload'
export const albumPath = 'albumId'
export const albumIdPath = ':albumId(\\d+)'
export const providerPath = 'provider'
export const providerNamePath = ':provider'
export const providerNameClassMatePath = 'odnoklassniki'
export const providerNameFacebookPath = 'facebook'
export const accessDeniedPath = 'access-denied'
export const errorPath = ':error'

export const classMatesRedirectPath = mergeAllUrls(
  profilePath,
  okPhotoUploadPath
)

export const classMatesRedirectAccessDeniedPath = mergeAllUrls(
  profilePath,
  okPhotoUploadPath,
  accessDeniedPath
)

/**
 * Через токен в режиме SPA
 */
export const providerTokenPart = 'token'
export interface ProviderTokenParams {
  token: string
}
export const providerTokenPath = `/${providerTokenPart}/:token`

/**
 * Через токен в режиме редиректа
 */
export const vkConnectRedirectUploadPart = '/vk-connect-upload'
export interface RedirectUploadParams {
  token: string
}
export const vkConnectRedirectUploadPath = `${vkConnectRedirectUploadPart}/:token`

export const photosPath = 'photos'
export const photosNotFoundPath = 'photos-not-found'
export const progressUploadPath = 'progress-upload'
export const attachAlbumPath = 'attach-album'

export interface AlbumRouteParams {
  albumId: string
  tempAlbumId: string
  provider: VendorSocialList
  albumType: AlbumType
}

export enum UploaderRoutesPaths {
  Processing = 'processing',
  Crop = 'crop',
  Albums = 'albums',
  TempAlbumId = ':tempAlbumId',
  AlbumType = ':albumType(private|common)?',
  ListPhotosFromDropdown = 'list-photos-drop-down',
  PhotoUploadRules = 'photo-upload-rules',
}

export enum AlbumType {
  Private = 'private',
  Common = 'common',
}
