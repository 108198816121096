import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/phone_verification'

type Post = paths['/phone_verification/start']['post']
type Response = Post['responses']['200']['schema']
type Body = Post['parameters']['body']['body']

export const phoneVerificationStartApi = (phone: string, password?: string) => {
  return postApi<Response, Body>('/phone_verification/start', {
    phone,
    password,
  })
}
