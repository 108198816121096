import { NodeHeaders } from 'server/NodeHeaders'
import { definitions } from 'api/generated/language'
import { DeprecatedApi6Error } from 'api/types'
import { ApiResult } from 'api/fetchApi'
import { setTag } from '@sentry/core'

export const fixLanguage = (
  language: definitions['LanguageId']
): definitions['LanguageId'] => {
  language.code = fixBackendLocale(language?.code as string)

  return language
}

/**
 * На бекенде используются не соответствующие стандартам коды.
 * @param backendCode
 */
export const fixBackendLocale = (backendCode: string): string => {
  switch (backendCode) {
    case 'zn':
      return 'zh'
    case 'gr':
      return 'el'
    case 'rs':
      return 'sr'
  }

  return backendCode
}

/**
 * Нужно вернуть ожидаемую локаль в запросе капибарыю
 * @param locale
 */
export const restoreBackendLocale = (locale: string): string => {
  switch (locale) {
    case 'zh':
      return 'zn'
    case 'el':
      return 'gr'
    case 'sr':
      return 'rs'
  }

  return locale
}

export const resolveLocale = (headers: NodeHeaders): string => {
  if ((process.env.browser as unknown) as boolean) {
    return window.__INITIAL_STATE__.systemReducer.locale
  }

  if (headers && headers['X-ApiLocale']) {
    return headers['X-ApiLocale']
  }

  return ''
}

export const hasApiError = (error: DeprecatedApi6Error | ApiResult) => {
  if ((error as ApiResult).error) {
    return true
  }

  return Boolean((error as DeprecatedApi6Error).internalError)
}

export const setSentryTags = (response: Response) => {
  const nodeName = response.headers.get('X-Mmb-Powered-By')
  const server = response.headers.get('Server')

  if (nodeName) {
    setTag('node', nodeName)
  }

  if (server) {
    /**
     * Нужно для отлова Варити:
     * https://variti.io/
     */
    setTag('server', server)
  }
}
