import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const UseActivateVipBeelineSubscriptionLoadable = loadable(
  async () =>
    (await import('./UseActivateVipBeelineSubscription'))
      .UseActivateVipBeelineSubscription,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('UseActivateVipBeelineSubscription split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
