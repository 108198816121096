import { definitions } from 'api/generated/photo'

export const reducePhotoLike = (
  photo: definitions['OmniAlbumPhoto']
): definitions['OmniAlbumPhoto'] => {
  return {
    ...photo,
    voted: true,
    liked: true,
    likes: photo.likes + 1,
  }
}
