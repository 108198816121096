import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { AbTestGeolocationRequestGroup } from 'reducers/system/abTestReducer.constants'

export const useAppRestrictionsWithoutGeolocationAbTest = () => {
  const {
    bestOptionForRequestingLocation,
    ios,
    iosVersion,
    isSafari,
    touch,
    browserName,
  } = useShallowEqualSelector(
    ({
      abTest: { bestOptionForRequestingLocation },
      systemReducer: { ios, iosVersion, isSafari, touch, browserName },
    }) => ({
      bestOptionForRequestingLocation,
      ios,
      iosVersion,
      isSafari,
      touch,
      browserName,
    })
  )

  /** Не можем проводить АБ-тест, если navigator.permissions работает некорректно */
  const navigatorPermissionsWorksWrong =
    // Safari desktop врет о статусе в navigator.permissions
    (!touch && isSafari) ||
    // В iOS до версии 15.7 не поддержана работа navigator.permissions
    // https://caniuse.com/?search=navigator.permissions
    (ios && iosVersion! < 16) ||
    // FF работает, если поставить галочку "Запомнить мое решение"
    browserName!.toLowerCase().includes('firefox') ||
    browserName!.toLowerCase().includes('opera') ||
    browserName!.toLowerCase().includes('yandex')

  if (navigatorPermissionsWorksWrong) {
    return
  }

  switch (bestOptionForRequestingLocation) {
    case AbTestGeolocationRequestGroup.GroupA:
    case AbTestGeolocationRequestGroup.GroupB:
      return {
        /**
         * Вариант 1. Максимальное ограничение.
         * В этом варианте система не позволяет пользоваться сервисами без предоставления координат пользователя.
         * Система постоянно отображает экран с запросом на доступ к координатам,
         * если пользователь находится в ограниченном сервисе (см. ниже).
         * Причем, эту плашку (или экран) невозможно скрыть, не предоставляя координаты системе.
         *
         * Сервисы, доступ к которым ограничен до получения координаты:
         * Знакомства
         * Поиск
         * Лента (включая, “Все”, “Фотолента”, “Трансляции”)
         */
        maximumRestriction: true,
      }
    case AbTestGeolocationRequestGroup.GroupC:
    case AbTestGeolocationRequestGroup.GroupD:
      return {
        /**
         * Вариант 2. Среднее ограничение.
         * В этом варианте система позволяет использовать основной функционал сервисов,
         * но не отображает город и расстояние другого пользователя.
         * А также постоянно отображает плашку (или экран) с запросом на доступ к координатам.
         * Причем, эту плашку (или экран) невозможно скрыть, не предоставляя координаты системе.
         *
         * Сервисы, доступ к которым ограничен до получения координаты:
         * Знакомства
         * Лента (включая, “Все”, “Фотолента”, “Трансляции”)
         * Поиск
         * Поиск-фильтры поиска
         */
        mediumRestriction: true,
      }
    case AbTestGeolocationRequestGroup.GroupE:
    case AbTestGeolocationRequestGroup.GroupF:
      return {
        /**
         * Вариант 3. Легкое ограничение.
         * В этом варианте система никак не ограничивает пользователя в функционале.
         * Система отображает плашку (или экран) с запросом на доступ к координатам,
         * если пользователь находится в ограниченном сервисе (см. ниже).
         * Причем, эту плашку (или экран) можно скрыть, не предоставляя координаты системе.
         * И только при обновлении версии приложения эта плашка (или экран) будет показана вновь.
         *
         * Сервисы, доступ к которым ограничен до получения координаты:
         * Знакомства
         * Поиск
         */
        lowRestriction: true,
      }
    case AbTestGeolocationRequestGroup.GroupG:
    case AbTestGeolocationRequestGroup.GroupH:
      return
  }
}
