import {
  compose,
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  Reducer,
} from 'redux'
import thunk from 'redux-thunk/es/index'
import { createReduxEnhancer } from '@sentry/react'
import { staticReducers, RootReducers } from 'reducers/index'
import { readyStatePromise } from './storeMiddleware'
import { noticeMiddleware } from 'common/store/noticeMiddleware'
import { AppStore } from 'common/store/store.types'
import { RootState } from 'reducers/RootState'
import { limitArray } from 'functions/limitArray'

export const combineStaticReducers = ({
  routerReducer,
  asyncReducers = {},
}: {
  routerReducer?
  asyncReducers?: Partial<RootReducers>
}): Reducer => {
  const reducers = {
    router: routerReducer,
    ...staticReducers,
    ...asyncReducers,
  }

  // Отсортируем редюсеры, чтобы было удобно искать в redux devtools
  if (process.env.NODE_ENV === 'development') {
    return combineReducers(
      Object.keys(reducers)
        .sort()
        .reduce((acc, reducerName) => {
          acc[reducerName] = reducers[reducerName]
          return acc
        }, {})
    )
  }

  return combineReducers(reducers as any)
}

const SENTRY_ARRAY_LIMIT = 3

const sentryReduxEnhancer = () => {
  return createReduxEnhancer({
    actionTransformer: ({ type }) => ({ type }),
    stateTransformer: (state) => {
      if (!state.locationsHistory) {
        const error = new Error('Sentry Redux Enhancer: wrong state')
        console.warn(error)
      }

      /**
       * При локальной разработке state с другим типом и приходит в computedStates.
       * Почему типы разные, вопрос к Сентри.
       */
      if (!process.env.production && process.env.browser) {
        const developmentBrowserState: { committedState: RootState } = state

        // При доступе через ip, developmentBrowserState.committedState почему-то undefined.
        // Делаю пока заглушку. Нужно будет разобраться почему так происходит.
        if (!developmentBrowserState.committedState) {
          return { locationsHistory: [] }
        }

        const locationsHistory = limitArray(
          developmentBrowserState.committedState?.locationsHistory
            ?.browserHistory ?? [],
          SENTRY_ARRAY_LIMIT
        )

        console.warn('Sentry state workaround was applied')

        return { locationsHistory }
      }

      return {
        locationsHistory: limitArray(
          state.locationsHistory.browserHistory,
          SENTRY_ARRAY_LIMIT
        ),
      }
    },
  })
}

export default function configureStore(
  initialState,
  connectTools = true,
  routerMiddleware,
  routerReducer
): AppStore {
  const middlewares = createMiddleware(connectTools, routerMiddleware)

  return createStore(
    combineStaticReducers({ routerReducer }),
    initialState,
    process.env.SENTRY_ENABLED
      ? compose(middlewares, sentryReduxEnhancer())
      : middlewares
  )
}

/**
 * Избегаем ошибки памяти для devtools Redux
 * https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#actionsanitizer--statesanitizer
 */
const actionSanitizer = (action) => {
  switch (action.type) {
    case 'CREATE_CUSTOM_ALBUM_FROM_UPLOAD_PHOTOS':
      const _photos = action._photos.images.map(({ id, stretchWidth }) => ({
        id,
        source: '<<LONG_BLOB>>',
        thumbnail: '<<LONG_BLOB>>',
        stretchWidth,
      }))

      return { ...action, _photos: { id: action._photos.id, images: _photos } }

    case 'UPDATE_PHOTO_FROM_USER':
      if (action.link) {
        return { ...action, link: '<<LONG_BLOB>>' }
      }
      return action

    default:
      return action
  }
}

function createMiddleware(connectTools, routerMiddleware) {
  const middleware = [
    routerMiddleware,
    thunk,
    readyStatePromise,
    noticeMiddleware,
    // loggerMiddleware,
  ]

  if (process.env.production) {
    return applyMiddleware(...middleware)
  } else if (process.env.browser) {
    const composeEnhancers =
      (connectTools &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // actionSanitizer,
          stateSanitizer: (state: RootState) => {
            if (state.uploaderPhotos) {
              const photosArrayUpload = state.uploaderPhotos.photosArrayUpload.map(
                (item) => ({
                  id: item.id,
                  link: '<<LONG_BLOB>>',
                })
              )

              return {
                ...state,
                uploaderPhotos: {
                  ...state.uploaderPhotos,
                  photosArrayUpload: [...photosArrayUpload],
                },
              }
            }

            return state
          },
          trace: true,
          traceLimit: 25,
          shouldHotReload: false, // https://github.com/reduxjs/redux-devtools/issues/304
        } as any)) ||
      compose

    return composeEnhancers(applyMiddleware(...middleware))
  } else {
    return applyMiddleware(...middleware)
  }
}
