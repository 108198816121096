import { mergeAllUrls } from 'functions/mergeAllUrls'

export const deleteContactListRootPath = 'delete-contacts-modal'
export const deleteContactListPath = mergeAllUrls(
  deleteContactListRootPath,
  ':type'
)

export interface DeleteContactListParameters {
  type: string
}
