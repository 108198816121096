import {
  TOGGLE_DISPLAY_PRELOADER,
  UPDATE_IFRAME_CHAT,
} from 'actions/chatAction'

export default function chatReducer(
  state = {
    showPreloader: true,
    isUpdateChat: false,
  },
  action
) {
  const { type, flag, isUpdateChat } = action
  switch (type) {
    case TOGGLE_DISPLAY_PRELOADER:
      return {
        ...state,
        showPreloader: flag,
      }

    case UPDATE_IFRAME_CHAT:
      return {
        ...state,
        isUpdateChat: isUpdateChat,
      }

    default:
      return state
  }
}

const selectors = {}
selectors.root = state => state.chatReducer
selectors.showPreloader = state => selectors.root(state).showPreloader
selectors.isUpdateChat = state => selectors.root(state).isUpdateChat

export { selectors as chatReducerSelectors }
