import { POSITION_OPTIONS } from './constants'

export const getCurrentPositionApi = () => {
  return new Promise(
    (resolve: PositionCallback, reject: PositionErrorCallback) => {
      window.navigator.geolocation.getCurrentPosition(
        resolve,
        reject,
        POSITION_OPTIONS
      )
    }
  )
}
