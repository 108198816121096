import { css } from 'styled-components'

export const flexBoxCss = css`
  display: flex;
`

export const flexGrowCss = css`
  flex-grow: 1;
`

export const flexBoxGrowCss = css`
  ${flexBoxCss};
  ${flexGrowCss};
`

export const FlexColumn = css`
  ${flexBoxCss}
  flex-direction: column;
`

export const FlexRow = css`
  ${flexBoxCss}
  flex-direction: row;
`
