import React from 'react'
import loadable from '@loadable/component'
import { History } from 'history'

import { AppStore } from 'common/store/store.types'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { withAppContextHoc } from 'components/system/withAppContextHoc'
import { insertReducers } from 'hooks/useReducersInsert'

export const SearchLoadable = withAppContextHoc(
  'search',
  loadable<{ store: AppStore; history: History }>(
    async (props) => {
      const module = await import('./SearchIndex')
      insertReducers(props.store, props.history, module.asyncReducers)
      return module.SearchIndex
    },
    {
      fallback: <DefaultLoader />,
      ssr: false,
    }
  )
)
