/**
 * Парсит ответ из:
 * https://developers.wamba.com/api/services/system_settings/index.html#!/system_settings/features_getAllowedFeatures
 */
export const parseFeaturesHexStrings = (
  hexString: string,
  details: string = ''
) => {
  const result: Record<number, number> = {}

  const parse = (
    hexString: string,
    keySize: number,
    valueSize: number = 0
  ): Record<number, number> => {
    if (!hexString) {
      return {}
    }

    if (
      !/^[0-9A-Fa-f]+$/.test(hexString) ||
      hexString.length % (keySize + valueSize) !== 0
    ) {
      throw new Error(`Invalid hex string "${hexString}" provided.`)
    }

    let offset = 0

    while (offset < hexString.length) {
      const hex = hexString.substring(offset, offset + keySize)
      const key = parseInt(hex, 16)
      offset += keySize

      if (valueSize > 0) {
        const hex = hexString.substring(offset, offset + valueSize)
        const value = parseInt(hex, 16)
        offset += valueSize

        result[key] = value
      } else {
        result[key] = 0
      }
    }

    return result
  }

  const features = parse(hexString, 4)
  parse(details, 4, 4)

  return features
}
