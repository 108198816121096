import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const VipCancelFromEmailLoadable = loadable(
  async () => (await import('./VipCancelFromEmail')).VipCancelFromEmail,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('VipCancelFromEmail split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
