import { findCountryCode } from 'actions/profile/function/findCountryCode'
import { concatPhone } from 'components/page/RestorePassword/function/concatPhone'
import { CountryInfo } from 'reducers/countries/countriesInfoReducer'

export const createPhoneNumberWithCountryCodeNumber = (
  phoneNumber: number,
  countryCode: string,
  countriesList: CountryInfo[]
) => {
  const foundCountry = findCountryCode(countriesList, countryCode)

  if (!foundCountry) {
    console.error(`cannot find country with code ${countryCode}`)
    return
  }

  return concatPhone(foundCountry.prefix, phoneNumber)
}
