import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { sendStatisticsAuthorizedApi } from 'api/analytics/sendStatisticsAuthorizedApi'
import {
  GeolocationAbTestEventType,
  GeolocationAbTestPageName,
} from 'components/block/GeoPermissionRequest/hooks/useGeolocationAbTestAnalytics.types'

export const SEND_GEOLOCATION_ANALYTICS = 'SEND_GEOLOCATION_ANALYTICS' as const

export const sendGeolocationAnalyticsThunkAction = (
  type: GeolocationAbTestEventType,
  fromId: GeolocationAbTestPageName
) => (dispatch: AppDispatchNext, getState: AppGetState) => {
  const {
    abTest: { bestOptionForRequestingLocation },
    geolocation: {
      analyticsGeolocationAbTestPermissionGranted,
      analyticsGeolocationAbTestRestrictionShown,
    },
  } = getState()

  if (!bestOptionForRequestingLocation) {
    return
  }

  switch (type) {
    case 'show':
      if (analyticsGeolocationAbTestRestrictionShown) {
        // Уже отправили событие показа
        return
      }
    case 'success':
      if (analyticsGeolocationAbTestPermissionGranted) {
        // Уже отправили событие получения разрешения
        return
      }
  }

  dispatch(sendGeolocationAnalyticsAction(type, fromId))
}

export const sendGeolocationAnalyticsAction = (
  type: string,
  fromId: string
) => ({
  type: SEND_GEOLOCATION_ANALYTICS,
  promise: () =>
    sendStatisticsAuthorizedApi(type, 'geolocation_ab_test', fromId),
  eventType: type,
})
