import { replacePhoto } from 'reducers/photoLine/photoLine.functions'
import { PhotoLineItem } from 'reducers/photoLine/photoLine.types'
import { PhotoLineState } from 'reducers/photoLine/photoLineReducer'
import { SocketReceiveDataAction } from 'actions/socket/socketReceiveDataAction'
import isEmpty from 'functions/isEmpty'

import { limitArray } from 'functions/limitArray'

const newPhotosReducer = (
  state: Partial<PhotoLineState>,
  newPhotos: PhotoLineItem[]
) => {
  if (newPhotos.length) {
    return {
      delayedPhotos: limitArray([...newPhotos, ...state.delayedPhotos!]),
    }
  }
}

const modifyPhotoReducer = (
  state: Partial<PhotoLineState>,
  modifications: PhotoLineItem[]
) => {
  if (modifications.length) {
    modifications.forEach((modifiedData) => {
      replacePhoto(state.photos!, modifiedData)
      replacePhoto(state.delayedPhotos!, modifiedData)
    })
    return state
  }
}

export const cometReducer = (
  state: PhotoLineState,
  action: SocketReceiveDataAction,
  newPhotos: PhotoLineItem[],
  modifications: PhotoLineItem[],
  removed: number[]
) => {
  let newState: Partial<PhotoLineState> = {}
  newState = { ...newState, ...newPhotosReducer(state, newPhotos) }
  newState = {
    ...newState,
    ...modifyPhotoReducer(
      {
        photos: [...state.photos],
        delayedPhotos: [...state.delayedPhotos],
        ...newState,
      },
      modifications
    ),
  }

  const deleted = {
    ...state.deleted,
    ...removed.reduce<Record<number, boolean>>(
      (acc, next) => ({ ...acc, [next]: true }),
      {}
    ),
  }

  newState = {
    ...newState,
    delayedPhotos: [...(newState.delayedPhotos || state.delayedPhotos)].filter(
      ({ id }) => removed.indexOf(Number(id)) === -1
    ),
    deleted: isEmpty(deleted) ? emptyFrozenObject : deleted,
  }
  return newState
}

const emptyFrozenObject = Object.freeze({})
