import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'

export interface LayoutContext {
  id: string
  baseUrl: string
  basePath: string
  secondary: boolean
}

export const useLayoutContext = (): LayoutContext =>
  useShallowEqualSelector(
    ({ systemReducer: { contextId, baseUrl, basePath, secondary } }) => ({
      id: contextId!,
      baseUrl,
      basePath,
      secondary,
    })
  )
