import { VkRedirectPlace } from 'components/system/third-party/vk/vk.constants'
export interface VkRedirectPageParams {
  host: string
  partnerId: string
  place: VkRedirectPlace
  relativeRedirectUrl?: string
}

export const vkRedirectPagePath = '/system/vk-redirect-authorization'
export const vkRedirectPageFullPath = `${vkRedirectPagePath}/:host/:partnerId/:place/:relativeRedirectUrl?`

/**
 * В place для photo будет лежать строка вида
 * some_path_to_place
 * Надо ее превратить в путь. /some/path/to/place
 */
export interface TokenAuthorizationParams {
  token: string
  place: string
  relativeRedirectUrl: string
}

export const tokenPath = '/system/token'
export const tokenFullPath = `${tokenPath}/:token/:place/:relativeRedirectUrl?`
