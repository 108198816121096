import React, { FC, PropsWithChildren } from 'react'

import {
  genericLayoutContextId,
  landingLayoutContextId,
  mambaLayoutContextId,
} from './MambaLayout/Context'

import { CreateBaseUrl } from 'components/layout/MambaLayout/CreateContext'
import { indexPath } from 'components/paths'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { Background } from 'components/presentational/Background'

/**
 * Добавляет фон для общего контекста кроме основного и лендинга.
 * @param children
 * @constructor
 */
export const WrapperLayout: FC<
  { withBaseUrl?: boolean } & PropsWithChildren
> = ({ withBaseUrl = true, children = null }) => {
  const { id } = useLayoutContext()

  /** Базовый урл '/' вызывает наслоение модала по тому же пути */
  if (withBaseUrl === false) {
    return (
      <>
        <Background />
        {children}
      </>
    )
  }

  if (id === mambaLayoutContextId || id === landingLayoutContextId) {
    return <>{children}</>
  }

  return (
    <>
      <Background />
      <CreateBaseUrl
        uid="wrapper"
        contextId={genericLayoutContextId}
        baseUrl={indexPath}
        basePath={indexPath}
      >
        {children}
      </CreateBaseUrl>
    </>
  )
}

export const GenericLayoutContextWrapper: FC<PropsWithChildren> = ({
  children,
}) => (
  <CreateBaseUrl
    contextId={genericLayoutContextId}
    baseUrl={indexPath}
    basePath={indexPath}
  >
    {children}
  </CreateBaseUrl>
)
