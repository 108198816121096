import { mergeAllUrls } from 'functions/mergeAllUrls'
import { chatPath, contactPath, settingsModalPath } from 'components/paths'
import { SUPPORT_BOT_PROFILE_ID } from 'components/page/Chat/Messenger/Messenger.constants'
import { shellPath } from 'components/page/Pwa/Pwa.paths'
import { noticePath } from 'common/constants'
import { UniNoticeType } from 'components/uninotice/UniNotice.constants'
import {
  gdprDeleteWarningPath,
  gdprRemovePath,
  settingsPrivacyPath,
} from 'components/page/Settings/Settings.paths'

export const isAccessibleRouteForUserWithRestrictions = (path: string) => {
  const routes = [
    mergeAllUrls(noticePath, UniNoticeType.user_banned),
    mergeAllUrls(noticePath, UniNoticeType.user_ban_reasons),
    mergeAllUrls(chatPath, SUPPORT_BOT_PROFILE_ID, contactPath),
    mergeAllUrls(settingsModalPath, settingsPrivacyPath, gdprDeleteWarningPath), // чтобы можно было отозвать согласие GDPR
    mergeAllUrls(settingsModalPath, settingsPrivacyPath, gdprRemovePath), // чтобы можно было отозвать согласие GDPR
    shellPath, // https://redmine.mamba.ru/issues/122308
  ]

  return Boolean(routes.find((route) => path.includes(route)))
}
