import { fetchApi } from 'api/fetchApi'
import { paths } from 'api/generated/incognito'
import { NodeHeaders } from 'api/NodeHeaders'

type Method = paths['/incognito/{aid}']['get']
export type Result = Method['responses']['200']['schema']

export const fetchIncognitoStatusApi = (
  userId: number,
  headers?: NodeHeaders
) => fetchApi<Result>(`/incognito/${userId}` as keyof paths, { headers })
