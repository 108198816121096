export const metaphorNameExtendedSearch = 'extended-search'
export const metaphorNameContacts = 'new-contacts'
export const metaphorNameInvisibleMan = 'invisible-man'
export const metaphorNameSticker = 'stickers-in-messenger'
export const metaphorNameFavourite = 'add-to-favourite'
export const metaphorNameShowHitlistRating = 'show-hitlist-rating'
export const metaphorNameBackToProfileInRating = 'back-to-profile-in-rating'
export const metaphorNameLimitNoVip = 'limit-no-vip'
export const metaphorNameAdblock = 'ad-block'
export const metaphorNameStopChat = 'stop-chat'
export const metaphorNameShowGuests = 'show-guests'
export const metaphorSlideExtendedSearch = `slide/${metaphorNameExtendedSearch}`
export const metaphorSlideContacts = `slide/${metaphorNameContacts}`
export const metaphorSlideInvisibleMan = `slide/${metaphorNameInvisibleMan}`
export const metaphorSlideStickers = `slide/${metaphorNameSticker}`
export const metaphorSlideFavourite = `slide/${metaphorNameFavourite}`
export const metaphorSlideShowHitlistRating = `slide/${metaphorNameShowHitlistRating}`
export const metaphorSlideBackToProfileInRating = `slide/${metaphorNameBackToProfileInRating}`
export const metaphorSlideLimitNoVip = `slide/${metaphorNameLimitNoVip}`
export const metaphorSlideAdblock = `slide/${metaphorNameAdblock}`
export const metaphorSlideStopChat = `slide/${metaphorNameStopChat}`
export const metaphorSlideShowGuests = `slide/${metaphorNameShowGuests}`

export enum SlidesName {
  metaphorNamePhotoline = 'add-to-photoline',
  metaphorNameMakeTop = 'profile-make-top',
  metaphorNameSendingGift = 'sending-gift',
  metaphorNameFeaturedPhotos = 'featured-photos',
}
