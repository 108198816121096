import { extractFormFieldsErrors, extractGenericFormError } from './index'

/**
 * Печально известный Форм Билдер из API5.
 */
export interface FormBuilder {
  // Сложный случай типизации, поэтому any
  blocks: { fields: any[] }[]
}

export const extractFormErrors = (formBuilder: FormBuilder) => {
  const errors: Record<string, string> = extractFormFieldsErrors(formBuilder)

  const genericFormError = extractGenericFormError(formBuilder)
  if (genericFormError) {
    errors.loginError = genericFormError
  }
  return errors
}

export interface ResponseWithFromBuilder {
  formBuilder: FormBuilder
  internalError: {
    code: string
    formBuilder: FormBuilder
  }
}
