import React from 'react'
import { AppRoute } from 'components/components.types'
import {
  StandaloneAgreementAndroidPage,
  StandaloneAgreementIOSPage,
  AgreementVipPage,
  ConfidentialityPage,
  PlainAlertPaymentPage,
  PlainConfidentialityPage,
  StandaloneAgreementPage,
} from 'components/page/Legal'
import {
  baseAgreementPath,
  appStandaloneAgreementPath,
  appAgreementPath,
} from 'components/paths'
import { Replace } from 'components/system/Replace'
import { RedirectAgreement } from 'components/system/RedirectAgreement'
import { alertPaymentPagePlainPath } from 'components/page/Boarding/AlertPayment/AlertPaymentLoadable'
import {
  agreementAndroidStandalonePath,
  agreementIOSStandalonePath,
} from 'components/page/Legal/Agreement/agreement.paths'

export const privacyPath = '/privacy'
export const privacyWebviewPath = '/privacy_webview'

export const mobileAppSupportRoutes: AppRoute[] = [
  {
    path: agreementAndroidStandalonePath,
    component: StandaloneAgreementAndroidPage,
  },
  {
    path: agreementIOSStandalonePath,
    component: StandaloneAgreementIOSPage,
  },
  {
    path: alertPaymentPagePlainPath,
    component: PlainAlertPaymentPage,
  },
  {
    path: privacyPath,
    component: ConfidentialityPage,
  },
  {
    path: privacyWebviewPath,
    component: PlainConfidentialityPage,
  },
  {
    path: appAgreementPath,
    component: () => (
      <Replace to="/app/agreement/index" uid="appAgreementPath" />
    ),
    exact: true,
  },
  {
    path: `${appStandaloneAgreementPath}/index`,
    component: StandaloneAgreementPage,
  },
  {
    path: `${appStandaloneAgreementPath}/vip`,
    component: AgreementVipPage,
  },
  {
    path: appStandaloneAgreementPath,
    component: () => (
      <Replace
        to={`${appStandaloneAgreementPath}/index`}
        uid="standaloneAgreementPath"
      />
    ),
  },
  {
    path: baseAgreementPath,
    component: RedirectAgreement,
  },
]
