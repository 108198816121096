import { fetchApi } from './index'

const fetchCometUrlApi = () => {
  return fetchApi('/system_settings?settings=comet')
}

function fetchCometApi(cometUrl, method, params) {
  // console.log({ method, params })
  return fetch(cometUrl, {
    method: 'POST',
    body: JSON.stringify({
      id: 1,
      jsonrpc: '2.0',
      method,
      params,
    }),
  })
}

function fetchUuidApi(cometUrl, params) {
  return fetchCometApi(cometUrl, 'create', params)
}

export async function subscribeCometApi(cometUrl, uuid) {
  const response = await fetchCometApi(cometUrl, 'subscribe', { uuid })
  const { result, error } = await response.json()
  // TODO запись ошибки коменты в логи: !response.ok >= 400
  if (error) {
    console.error('Comet error', error)
    result.error = error
  }
  return result
}

export async function openCometApi(cometUrl, uuid, channels) {
  const response = await fetchCometApi(cometUrl, 'open', { uuid, channels })
  const { result } = await response.json()
  return result
}

export async function startCometSubscriptionApi(params) {
  const urls = await fetchCometUrlApi()
  if (urls.code !== 'API6_ERROR' || urls.code !== 'API5_ERROR') {
    const { http, ws } = urls.comet
    const response = await fetchUuidApi(http, params)
    const { result } = await response.json()
    return { http, uuid: result }
  }

  return urls
}

export const fetchWebSocketUrlApi = () => fetchCometUrlApi()
