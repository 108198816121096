import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { LoginVariantsLoadable } from 'components/page/Boarding/LoginVariantsLoadable'

export const LoginVariantsWithEmail: FC = () => {
  return (
    <LoginVariantsLoadable
      showEmailVariant={true}
      title={<FormattedMessage id="app.enter" defaultMessage="Войти" />}
      clearStepRegistration={true}
    />
  )
}
