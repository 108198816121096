import { registrationApi } from 'api/authorization/registrationApi'
import { findApiLocale } from 'actions/actions.functions'
import { apiResultWithoutErrors } from 'api/index'
import { postRegistrationAction } from 'actions/authorization/postRegistrationAction'
import { pathsOrder } from 'components/page/StepRegistration/StepRegistration.constants'
import { enterNamePath } from 'components/page/StepRegistration/StepRegistration.paths'
import { go } from 'functions/router'
import { nextStepRegistrationFieldAction } from 'actions/form/stepRegistrationAction'
import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { Gender } from 'api/types'
import { Api5AuthResponse } from 'common-constants/api5.types'
import { updateLastAuthMethodAction } from 'actions/authorization/updateLastAuthMethodAction'

export const REGISTER_WITH_EMAIL = 'REGISTER_WITH_EMAIL'

export interface StepRegisterWithEmailAction extends AsyncAction {
  type: typeof REGISTER_WITH_EMAIL
}

export const stepRegisterWithEmailAction = () => async (dispatch, getState) => {
  const {
    authorizationReducer: {
      form: { email: authorizationEmail },
    },
    stepRegistration: {
      form: { name, email, year, month, day },
      gender,
      targetGender,
    },
    stepRegistration,
    systemReducer: { locale },
  } = getState()

  if (
    !hasAllRegistrationData({
      ...stepRegistration.form,
      gender: stepRegistration.gender,
    })
  ) {
    return dispatch(nextStepRegistrationFieldAction())
  }

  const { result: json } = ((await dispatch({
    type: REGISTER_WITH_EMAIL,
    promise: () =>
      registrationApi(
        /** либо email из дореги */
        email || authorizationEmail,
        name,
        gender!,
        targetGender as string,
        '',
        { year, month, day },
        findApiLocale(false, locale)
      ),
  })) as unknown) as { result: Api5AuthResponse }

  const {
    stepRegistration: { formErrors },
    systemReducer: { redesign },
  } = getState()

  /** В редизайне, имя проверяется на шаге ввода имени */
  if (!redesign && formErrors.name) {
    // отправляем поправить имя
    return dispatch(goNameAction())
  }
  if (apiResultWithoutErrors(json)) {
    dispatch(updateLastAuthMethodAction(null))
    dispatch(postRegistrationAction(json))
  }
}

const goNameAction = (): AsyncThunkAction => (dispatch) => {
  const index = pathsOrder.indexOf(enterNamePath)
  dispatch(go(-(pathsOrder.length - index)))
}

export const hasAllRegistrationData = ({
  name,
  email,
  year,
  month,
  day,
  gender,
}: {
  name?: string
  email?: string
  year?: string
  month?: string
  day?: string
  gender?: Gender | string | null
}) => {
  return Boolean(gender && name && email && year && month && day)
}
