import { fetchApi } from './index.ts'

export async function fetchPersonalDiamondsAndSettingsApi() {
  const diamonds = await fetchApi('/diamonds/my')
  const settings = await fetchApi('/diamonds/settings')

  return {
    ...diamonds,
    ...settings,
  }
}

export function fetchDiamondsSettingsApi() {
  return fetchApi('/diamonds/settings')
}

export function convertDiamondsToCoinApi(coins) {
  return fetchApi('/diamonds/withdrawToCoins', {
    method: 'POST',
    body: JSON.stringify({
      coinsAmount: coins,
    }),
  })
}

export function convertDiamondsToCashApi(cash) {
  return fetchApi('/diamonds/withdrawToMoney', {
    method: 'POST',
    body: JSON.stringify({
      moneyAmount: cash,
    }),
  })
}
