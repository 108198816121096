import { postApi } from 'api/fetchApi'
import { definitions } from 'api/generated/support'

export const supportFormSendNotAuthApi = (
  name: string,
  login: string,
  email: string,
  textarea: string,
  screenshot: string | null
) => {
  const image = screenshotExists(screenshot)

  return postApi<
    unknown,
    definitions['UnauthorizedSupportMessagePostBodyRequest']
  >('/support/unauthorized/message', {
    message: {
      name,
      email,
      text: textarea,
      login,
    },
    image,
  })
}

export const supportFormSendAuthApi = (
  textarea: string,
  screenshot: string | null
) => {
  const image = screenshotExists(screenshot)

  return postApi<
    unknown,
    definitions['UserSupportChatMessagesPostBodyRequest']
  >('/support/chat/messages', {
    message: {
      text: textarea,
      bugType: 'other',
      folderId: 190,
    },
    image,
  })
}

const screenshotExists = (
  screenshot: string | null
):
  | {
      contents: string
    }
  | undefined => {
  return screenshot
    ? {
        contents: screenshot,
      }
    : undefined
}
