import { ApiResult, fetchApi } from 'api/fetchApi'
import { definitions } from 'api/generated/banners'
import { NodeHeaders } from 'api/NodeHeaders'

export type BannersAvailableResult = definitions['BannersAvailability']

export const fetchBannersAvailableApi = (
  authorized: boolean,
  headers?: NodeHeaders
): Promise<ApiResult<BannersAvailableResult>> => {
  if (authorized) {
    return fetchApi<BannersAvailableResult>('/banners/availability', {
      headers,
    })
  }
  return Promise.resolve<ApiResult<BannersAvailableResult>>({
    result: {
      available: false,
      maxAds: false,
      mediationType: 'AdFox',
      directAdFox: [],
    } as BannersAvailableResult,
    headers: {} as Headers,
    ok: true,
  } as ApiResult<BannersAvailableResult>)
}
