import { fetchApi, postApi } from 'api/fetchApi'
import { paths } from 'api/generated/captcha'

type Method = paths['/captcha']
type ResultGet = Method['get']['responses']['200']['schema']

type Parameters = Method['post']['parameters']['body']['body'] & {
  'g-recaptcha-response': string
}
type ResultPost = Method['post']['responses']['200']['schema']

export const fetchCaptchaApi = () => fetchApi<ResultGet>('/captcha')

export const verifyCaptchaApi = (response: string) => {
  return postApi<ResultPost, Parameters>('/captcha', {
    'g-recaptcha-response': response,
  })
}
