/* eslint-disable max-lines */
import {
  CHECK_PASSWORD_PROFILE,
  FETCH_MAIN_PHOTO_UPDATE_SETTING,
  FETCH_PERSONAL_SETTINGS,
  FETCH_PROFILE,
  FETCH_USER_SETTINGS_CONTAINER,
  FetchCheckPasswordProfileAction,
  FetchMainPhotoUpdateSettingAction,
  FetchPersonalSettingsAction,
  FetchProfileIfNeededAction,
  FetchSettingsContainerAction,
  RESET_LOCATION_SETTINGS,
  RESET_SETTINGS,
  ResetLocationSettingsAction,
  ResetSettingsAction,
  SAVE_SETTING_FIELD_EMAIL_ACTION,
  SAVE_SETTING_FIELD_LEGACY_ACTION,
  SaveEmailFieldAction,
  SaveFormBuilderSettingsFieldAction,
  UPDATE_SETTING_FIELD_ACTION,
  UpdateSettingsFieldAction,
} from 'actions/userAction'
import {
  apiResultHasErrors,
  extractFieldsFlatValues,
  extractFieldsValues,
  extractFormFieldsErrors,
  isApi5PersonalBlockedError,
} from 'api/index'
import {
  ALLOWED_TO_DELETE,
  CLEAR_LOCATION,
  CLEAR_SETTING,
  FETCH_ALLOWED_VERIFICATION,
  FETCH_EXTERNAL_VERIFICATION,
  FETCH_GESTURE,
  FETCH_MESSAGE_FILTER,
  FETCH_PHONE_CODE_LIST,
  FETCH_SETTING,
  FETCH_SOCIAL_VERIFICATION,
  FETCH_VIP_SETTINGS,
  PASSWORD_REQUIRED,
  SettingsActionTypes,
  TOGGLE_EMAIL_SUBSCRIPTION,
  UPLOAD_VERIFICATION_PHOTO,
} from 'actions/settingsAction'
import {
  FetchPushSubscriptionsIfNeededAction,
  FETCH_PUSH_SUBSCRIPTIONS,
} from 'actions/settings/fetchPushSubscriptionsIfNeededAction'
import {
  FetchEmailSubscriptionsIfNeededAction,
  FETCH_EMAIL_SUBSCRIPTIONS,
} from 'actions/settings/fetchEmailSubscriptionsIfNeededAction'
import {
  ACCEPT_GDPR,
  AcceptGdprAction,
  FETCH_GDPR_ARCHIVE,
  FetchArchiveAction,
  RECALL_GDPR,
  RecallGdprAction,
  REMOVE_GDPR,
  RemoveGdprAction,
  REQUEST_GDPR_DATA,
  ResolveGdprDataAction,
} from 'actions/gdprAction'
import { RESET_FORM, ResetFormAction } from 'actions/formAction'
import { getApi6ErrorCode } from 'api/functions'
import {
  TOGGLE_SETTINGS_INFO,
  ToggleSettingsInfoAction,
} from 'actions/settings/toggleSettingsInfoAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import {
  Fields,
  SingleSelectFieldsSettings,
} from 'components/page/Settings/Fields'
import {
  initialState,
  SettingsForm,
  SettingsReducerState,
} from 'reducers/settings/SettingsReducerState'
import {
  errorCode,
  extractFetchFieldResult,
  extractVipSettings,
} from 'reducers/settings/settings.function'
import {
  FETCH_MY_LOCATION,
  fetchProfilesMyLocationPlainAction,
} from 'actions/location/fetchLocationIfNeededAction'

import {
  VERIFY_PHONE,
  verifyPhonePlainAction,
} from 'actions/settings/verifyPhoneAction'

import {
  LocalStorageActionTypes,
  RESTORE_PARTIAL_STATE,
} from 'actions/system/localStorageAction'
import {
  DELETE_ACCOUNT,
  DeleteAccountAction,
} from 'actions/settings/deleteAccountAction'
import { Reducer } from 'redux'
import { FormBuilder } from 'api/FormBuilder'
import { clone } from 'functions/clone'
import {
  definitions as real_user_phone_verification,
  definitions,
} from 'api/generated/real_user_phone_verification'
import { UPDATE_SETTING } from 'actions/updateSettingAction'
import {
  REMOVE_ACCOUNT,
  RemoveAccountAction,
} from 'actions/settings/removeAccountAction'
import {
  REQUEST_ACCOUNT_REMOVAL_WITH_REASON,
  requestAccountRemovalWithReasonPlainAction,
} from 'actions/settings/requestAccountRemovalWithReasonAction'
import { getVerifyPhoneCaseHandler } from './settingsReducer.verifyPhone'
import { getVerifyCodeCaseHandler } from './settingsReducer.verifyCode'
import { VERIFY_CODE } from 'actions/settings/verifyCodeAction'
import {
  PHONE_VERIFICATION_CODE_RESEND,
  PhoneVerificationCodeResendAction,
} from 'actions/settings/phoneVerificationCodeResendAction'
import { getPhoneVerificationCodeResendCaseHandler } from './settingsReducer.phoneVerificationCodeResend'
import {
  PHONE_VERIFICATION_ALTERNATIVE_METHOD,
  PhoneVerificationAlternativeMethodAction,
} from 'actions/settings/phoneVerificationAlternativeMethodAction'
import { getPhoneVerificationAlternativeMethodCaseHandler } from './settingsReducer.phoneVerificationAlternativeMethod'
import {
  SOCKET_RECEIVE_DATA,
  SocketReceiveDataAction,
} from 'actions/socket/socketReceiveDataAction'
import { getSocketReceiveDataCaseHandler } from './settingsReducer.socketMessage'
import {
  RESET_PHONE_VERIFICAITON_MOBILE_ID_SUCCESS,
  resetPhoneVerificationMobileIdSuccessAction,
} from 'actions/settings/resetPhoneVerificationMobileIdSuccessAction'
import { TOGGLE_SETTING_ENABLED } from 'actions/settings/toggleSettingAction'
import { TOGGLE_SEARCH_VISIBILITY } from 'actions/settings/toggleSearchVisibilityAction'
import { LOGOUT_ACTION, LogoutAction } from 'actions/authorization/logoutAction'
import {
  SubscribeOnPushesAction,
  SUBSCRIBE_ON_PUSHES,
} from 'actions/push/subscribeOnPushesAction'
import { FETCH_SOUND_NOTIFICATION_STATUS } from 'actions/settings/isEnabledSoundNotificationAction'
import {
  FETCH_GDPR_STATUS,
  fetchGdprStatusPlainAction,
} from 'actions/user/fetchGdprStatusAction'
import {
  actionHasErrors,
  isActionWithoutErrors,
} from 'reducers/isActionWithoutErrors'
import {
  RESTORE_INITIAL_STATE,
  RestoreInitialStateAction,
} from 'actions/system/restoreInitialStateAction'
import { initialStateReducer } from 'reducers/default/initialStateReducer'
import {
  FETCH_CURRENT_LOCATION,
  fetchCurrentLocationPlainAction,
} from 'actions/location/fetchCurrentLocationAction'
import { VERIFICATION_PHOTO_STATUS } from 'actions/settings/photoVerificationAction'

/** Разбить редьюсер на части, сейчас не правлю т.к. хз сколько займет */
// @ts-ignore
export const settingsReducer: Reducer<
  SettingsReducerState,
  | SettingsActionTypes
  | FetchProfileIfNeededAction
  | UpdateSettingsFieldAction
  | FetchPersonalSettingsAction
  | ReturnType<typeof fetchCurrentLocationPlainAction>
  | ResetLocationSettingsAction
  | ResetFormAction
  | SaveFormBuilderSettingsFieldAction
  | SaveEmailFieldAction
  | ResetSettingsAction
  | ToggleSettingsInfoAction
  | ReturnType<typeof verifyPhonePlainAction>
  | DeleteAccountAction
  | ResolveGdprDataAction
  | FetchArchiveAction
  | RemoveGdprAction
  | RecallGdprAction
  | FetchCheckPasswordProfileAction
  | ReturnType<typeof fetchProfilesMyLocationPlainAction>
  | AcceptGdprAction
  | FetchMainPhotoUpdateSettingAction
  | FetchSettingsContainerAction
  | LocalStorageActionTypes
  | LogoutAction
  | RemoveAccountAction
  | ReturnType<typeof requestAccountRemovalWithReasonPlainAction>
  | ReturnType<typeof resetPhoneVerificationMobileIdSuccessAction>
  | PhoneVerificationCodeResendAction
  | PhoneVerificationAlternativeMethodAction
  | SocketReceiveDataAction
  | FetchEmailSubscriptionsIfNeededAction
  | FetchPushSubscriptionsIfNeededAction
  | SubscribeOnPushesAction
  | RestoreInitialStateAction
  | ReturnType<typeof fetchGdprStatusPlainAction>
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      if (action.error) {
        return { ...state, settingsLoading: false }
      } else if (action.ready) {
        return {
          ...state,
          settingsLoading: false,
          profile: { ...state.profile, ...action.result },
          form: {
            ...state.form,
            name: action.result?.name,
            languageId: action.result?.languageId,
          },
        }
      }
      return { ...state, settingsLoading: true }

    case UPDATE_SETTING_FIELD_ACTION:
      const newState = { ...state }
      const { name, value } = action

      if ((name as string) === Fields.AgeFilterFrom) {
        newState.form.ageFilter.from = Number(value)
      } else if ((name as string) === Fields.AgeFilterTo) {
        newState.form.ageFilter.to = Number(value)
      } else if (name === Fields.DefaultPhoto) {
        newState.form.defaultPhoto = value.toLowerCase()
      } else {
        newState.form[name] = (value as unknown) as never
      }

      newState.errorCode = null

      return newState

    case FETCH_PERSONAL_SETTINGS:
      if (action.ready) {
        if (
          action.error ||
          (apiResultHasErrors(action.result) &&
            !isApi5PersonalBlockedError(action.result))
        ) {
          return { ...state, settingsLoading: false }
        }

        const values = extractFieldsValues(
          action.result?.formBuilder ||
            action.result?.internalError.formBuilder,
          {}
        )
        const form = extractFieldsFlatValues(values)
        const newVariants = Object.keys(values).reduce(
          (acc, field) => ({ ...acc, [field]: values[field].variants }),
          {}
        )

        return {
          ...state,
          settingsLoading: false,
          profile: { ...state.profile, ...form },
          form: { ...state.form, ...form },
          variants: { ...state.variants, ...newVariants },
          personalSettingsLoaded: true,
        }
      }
      return { ...state, settingsLoading: true }

    case FETCH_MESSAGE_FILTER:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          settingsLoading: true,
        }),
        (result) => {
          if (result) {
            return {
              settingsLoading: false,
              personalSettingsLoaded: true,
              form: {
                ...state.form,
                genderFilter: result.genderFilter,
                likedFilter: result.onlyLiked,
                photoFilter: result.messagesOnlyFromVerifiedByPhoto,
                isPhotoFilterAvailable: Boolean(
                  result.isAvailableMessagesOnlyFromVerifiedByPhoto
                ),
                vipFilter: result.onlyVip,
              },
            }
          }

          return state
        },
        () => ({
          settingsLoading: false,
        })
      )

    case RESET_LOCATION_SETTINGS:
      return { ...state, locationLoaded: false }

    case RESET_FORM:
      return { ...state, formError: {} }

    case SAVE_SETTING_FIELD_LEGACY_ACTION:
      if (action.ready) {
        if (
          action.error ||
          (apiResultHasErrors(action.result) &&
            !isApi5PersonalBlockedError(action.result))
        ) {
          return { ...state, settingsLoading: false }
        }
        const formError = extractFormFieldsErrors(
          (action.result?.formBuilder ||
            action.result?.internalError.formBuilder) as FormBuilder
        )
        if (Object.keys(formError).length === 0) {
          return {
            ...state,
            profile: {
              ...state.profile,
              [action.field]: state.form[action.field],
            },
            settingsLoading: false,
            formError,
          }
        }
        return { ...state, settingsLoading: false, formError }
      }
      return { ...state, settingsLoading: true }

    case SAVE_SETTING_FIELD_EMAIL_ACTION:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ settingsLoading: true }),
        () => ({
          profile: {
            ...state.profile,
            [action.field]: state.form[action.field],
          },
          settingsLoading: false,
        }),
        () => ({
          settingsLoading: false,
          formError: {
            [action.field]: action.result!.internalError.code,
          },
        })
      )

    case CLEAR_SETTING:
      return {
        ...state,
        profile: {
          ...state.profile,
          [action.field]: undefined,
        },
      }

    case FETCH_SETTING:
      if (action.ready && action.result) {
        return {
          ...state,
          profile: {
            ...state.profile,
            [action.field]: extractFetchFieldResult(
              action.field,
              action.result
            ),
          },
          form: {
            ...state.form,
            [action.field]: extractFetchFieldResult(
              action.field,
              action.result
            ),
          },
        }
      }
      return state

    case RESET_SETTINGS:
      return {
        ...state,
        form: {
          ...state.form,
          password: '',
          passwordCheck: '',
          passwordNewCheck: '',
        },
        photoStatus: null,
      }

    case TOGGLE_SETTINGS_INFO:
      return {
        ...state,
        info: { [action.settingInfoId]: !state.info[action.settingInfoId] },
      }

    case TOGGLE_SETTING_ENABLED:
      return defaultPromiseReducer(
        state,
        action,
        () => {
          const hasActiveNotification =
            (action.field === Fields.MessageSound && action.enabled) ||
            state.hasActiveNotification
          return {
            lockUpdates: { ...state.lockUpdates, [action.field]: true },
            profile: {
              ...state.profile,
              [action.field]: Boolean(action.enabled),
            },
            form: { ...state.form, [action.field]: Boolean(action.enabled) },
            hasActiveNotification: Boolean(hasActiveNotification),
            ...(action.field === SingleSelectFieldsSettings.Incognito && {
              incognitoStatusUpdating: true,
            }),
          }
        },
        () => ({
          lockUpdates: { ...state.lockUpdates, [action.field]: false },
          ...(action.field === SingleSelectFieldsSettings.Incognito && {
            incognitoStatusUpdating: false,
          }),
        }),
        () => ({
          ...(action.field === SingleSelectFieldsSettings.Incognito && {
            incognitoStatusUpdating: false,
          }),
        })
      )

    case UPDATE_SETTING:
      if (action.ready) {
        return {
          ...state,
          lockUpdates: { ...state.lockUpdates, [action.field]: false },
        }
      }
      return {
        ...state,
        lockUpdates: { ...state.lockUpdates, [action.field]: true },
        profile: { ...state.profile, [action.field]: action.value },
        form: { ...state.form, [action.field]: action.value },
      }

    case TOGGLE_EMAIL_SUBSCRIPTION:
      if (action.ready) {
        return {
          ...state,
          lockUpdates: { ...state.lockUpdates, [action.field]: false },
        }
      }

      const notifications = clone(state.notifications)
      const notification = notifications.find(({ id }) => id === action.field)
      notification!.subscribed = action.value
      const hasActiveNotification =
        notifications.some(
          (emailNotification) => emailNotification.subscribed
        ) || state.profile[Fields.MessageSound as keyof SettingsForm]
      return {
        ...state,
        lockUpdates: { ...state.lockUpdates, [action.field]: true },
        notifications,
        hasActiveNotification: Boolean(hasActiveNotification),
      }

    case SUBSCRIBE_ON_PUSHES:
      if (action.ready) {
        return {
          ...state,
          lockUpdates: { ...state.lockUpdates, [action.field]: false },
        }
      }

      const pushSubscriptionGroups = clone(state.pushSubscriptionGroups)
      const pushSubscriptionGroup = pushSubscriptionGroups.find(({ id }) => {
        return String(id) === action.field
      })!
      pushSubscriptionGroup.subscribed = action.value

      return {
        ...state,
        lockUpdates: { ...state.lockUpdates, [action.field]: true },
        pushSubscriptionGroups,
      }

    case TOGGLE_SEARCH_VISIBILITY:
      if (action.ready) {
        return { ...state, settingsLoading: false }
      }
      return { ...state, settingsLoading: true }

    case FETCH_VIP_SETTINGS:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ settingsLoading: true }),
        () => ({
          profile: { ...state.profile, ...clone(action.result) },
          form: { ...state.form, ...clone(action.result) },
          settingsLoading: false,
          vipSettingsLoaded: true,
        }),
        () => ({
          settingsLoading: false,
          vipSettingsLoaded: true,
          vipRequired: action.result?.internalError?.code === 'vip_is_required',
        })
      )

    case FETCH_ALLOWED_VERIFICATION:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ verificationMethodsLoading: true }),
        () => ({
          verificationMethodsLoading: false,
          verificationMethods: action.result,
        }),
        () => ({ verificationMethodsLoading: false })
      )

    case FETCH_PHONE_CODE_LIST:
      if (action.ready) {
        if (isActionWithoutErrors(action)) {
          // @ts-ignore Кривая документация бекенда
          const phoneCodeList: (real_user_phone_verification['RealUserPrefixesGroup'] & {
            selected: boolean
          })[] = action.result!.reduce<
            {
              countries: (definitions['RealUserPrefix'] & {
                selected: boolean
              })[]
            }[]
          >((acc, { countries }) => {
            // @ts-ignore
            return acc.concat(countries)
          }, [])
          const selectedCode = phoneCodeList.find(({ selected }) => selected)
          if (selectedCode) {
            // @ts-ignore
            const { prefix, countryCode } = selectedCode
            const phone = {
              code: prefix,
              number: state.form.number,
              countryCode,
            }
            return {
              ...state,
              phoneCodeList,
              profile: { ...state.profile, phone },
              form: { ...state.form, phone },
            }
          }
          return { ...state, phoneCodeList }
        }
      }
      return state

    case DELETE_ACCOUNT:
    case REMOVE_ACCOUNT:
    case REQUEST_GDPR_DATA:
    case FETCH_GDPR_ARCHIVE:
    case REMOVE_GDPR:
      // case RECALL_GDPR:
      if (action.ready) {
        if (actionHasErrors(action)) {
          return { ...state, ...errorCode(action.result) }
        }
        return { ...state, settingsLoading: false }
      }
      return { ...state, settingsLoading: true }

    case VERIFY_PHONE:
      return getVerifyPhoneCaseHandler(state, action)

    case VERIFY_CODE:
      return getVerifyCodeCaseHandler(state, action)

    case PHONE_VERIFICATION_CODE_RESEND:
      return getPhoneVerificationCodeResendCaseHandler(state, action)

    case PHONE_VERIFICATION_ALTERNATIVE_METHOD:
      return getPhoneVerificationAlternativeMethodCaseHandler(state, action)

    case RESET_PHONE_VERIFICAITON_MOBILE_ID_SUCCESS:
      return {
        ...state,
        phoneVerificationMobileIdSuccess:
          initialState.phoneVerificationMobileIdSuccess,
      }

    case FETCH_SOCIAL_VERIFICATION:
      const { provider } = action
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          socialLoading: { ...state.socialLoading, [provider]: true },
        }),
        (result) => ({
          socialLoading: { ...state.socialLoading, [provider]: false },
          socialVerify: {
            ...state.socialVerify,
            [action.provider]: result,
          },
        }),
        () => ({
          socialLoading: { ...state.socialLoading, [provider]: false },
        })
      )

    case FETCH_EXTERNAL_VERIFICATION:
      return defaultPromiseReducer(state, action, undefined, () => ({
        externalVerify: {
          ...state.externalVerify,
          [action.method]: action.result,
        },
      }))

    case FETCH_GESTURE:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          settingsLoading: true,
          gestureLoading: true,
        }),
        () => ({
          settingsLoading: false,
          gesture: action.result?.gesture,
          gestureLoaded: true,
          gestureLoading: false,
        }),
        () => ({
          gestureErrorCode: getApi6ErrorCode(action.result),
          settingsLoading: false,
          gestureLoaded: true,
          gestureLoading: false,
        })
      )

    case UPLOAD_VERIFICATION_PHOTO:
      if (action.ready) {
        if (actionHasErrors(action)) {
          return { ...state, ...errorCode(action.result), photoLoaded: false }
        }

        return { ...state, photoLoaded: true }
      }
      return { ...state, settingsLoading: true }

    case VERIFICATION_PHOTO_STATUS:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ settingsLoading: true, photoStatusLoading: true }),
        (result) => ({
          photoStatus: result?.status,
          settingsLoading: false,
          photoStatusLoading: false,
        }),
        () => ({ settingsLoading: false, photoStatusLoading: false })
      )

    case FETCH_EMAIL_SUBSCRIPTIONS:
      if (action.ready) {
        if (actionHasErrors(action)) {
          return { ...state, ...errorCode(action.result) }
        }
        if (action.result) {
          const { email, emailNotifications } = action.result
          return {
            ...state,
            settingsLoading: false,
            notifications: emailNotifications,
            email: email.email,
            hasActiveNotification: emailNotifications.some(
              (emailNotification) => emailNotification.subscribed
            ),
          }
        }

        return state
      }
      return { ...state, settingsLoading: true }

    case FETCH_PUSH_SUBSCRIPTIONS: {
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          settingsLoading: true,
        }),
        (result) => ({
          settingsLoading: false,
          pushSubscriptionGroups: result.pushGroups,
        }),
        () => ({
          settingsLoading: false,
        })
      )
    }

    case ALLOWED_TO_DELETE:
      return defaultPromiseReducer(state, action, undefined, () => ({
        willBeAllowedAt: action.result?.willBeAllowedAt,
        willBeAllowedAtLoaded: true,
      }))

    case FETCH_GDPR_STATUS:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          gdprStatusLoading: true,
        }),
        ({ consentGivenAt }) => ({
          gdprDate: consentGivenAt,
          gdprStatusLoading: false,
          gdprStatusLoaded: true,
        }),
        () => ({
          gdprStatusLoading: false,
          gdprStatusLoaded: true,
        })
      )

    case RECALL_GDPR:
      return defaultPromiseReducer(
        state,
        action,
        () => ({}),
        ({ ok }) => {
          if (ok) {
            return {
              ...state,
              wrongPassword: false,
            }
          }
          return {
            ...state,
            wrongPassword: true,
          }
        }
      )

    case CHECK_PASSWORD_PROFILE:
      return defaultPromiseReducer(
        state,
        action,
        () => ({}),
        () => {
          if (action.result && !action.result.result) {
            return {
              wrongPassword: true,
            }
          }

          return null
        }
      )

    case FETCH_MY_LOCATION:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ locationUpdating: true }),
        (result) => ({
          locationUpdating: false,
          location: result,
          locationLoaded: true,
        }),
        () => ({
          locationUpdating: false,
          locationLoaded: true,
        })
      )

    case FETCH_CURRENT_LOCATION:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ locationUpdating: true }),
        (result) => ({
          locationUpdating: false,
          location: {
            location: result,
            // Сервер не присылает
            locationString: '',
          },
          locationLoaded: true,
        }),
        () => ({
          locationUpdating: false,
          locationLoaded: true,
        })
      )

    case PASSWORD_REQUIRED:
      return defaultPromiseReducer(
        state,
        action,
        (): Partial<SettingsReducerState> => ({
          passwordRequiredLoading: true,
        }),
        (): Partial<SettingsReducerState> => ({
          phonePasswordRequired: action.result?.required,
          passwordRequiredLoading: false,
        }),
        (): Partial<SettingsReducerState> => ({
          passwordRequiredLoading: false,
        })
      )

    case ACCEPT_GDPR:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ settingsLoading: true }),
        () => ({ settingsLoading: false }),
        () => ({ settingsLoading: false })
      )

    case FETCH_SOUND_NOTIFICATION_STATUS:
      return defaultPromiseReducer(state, action, undefined, () => {
        return {
          form: {
            ...state.form,
            messageSound: action.result?.enabled,
          },
        }
      })

    case FETCH_MAIN_PHOTO_UPDATE_SETTING:
      return defaultPromiseReducer(state, action, undefined, () => ({
        form: {
          ...state.form,
          defaultPhoto: action.result?.mode,
        },
      }))

    case FETCH_USER_SETTINGS_CONTAINER:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ settingsContainerLoaded: false }),
        (result) => {
          /**
           * Кривой контейнерный запрос,
           * статус всегда 200 дальше если во всех ответах ошибка.
           */
          if (result[0].code === 403) {
            return {
              ...state,
              settingsContainerLoaded: false,
            }
          }

          const isVipRequired =
            result[3].body &&
            result[3].body.code &&
            result[3].body.code === 'vip_is_required'

          const newState: Partial<SettingsReducerState> = {
            settingsContainerLoaded: true,
            notifications: result[0].body?.emailNotifications,
            email: result[0].body?.email.email,
            profile: {
              ...state.profile,
              email: result[0].body?.email.email,
            },
            hasActiveNotification:
              result[0].body?.emailNotifications.some(
                (emailNotification) => emailNotification.subscribed
              ) || Boolean(state.form.messageSound),
            form: {
              ...state.form,
              incognito: result[1].body.isOn,
              likedFilter: result[4].body.onlyLiked,
              profileVisibilityStatus: result[2].body.status,
              defaultPhoto: result[5].body.mode,
            },
          }
          return {
            ...extractVipSettings(
              isVipRequired,
              result[4].body,
              newState,
              state
            ),
          }
        },
        () => ({
          settingsContainerLoaded: false,
        })
      )

    case REQUEST_ACCOUNT_REMOVAL_WITH_REASON:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          accountRemovalWithReasonRequesting: true,
          accountRemovalWithReasonRequested: false,
          accountRemovalWithReasonRequestFailed: false,
        }),
        () => ({
          accountRemovalWithReasonRequesting: false,
          accountRemovalWithReasonRequested: true,
          accountRemovalWithReasonRequestFailed: false,
        }),
        () => ({
          accountRemovalWithReasonRequesting: false,
          accountRemovalWithReasonRequested: true,
          accountRemovalWithReasonRequestFailed: true,
        })
      )

    case SOCKET_RECEIVE_DATA:
      return getSocketReceiveDataCaseHandler(state, action)

    case RESTORE_PARTIAL_STATE:
      if (action.name === nameReducer) {
        return {
          ...state,
          ...action.partialState.settingsReducer,
        }
      }

      return state

    case LOGOUT_ACTION:
      return defaultPromiseReducer(state, action, undefined, () => ({
        ...initialState,
      }))

    case CLEAR_LOCATION:
      return {
        ...state,
        locationLoaded: false,
        location: {
          locationString: '',
          location: {
            location: '',
          },
        },
      }

    /**
     * Нужно как минимум для https://youtrack.mamba.ru/issue/M-4262
     */
    case RESTORE_INITIAL_STATE:
      return initialStateReducer(state, action, 'settingsReducer')
  }

  return state
}

const nameReducer = 'settingsReducer'
