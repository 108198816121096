import cookie from 'js-cookie'
import { shellPath } from 'components/page/Pwa/Pwa.paths'
import { sPostCookie } from 'common/constants'
import { internalSessionInitApi } from 'api/authorization/sessionInitApi'

/**
 * Если сессия отвалилась, пробуем восстановить.
 * В случае PWA всегда делаем последовательный запрос.
 * @returns {Promise<void>}
 */
export const checkSession = async () => {
  if (
    window.__INITIAL_STATE__.router.location.pathname === shellPath ||
    !Boolean(cookie.get(sPostCookie))
  ) {
    await internalSessionInitApi()
  }

  return Promise.resolve()
}
