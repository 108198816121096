import { setCounterDownAction } from 'actions/utility/counterDown/counterDownAction'
import { phoneVerificationStartApi } from 'api/phoneVerification/phoneVerificationStartApi'
import { getPhoneVerificationSuccessPath } from 'components/page/Settings/Verification/functions'
import { replace } from 'functions/router'
import { createPhoneNumberWithCountryCodeNumber } from './verifyPhoneAction.functions'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'

export const verifyPhoneAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    settingsReducer: {
      form: {
        phone: { countryCode, phoneNumber, phoneNumberFull },
        passwordCheck,
      },
    },
    countriesInfo: { countriesList },
    userReducer: { userId },
    errorReducer: { realStatusNeeded },
    systemReducer: { redesign },
  } = getState()

  const _phoneNumber = redesign
    ? phoneNumberFull
    : createPhoneNumberWithCountryCodeNumber(
        phoneNumber!,
        countryCode!,
        countriesList
      )

  if (!_phoneNumber) {
    return
  }

  return dispatch(
    verifyPhonePlainAction(
      dispatch,
      _phoneNumber,
      passwordCheck,
      userId,
      realStatusNeeded
    )
  )
}

export const VERIFY_PHONE = 'VERIFY_PHONE' as const

export const verifyPhonePlainAction = (
  dispatch: AppDispatchNext,
  phoneNumber: string,
  passwordCheck: string,
  userId: number | null,
  realStatusNeeded: boolean
) => ({
  type: VERIFY_PHONE,
  promise: async () => {
    const response = await phoneVerificationStartApi(phoneNumber, passwordCheck)

    const result = response.result

    /** @ts-expect-error Неполнота типа result */
    if (result.code === 'phone_number_already_verified_with_this_user') {
      dispatch(
        replace(getPhoneVerificationSuccessPath(userId!, realStatusNeeded))
      )
      return response
    }

    // Установим таймер, отведенный для верификации
    if (result!.timer) {
      dispatch(setCounterDownAction(result!.timer))
    }

    return response
  },
})
