import { profilePath, settingsModalPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { settingsVerificationPath } from '../Settings.paths'
import {
  checkCodePath,
  phonePath,
  verifyByPhonePath,
  verifySuccessPath,
} from './Verification.paths'
import { definitions as real_user_general } from 'api/generated/real_user_general'

/** /profile/{profileId}/settings/verification/update/phone */
export const getPhoneVerificationPath = (
  profileId: string | number,
  realStatusNeeded: boolean
) =>
  mergeAllUrls(
    profilePath,
    realStatusNeeded ? '' : profileId,
    settingsModalPath,
    settingsVerificationPath,
    verifyByPhonePath
  )

/** /profile/{profileId}/settings/verification/check/success/phone */
export const getPhoneVerificationSuccessPath = (
  profileId: string | number,
  withoutProfileId: boolean
) =>
  mergeAllUrls(
    profilePath,
    withoutProfileId ? '' : profileId,
    settingsModalPath,
    settingsVerificationPath,
    verifySuccessPath,
    phonePath
  )

/** /profile/{profileId}/settings/verification/check/code */
export const getCheckVerificationCodePath = (
  profileId: string | number,
  withoutProfileId: boolean
) =>
  mergeAllUrls(
    profilePath,
    withoutProfileId ? '' : profileId,
    settingsModalPath,
    settingsVerificationPath,
    checkCodePath
  )

export const isVerifiedByAnyMethod = (
  verificationMethods: real_user_general['AllowedRealMethodsList'] | null
) => {
  if (!verificationMethods) {
    return
  }

  return Boolean(
    Object.keys(verificationMethods).find(
      (key) => verificationMethods[key].status === 'verified'
    )
  )
}
