import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'

export const DropSupportOldBrowserLoadable = loadable(
  () => import('./DropSupportOldBrowserIndex'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('DropSupportOldBrowserIndex split error', error)
      }
      return <DefaultLoader />
    })({
      pastDelay: true,
      error: false,
      timedOut: false,
    }),
  }
)
