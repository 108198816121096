/**
 * Места откуда произошел логин и куда надо перейти после.
 */
export enum ThenPlace {
  wink = 'wink',
  messenger = 'messenger',
  favourite = 'favourite',
  gift = 'gift',
  vip = 'vip',
  topUp = 'topUp',
}
