import { PartialRootState } from 'common/store/store.types'

export const RESTORE_INITIAL_STATE = 'RESTORE_INITIAL_STATE' as const

export interface RestoreInitialStateAction {
  type: typeof RESTORE_INITIAL_STATE
  stateName: string
  initialState: PartialRootState
}

export const restoreInitialStateAction = (
  stateName: string,
  initialState: PartialRootState
) => ({
  type: RESTORE_INITIAL_STATE,
  stateName,
  initialState,
})
