export const zIndex = {
  navigationHeader: 6,
  singleStream: 6,
  singleStreamNav: 7,
  messengerTooltip: 7,
  secondaryWrapper: 4,
  photoLine: 5,
  mailRuMenu: 7,
  messengerLayout: 7,
  geoLanding: 4,
  chatReplyBlock: 4,
  verificationTooltip: 2,
  base: 1,
  ratingDrag: 9,
  modal: 12,
  installAppBanner: 11,
  overAdvertising: 3,
} as const
