/**
 * Нужно для того, чтобы не выкидывать за пределы мамбы.
 * 2 - потому что, первая это пустая вкладка, потом уже переход
 * 1 - в случае перехода из другой страницы
 * Пример:
 * https://redmine.mamba.ru/issues/113424
 *
 * FYI
 * В Safari Desktop при переходе по ссылке с target="_blank",
 * в новой вкладке сохраняется window.history с прерыдущей вкладки, в Chrome Desktop нет.
 */
export const isHistoryEmpty = () => window.history.length < 2
