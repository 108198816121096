const hasSupportSessionStorage = () => {
  try {
    sessionStorage.setItem('test', '1')
    sessionStorage.removeItem('test')
    return true
  } catch (error) {
    return false
  }
}

const isSupportSessionStorage = hasSupportSessionStorage()

/**
 * @deprecated Сохраняем в сторадж через стейт.
 * Отдельно не делаем сохранения.
 * @param key
 * @param value
 */
export function setSessionKeyValue(key: string, value: unknown) {
  if (isSupportSessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(value))
  }
}

/**
 * @deprecated Сохраняем в сторадж через стейт.
 * Отдельно не делаем сохранения.
 * @param key
 */
export function getSessionKeyValue(key: string) {
  if (isSupportSessionStorage) {
    return sessionStorage.getItem(key)
  }
}
