import {
  SUPPORT_FORM_ACTION,
  RESET_SUPPORT_FORM_ACTION,
  UPDATE_SUPPORT_FIELD_ACTION,
  ERROR_SUPPORT_FIELD_ACTION,
  ADD_MAIL_SENDER,
  SupportActionTypes,
} from 'actions/supportAction'

import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

interface FormError {
  screenshot: string | null
}
export interface SupportFormState {
  form: {
    name: string
    login: string
    email: string
    textarea: string
    screenshot: null | string
  }
  formError: FormError
  genericError: boolean
  sending: boolean
  sent: boolean
  emailSender: string
}

export const supportFormReducer = (
  state: SupportFormState = {
    form: {
      name: '',
      login: '',
      email: '',
      textarea: '',
      screenshot: null,
    },
    formError: {
      screenshot: null,
    },
    genericError: false,
    sending: false,
    sent: false,
    emailSender: '',
  },
  action: SupportActionTypes
): SupportFormState => {
  switch (action.type) {
    case UPDATE_SUPPORT_FIELD_ACTION:
      const update = {
        form: {
          ...state.form,
          [action.name]: action.value,
        },
        formError: {
          screenshot: null,
        },
      }

      if (action.name === 'email') {
        update.form[action.name] = (action.value as string).trim()
      }

      return {
        ...state,
        ...update,
      }

    case SUPPORT_FORM_ACTION:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          sending: true,
        }),
        () => ({
          sending: false,
        }),
        () => ({
          sending: false,
        })
      )

    case ADD_MAIL_SENDER: {
      return {
        ...state,
        emailSender: action.result,
      }
    }

    case ERROR_SUPPORT_FIELD_ACTION:
      return {
        ...state,
        formError: { ...state.formError, [action.name]: action.error },
      }

    case RESET_SUPPORT_FORM_ACTION:
      return {
        ...state,
        form: {
          name: '',
          login: '',
          email: '',
          textarea: '',
          screenshot: null,
        },
        formError: {
          screenshot: null,
        },
        genericError: false,
        sending: false,
      }
    default:
      return state
  }
}
