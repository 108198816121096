import {
  clearCounterDownAction,
  decreaseCounterDownAction,
  setCounterDownIdAction,
} from 'actions/utility/counterDown/counterDownAction'
import { TimeRange } from 'common-constants/timeRange'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

/**
 * Активирует счетчик при появлении значения timer в counterDownReducer.
 * Хук предполагается использовать в корне приложения.
 */
export const useCounterDown = () => {
  const dispatch = useDispatch()

  const { counterDown } = useShallowEqualSelector(({ counterDown }) => ({
    counterDown,
  }))

  const [start, setStart] = useState(false)

  const timer = counterDown?.timer
  const timerId = counterDown?.timerId

  // Включим таймер
  useEffect(() => {
    if (timer > 0 && !start) {
      setStart(true)
    }
  }, [timer, start, dispatch])

  // Выключим таймер
  useEffect(() => {
    if (timer === 0 && start) {
      setStart(false)
    }
  }, [timer, start, dispatch])

  // Установим интервал для таймера
  useEffect(() => {
    if (start && !timerId) {
      const _timerId = window.setInterval(() => {
        dispatch(decreaseCounterDownAction())
      }, TimeRange.second)
      dispatch(setCounterDownIdAction(_timerId))
    }
  }, [dispatch, start, timerId])

  // Очистим интервал для таймера и состояние таймера
  useEffect(() => {
    if (!start && timerId) {
      dispatch(clearCounterDownAction())
    }
  }, [dispatch, start, timerId])
}
