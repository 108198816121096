import { AsyncAction } from 'actions/actions.types'
import { Api6Error } from 'api/api.types'
import { actionHasErrors, notEmpty } from 'reducers/isActionWithoutErrors'

export type PromiseHandler<R, S> = (result: R, state: S) => Partial<S> | null
export type PromiseErrorHandler<R, S> = (
  result: R,
  state: S,
  error: Api6Error,
  errorStatus: number
) => Partial<S> | null | undefined

export const defaultPromiseReducer = <S, R>(
  state: S,
  action: AsyncAction<R>,
  beforeReadyReducer?: PromiseHandler<R, S>,
  afterReadyReducer?: PromiseHandler<R, S>,
  errorReadyReducer?: PromiseErrorHandler<R, S>
): S => {
  const { ready, result, error, errorStatus } = action
  if (ready) {
    if (actionHasErrors(action)) {
      if (errorReadyReducer) {
        return {
          ...state,
          ...errorReadyReducer(result!, state, error!, errorStatus!),
        }
      }
      return state
    }
    if (afterReadyReducer) {
      const stateUpdate = afterReadyReducer(result!, state)
      if (notEmpty(stateUpdate)) {
        return { ...state, ...stateUpdate }
      }
    }
  }
  if (beforeReadyReducer) {
    const stateUpdate = beforeReadyReducer(result!, state)
    if (notEmpty(stateUpdate)) {
      return { ...state, ...stateUpdate }
    }
  }
  return state
}
