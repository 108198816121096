import { AuthorizationTypes } from 'actions/authorizationAction'
import {
  DELETE_META_DATA,
  FETCH_CUSTOM_META_DATA,
  MetaDataUpdateTypes,
  SET_CUSTOM_META_DATA,
  UPDATE_META_DATA_BOOTSTRAP,
} from 'actions/metaData/metaDataAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { initialState, MetaDataState } from 'reducers/metaData/MetaDataState'
import { LOGIN_ACTION } from 'actions/authorization/loginAction'
import { Reducer } from 'redux'
import { LOCATION_CHANGE, RouterActions } from 'redux-first-history'
import {
  MessengerMessagesCounterComet,
  SOCKET_RECEIVE_DATA,
  SocketData,
  SocketReceiveDataTypes,
} from 'actions/socket/socketReceiveDataAction'
import {
  SWITCH_OFF_UPDATE_TITLE_NEW_MESSAGE,
  toggleSwitchTitleNewMessagePlainAction,
} from 'actions/metaData/toggleSwitchTitleNewMessage'
import { filterCometMessages } from 'reducers/functions'
import { createMessengerCounterChannel } from 'functions/comet'
import { notEmptyArray } from 'functions/array'

export const metaDataReducer: Reducer<
  MetaDataState,
  | MetaDataUpdateTypes
  | AuthorizationTypes
  | RouterActions
  | SocketReceiveDataTypes
  | ReturnType<typeof toggleSwitchTitleNewMessagePlainAction>
> = (state = initialState, action): MetaDataState => {
  switch (action.type) {
    case UPDATE_META_DATA_BOOTSTRAP:
      return {
        ...state,
        title: action.title,
        description: action.description,
        metaRobots: action.metaRobots,
        canonicalUrl: action.canonicalUrl,
        hasInfo: true,
      }

    case LOCATION_CHANGE:
    case DELETE_META_DATA:
      return { ...initialState }

    /**
     * Если по url ничего не найдено, то будет ошибка,
     * сбрасывает состояние до первоначального.
     */
    case FETCH_CUSTOM_META_DATA:
      return defaultPromiseReducer(
        state,
        action,
        undefined,
        ({ title, description, canonicalUrl, metaRobots }) => ({
          title,
          description,
          metaRobots,
          canonicalUrl,
          hasInfo: true,
        }),
        () => ({
          ...initialState,
        })
      )

    case SET_CUSTOM_META_DATA:
      const update: Partial<MetaDataState> = {}
      if (action.title) {
        update.title = action.title
      }
      if (action.description) {
        update.description = action.description
      }
      if (action.metaRobots) {
        update.metaRobots = action.metaRobots
      }
      if (action.canonicalUrl) {
        update.canonicalUrl = action.canonicalUrl
      }
      return {
        ...state,
        ...update,
        hasInfo: true,
      }

    // TODO смотрится так себе, но пока времени нет переделывать внутри socketReceiveDataAction на map -> action
    case SOCKET_RECEIVE_DATA:
      return defaultPromiseReducer(state, action, undefined, () => {
        const unreadMessages = filterCometMessages<
          MessengerMessagesCounterComet
        >(
          action.result as SocketData<MessengerMessagesCounterComet>[],
          createMessengerCounterChannel(action.userId)
        ).filter((message) => Boolean(message.content.count_unread))

        if (notEmptyArray(unreadMessages)) {
          return {
            updateTitleNewMessage: true,
          }
        }

        return state
      })

    case SWITCH_OFF_UPDATE_TITLE_NEW_MESSAGE:
      return {
        ...state,
        updateTitleNewMessage: false,
      }

    case LOGIN_ACTION:
      return defaultPromiseReducer(state, action, undefined, () => ({
        ...initialState,
      }))

    default:
      return state
  }
}
