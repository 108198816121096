import { fetchApi, postApi } from 'api/fetchApi'
import { definitions, paths } from 'api/generated/complaints'

export type ProfileId = number
type PhotoId = number

export type ComplaintsEntityType = ProfileId | PhotoId

/**
 * TODO: Попросить сервер сделать правильный enum
 * и добавить его в swagger
 */
export enum ComplaintsType {
  photo = 'photo',
  profile = 'anketa',
  message = 'message',
  photoTop = 'photo_top',
}

export const complaintListApi = (
  violatorId: ProfileId,
  complaintsType: ComplaintsType,
  entityId: ComplaintsEntityType
) =>
  fetchApi<definitions['ComplaintType']>(
    `/complaints/general_causes/${violatorId}/${complaintsType}/${entityId}` as keyof paths
  )

export const saveComplaintApi = (
  violatorId: ProfileId,
  type: ComplaintsType,
  cause: number,
  entityId: ComplaintsEntityType
) =>
  postApi<
    definitions['EmptyResultUniNotice'],
    definitions['AddComplaintPostBodyRequest']
  >('/add_complaint', {
    violatorId,
    type,
    cause,
    entityId,
  })
