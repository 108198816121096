import { fetchApi } from 'api/index'
import { capitalizeFirstLetter } from 'functions/index'
import { NumberRange } from 'reducers/registration/stepRegistrationReducer'

interface FieldValue {
  value: number
}

/**
 *
 * Изменяет поле в профиле знакомств
 * @param fieldName - Имя поля
 * @param fieldValue - Значение поля
 * @returns {Promise<*|*|*|*|*|undefined>}
 */
export function changeDatingProfileApi(
  fieldName: string,
  fieldValue: string | FieldValue | NumberRange
) {
  const pathName = capitalizeFirstLetter(fieldName)
  if (
    (Array.isArray(fieldValue) && fieldValue.length > 0) ||
    (!Array.isArray(fieldValue) && !!fieldName)
  ) {
    let resultValue = fieldValue
    if (fieldValue && (fieldValue as FieldValue).value === 0) {
      // Бекенд не может принять value 0 для сброса, только null
      resultValue = null!
    }
    return fetchApi(`/dating-profile/change${pathName}`, {
      method: 'POST',
      body: JSON.stringify({
        [fieldName]: resultValue,
      }),
    })
  }

  // TODO: я не помню зачем это(
  return fetchApi(`/dating-profile/change${pathName}`, {
    method: 'POST',
  })
}
