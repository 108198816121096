import { mergeAllUrls } from 'functions/mergeAllUrls'
import { LOCALES_REGEXP } from 'common-constants/locale'

export const cleanRegExp = new RegExp(`^/(${LOCALES_REGEXP})/?`, 'gi')

export const removeLocale = (pathname: string) =>
  mergeAllUrls(pathname.replace(cleanRegExp, ''))

/**
 * @deprecated Использовать removeLocaleFromPath2, эта функция срезает лишнего,
 * если первые буквы совпадают с локалью.
 * @param pathname
 */
export const removeLocaleSaveSlash = (pathname: string) => {
  const withoutLocale = removeLocale(pathname)
  if (withoutLocale[0] === '/') {
    return withoutLocale
  }
  return '/' + withoutLocale
}
