import { AsyncThunkAction } from 'actions/types'
import { Api5AuthResponse } from 'common-constants/api5.types'
import { metricRegisteredAction } from 'actions/metric/metricAction'

export const postRegistrationAction = (
  json: Api5AuthResponse
): AsyncThunkAction => (dispatch) => {
  if (json.isAuth) {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'registration' })
    }
    dispatch(metricRegisteredAction())
  }
}
