import { userProfileAlbumPath } from 'components/page/UserProfile/UserProfileAlbum/UserProfileAlbum.paths'
import { UserProfileAlbumLoadable } from 'components/page/UserProfile/UserProfileAlbum/UserProfileAlbumLoadable'

export const userProfileAlbumRoutes = [
  {
    path: userProfileAlbumPath,
    component: UserProfileAlbumLoadable,
    exact: false,
  },
]
