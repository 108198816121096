import { getApi6ErrorCode } from 'api/functions'
import { AsyncAction } from 'actions/actions.types'
import { apiResultHasErrors } from 'api/index'

export const isActionBlockedTrackLegacy = (result) => {
  if (result) {
    return result.errorCode === 131
  }
}
export const actionHasErrors = (action: AsyncAction) => {
  return (
    action.error ||
    action.errorStatus === 500 ||
    isActionBlockedTrackLegacy(action.result) ||
    apiResultHasErrors(action.result)
  )
}

/**
 * @deprecated Нужно использовать из defaultPromiseReducer.ts
 */
export function defaultPromiseReducer(
  state,
  action,
  beforeReadyReducer,
  afterReadyReducer,
  errorReadyReducer
) {
  const { ready } = action

  if (ready === undefined) {
    console.warn('Action without promise status', action)
  }

  if (ready) {
    if (actionHasErrors(action)) {
      const errorCode = getApi6ErrorCode(action.result)
      if (errorReadyReducer) {
        return {
          ...state,
          errorCode,
          ...errorReadyReducer(),
        }
      }
      return state
    }
    if (afterReadyReducer) {
      const result = afterReadyReducer(action.result)
      if (notEmpty(result)) {
        return { ...state, ...result }
      }
    }
  }
  if (beforeReadyReducer) {
    const result = beforeReadyReducer(action)
    if (notEmpty(result)) {
      return { ...state, ...result }
    }
  }
  return state
}

export const notEmpty = (result) => {
  return result && Object.keys(result).length
}
export const isActionWithoutErrors = (action: AsyncAction) => {
  return !actionHasErrors(action)
}
