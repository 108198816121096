/* eslint-disable max-lines */
import { push } from 'functions/router'
import {
  allowedToDeleteApi,
  eroticPhotosEnabledApi,
  fetchAtlasApi,
  fetchExternalVerifyApi,
  fetchIncognitoApi,
  fetchPhoneCodeListApi,
  fetchSocialVerifyApi,
  fetchVipSettingsApi,
  fetchWhoCanFindApi,
  messageSoundEnabledApi,
  requestGestureApi,
  updateEmailSubscriptionApi,
  uploadVerificationPhotoApi,
} from 'api/settings/settingsApi'
import { updateSettingsFieldAction } from 'actions/userAction'
import { AsyncThunkAction } from 'actions/types'
import {
  Fields,
  SingleSelectFieldsSettings,
} from 'components/page/Settings/Fields'
import { fetchAllowedVerificationApi } from 'api/verification/fetchAllowedVerificationApi'
import {
  AsyncAction,
  AsyncThunkAction2,
  AsyncThunkActionVoid,
  PlainAction,
} from 'actions/actions.types'
import { SettingsForm } from 'reducers/settings/SettingsReducerState'
import { Api5Error } from 'common-constants/api5.types'
import { paths } from 'api/generated/real_user_general'
import { definitions } from 'api/generated/real_user_phone_verification'
import { paths as photo_verification } from 'api/generated/photo_verification'
import { paths as user_settings } from 'api/generated/user_settings'
import { UpdateSettingAction } from 'actions/updateSettingAction'
import { VerifyCodeAction } from './settings/verifyCodeAction'
import { phoneVerificationPasswordRequirementApi } from 'api/phoneVerification/phoneVerificationPasswordRequirementApi'
import {
  ToggleSettingsCommonAction,
  toggleSettingsCommonAction,
} from 'actions/settings/toggleSettingAction'
import { updateFieldAction } from 'actions/user/updateFieldAction'
import { TOGGLE_SEARCH_VISIBILITY } from 'actions/settings/toggleSearchVisibilityAction'
import { IsEnabledSoundNotificationAction } from 'actions/settings/isEnabledSoundNotificationAction'
import { NodeHeaders } from 'api/NodeHeaders'
import { fetchMessagesFilterApi } from 'api/settings/fetchMessagesFilterApi'
import { fetchVerificationPhotoStatusPlainAction } from './settings/photoVerificationAction'

const fetchSettingSupportedFields = [
  'eroticPhotos',
  Fields.MessageSound,
  Fields.ProfileVisibilityStatus,
  'incognito',
  'atlas',
]

export const CLEAR_SETTING = 'CLEAR_SETTING'

interface ClearSettingAction extends PlainAction {
  type: typeof CLEAR_SETTING
  field: keyof SettingsForm
}

export const clearSettingAction = (field: string) => ({
  type: CLEAR_SETTING,
  field,
})

export const FETCH_SETTING = 'FETCH_SETTING'

interface FetchSettingIfNeededAction extends AsyncAction {
  type: typeof FETCH_SETTING
  field: keyof SettingsForm
}

export const fetchSettingIfNeededAction = (
  field: keyof SettingsForm,
  callback?: Function
): AsyncThunkAction => (dispatch, getState) => {
  if (
    fetchSettingSupportedFields.indexOf(field) > -1 &&
    getState().settingsReducer.profile[field] === undefined
  ) {
    dispatch({
      field,
      type: FETCH_SETTING,
      promise: async () => {
        const response = await findFetchSettingMethod(field)

        if (typeof callback === 'function') {
          callback()
        }

        return response
      },
    })
  }
}

export const findFetchSettingMethod = (field: string) => {
  switch (field) {
    case 'eroticPhotos':
      return eroticPhotosEnabledApi()
    case Fields.MessageSound:
      return messageSoundEnabledApi()
    case Fields.ProfileVisibilityStatus:
      return fetchWhoCanFindApi()
    case SingleSelectFieldsSettings.Incognito:
      return fetchIncognitoApi()
    case 'atlas':
      return fetchAtlasApi()
  }
}

export const toggleSettingSoundSoundAction = (
  field: keyof SettingsForm
): AsyncThunkAction => async (dispatch, getState) => {
  const { settingsReducer } = getState()
  if (!settingsReducer.lockUpdates[field]) {
    const enabled = !settingsReducer.form[field]
    dispatch(updateFieldAction(field, enabled))
    dispatch(toggleSettingsCommonAction(field, enabled))
  }
}

interface ToggleSearchVisibilityAction extends AsyncAction {
  type: typeof TOGGLE_SEARCH_VISIBILITY
  enabled: boolean
}

export const FETCH_VIP_SETTINGS = 'FETCH_VIP_SETTINGS'

interface FetchVipSettingsIfNeededAction extends AsyncAction<Api5Error> {
  type: typeof FETCH_VIP_SETTINGS
}

export const fetchVipSettingsIfNeededAction = (): AsyncThunkAction2 => (
  dispatch,
  getState
) => {
  if (!getState().settingsReducer.vipSettingsLoaded) {
    return dispatch({
      type: FETCH_VIP_SETTINGS,
      promise: () => fetchVipSettingsApi(),
    })
  }

  return Promise.resolve()
}

export const FETCH_ALLOWED_VERIFICATION = 'FETCH_ALLOWED_VERIFICATION'

interface FetchAllowedVerificationIfNeededAction
  extends AsyncAction<
    paths['/real_user/allowed_methods']['get']['responses']['200']['schema']
  > {
  type: typeof FETCH_ALLOWED_VERIFICATION
}

export const fetchAllowedVerificationIfNeededAction = (
  force = false,
  headers?: NodeHeaders
): AsyncThunkAction2 => (dispatch, getState) => {
  const {
    verificationMethodsLoading,
    verificationMethods,
  } = getState().settingsReducer

  if (!verificationMethodsLoading && (!verificationMethods || force)) {
    return dispatch({
      type: FETCH_ALLOWED_VERIFICATION,
      promise: () => fetchAllowedVerificationApi(headers),
    })
  }

  return Promise.resolve()
}

export const FETCH_PHONE_CODE_LIST = 'FETCH_PHONE_CODE_LIST'

export interface FetchPhoneCodeIfNeededAction
  extends AsyncAction<
    // real_user_phone_verification['/real_user/phone_prefixes']['get']['responses']['200']['schema']
    { countries: (definitions['RealUserPrefix'] & { selected: boolean })[] }[]
  > {
  type: typeof FETCH_PHONE_CODE_LIST
}

export const fetchPhoneCodeIfNeededAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  if (!getState().settingsReducer.phoneCodeList) {
    dispatch({
      type: FETCH_PHONE_CODE_LIST,
      promise: () => fetchPhoneCodeListApi(getState().systemReducer.locale),
    })
  }
}

export const FETCH_SOCIAL_VERIFICATION = 'FETCH_SOCIAL_VERIFICATION'

interface FetchSocialVerifyAction extends AsyncAction<{ url: string }> {
  type: typeof FETCH_SOCIAL_VERIFICATION
  provider: string
}

export const fetchSocialVerifyAction = (provider: string): AsyncThunkAction => (
  dispatch,
  getState
) => {
  if (!getState().settingsReducer.socialLoading[provider]) {
    dispatch({
      type: FETCH_SOCIAL_VERIFICATION,
      promise: () => fetchSocialVerifyApi(provider),
      provider,
    })
  }
}

export const FETCH_EXTERNAL_VERIFICATION = 'FETCH_EXTERNAL_VERIFICATION'

interface FetchExternalVerifyAction extends AsyncAction<string> {
  type: typeof FETCH_EXTERNAL_VERIFICATION
  method: string
}

export const fetchExternalVerifyAction = (method: string): AsyncThunkAction => (
  dispatch,
  getState
) => {
  if (!getState().settingsReducer.externalVerify[method]) {
    dispatch({
      type: FETCH_EXTERNAL_VERIFICATION,
      promise: () => fetchExternalVerifyApi(method),
      method,
    })
  }
}

export const FETCH_GESTURE = 'FETCH_GESTURE'

interface RequestGestureAction
  extends AsyncAction<
    photo_verification['/photo_verification/requestGesture']['post']['responses']['200']['schema']
  > {
  type: typeof FETCH_GESTURE
}

export const requestGestureAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const { gestureLoading, gestureLoaded } = getState().settingsReducer
  if (!gestureLoading && !gestureLoaded) {
    dispatch({
      type: FETCH_GESTURE,
      promise: () => requestGestureApi(),
    })
  }
}

export const UPLOAD_VERIFICATION_PHOTO = 'UPLOAD_VERIFICATION_PHOTO'

interface UploadVerificationPhotoAction extends AsyncAction {
  type: typeof UPLOAD_VERIFICATION_PHOTO
}

export const uploadVerificationPhotoAction = (
  photo: string
): AsyncThunkActionVoid => async (dispatch, getState) => {
  const {
    systemReducer: { baseUrl },
  } = getState()
  const { result: json } = ((await dispatch({
    type: UPLOAD_VERIFICATION_PHOTO,
    promise: () => uploadVerificationPhotoApi(photo),
  })) as unknown) as { result: { error: unknown } }

  if (!json.error) {
    dispatch(push(baseUrl))
  }
}

export const TOGGLE_EMAIL_SUBSCRIPTION = 'TOGGLE_EMAIL_SUBSCRIPTION'

interface ToggleEmailSubscriptionAction extends AsyncAction {
  type: typeof TOGGLE_EMAIL_SUBSCRIPTION
  field: string
  value: boolean
}

export const toggleEmailSubscriptionAction = (
  field: string,
  value: string
) => ({
  type: TOGGLE_EMAIL_SUBSCRIPTION,
  promise: () => updateEmailSubscriptionApi(field, value),
  field,
  value,
})

export const FETCH_MESSAGE_FILTER = 'FETCH_MESSAGE_FILTER' as const

export const fetchMessagesFilterIfNeededAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  if (getState().settingsReducer.profile.filterEnabled === undefined) {
    dispatch(fetchMessagesFilterPlainAction())
  }
}

const fetchMessagesFilterPlainAction = () => ({
  type: FETCH_MESSAGE_FILTER,
  promise: () => fetchMessagesFilterApi(),
})

export const ALLOWED_TO_DELETE = 'ALLOWED_TO_DELETE'

interface FetchAllowedToDeleteIfNeededAction
  extends AsyncAction<
    user_settings['/settings/delete_account/is_allowed_to_delete']['post']['responses']['200']['schema']
  > {
  type: typeof ALLOWED_TO_DELETE
}

export const fetchAllowedToDeleteIfNeededAction = () => ({
  type: ALLOWED_TO_DELETE,
  promise: () => allowedToDeleteApi(),
})

export const PASSWORD_REQUIRED = 'PASSWORD_REQUIRED' as const

interface PasswordRequiredAction
  extends AsyncAction<
    Awaited<
      ReturnType<typeof phoneVerificationPasswordRequirementApi>
    >['result']
  > {
  type: typeof PASSWORD_REQUIRED
}

export const passwordRequiredAction = (): PasswordRequiredAction => ({
  type: PASSWORD_REQUIRED,
  promise: phoneVerificationPasswordRequirementApi,
})

export const CLEAR_LOCATION = 'CLEAR_LOCATION'

interface ClearLocationAction extends PlainAction {
  type: typeof CLEAR_LOCATION
}

export const clearLocationAction = () => ({
  type: CLEAR_LOCATION,
})

export const updateSettingsVipFieldsAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const {
    hiddenLastAccessTime,
    invisibleMode,
    hiddenAge,
  } = getState().settingsReducer.form

  if (invisibleMode) {
    dispatch(
      updateSettingsFieldAction(SingleSelectFieldsSettings.InvisibleMode, false)
    )
  }

  if (hiddenLastAccessTime) {
    dispatch(
      updateSettingsFieldAction(
        SingleSelectFieldsSettings.HiddenLastAccessTime,
        false
      )
    )
  }

  if (hiddenAge) {
    dispatch(
      updateSettingsFieldAction(SingleSelectFieldsSettings.HiddenAge, false)
    )
  }
}

export type SettingsActionTypes =
  | ReturnType<typeof fetchMessagesFilterPlainAction>
  | ClearSettingAction
  | FetchSettingIfNeededAction
  | ToggleSettingsCommonAction
  | UpdateSettingAction
  | ToggleEmailSubscriptionAction
  | ToggleSearchVisibilityAction
  | FetchVipSettingsIfNeededAction
  | FetchAllowedVerificationIfNeededAction
  | FetchPhoneCodeIfNeededAction
  | VerifyCodeAction
  | FetchSocialVerifyAction
  | FetchExternalVerifyAction
  | RequestGestureAction
  | UploadVerificationPhotoAction
  | ReturnType<typeof fetchVerificationPhotoStatusPlainAction>
  | FetchAllowedToDeleteIfNeededAction
  | PasswordRequiredAction
  | IsEnabledSoundNotificationAction
  | ClearLocationAction
