import React, { FC } from 'react'
import { androidOsType, iOsOsType } from 'common-constants/userAgent'
import { isStandalone } from 'functions/pwa/isStandalone'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useShowBannerOnCurrentPath } from './hooks/useShowBannerOnCurrentPath'
import { InstallPwaBannerLoadable } from './InstallPwaBannerLoadable'
import { useAddToHomescreenPrompt } from './hooks/useAddToHomescreenPrompt'
import { isPureDevMode } from 'functions/isPureDevMode'
import { BrowserList } from 'common-constants/bowser'
import { isLoveMailru, isMamba, isWamba, isYonja } from 'common/constants'
import { InstallMobileAppBannerLoadable } from './InstallMobileAppBannerLoadable'

/** Рендерит либо баннер установки мобильного приложения, либо баннер установки pwa */
export const InstallAppBanner: FC = () => {
  const { osType, partnerId, touch, browserName } = useShallowEqualSelector(
    ({ systemReducer: { osType, partnerId, touch, browserName } }) => ({
      osType,
      partnerId,
      touch,
      browserName,
    })
  )

  const showBannerOnCurrentPath = useShowBannerOnCurrentPath()
  const { deferredPrompt, setDeferredPrompt } = useAddToHomescreenPrompt()

  if (isPureDevMode()) {
    return null
  }

  const android = osType === androidOsType
  const ios = osType === iOsOsType

  if (
    touch &&
    (android || ios) &&
    !isStandalone() && // Не показываем для PWA
    showBannerOnCurrentPath
  ) {
    return <InstallMobileAppBannerLoadable />
  }

  const chrome = browserName === BrowserList.Chrome
  const edge = browserName === BrowserList.Edge
  /**
   * https://caniuse.com/web-app-manifest
   * Пришлось сделать проверку на браузер,
   * потому что в opera событие beforeinstallprompt фаерится,
   * но установить PWA нельзя.
   * Из-за этого показываем баннер бессмысленно.
   */
  const supportedBrowser = chrome || edge

  const supportedPartner =
    isMamba(partnerId) ||
    isWamba(partnerId) ||
    isLoveMailru(partnerId) ||
    isYonja(partnerId)

  if (
    !touch &&
    supportedBrowser &&
    supportedPartner &&
    deferredPrompt &&
    showBannerOnCurrentPath
  ) {
    return (
      <InstallPwaBannerLoadable
        deferredPrompt={deferredPrompt}
        setDeferredPrompt={setDeferredPrompt}
      />
    )
  }

  return null
}
