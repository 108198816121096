import { AsyncAction } from 'actions/actions.types'
import { fetchMiniApi } from 'api/fetchMiniApi'
import { NodeHeaders } from 'api/NodeHeaders'
import { MiniApiResult } from 'api/user/MiniApiResult'

export const UPDATE_MINI = 'UPDATE_MINI'

export interface UpdateMiniAction extends AsyncAction<MiniApiResult> {
  type: typeof UPDATE_MINI
}

export const updateMiniAction = (headers: NodeHeaders) => ({
  type: UPDATE_MINI,
  promise: () => fetchMiniApi(headers),
})
