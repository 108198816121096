import React, { FC } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { removeLocale } from 'components/system/system.functions'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { settingsPath } from 'components/paths'
import {
  accountDeletedPath,
  settingsMainPath,
} from 'components/page/Settings/Settings.paths'
import { confirmExitPath } from 'components/page/Settings/Verification/Verification.paths'
import { NoticeEmails } from 'components/system/redirect/NoticeEmail/constants'
import { Replace } from './Replace'
import { useAuthorized } from 'hooks/useAuthorized'

/**
 * https://redmine.mamba.ru/issues/115264
 * Почему сдвинули вниз по Дереву App.tsx
 * После авторизация на клиенте, мы ловим ошибку что пользователь удален
 * Сразу срабатывает редирект на notice удаленного пользователя.
 * Но сразу после авторизаций в url есть локаль. Поэтому
 * Происходит еще один Redirect App.tsx:100, который редирект нас обратно в корень
 * В дальнейшем Redirect в этом компонент не работает.
 * Поэтому мы сдвинули ниже.
 * После авторизации с начало пройдут все редиректы в RouterSwitch
 * Потом сработает наш Redirect на notice удаленного пользователя.
 */

const confirmDeletedFullPath = mergeAllUrls(
  settingsPath,
  settingsMainPath,
  accountDeletedPath,
  confirmExitPath
)

const restoreProfileFullPath = mergeAllUrls(
  settingsPath,
  settingsMainPath,
  accountDeletedPath
)

const shouldRedirectToProfileRestore = (pathname: string) =>
  pathname.indexOf(restoreProfileFullPath) === -1 &&
  pathname.indexOf(confirmDeletedFullPath) === -1 &&
  pathname.indexOf(NoticeEmails.RestoreProfile) === -1

export const AppRedirect: FC = () => {
  const { profileRemoved, pathname } = useShallowEqualSelector(
    ({
      errorReducer: { profileRemoved },
      router: {
        location: { pathname },
      },
    }) => ({
      profileRemoved,
      pathname,
    })
  )
  const pathnameWithoutLocale = removeLocale(pathname)
  const authorized = useAuthorized()

  if (
    profileRemoved &&
    shouldRedirectToProfileRestore(pathnameWithoutLocale) &&
    authorized
  ) {
    return <Replace to={restoreProfileFullPath} uid="AppRedirect" />
  }
  return null
}
