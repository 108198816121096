import { styled } from '@linaria/react'
import { WithChildren } from 'common/types'
import React, { FC } from 'react'
import { LogoSvg } from '../svgr/LogoSvg'

export const Logo: FC<{ ready: boolean } & WithChildren> = ({
  ready,
  children,
}) => {
  return (
    // Чтобы не было некрасиво при FOUC (Flash of unstyled content)
    <Container style={{ display: 'none' }}>
      <AnimatedLogoWrapper style={{ opacity: ready ? 0 : 1 }}>
        <StyledLogoSvg />
      </AnimatedLogoWrapper>
      {children}
    </Container>
  )
}

export const Container = styled.div`
  display: block !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  direction: ltr;
`
export const AnimatedLogoWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s;
  transition-delay: 1s;
`
export const StyledLogoSvg = styled(LogoSvg)`
  width: 86px;
  height: 86px;
  will-change: transform;

  @keyframes pulse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }

  animation: pulse 0.8s infinite ease alternate;
`
