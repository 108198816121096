import {
  FETCH_SOCIAL_DATA_USER,
  FetchSocialDataUserActionTypes,
} from 'actions/socialUserAction'
import { defaultPromiseReducer } from './defaultPromiseReducer'
import { SocialUserState } from './authorization/SocialUserState'
import { Reducer } from 'redux'

export const socialUserReducer: Reducer<
  SocialUserState,
  FetchSocialDataUserActionTypes
> = (
  state = {
    providerId: null,
    socialDataUserLoaded: false,
    socialName: null,
  },
  action
): SocialUserState => {
  switch (action.type) {
    case FETCH_SOCIAL_DATA_USER:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          socialDataUserLoaded: false,
        }),
        (result) => {
          if (result) {
            return {
              socialDataUserLoaded: true,
              providerId: result.providerId,
              socialName: result.providerName,
              socialUserName: result.name,
              socialUserPic: result.userPicUrl,
            }
          }

          return state
        },
        () => ({
          socialDataUserLoaded: false,
        })
      )

    default:
      return state
  }
}
