import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export function useAddToHomescreenPrompt(): {
  deferredPrompt: BeforeInstallPromptEvent | null
  setDeferredPrompt: Dispatch<SetStateAction<BeforeInstallPromptEvent>>
} {
  const [
    deferredPrompt,
    setDeferredPrompt,
  ] = useState<BeforeInstallPromptEvent | null>(null)

  useEffect(() => {
    const eventHandler = (event: BeforeInstallPromptEvent) => {
      event.preventDefault()
      setDeferredPrompt(event)
    }

    window.addEventListener('beforeinstallprompt', eventHandler)

    return () => {
      window.removeEventListener('beforeinstallprompt', eventHandler)
    }
  }, [])

  return { deferredPrompt, setDeferredPrompt }
}

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}
