export const fetchPermissionStatus = async (
  params: PermissionDescriptor
): Promise<PermissionStatus | undefined> => {
  const permissionsSupported = 'permissions' in window.navigator

  if (!permissionsSupported) {
    return
  }

  try {
    return await window.navigator.permissions.query(params)
  } catch (ignored) {}
}

export const fetchGeoPermissionStatus = () =>
  fetchPermissionStatus({
    name: 'geolocation',
  })

export const fetchNotificationsPermissionStatus = () =>
  fetchPermissionStatus({
    name: 'notifications',
  })

export const enum AppPermissionState {
  Prompt = 'prompt',
  Granted = 'granted',
  Denied = 'denied',
}
