import { defineMessages } from 'react-intl'
import isEmpty from 'functions/isEmpty'
import {
  allCitiesPath,
  allRegionsPath,
  GAYS,
  GIRLS,
  GUYS,
  LESBIANS,
} from './constants'
import { findMatchUrl } from 'functions/findMatch'
import { removeUrlQuery } from 'functions/url'
import { Gender } from 'common-constants/api6.types'
import { LandingType } from 'reducers/landing/landingReducer'
import { capitalizeFirstLetter } from 'functions/capitalizeFirstLetter'

const landingTitles: Record<string, Record<string, string>> = defineMessages({
  country: {
    id: 'landing.meta.title.country',
    defaultMessage:
      'Онлайн знакомства {countryName}. Знакомства с мужчинами и женщинами {countryName}. Сайт онлайн знакомств {siteName}.',
  },
  city: {
    id: 'landing.meta.title.city',
    defaultMessage:
      'Онлайн знакомства г. {cityName}. Знакомства с мужчинами и женщинами {cityName}, {regionName}. Сайт онлайн знакомств {siteName}.',
  },
  region: {
    id: 'landing.meta.title.region',
    defaultMessage:
      'Онлайн знакомства {regionName}. Знакомства с мужчинами и женщинами {regionName}, {countryName}. Сайт онлайн знакомств {siteName}.',
  },

  countryGirls: {
    id: 'landing.meta.title.country.girls',
    defaultMessage:
      'Онлайн знакомства девушки {countryName}. Познакомься с девушкой Россия. Сайт онлайн знакомств {siteName}.',
  },
  cityGirls: {
    id: 'landing.meta.title.city.girls',
    defaultMessage:
      'Онлайн знакомства с девушками г. {cityName}. Познакомься с девушкой {cityName}, {regionName}. Сайт онлайн знакомств {siteName}.',
  },
  regionGirls: {
    id: 'landing.meta.title.region.girls',
    defaultMessage:
      'Знакомства девушки {regionName}. Познакомься с девушкой {regionName}, {countryName}. Сайт онлайн знакомств {siteName}.',
  },

  countryGuys: {
    id: 'landing.meta.title.country.guys',
    defaultMessage:
      'Онлайн знакомства парни {countryName}. Познакомься с парнем Россия. Сайт онлайн знакомств {siteName}.',
  },
  cityGuys: {
    id: 'landing.meta.title.city.guys',
    defaultMessage:
      'Онлайн знакомства с парнями г. {cityName}. Познакомься с парнем {cityName}, {regionName}. Сайт онлайн знакомств {siteName}.',
  },
  regionGuys: {
    id: 'landing.meta.title.region.guys',
    defaultMessage:
      'Знакомства парни {regionName}. Познакомься с парнем {regionName}, {countryName}. Сайт онлайн знакомств {siteName}.',
  },

  countryGays: {
    id: 'landing.meta.title.country.gays',
    defaultMessage:
      'Гей знакомства {countryName} - бесплатный сайт знакомств Mamba {countryName}',
  },
  regionGays: {
    id: 'landing.meta.title.region.gays',
    defaultMessage:
      'Гей знакомства {regionName} - бесплатный сайт знакомств Mamba {regionName}',
  },
  cityGays: {
    id: 'landing.meta.title.city.gays',
    defaultMessage:
      'Гей знакомства {cityName} - бесплатный сайт знакомств Mamba {cityName}',
  },

  countryLesbians: {
    id: 'landing.meta.title.country.lesbians',
    defaultMessage:
      'Лесби знакомства {countryName} - бесплатный сайт знакомств Mamba {countryName}',
  },
  regionLesbians: {
    id: 'landing.meta.title.region.lesbians',
    defaultMessage:
      'Лесби знакомства {regionName} - бесплатный сайт знакомств Mamba {regionName}',
  },

  cityLesbians: {
    id: 'landing.meta.title.city.lesbians',
    defaultMessage:
      'Лесби знакомства {cityName} - бесплатный сайт знакомств Mamba {cityName}',
  },
})

const landingMetaDescriptions: Record<
  string,
  Record<string, string>
> = defineMessages({
  country: {
    id: 'landing.meta.description.country',
    defaultMessage:
      'Знакомься с новыми людьми {countryName}. Общайся, заводи новых друзей с мужчинами и женщинами {countryName}. Найди свою любовь на сайте {siteName}.',
  },
  city: {
    id: 'landing.meta.description.city',
    defaultMessage:
      'Знакомства с новыми людьми г. {cityName}. Общайся, заводи новых друзей с мужчинами и женщинами {cityName}, {regionName}. Найди свою любовь на сайте {siteName}.',
  },
  region: {
    id: 'landing.meta.description.region',
    defaultMessage:
      'Знакомства с новыми людьми {regionName}. Общение, новые друзья мужчины и женщины {regionName}, {countryName}. Твоя любовь ждет тебя на сайте {siteName}.',
  },

  countryGirls: {
    id: 'landing.meta.description.country.girls',
    defaultMessage:
      'Знакомства девушки {countryName}. Встречайся, найди общие интересы с девушкой {countryName}. Встреть свою любовь на сайте {siteName}.',
  },
  cityGirls: {
    id: 'landing.meta.description.city.girls',
    defaultMessage:
      'Знакомства с девушками г. {cityName}. Знакомься, флиртуй, приглашай на свидания девушек {cityName}, {regionName}. Найди свою любовь на сайте {siteName}.',
  },
  regionGirls: {
    id: 'landing.meta.description.region.girls',
    defaultMessage:
      'Знакомства девушки {regionName}. Общение, флирт, встречи с девушками {regionName}, {countryName}. Твоя любовь на сайте {siteName} ждет тебя.',
  },

  countryGuys: {
    id: 'landing.meta.description.country.guys',
    defaultMessage:
      'Знакомства парни {countryName}. Встречайся, найди общие интересы с парнем {countryName}. Встреть свою любовь на сайте {siteName}.',
  },
  cityGuys: {
    id: 'landing.meta.description.city.guys',
    defaultMessage:
      'Знакомства с парнями г. {cityName}. Знакомься, флиртуй, приглашай на свидания парней {cityName}, {regionName}. Найди свою любовь на сайте {siteName}.',
  },
  regionGuys: {
    id: 'landing.meta.description.region.guys',
    defaultMessage:
      'Знакомства парни {regionName}. Общение, флирт, встречи с парнями {regionName}, {countryName}. Твоя любовь на сайте {siteName} ждет тебя.',
  },

  countryGays: {
    id: 'landing.meta.description.country.gays',
    defaultMessage:
      'Онлайн гей знакомства {countryName} бесплатно с проверенными фото и номерами телефонов на Мамбе. Лучшая альтернатива бесплатным доскам гей объявлений без регистрации.',
  },
  regionGays: {
    id: 'landing.meta.description.region.gays',
    defaultMessage:
      'Онлайн гей знакомства {regionName} бесплатно с проверенными фото и номерами телефонов на Мамбе. Лучшая альтернатива бесплатным доскам гей объявлений без регистрации.',
  },
  cityGays: {
    id: 'landing.meta.description.city.gays',
    defaultMessage:
      'Онлайн гей знакомства {cityName} бесплатно с проверенными фото и номерами телефонов на Мамбе. Лучшая альтернатива бесплатным доскам гей объявлений без регистрации.',
  },

  countryLesbians: {
    id: 'landing.meta.description.country.lesbians',
    defaultMessage:
      'Онлайн лесби знакомства {countryName} бесплатно с проверенными фото и номерами телефонов на Мамбе. Лучшая альтернатива бесплатным доскам лесби объявлений без регистрации.',
  },
  regionLesbians: {
    id: 'landing.meta.description.region.lesbians',
    defaultMessage:
      'Онлайн лесби знакомства {regionName} бесплатно с проверенными фото и номерами телефонов на Мамбе. Лучшая альтернатива бесплатным доскам лесби объявлений без регистрации.',
  },
  cityLesbians: {
    id: 'landing.meta.description.city.lesbians',
    defaultMessage:
      'Онлайн лесби знакомства {cityName} бесплатно с проверенными фото и номерами телефонов на Мамбе. Лучшая альтернатива бесплатным доскам лесби объявлений без регистрации.',
  },
})

export const getLandingTitle = ({ intl, locationType, gender, values }) => {
  return intl.formatMessage(
    landingTitles[
      gender ? `${locationType}${capitalizeFirstLetter(gender)}` : locationType
    ],
    values
  )
}

export const getLandingMetaDescription = ({
  intl,
  locationType,
  values,
  gender,
}) => {
  return intl.formatMessage(
    landingMetaDescriptions[
      gender ? `${locationType}${capitalizeFirstLetter(gender)}` : locationType
    ],
    values
  )
}

export interface GenericGeoLandingParams {
  city?: string
  cityId?: string
  region?: string
  regionId?: string
  country?: string
  countryId?: string
  gender?: string
}

export const genericSeoLandingPaths = [
  // `/:locale?/landing/:country/:region-:regionId(\\d+)/${allCitiesPath}`,
  '/landing/:country/:region/:city-:cityId(\\d+)/:gender(girls|guys|gays|lesbians)?',
  '/landing/:country/:region-:regionId(\\d+)/:gender(girls|guys|gays|lesbians)?',
  '/landing/:country-:countryId(\\d+)/:gender(girls|guys|gays|lesbians)?', // TODO попадает и all-regions
]

export const noJsGenericPaths = [
  `/landing/:country/:region-:regionId(\\d+)/${allCitiesPath}`,
  `/landing/:country-:countryId(\\d+)/${allRegionsPath}`,
  '/landing/:country/:region/:city-:cityId(\\d+)/:gender(girls|guys|gays|lesbians)?',
  '/landing/:country/:region-:regionId(\\d+)/:gender(girls|guys|gays|lesbians)?',
  '/landing/:country-:countryId(\\d+)/:gender(girls|guys|gays|lesbians)?',
]

export const findLandingMatchParams = (
  locationUrl: string,
  landingPaths: string[],
  exact = false
): { params: GenericGeoLandingParams } => {
  return landingPaths.reduce((match, path) => {
    if (!isEmpty(match)) {
      return match
    }

    return findMatchUrl(locationUrl, path, exact, false, false)!
  }, {} as { params: GenericGeoLandingParams })
}

// Need to SEO without modal
export const parseLandingMatchParamsWithoutModal = (locationUrlRaw) => {
  const locationUrl = removeUrlQuery(locationUrlRaw)
  const match = noJsGenericPaths.reduce((match, path) => {
    if (!isEmpty(match)) {
      return match
    }

    return findMatchUrl(locationUrl, path, false, false, false)
  }, {} as { url: string })
  if (match) {
    return match.url
  }
  throw new Error(`Landing without modal not found for "${locationUrlRaw}"`)
}

export const createLocationParams = (matchParams: GenericGeoLandingParams) => {
  const { cityId, regionId, countryId } = matchParams

  return {
    locationId: cityId || regionId || countryId,
    locationType: createSeoLandingLocationType(countryId, regionId, cityId),
  }
}

export const createSeoLandingLocationType = (
  country: number | string | undefined,
  region: number | string | undefined,
  city: number | string | undefined
): LandingType =>
  (city && LandingType.city) ||
  (region && LandingType.region) ||
  (country && LandingType.country) ||
  LandingType.country

export const findGenderCode = (gender?: string): Gender | undefined => {
  switch (gender) {
    case LESBIANS:
    case GIRLS:
      return Gender.Female

    case GUYS:
    case GAYS:
      return Gender.Male

    default:
      return Gender.Any
  }
}

export const findPreferGenderCode = (gender?: string): Gender | undefined => {
  switch (gender) {
    case LESBIANS:
      return Gender.Female

    case GAYS:
      return Gender.Male

    default:
      return undefined
  }
}
