import { AppRoute } from 'components/components.types'
import {
  tokenFullPath,
  vkRedirectPageFullPath,
} from 'components/system/third-party/vk/vk.paths'
import { VkRedirectAuthorizationPageLoadable } from 'components/system/third-party/vk/VkRedirectAuthorizationPageLoadable'
import { vkApiIframePath } from 'components/system/third-party/vk/api/api.paths'
import { VkApiIframePageLoadable } from 'components/system/third-party/vk/api/VkApiIframePageLoadable'
import loadable from '@loadable/component'

/**
 * Технические урлы, без визуальной части.
 */
export const systemRoutes: AppRoute[] = [
  {
    path: vkRedirectPageFullPath,
    component: VkRedirectAuthorizationPageLoadable,
    // иначе needReal перехватывает и теряются серверные нотайсы
    // shouldCheck: true,
  },
  {
    path: tokenFullPath,
    component: loadable(() =>
      import('components/system/authorization/TokenAuthorizationPage')
    ),
    exact: false,
    // иначе needReal перехватывает и теряются серверные нотайсы
    // shouldCheck: true,
  },
  {
    path: vkApiIframePath,
    component: VkApiIframePageLoadable,
  },
]
