import { replace } from 'functions/router'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import {
  supportFormPath,
  supportFormSuccessPath,
} from 'components/page/Boarding/SupportForm/SupportForm.paths'
import { supportFormExtendPath } from 'components/paths'
import {
  supportFormSendAuthApi,
  supportFormSendNotAuthApi,
} from 'api/support/supportFormApi'
import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'

export const SUPPORT_FORM_ACTION = 'SUPPORT_FORM_ACTION'
export const RESET_SUPPORT_FORM_ACTION = 'RESET_SUPPORT_FORM_ACTION'
export const ERROR_SUPPORT_FIELD_ACTION = 'ERROR_SUPPORT_FIELD_ACTION'
export const UPDATE_SUPPORT_FIELD_ACTION = 'UPDATE_SUPPORT_FIELD_ACTION'
export const ADD_MAIL_SENDER = 'ADD_MAIL_SENDER'

interface SupportFormNotAuthAction extends AsyncAction {
  type: typeof SUPPORT_FORM_ACTION
}

export const supportFormNotAuthAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const {
    supportFormReducer: {
      form: { name, login, email, textarea, screenshot },
    },
    systemReducer: { locale },
  } = getState()
  const { result } = (await dispatch({
    type: SUPPORT_FORM_ACTION,
    promise: () =>
      supportFormSendNotAuthApi(name, login, email, textarea, screenshot),
  })) as any

  if (result) {
    dispatch(
      replace(mergeAllUrls(locale, supportFormPath, supportFormSuccessPath))
    )
  }
}

interface SupportFormAuthAction extends AsyncAction {
  type: typeof SUPPORT_FORM_ACTION
}

interface AddMailSenderAction {
  type: typeof ADD_MAIL_SENDER
  result: string
}

export const supportFormAuthAction = (): AsyncThunkAction => async (
  dispatch,
  getState
) => {
  const { supportFormReducer } = getState()
  const { form } = supportFormReducer
  const { textarea, screenshot } = form

  const { result } = (await dispatch({
    type: SUPPORT_FORM_ACTION,
    promise: () => supportFormSendAuthApi(textarea, screenshot),
  })) as any

  if (result && result.response) {
    dispatch({
      type: ADD_MAIL_SENDER,
      result: result.response,
    })
    dispatch(
      replace(mergeAllUrls(supportFormExtendPath, supportFormSuccessPath))
    )
  }
}

interface ErrorSupportFieldErrorAction {
  type: typeof ERROR_SUPPORT_FIELD_ACTION
  name: string
  error: string
}

interface SupportError {
  name: string
  error: string
}

export const errorSupportFieldErrorAction = ({
  name,
  error,
}: SupportError) => ({
  type: ERROR_SUPPORT_FIELD_ACTION,
  name,
  error,
})

interface UpdateSupportFieldAction {
  type: typeof UPDATE_SUPPORT_FIELD_ACTION
  name: string
  value: string | boolean
}

export const updateSupportFieldAction = (
  name: string,
  value: string | boolean
) => ({
  type: UPDATE_SUPPORT_FIELD_ACTION,
  name,
  value,
})

interface ResetSupportFormAction {
  type: typeof RESET_SUPPORT_FORM_ACTION
}

export const resetSupportFormAction = () => ({
  type: RESET_SUPPORT_FORM_ACTION,
})

export type SupportActionTypes =
  | SupportFormNotAuthAction
  | SupportFormAuthAction
  | ErrorSupportFieldErrorAction
  | UpdateSupportFieldAction
  | ResetSupportFormAction
  | AddMailSenderAction
