import { PartialRootState } from 'common/store/store.types'

import { RootState } from 'reducers/RootState'

export const mutatePartialRootStateWithVipMegafonState = (
  reducerState: RootState,
  partialRootState: PartialRootState
): void => {
  if (reducerState.vipMegafon) {
    partialRootState.vipMegafon = {
      subscriptionId: reducerState.vipMegafon.subscriptionId,
    }
  }
}
