import React, { SVGProps } from 'react'

export const GeolocationPermissionSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={113}
    height={123}
    viewBox="0 0 113 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.9739 66.1059C48.4535 66.1059 40.7082 73.8835 40.7082 83.4437C40.7082 93.0039 48.4535 100.781 57.9739 100.781C67.4944 100.781 75.2396 93.0039 75.2396 83.4437C75.2396 73.8835 67.4944 66.1059 57.9739 66.1059ZM57.9739 103.379C47.0267 103.379 38.1204 94.4358 38.1204 83.4437C38.1204 72.4508 47.0267 63.5073 57.9739 63.5073C68.9211 63.5073 77.8274 72.4508 77.8274 83.4437C77.8274 94.4358 68.9211 103.379 57.9739 103.379Z"
      fill="#BDCCD4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4329 79.1099C20.3699 79.1099 20.3061 79.1056 20.2414 79.0952C19.5341 78.9903 19.0467 78.3303 19.1511 77.62C21.977 58.4719 38.6665 44.0315 57.9739 44.0315C71.383 44.0315 83.7276 50.7991 90.9967 62.1341C91.3831 62.7388 91.2097 63.5417 90.6085 63.9306C90.0081 64.3196 89.2068 64.1455 88.8203 63.5417C82.0309 52.9525 70.4997 46.6301 57.9739 46.6301C39.9396 46.6301 24.35 60.1168 21.7113 78.0011C21.6164 78.6465 21.0635 79.1099 20.4329 79.1099Z"
      fill="#BDCCD4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.9739 122.855C41.8917 122.855 27.6045 113.182 21.575 98.2137C21.3076 97.5485 21.6268 96.7906 22.2892 96.522C22.9517 96.2535 23.7056 96.5732 23.9739 97.2393C29.6066 111.221 42.9518 120.257 57.9739 120.257C68.401 120.257 78.3691 115.775 85.3208 107.962C85.797 107.427 86.6156 107.381 87.1478 107.859C87.68 108.336 87.7266 109.158 87.2504 109.693C79.808 118.057 69.1368 122.855 57.9739 122.855Z"
      fill="#BDCCD4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.399 91.8169C57.399 91.8169 23.1824 67.2741 21.7453 35.8025C20.3074 4.33099 51.6481 0 57.9743 0C64.2997 0 93.628 1.44395 94.7778 36.3803C94.7778 36.3803 96.2158 69.2958 57.399 91.8169Z"
      fill="#E06065"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.5684 35.3694C78.5684 46.5512 69.5413 55.6168 58.4052 55.6168C47.2691 55.6168 38.2421 46.5512 38.2421 35.3694C38.2421 24.1877 47.2691 15.1221 58.4052 15.1221C69.5413 15.1221 78.5684 24.1877 78.5684 35.3694Z"
      fill="#FED576"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8099 26.3995C29.8099 26.3995 26.5294 23.5298 29.175 18.1117C31.8206 12.6919 36.0533 11.5234 36.0533 11.5234C36.0533 11.5234 38.2754 10.0353 39.4399 12.4797C40.6035 14.9233 33.8313 30.6499 29.8099 26.3995Z"
      fill="#E2767E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.6629 10.9535C42.6629 10.9535 41.6924 8.57144 43.8489 7.43499C46.0054 6.29768 46.7059 8.57144 46.7059 8.57144C46.7059 8.57144 47.4071 10.3584 45.6285 11.5486C43.8489 12.7405 42.6629 10.9535 42.6629 10.9535Z"
      fill="#E2767E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.754 66.2065C8.1895 66.2429 5.6552 68.301 5.08502 70.8156V70.8182C4.73308 72.3678 4.75465 73.6506 5.16869 75.2418C5.97953 79.3927 8.28611 82.2373 10.7971 82.2373C13.2348 82.2373 15.519 79.4923 16.3583 75.5623C16.7732 74.0439 16.9526 72.697 16.5248 70.8182C15.9503 68.2845 13.3841 66.2169 10.8092 66.2065H10.754Z"
      fill="#6679BF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0489914 91.4963C0.0489914 91.4963 -0.249467 92.3738 0.62693 92.3738C1.50419 92.3738 12.1814 91.5621 20.4347 92.2499C20.6452 92.2681 21.5388 92.3738 21.4517 91.6011C21.3637 90.8293 21.048 89.3395 20.8729 88.8128C20.8729 88.8128 20.6271 87.3403 19.4531 86.9011C18.2782 86.4628 14.2473 85.2354 10.7236 85.0249C7.20077 84.8145 2.08213 86.9548 2.08213 86.9548C2.08213 86.9548 1.04788 87.4633 0.714915 88.4456C0.714915 88.4456 0.17148 90.5322 0.0489914 91.4963Z"
      fill="#6679BF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.7831 66.5703C94.3016 66.6197 90.8599 69.414 90.0861 72.8295V72.8321C89.6074 74.9369 89.6367 76.6788 90.1991 78.8391C91.3007 84.4755 94.4319 88.3387 97.8409 88.3387C101.152 88.3387 104.254 84.6115 105.394 79.274C105.956 77.2115 106.199 75.3839 105.62 72.8321C104.84 69.3924 101.355 66.585 97.8581 66.5703H97.7831Z"
      fill="#4ECFA8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.2465 100.347C83.2465 100.347 82.8419 101.538 84.0323 101.538C85.2227 101.538 99.7221 100.437 110.929 101.372C111.214 101.395 112.428 101.538 112.309 100.49C112.19 99.4417 111.762 97.4174 111.524 96.7037C111.524 96.7037 111.19 94.7028 109.595 94.1077C108.001 93.5126 102.527 91.846 97.7424 91.5593C92.9576 91.2735 86.0077 94.1796 86.0077 94.1796C86.0077 94.1796 84.6033 94.8699 84.1513 96.2039C84.1513 96.2039 83.413 99.0372 83.2465 100.347Z"
      fill="#4ECFA8"
    />
  </svg>
)
