import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const useAuthorized = (): boolean => {
  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  return authorized
}
