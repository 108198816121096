import { Application } from 'server/bootstrap/appVersion'

export interface Api6Platform {
  platform: string
  // Поля ниже используются только в запросах /api/notifications/*
  brand?: string
  build?: number
}

export const createApi6Header = (brand?: string): Api6Platform => {
  const headers: Api6Platform = {
    platform: 'web',
    build: getMinorVersion(),
  }

  if (brand) {
    headers.brand = brand
  }

  return headers
}

const getMinorVersion = (): number => {
  try {
    const minorVersion = Number(Application.version.split('.')[1])
    return minorVersion || /** Подстрахуемся */ FALLBACK_VERSION_VALUE
  } catch (error) {
    console.error('Failed getting minor version from:', Application.version)
    return FALLBACK_VERSION_VALUE
  }
}

// Для dev и на случай ошибки при получении версии
const FALLBACK_VERSION_VALUE = 1
