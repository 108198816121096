import { PlainAction } from 'actions/actions.types'

export const RESET_REGISTRATION_NEEDED = 'RESET_REGISTRATION_NEEDED'

export interface ResetRegistrationNeededAction extends PlainAction {
  type: typeof RESET_REGISTRATION_NEEDED
  value: boolean
}

/**
 * @param value
 * Action сбрасывает флаг, что нужна дорега.
 * Но на самом деле надо сбрасывать везде(клиент->сервер).
 * Так как на сервер остаются следы дореги.
 * Возможно из-за этого всплывет бага.
 * https://redmine.mamba.ru/issues/117012
 */
export const resetRegistrationNeededAction = (value: boolean) => {
  return {
    type: RESET_REGISTRATION_NEEDED,
    value,
  }
}
