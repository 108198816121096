import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from './useShallowEqualSelector'
import { usePrevious } from './usePrevious'
import { defineStartScreenPath } from 'functions/defineStartScreenPath'
import { replace } from 'functions/router'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'

export const useWatchProfileUnbanned = () => {
  const dispatch = useDispatch()
  const { authorized, profileBanned, startScreen } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      errorReducer: { profileBanned },
      systemReducer: { startScreen },
    }) => ({
      authorized,
      profileBanned,
      startScreen,
    })
  )
  const previousProfileBanned = usePrevious(profileBanned)

  if (authorized && previousProfileBanned && !profileBanned) {
    dispatch(replace(defineStartScreenPath(startScreen)))
    dispatch(fetchMiniIfNeededAction())
  }
}
