import React, { createContext } from 'react'
import { useLayoutContext } from 'common/hooks/useLayoutContext'

export const defaultContextId = 'defaultContextId'
export const mambaLayoutContextId = 'mambaLayoutContextId'
export const genericLayoutContextId = 'genericLayoutContextId'
export const landingLayoutContextId = 'landingLayoutContextId'

export const StaticContext = createContext<{ baseUrl: string } | null>(null)

/***
 * @deprecated
 */
export const withLayoutContext = (WrappedComponent) => {
  return (props) => {
    const layoutContext = useLayoutContext()
    return <WrappedComponent layoutContext={layoutContext} {...props} />
  }
}
