import { ShellUpdateOptions } from 'functions/pwa/pwa.types'

const UPDATE_SHELL = 'UPDATE_SHELL'

/**
 * Обновить стартовую страницу PWA которая всегда берется "с диска".
 * В случае reload = true, страницы клиентов будут перезагружены.
 */
export const updateShell = (options: ShellUpdateOptions) => {
  const message: UpdateShellClientMessage = {
    type: UPDATE_SHELL,
    options,
  }
  navigator.serviceWorker.controller?.postMessage(message)
}

export interface UpdateShellClientMessage {
  type: typeof UPDATE_SHELL
  options: ShellUpdateOptions
}
