import React from 'react'
import { useLocation } from 'react-router'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { agreementIndexPath } from 'components/paths'
import resolvePathLocale from '../../../server/bootstrap/resolvePathLocale'
import { Replace } from './Replace'

export const RedirectAgreement = () => {
  const { pathname } = useLocation()
  const locale = resolvePathLocale(pathname, {})

  return (
    <Replace
      to={mergeAllUrls(locale, agreementIndexPath)}
      uid="RedirectAgreement"
    />
  )
}
