import { internalApiPath } from 'api/internal/internal.constants'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { buildQuery } from 'functions/queryString'
import { DatingGoals, Gender } from 'common-constants/api6.types'

export const searchApiPath = '/search'

export enum SearchQueryParams {
  gender = 'gender',
  birthYear = 'birthYear',
  datingGoal = 'datingGoal',
}

export const fetchStepRegistrationResultApi = async (
  gender: Gender | null,
  targetGender: Gender[] | Gender | null,
  year: number,
  datingGoal: DatingGoals
) => {
  const query = buildQuery({
    [SearchQueryParams.gender]: `${gender}-${targetGender}`,
    [SearchQueryParams.birthYear]: year,
    [SearchQueryParams.datingGoal]: datingGoal,
  })
  const response = await fetch(
    `${mergeAllUrls(internalApiPath, searchApiPath)}?${query}`
  )
  return await response.json()
}
