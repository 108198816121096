import { mergeAllUrls } from 'functions/mergeAllUrls'
import { FIELD_PATH_NAME } from 'components/page/DatingProfile/DatingProfile.constants'
import { fieldNamePath } from 'components/page/DatingProfile/DatingProfile.paths'
import { profileSearchFullPath } from 'components/page/Search/SearchProfile/SearchProfile.paths'
import { FeedFilter } from 'components/page/Feed/FeedPage.constants'
import { ContactAlias } from './page/Chat/Messenger/Contact/ContactsList.constants'
import { SUPPORT_BOT_PROFILE_ID } from './page/Chat/Messenger/Messenger.constants'

const appPath = '/app' // app чтобы не было конфликта с текущим урлом, который используют мобильщики
export const appConfidentialityPath = `${appPath}/confidentiality`
export const confidentialityPath = '/confidentiality'
export const appAgreementPath = `${appPath}/agreement` // мобильщики используют https://mamba.ru/ru/agreement
export const appAgreementIndexPath = `${appPath}/agreement/index`
export const appAgreementVipAppPath = `${appPath}/agreement/vip`
// TODO: глобально избавиться от разных урлов для настроек профиля
export const settingsPath = '/profile/settings'
export const settingsModalPath = '/settings'
export const standaloneAgreementPartPath = 'standalone-agreement'
export const appStandaloneAgreementPath = `${appPath}/${standaloneAgreementPartPath}`
export const checkIfModalNeededPath = appPath

// Лента
export const feedPath = '/feed'
export const feedFilterParam = 'filter'
export const feedFilterPath = mergeAllUrls(
  feedPath,
  `/:${feedFilterParam}(${FeedFilter.All}|${FeedFilter.Photoline})`
)

export const feedAllPath = mergeAllUrls(feedPath, FeedFilter.All)
export const feedPhotolinePath = mergeAllUrls(feedPath, FeedFilter.Photoline)
export interface FeedRouteParams {
  [feedFilterParam]: FeedFilter
}

export const hitListPath = '/hitlist'
export const seoLandingPath = '/landing'
export const photolineBasePath = 'photoline'
export const networkErrorModalPath = '/network-error'
export const blockedByIpReasonsPath = '/blocked-by-ip-reasons'
export const blockedByTrackReasonsPath = '/blocked-by-track-reasons'
/**
 * Почему legacy?
 */
export const blockedByIpReasonsLegacyPath = '/legacy/blocked-by-ip-reasons'
/**
 * Почему legacy?
 */
export const blockedByTrackReasonsLegacyPath =
  '/legacy/blocked-by-track-reasons'
export const blockedByPersonalPath = '/blocked-personal'
export const blockedProfilePath = '/blocked-profile'
export const agreementVipPathMobile = '/agreement/vip'
export const boundSocialAccountPath = '/binded/account'

export const photoRestrictionsPath = 'photo-restrictions'

export const downloadAppPath = '/download/app'
export const logoutPath = '/logout'
export const contactsFolderListPath = 'folders'

export const contactsFolderPath = 'folder'
export interface EditContactParams {
  numberContacts: string
}
export const deleteContactsIgnorePath = '/delete/all/contacts'
export const redirectChatPath = '/redirect-chats'
export const contactPath = 'contact'
export const contactListPath = `/${contactPath}/list`
export const contactRequestPath = `/${contactPath}/requests`
export const chatPath = '/chats'

export const commonFolderContactPath = `${chatPath}/:profileId/contact/:contactId/contact`
export interface ChatRouteParams {
  profileId: string
}
export const contactWithoutContactIdPath = `${chatPath}/:profileId/contact`
export const supportChatPath = `${chatPath}/${SUPPORT_BOT_PROFILE_ID}/contact`

export const vipPromoPath = '/promo/vip-for-one'
export const contestPromoPath = '/promo/contest'
export const cashOutPath = `${appPath}/cash-out`
export const diamondsPath = '/diamonds'

export const storeFrontPath = `${appPath}/storefront`
export const storeFrontSlide = 'slide'
export interface SlideNameParams {
  slideName: string
}

export const storeFrontStartPath = 'slide/:slideName'
export const storeFrontStartPathSlideWithParametersAutoStart =
  ':autoStartSlider'
export const storeFrontStartAutoStartSliderPath = `slide/:slideName/${storeFrontStartPathSlideWithParametersAutoStart}`
export const storeFrontAnalyticPath = 'place/:placeName'
export const storeFrontPaymentPath = 'payment'
export const successPath = `${storeFrontPaymentPath}/success`
export const unsubscribePath = 'unsubscribe'

/**
 * @deprecated use StorefrontService.featuredPhotos
 */
export const featuredPhotosBasePath = 'featured-photos'
export const featuredPhotosPath = `${storeFrontPath}/${featuredPhotosBasePath}`
export const supportPath = '/support'
export const supportFormExtendPath = '/support-form-extend'
export const storeFrontVipSubscribedPath = 'vip-subscribed'

export interface ProfileRouteParams {
  userId: string
}
export const profilePath = '/profile'
export const userProfilePath = mergeAllUrls(profilePath, ':userId(\\d+)')
export const requestGeoPermissionPath = 'request-geo-permission'

export const userProfileSearchPath = mergeAllUrls(
  userProfilePath,
  profileSearchFullPath
)
export const ratingPath = '/rating' as const
export const inlineNoticePath = '/notice'
export const uniNoticeInlinePath = mergeAllUrls(inlineNoticePath, ':noticeId')

export const legacyProfilePath = mergeAllUrls(
  appPath,
  profilePath,
  ':userId(\\d+)'
)
export const userProfileMainSettingsPath = '/settings/main'
export interface PhotoCommentParams {
  userId: string
  photoId: string
  contentId: string
  commentId?: string
}

export const analyticStoreFrontPath = '/place'
export const analyticPlaceNameActivityPath = `${analyticStoreFrontPath}/activity`
export const analyticPlaceNameMainSettingPath = `${analyticStoreFrontPath}/main-settings`
export const notFoundPath = '/not-found'
export const internalServerErrorPath = '/internal-server-error'
export const searchSettingsPath = '/search/settings'

export const announceAddPath = '/announce/add'
export const androidAppPath = '/androidapp'

export const subscriptionActivatorPath = 'subscription-activator'
export const paymentMethodsPath = 'payment-methods'

export interface SubscriptionMethodParams {
  methodName: string
}
export const methodName = '/:methodName'

export const datingProfilePath = '/dating-profile'
export const editNamePath = mergeAllUrls(
  datingProfilePath,
  fieldNamePath,
  FIELD_PATH_NAME
)
export const datingProfileFieldsListPath = 'list-fields'
export const stopFillingDatingProfilePath = 'stop-filling'

export const messengerStickerPath = '/stickers/messenger'
export const messengerEditContactPath = '/edit-contact'
export const messengerEditContactVendorPath = mergeAllUrls(
  messengerEditContactPath,
  ':alias'
)
export interface MessengerEditContactParams {
  alias: ContactAlias
}
export const commentStickerPath = '/stickers/comment'
/**
 * По-видимому этот роут нужен, т.к. где-то в моб приложениях зашит этот путь.
 * Редиректит на /agreement/index
 */
export const baseAgreementPath = '/agreement'
/** Для мобильных приложений */
export const agreementIndexPath = '/agreement/index'

export const storkPromoPath = '/promo/wwf'
export const registrationPath = '/register'
export const enterEmailPath = '/enter-email'
export const indexPath = '/'

export const guaranteedImpressionsResultPath = 'guaranteed-impressions-result'
export const appUniWebPath = '/app'

export const fieldLocationMainSettings = 'field/location'

export const dropSupportOldBrowserPath = '/old-browser'
export const teamoModalCompabiltyPath = 'teamo-modal-compabilty'
export const teamoModalCompabilityTypePath = mergeAllUrls(
  teamoModalCompabiltyPath,
  ':type'
)

export interface teamoModalCompabilityTypeParams {
  type: string
}

export const okPhotoUploadPath = 'ok-photo-upload'
export const vendorErrorPath = ':error'

export const profileOkPhotoUploadPath = mergeAllUrls(
  profilePath,
  okPhotoUploadPath
)
export const profileOkPhotoUploadVendorErrorPath = mergeAllUrls(
  profilePath,
  okPhotoUploadPath,
  vendorErrorPath
)

export interface UniNoticeInlineRouteParams {
  noticeId: string
}

export const astroStarModalCurrentUserPath = 'astro-star-current-user-modal'

export interface PhotoCommentPathParams {
  profileId: string
  photoId: string
  comment: string
}

export const photoCommentOldPath =
  '/photo-comment/:profileId/photo/:photoId/content/:comment'

export interface PhotoviewerPathParams {
  profileId: string
  photoId: string
}
export const photoviewerOldPath = `/${profilePath}/:profileId/photoviewer/:profileIdDuplicate/:photoId`

/**
 * Редиректит после авторизации на путь, который указан после этой части.
 * https://mamba.ru/ru/redirect-after-auth/profile/1234567/then/gift средиректит на
 * https://mamba.ru/profile/1234567/then/gift
 */
export const redirectAfterAuthPath = '/redirect-after-auth'
