import { LOCATION_CHANGE } from 'redux-first-history'
import { HIDE_TOOLTIPS, SHOW_TOOLTIP } from 'actions/tooltipAction'

export interface TooltipReducerState {
  tooltip: Record<string, boolean>
}

export const tooltipReducer = (
  state: TooltipReducerState = {
    tooltip: {},
  },
  action
): TooltipReducerState => {
  switch (action.type) {
    case SHOW_TOOLTIP:
      return {
        ...state,
        tooltip: {
          [action.id]: true,
        },
      }

    case HIDE_TOOLTIPS:
    case LOCATION_CHANGE:
      if (Object.keys(state.tooltip).length) {
        return {
          ...state,
          tooltip: {},
        }
      }
      return state

    default:
      return state
  }
}
