import { UniNotice } from 'api/uninotice/UniNotice'

export const encodeUniNotice = (notice: UniNotice): string => {
  return encodeURIComponent(JSON.stringify(notice))
}

export const decodeUniNotice = (notice: string): UniNotice | null => {
  try {
    return JSON.parse(decodeURIComponent(notice)) as UniNotice
  } catch (error) {
    console.error('Decode UniNotice error', error)
  }
  return null
}
