import React, { FC } from 'react'
import { Route } from 'react-router'
import { Replace } from 'components/system/Replace'
import { AppStaticContext } from 'components/components.types'

const RedirectWithStatus: FC<{
  uid: string
  from?: string
  to: string
  status: number
  push?: boolean
}> = ({ uid = 'redirect-with-status', from, to, status, push = false }) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          ;(staticContext as AppStaticContext).status = status
        }
        return <Replace uid={uid} from={from} to={to} push={push} />
      }}
    />
  )
}

export default RedirectWithStatus
