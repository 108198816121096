import loadable from '@loadable/component'
import { AppStore } from 'common/store/store.types'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'
import { withAppContextHoc } from 'components/system/withAppContextHoc'
import { History } from 'history'
import { insertReducers } from 'hooks/useReducersInsert'

export const PhotoRatingSettingsLoadable = withAppContextHoc(
  'rating-settings',
  loadable<{ store: AppStore; history: History }>(
    async (props) => {
      const module = await import('./PhotoRatingSettings')
      insertReducers(props.store, props.history, module.asyncReducers)
      return module.PhotoRatingSettings
    },
    {
      fallback: (({ error }) => {
        if (error) {
          console.error('Photo rating settings split error', error)
        }
        return undefined
      })(defaultLoadableProps),
      ssr: false,
    }
  )
)
