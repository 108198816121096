import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { fetchGdprStatusApi } from 'api/fetchGdprStatusApi'

export const FETCH_GDPR_STATUS = 'FETCH_GDPR_STATUS' as const

export const fetchGdprStatusAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    settingsReducer: { gdprStatusLoading, gdprStatusLoaded, gdprDate },
    authorizationReducer: { authorized },
  } = getState()
  if (!gdprDate && !gdprStatusLoading && !gdprStatusLoaded && authorized) {
    return dispatch(fetchGdprStatusPlainAction())
  }

  return Promise.resolve()
}

export const fetchGdprStatusPlainAction = () => ({
  type: FETCH_GDPR_STATUS,
  promise: () => fetchGdprStatusApi(),
})
