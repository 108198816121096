import { fetchUniNoticeApi, UniNoticeId } from 'api/uninotice/uniNoticeApi'
import { UniNoticeParams } from 'actions/uninotice/uniNoticeAction'
import { NotificationType } from 'components/presentational/Notifications/NotificationType'
import { NotificationItem } from 'reducers/notice/noticeShortCut'

import { UniNotice } from 'api/uninotice/UniNotice'

export const FETCH_NOTICE_SHORTCUT = 'FETCH_NOTICE_SHORTCUT'

interface FetchNoticeShortCutAction {
  type: typeof FETCH_NOTICE_SHORTCUT
  uniNoticeId: string
}

export const fetchNoticeShortCutAction = (
  uniNoticeId: UniNoticeId,
  params: UniNoticeParams = {}
) => ({
  type: FETCH_NOTICE_SHORTCUT,
  promise: () => fetchUniNoticeApi(uniNoticeId, params),
})

export const PUSH_SHORTCUT_NOTIFICATION = 'PUSH_SHORTCUT_NOTIFICATION'

interface PushShortCutNotificationAction {
  type: typeof PUSH_SHORTCUT_NOTIFICATION
  notification: NotificationItem
}

let notificationId = 0

export const pushShortCutNotificationAction = (
  notificationType: NotificationType | string,
  id: UniNoticeId = `notification-${++notificationId}`
) => ({
  type: PUSH_SHORTCUT_NOTIFICATION,
  notification: {
    id,
    type: notificationType,
  },
})

export const REMOVE_SHORTCUT_NOTIFICATION = 'REMOVE_SHORTCUT_NOTIFICATION'

interface RemoveShortCutNotificationAction {
  type: typeof REMOVE_SHORTCUT_NOTIFICATION
  id: UniNoticeId
}

export const removeShortCutNotificationAction = (id: UniNoticeId) => ({
  type: REMOVE_SHORTCUT_NOTIFICATION,
  id,
})

export const ADD_NOTICE_SHORT_CUT_TO_COLLECTION =
  'ADD_NOTICE_SHORT_CUT_TO_COLLECTION'

interface AddNoticeShortCutCollectionAction {
  type: typeof ADD_NOTICE_SHORT_CUT_TO_COLLECTION
  id: UniNoticeId
  result: UniNotice
}

export const addNoticeShortCutCollectionAction = (
  uniNotice: UniNotice,
  id: UniNoticeId
) => ({
  type: ADD_NOTICE_SHORT_CUT_TO_COLLECTION,
  result: uniNotice,
  id,
})

export type NoticeShortCutTypes =
  | FetchNoticeShortCutAction
  | PushShortCutNotificationAction
  | RemoveShortCutNotificationAction
  | AddNoticeShortCutCollectionAction
