import { VendorSocialList } from 'common-constants/vendorSocialList'

export const LAST_AUTH_METHOD = 'LAST_AUTH_METHOD' as const

export const updateLastAuthMethodAction = (
  method: 'email' | VendorSocialList | null
) => ({
  type: LAST_AUTH_METHOD,
  method,
})
