import { fetchEdnaInformationApi } from 'api/edna/fetchEdnaInformationApi'

export const FETCH_EDNA_INFORMATION = 'FETCH_EDNA_INFORMATION' as const

export const fetchEdnaInformationAction = () => ({
  type: FETCH_EDNA_INFORMATION,
  promise: () => fetchEdnaInformationApi(),
})

export const RESET_EDNA_INFORMATION = 'RESET_EDNA_INFORMATION' as const

export const resetEdnaInformationAction = () => ({
  type: RESET_EDNA_INFORMATION,
})
