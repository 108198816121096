import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import React from 'react'

export const LogoutModalLoadable = loadable(() => import('./LogoutModal.js'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('Logout modal split error', error)
    }
    return <DefaultLoader />
  })({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
})
