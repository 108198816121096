// Обсудили с Сашей Шустовым, что лучше скопипастить типы из GQL
// так как они могу разойтись с GQL

export enum Gender {
  Male = 'M',
  Female = 'F',
  Any = 'N',
}

export enum DatingGoals {
  Friendship = 'Friendship',
  Email = 'Email',
  Love = 'Love',
  Flirtation = 'Flirtation',
  Family = 'Family',
  Sport = 'Sport',
  Travel = 'Travel',
  Meet = 'Meet',

  /** Update Goals https://youtrack.mamba.ru/issue/M-4832 */
  SeriousRelationship = 'SeriousRelationship',
  FriendlyCommunication = 'FriendlyCommunication',
  FlirtationAndDating = 'FlirtationAndDating',
  WillDecideWhenMeet = 'WillDecideWhenMeet',
}

export enum Constitution {
  Thin = 'Thin',
  Normal = 'Normal',
  Sports = 'Sports',
  Athletic = 'Athletic',
  Thick = 'Thick',
  Stout = 'Stout',
}

export type SearchFormFieldGender = 'N' | 'M' | 'F' | 'T' | 'MF' | 'MM' | 'FF'

/**
 * 204
 */
export type NoContentApi6 = null
