import React, { FC, useEffect } from 'react'
import { Replace } from 'components/system/Replace'
import { updateCaptchaFromUrlAction } from 'actions/captchaAction'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useDispatch } from 'react-redux'
import {
  authorizationCaptchaPath,
  unauthorizedCaptchaPath,
} from 'components/page/Captcha/Captcha.paths'

export const CaptchaRedirect: FC = () => {
  const dispatch = useDispatch()

  const { authorized, router } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized }, router }) => ({
      authorized,
      router,
    })
  )

  useEffect(() => {
    dispatch(updateCaptchaFromUrlAction(router.location.pathname))
  }, [dispatch, router.location.pathname])

  if (authorized) {
    return (
      <Replace to={authorizationCaptchaPath} uid="CaptchaRedirect-authorized" />
    )
  }

  return <Replace to={unauthorizedCaptchaPath} uid="CaptchaRedirect" />
}
