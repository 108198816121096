import styled, { css } from 'styled-components'
import { marginTopBottom1020 } from 'components/layout/MambaLayout/variantsIndent'
import { flexGrowCss } from 'common-constants/flex'

export const stepSize = 880
export const tabletMiddleSize = 930
export const desktopSize = 1024
export const stepMiddleDesktopSize = 1070
export const maxDesktopSize = 1420
export const stepDesktopSize = 1180
export const desktopModalPaddingSize = 40
export const mobileModalPaddingSize = 10
export const extendsMobileModalPaddingSize = 20
export const phoneModalPaddingSize = 20
export const stepMobileSize = 480

export const sizes = {
  tabletLarge: 992,
  tablet: 800,
  stepSize,
  phone: 600,
  minimalSize: 375,
  mailTopMenu: 540,
} as const

/**
 * before ThemedCssFunction<DefaultTheme>
 * нужно правильно типизировать.
 */
export interface Media {
  tabletLarge: typeof css
  tablet: typeof css
  stepSize: typeof css
  phone: typeof css
  minimalSize: typeof css
  mailTopMenu: typeof css
}

export const media = Object.keys(sizes).reduce<Media>((acc, label) => {
  acc[label] = (first, ...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(first, ...args)};
    }
  `
  return acc
}, {} as Media)

export const FlexGrow = styled.div`
  ${flexGrowCss};
  min-width: 0%;
`

export const Note = styled.p<{
  $margin?: string
  textAlign?: string
  fontSize?: string
  fontWeightBold?: boolean
}>`
  margin: ${({ $margin }) => ($margin ? $margin : marginTopBottom1020)};
  line-height: 1.5;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'inherit')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
  color: ${(props) => (props.color ? props.color : 'black')};
  cursor: default;
  font-weight: ${(props) => (props.fontWeightBold ? 'bold' : 'normal')};
`

export const modalPaddingCss = css`
  padding-right: ${desktopModalPaddingSize}px;
  padding-left: ${desktopModalPaddingSize}px;

  ${media.phone`
    padding-right: ${phoneModalPaddingSize}px;
    padding-left: ${phoneModalPaddingSize}px;
  `};
`
