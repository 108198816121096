export const noticePath = '/notice'
export const systemRedirectPath = '/system/redirect'
export const loginPath = '/login'
export const loginParameterPath = '/:login'
export const statisticPath = '/statistic'
export const statisticParameterPath = '/:statistic'
export const mailStatisticPath = '/mailstat'
export const mailStatisticParameterPath = '/:mailstat'

export interface NoticeEmailsParams {
  login: string
  mailstat: string
}
