import { Middleware } from 'redux'

import { push } from 'functions/router'

import { ApiData, ApiResult } from 'api/fetchApi'

import { CATCH_NETWORK_ERROR } from 'api/api.constants'
import { Api6Error, AppNetworkError } from 'api/api.types'

import { pwaOfflinePath } from 'components/routes/pwa.path'

import { AppDispatch, AsyncAction } from 'actions/actions.types'
import { isActionCaptchaNeeded } from 'reducers/isActionCaptchaNeeded'
import { isActionWithoutErrors } from 'reducers/isActionWithoutErrors'
import { VERIFY_CAPTCHA } from 'actions/captchaAction'
import { RootState } from 'reducers/RootState'

let captchaBlocked: AsyncAction<unknown>[] = []

/** У нового метода API6 всегда есть поле ok, и точно нет поля legacy  */
const isTypedApi6 = (result: ApiResult<unknown>) =>
  result && !((result as unknown) as { legacy: boolean }).legacy

export const readyStatePromise: Middleware<unknown, RootState, AppDispatch> = (
  store
) => (next) => (action: AsyncAction<unknown>) => {
  // if (action.type === UPDATE_CAPTCHA_KEY ) {
  //   dispatchPostponed(store)
  // }

  if (!action.promise) {
    return next(action)
  }

  const makeAction = (
    ready: boolean,
    ok: boolean | undefined,
    data: ApiData<unknown> | undefined = undefined
  ) => {
    const newAction: AsyncAction<unknown> = {
      ...action,
      ...{ ready },
      ...data,
      ok,
    }

    // Обработка отложенных каптчей действий, в дальнейшем надо будет вынести в отдельное middleware
    if (process.env.browser && isActionCaptchaNeeded(newAction)) {
      captchaBlocked.push({
        ...newAction,
        ready: false,
        postponed: true,
        /** Сбрасываем ошибку */
        error: undefined,
      })
    }
    delete newAction.promise

    if (
      ready &&
      action.type === VERIFY_CAPTCHA &&
      isActionWithoutErrors(newAction)
    ) {
      captchaBlocked.forEach((action) => store.dispatch(action))
      captchaBlocked = []
    }

    return newAction
  }

  next(makeAction(false, undefined))
  return action.promise().then(
    (response) => {
      if (isTypedApi6(response)) {
        if (response.ok === true) {
          return next(
            makeAction(true, response.ok, { result: response.result })
          )
        }
        if (response.ok === false) {
          return next(
            makeAction(true, response.ok, {
              error: response.result as Api6Error,
              errorStatus: response.status,
            })
          )
        }
      }

      /** Обработка старого метода API6 и API5 */
      return next(makeAction(true, undefined, { result: response }))
    },
    (error: Error) => {
      if (process.env.browser && window.onerror) {
        /**
         * Если от апи пришла оффлайн ошибка, попадаем сюда.
         * Вызвать экшен с ошибкой проблематично, т.к.
         */
        next(
          makeAction(true, false, {
            /** для типизированных методов API6 */
            error: { code: CATCH_NETWORK_ERROR } as Api6Error,
            /** для старых не типизированных API6 и API5 */
            result: { code: CATCH_NETWORK_ERROR },
          })
        )
      }
      console.error('Promise middleware:', error)

      if (process.env.browser) {
        const networkError = (error as unknown) as AppNetworkError

        const isOnOfflinePage = location.pathname === pwaOfflinePath

        if (!navigator.onLine && !isOnOfflinePage) {
          store.dispatch(push(pwaOfflinePath))
        } else if (networkError.code === CATCH_NETWORK_ERROR) {
          if (!isOnOfflinePage && action.critical === true) {
            /** Может быть онлайн, но бек 502 */
            store.dispatch(push(pwaOfflinePath))
          }
        }
      }

      return next(
        makeAction(true, false, {
          /** до выпила не типизированных методов API */
          // @ts-ignore
          internalError: {},
          error: {
            code: CATCH_NETWORK_ERROR,
            message: 'Client network error',
            data: null,
            humanMessage: null,
          },
        })
      )
    }
  )
}
