import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { fetchGeoPermissionStatus } from 'api/browser/fetchPermissionStatus'
import { updateGeolocationPermissionStateAction } from 'actions/geolocation/updateGeolocationPermissionStateAction'

export const useWatchGeolocationPermissionState = () => {
  const dispatch = useDispatch()
  const status = useRef<PermissionStatus | undefined>()

  const handleChange = useCallback(
    (event) => {
      const { state } = event.target as PermissionStatus
      dispatch(updateGeolocationPermissionStateAction(state))
    },
    [dispatch]
  )

  useEffect(() => {
    ;(async () => {
      const permissionStatus = await fetchGeoPermissionStatus()
      status.current = permissionStatus

      if (!permissionStatus) {
        return
      }

      dispatch(updateGeolocationPermissionStateAction(permissionStatus.state))

      permissionStatus.addEventListener('change', handleChange)
    })()

    return () => {
      status.current?.removeEventListener('change', handleChange)
    }
  }, [dispatch, handleChange])
}
