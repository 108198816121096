import loadable from '@loadable/component'
import React from 'react'

import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'

export const NotFoundPageLoadable = loadable(
  () => import('./NotFoundPageIndex'),
  {
    fallback: <DefaultLoader />,
  }
)
