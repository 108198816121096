import { mapBrowserCookies } from 'functions/cookie/browserCookies'

export const UPDATE_COOKIES = 'UPDATE_COOKIES' as const

/**
 * В браузере нужно обновлять куки.
 */
export const updateCookiesAction = () => ({
  type: UPDATE_COOKIES,
  value: mapBrowserCookies(),
})
