import { fetchApi } from 'api/index'

interface ClientStatPostBodyRequest {
  timers: ClientTimer[]
}

export enum ClientService {
  websocket = 'websocket',
  WebComponent = 'WebComponent',
  CdnImage = 'CdnImage',
}

export const searchOperation = 'search'
export const ratingOperation = 'rating'
export const feedOperation = 'feed'
export const profileOperation = 'profile'
export const landingOperation = 'landing'
export const geoLandingOperation = 'geo_landing'

export type Operation =
  | 'connect'
  | 'fail_connect'
  | 'subscribe'
  | 'get_message'
  /**
   * На самом деле image_duration[regionId], но не понятно как описать в TS.
   * https://youtrack.mamba.ru/issue/M-9634#focus=Comments-4-59539.0-0
   */
  | 'image_duration'
  | typeof searchOperation
  | typeof ratingOperation
  | typeof profileOperation
  | typeof landingOperation
  | typeof geoLandingOperation
  | typeof feedOperation

export interface ClientTimer {
  service: ClientService
  operation: Operation
  context: string
  time: number
  endTime: number
}

/**
 * Впервые сделано в:
 * https://redmine.mamba.ru/issues/102650
 *
 * Результаты по вебсокетам в:
 * http://btp.stat1.lan/#service/websocket//?cluster=production&scale=5
 * @param value
 * @returns
 */
export const saveClientStatisticApi = (value: ClientStatPostBodyRequest) =>
  fetchApi('/client_stat', {
    method: 'POST',
    body: JSON.stringify(value),
  })
