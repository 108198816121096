/**
 * Перейти в будущем?:
 * https://opentelemetry.io/docs/languages/js/getting-started/browser/
 */
import {
  init,
  browserTracingIntegration,
  reactRouterV5BrowserTracingIntegration,
} from '@sentry/react'

export const enableSentry = () => {
  const sentryUrl =
    'https://866cc3d7bf144f6ca6be8a4a4e5565ec@api.mobile-api.ru/6'
  /**
   * Отладочный урл, для SaaS Сентри
   */
  // const sentryUrl =
  //   'https://7ff01d9a79036ac9358b78b6eda697d4@o4505538710863872.ingest.sentry.io/4506025404268544'
  console.info('Sentry url', sentryUrl)
  const env = process.env.SENTRY_ENV
  const release = process.env.RELEASE
  const sampleRate = process.env.production ? 0.1 : 1

  console.info('Sentry env', env)
  console.info('Sentry release', release)
  console.info('Sentry sample rate', sampleRate)
  /**
   * Дефолтовый АПИ Сентри:
   * sentry.mamba.ru/api/6/envelope/
   * Наша дырка:
   * api.mobile-api.ru/api/6/envelope/
   */
  init({
    dsn: sentryUrl,
    integrations: [
      browserTracingIntegration(),
      /**
       * Пример самостоятельной реализацией:
       * https://github.com/getsentry/sentry-javascript/issues/4779
       * https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/
       */
      reactRouterV5BrowserTracingIntegration({
        history,
      }),
      // new HttpClient(),
    ],
    tracePropagationTargets: [
      'localhost',
      /^\/api/,
      /^\/mobile\/api/,
      /^\/node\/api/,
      // /^https:\/\/yourserver\.io\/api/,
    ],
    release: release,
    // debug: true,
    // По-идее должна включить полный стек трейс, согласно cli его нет
    attachStacktrace: true,
    tracesSampleRate: sampleRate,
    normalizeDepth: 3,
    normalizeMaxBreadth: 5,
    maxBreadcrumbs: 10,
    // beforeSendTransaction: (event, hint) => {
    //   console.info('transaction', event, { hint })
    //   return event
    // },

    /** Фильтрация: https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/ */
    ignoreErrors: [
      /**
       * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
       * > You can safely ignore this error.
       *
       * https://stackoverflow.com/questions/65732703/resizeobserver-loop-limit-exceeded-stop-exception
       */
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      /** Ошибка библиотеки hls.js, стримы закрываем, поэтому игнорируем */
      'this.log is not a function',
      /** Для iOS При попытке воспроизвести звук входящего сообщения. Через try catch не подавляется. */
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
      // Тоже что и выше, только для Chrome.
      // prettier-ignore
      'NotAllowedError: play() failed because the user didn\'t interact with the document first.',
      /**
       * Ошибка порождена из AppNetworkError, проблемы с сетью могут быть разные,
       * смысла сохранять в Сентри нет.
       *
       * Если будет какое-то расследование, можно попробовать включить.
       *
       * Скрываются так же и CORS-ошибки, но по-идее их отловим в регрессе.
       */
      'Failed to fetch',
      'TypeError: Failed to fetch',
      'Load failed',
      'отменено',
      'NetworkError when attempting to fetch resource.',
    ],

    denyUrls: [
      /**
       * https://youtrack.mamba.ru/issue/M-3907
       * /ads/js/tfav_adl_55.js
       * https://sentry.mamba.ru/organizations/mamba/issues/3091342/?project=6
       */
      /ads\/js/i,
      'https://yandex.ru/ads/system/context.js',
    ],
    environment: env,
  })
}
