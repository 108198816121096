import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'
import React from 'react'

export const PwaOfflineLoadable = loadable(() => import('./OfflinePage'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('PWA offline page split error', error)
    }
    return undefined
  })(defaultLoadableProps),
})
