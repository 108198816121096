import { fetchApi5 } from 'api/index'
import { NodeHeaders } from 'api/NodeHeaders'

/**
 * Используется только на стороне Node.js
 * @param headers
 * @returns {Promise<Response>}
 */
export const fetchMiniApi = (headers?: NodeHeaders) =>
  fetchApi5('/profile/mini', { headers })
