import { applyPromoCodeAction } from 'actions/promoCode/applyPromoCodeAction'
import { resetPromoCodeAboutAction } from 'actions/promoCode/resetPromoCodeAboutAction'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const usePromoCodeActivate = () => {
  const dispatch = useDispatch()
  const { isReal, aboutCode } = useShallowEqualSelector(
    ({ authorizationReducer: { profile }, promoCode: { aboutCode } }) => ({
      isReal: 'isReal' in profile && profile.isReal,
      aboutCode,
    })
  )

  const { promoCode, canBeApplied } = aboutCode || {}

  useEffect(() => {
    if (!promoCode || !isReal) {
      return
    }

    if (!canBeApplied) {
      dispatch(resetPromoCodeAboutAction())
      return
    }

    const applyPromoCode = async (promoCode: string) => {
      try {
        await dispatch(applyPromoCodeAction(promoCode))
      } catch (error) {
        console.error(error)
      }
      dispatch(resetPromoCodeAboutAction())
    }

    applyPromoCode(promoCode)
  }, [canBeApplied, dispatch, isReal, promoCode])
}
