import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { setGeolocationErrorCodeAction } from 'actions/geolocation/setGeolocationErrorCodeAction'
import { updateCoordinatesAction } from 'actions/user/updateCoordinatesAction'
import { AppPermissionState } from 'api/browser/fetchPermissionStatus'
import {
  clearWatchingGeolocationPositionApi,
  watchGeolocationPositionBrowserApi,
} from 'api/browser/watchPositionApi'
import { useAppRestrictionsWithoutGeolocationAbTest } from 'components/block/GeoPermissionRequest/hooks/useAppRestrictionsWithoutGeolocationAbTest'
import { hasLocationChangedBy100Meters } from 'functions/location/calculateDistance'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Coordinates } from 'reducers/geolocation/geolocationReducer.types'
import { useShallowEqualSelector } from './useShallowEqualSelector'

export const useWatchGeolocationPosition = () => {
  const dispatch = useDispatch()
  const { permissionState, authorized } = useShallowEqualSelector(
    ({
      geolocation: { permissionState },
      authorizationReducer: { authorized },
    }) => ({
      permissionState,
      authorized,
    })
  )
  const watchIdRef = useRef<number>()

  const restrictionWithoutGeolocationAbTest = useAppRestrictionsWithoutGeolocationAbTest()
  const hasAppRestrictionAbTest = Boolean(restrictionWithoutGeolocationAbTest)

  const shouldWatchGeolocation =
    !authorized || hasAppRestrictionAbTest
      ? permissionState === AppPermissionState.Granted
      : true // Для авторизованного без аб теста хотим геолокацию сразу

  useEffect(() => {
    if (!shouldWatchGeolocation) {
      return
    }

    try {
      watchIdRef.current = watchGeolocationPositionBrowserApi(
        (position) => {
          if (!dispatch(hasLocationChangedBy100MetersAction(position.coords))) {
            return
          }

          dispatch(
            updateCoordinatesAction({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          )
        },
        (error) => {
          dispatch(setGeolocationErrorCodeAction(error.code))
        }
      )
    } catch (error) {
      setGeolocationErrorCodeAction()
    }

    return () => {
      if (watchIdRef.current) {
        clearWatchingGeolocationPositionApi(watchIdRef.current)
      }
    }
  }, [dispatch, shouldWatchGeolocation])
}

const hasLocationChangedBy100MetersAction = (newCoords: Coordinates) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    geolocation: { coordinates },
  } = getState()

  if (!coordinates) {
    return true
  }

  return hasLocationChangedBy100Meters(
    newCoords.latitude,
    newCoords.longitude,
    coordinates.latitude,
    coordinates.longitude
  )
}
