import { ThenPlace } from 'components/block/Then/Then.constants'

export const thenPartPath = '/then'

export interface ThenParams {
  place: ThenPlace
}
export const thenModalPath = `${thenPartPath}/:place(${Object.keys(
  ThenPlace
).join('|')})`
