import {
  photoUploadFailedNoticeId,
  photoUploadFinishedNoticeId,
  photoUploadLimitNoticeId,
} from 'components/uninotice/uniNoticeIdList'
import { UniNoticeParams } from 'actions/uninotice/uniNoticeAction'

export const findResultNotice = ({
  succeededCount = 0,
  failedCount = 0,
  totalCount = 0,
  limitCount = 0,
}) => {
  if (limitCount) {
    return {
      noticeId: photoUploadLimitNoticeId,
    }
  }

  const condition = failedCount > 0 && succeededCount === 0

  // Получаем id notice
  const noticeId = condition
    ? photoUploadFailedNoticeId
    : photoUploadFinishedNoticeId

  // Получаем параметры для запроса
  const fieldParameter = condition ? { failedCount } : { succeededCount }

  const paramsForNoticeId: UniNoticeParams = {
    totalCount,
    ...fieldParameter,
  }

  return {
    noticeId,
    paramsForNoticeId,
  }
}
