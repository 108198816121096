import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const ManualDetectLoadable = loadable(() => import('./ManualDetect'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('manual detect split error', error)
    }
    return <DefaultLoader />
  })(defaultLoadableProps),
})
