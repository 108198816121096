import React, { FC, SVGProps } from 'react'

export const WavesSvg: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 804 804"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M804 527.086L436.249 414.949C276.875 353.957 116.731 496.283 168.779 647.458L168.751 647.445C183.622 690.701 209.205 727.886 244.004 757.952C264.784 775.92 288.855 791.345 315.908 804.001H804V527.086Z"
      fill="#FFC7E6"
    />
    <path
      d="M243.933 650.407C265.304 682.097 293.989 706.592 328.58 723.327C424.798 769.941 602.984 764.036 803.999 619.001L803.999 433L409.565 404.852C267.731 390.084 169.25 539.614 243.974 650.392L243.933 650.407Z"
      fill="#FB9BF9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M804.001 142.427L703.787 97.2891C624.086 254.67 460.363 403.466 392.61 405.119C291.812 415.416 239.261 535.418 305.18 604.721L305.221 604.705C324.205 624.661 347.594 638.455 374.243 645.841C442.473 664.776 603.362 669.728 804.001 467.914V142.427Z"
      fill="#FF7C30"
    />
    <path
      d="M804 0.09375L801 0H482C664.529 82.2148 554.888 334.585 431.112 394.017C420.386 399.167 408.939 402.645 397.511 406.117C382.919 410.55 368.357 414.974 355.37 422.858C322.375 442.888 292.351 477.321 319.243 528.089C363.296 611.254 603.268 568.073 804 226V0.09375Z"
      fill="#EA4D0A"
    />
  </svg>
)
