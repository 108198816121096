export const UPDATE_QUERY_ACTION = 'UPDATE_QUERY_ACTION' as const

export const updateQueryAction = () => {
  // TODO createSearch()
  const search = new URLSearchParams(location.search)

  const query: Record<string, string> = Array.from(search.entries()).reduce(
    (acc, next) => ({ ...acc, [next[0]]: next[1] }),
    {}
  )

  return {
    type: UPDATE_QUERY_ACTION,
    query,
  }
}
