export enum MessengerItemTypes {
  ASK_TO_FILL_INTERESTS = 'AskToFIllInterests',
  TEXT = 'Message',
  Gift = 'Gift',
  SharedContact = 'SharedContact',
  Failed = 'Failed',
  ATTACH_PHOTO = 'AttachPhoto',
  LIMIT_ALBUM_ATTACH_PHOTO = 'LimitAlbumAttachPhoto',
  MUTUAL_LIKE = 'MutualLike',
  PHOTO_COMMENT = 'PhotoComment',
  VIP_PRESENT = 'VipPresent',
  VIP_PRESENT_END = 'VipEndPresent',
  VIP_LONG = 'VipProlong',
  INCOGNITO_REQUEST = 'IncognitoRequest',
  INCOGNITO_RESPONSE = 'IncognitoResponse',
  STICKER = 'Sticker',
  GREETING = 'RegistrationGreeting',
  NON_ATTACH_PHOTO = 'NonAlbumAttachPhoto',
  WINK = 'Wink',
  STOPCHAT = 'StopChat',
  LEGACY_APP_PLATFORM = 'AppPlatform',
  ApplicationMessage = 'ApplicationMessage',
  REMOVED = 'Removed',
  UNKNOWN = 'Unknown',
  CONTENT_COMMENT = 'ContentComment',
  PROFILE_LINK = 'ProfileLink',
  SUPPORT = 'Support',
  SYSTEM = 'System',
  MISSED_CALL = 'MissedCall',
  DURATION_CALL = 'InformationAboutCall',
  REQUEST_APPROVED = 'RequestApproved',
  /**
   * Удаление входящего
   * https://youtrack.mamba.ru/issue/M-4551#focus=Comments-4-33879.0-0
   */
  RemovedForRecipient = 'RemovedForRecipient',
}

export const messageTypesArray = Object.keys(MessengerItemTypes).map(
  (item) => MessengerItemTypes[item]
)

export enum DATE_FORMAT_TYPES {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  DAY_MONTH = 'dayMonth',
  DAY_MONTH_YEAR = 'dayMonthYear',
}

export type ChatDateFormat =
  | typeof DATE_FORMAT_TYPES.TODAY
  | typeof DATE_FORMAT_TYPES.YESTERDAY
  | typeof DATE_FORMAT_TYPES.DAY_MONTH
  | typeof DATE_FORMAT_TYPES.DAY_MONTH_YEAR

export const FindStringEmojiTextRegExp = /\*[A-Za-z0-9-_+?!%^&()@#$;]+\*/g

export const SUPPORT_BOT_PROFILE_ID = 4
export const NEWS_BOT_PROFILE_ID = 1763992114

export enum Services {
  CONTACT = 'contact',
  EMPTY = 'empty',
  GIFT = 'gift',
  PHOTO = 'photo',
  STICKER = 'sticker',
  EMOJI = 'emoji',
}

export enum MessageMenuGradiend {
  Default,
  Failed,
  Incoming,
}

export enum MessengerTooltips {
  complains = 'chatMessengerComplaintTooltip',
}

export const TOOLTIP_ANIMATION_DURATION = 100
/** Вешается на родительский элемент сообщения, в целях установки z-index */
export const DATA_REACTIONS_OPENED_ATTRIBUTE = 'data-reactions-opened'
/** Вешается на родительский элемент сообщения, в целях установки z-index */
export const DATA_MENU_OPENED_ATTRIBUTE = 'data-menu-opened'

export enum PhotoRestrictionStatus {
  NoRestriction = 'noRestriction',
  RestrictAll = 'restrictAll',
}

export const removedTypes = [
  MessengerItemTypes.REMOVED,
  MessengerItemTypes.UNKNOWN,
  MessengerItemTypes.RemovedForRecipient,
]
export const DESKTOP_REGULAR_WIDTH = '70%'
export const TOUCH_REGULAR_WIDTH = DESKTOP_REGULAR_WIDTH
