import { fetchProfileAlbumMainAction } from 'actions/profile/fetchProfileAlbumMainAction'
import { profilePath } from 'components/paths'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import {
  accessDeniedPath,
  albumPath,
  classMatesRedirectAccessDeniedPath,
  classMatesRedirectPath,
  providerNameClassMatePath,
  providerPath,
  uploadPhotosPath,
} from './Uploader.paths'

/**
 * После авторизации в одноклассниках, в репозитории mamba шаблон mamba2/.templates/ru/default/openapi/odnoklassniki/auth_stub.tpl
 * редиректит нас на '/profile/ok-photo-upload'.
 * После этого, этот компонент редиректит нас на нужную страницу.
 */
export const ProviderClassMateRedirect: FC = () => {
  const match = useRouteMatch()
  const dispatch = useDispatch()

  const { userId, albumId, albumIdMain } = useShallowEqualSelector(
    ({
      userReducer: { albumId, albumIdMain },
      authorizationReducer: {
        profile: { id },
      },
    }) => ({
      userId: id,
      albumId,
      albumIdMain,
    })
  )

  useEffect(() => {
    if (!albumIdMain) {
      dispatch(fetchProfileAlbumMainAction())
    }
  }, [albumIdMain, dispatch])

  if ((albumIdMain || albumId) && userId) {
    if (match?.url === classMatesRedirectAccessDeniedPath) {
      return (
        <Replace
          to={mergeAllUrls(
            profilePath,
            userId,
            uploadPhotosPath,
            albumPath,
            albumId ?? albumIdMain!,
            providerPath,
            providerNameClassMatePath,
            accessDeniedPath
          )}
          uid="ProviderClassMateRedirect-accessDenied"
        />
      )
    }

    if (match?.url === classMatesRedirectPath) {
      return (
        <Replace
          to={mergeAllUrls(
            profilePath,
            userId,
            uploadPhotosPath,
            albumPath,
            albumId ?? albumIdMain!,
            providerPath,
            providerNameClassMatePath
          )}
          uid="ProviderClassMateRedirect"
        />
      )
    }
  }

  return <DefaultLoader />
}
