export const fullPageReload = (url?: string): void => {
  if (!process.env.browser) {
    throw new Error(`fullPageReload is client only: ${url}`)
  }

  console.info('fullPageReload', url)

  if (url) {
    window.location.replace(url)
  } else {
    window.location.reload()
  }
}
