import {
  GET_INTEREST_LIST_ACTION,
  GET_MY_INTEREST_LIST_ACTION,
  RESET_FORM_INTEREST,
  UPDATE_INTEREST_FIELD_ACTION,
  GET_POPULAR_INTEREST_LIST_ACTION,
  SAVE_INTEREST_FIELD_ACTION,
  InterestsActionTypes,
} from 'actions/interestsAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { Reducer } from 'redux'
import { Interest } from './interests.types'

export interface InterestsState {
  form: {
    interest: string
  }
  profile: {
    interestList: Interest[]
  }
  interestFindList: {
    isLoading: boolean
    interestsList: Interest[]
    isNotFound: boolean
  }
  interestPopularList: {
    isLoading: boolean
    interestsList: Interest[]
    lastModified: number
  }
  isLoading: boolean
}

export const interestsReducer: Reducer<InterestsState, InterestsActionTypes> = (
  state = {
    form: {
      interest: '',
    },
    profile: {
      interestList: [],
    },
    interestFindList: {
      isLoading: false,
      interestsList: [],
      isNotFound: false,
    },
    interestPopularList: {
      isLoading: true,
      interestsList: [],
      lastModified: 0,
    },
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case GET_MY_INTEREST_LIST_ACTION:
      return defaultPromiseReducer(state, action, undefined, () => ({
        profile: {
          ...state.profile,
          interestList: action.result!,
        },
      }))

    case UPDATE_INTEREST_FIELD_ACTION:
      const newState = { ...state }
      const { name, value } = action

      newState.form[name] = value

      return newState

    case GET_INTEREST_LIST_ACTION:
      if (action.ready) {
        return {
          ...state,
          interestFindList: {
            isLoading: false,
            interestsList: Object.values(action.result!),
            isNotFound:
              action.result!.length === 0 && state.form.interest.length > 1,
          },
        }
      }

      return {
        ...state,
        interestFindList: {
          isLoading: true,
          interestsList: [],
          isNotFound: state.interestFindList.isNotFound,
        },
      }

    case RESET_FORM_INTEREST:
      return {
        ...state,
        form: {
          ...state.form,
          interest: '',
        },
      }

    case GET_POPULAR_INTEREST_LIST_ACTION:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          interestPopularList: {
            ...state.interestPopularList,
            isLoading: true,
          },
        }),
        () => ({
          interestPopularList: {
            interestsList: action.result!.interests,
            isLoading: false,
            lastModified: new Date().getTime(),
          },
        }),
        () => ({
          interestPopularList: {
            ...state.interestPopularList,
            isLoading: false,
          },
        })
      )

    // Возможно надо будет тут отфильтровать либо популрные, либо найденные интересы после выбора.
    case SAVE_INTEREST_FIELD_ACTION:
      return state

    default:
      return state
  }
}
