import React, { FC, useCallback, useEffect, useState } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { loadLocalStorageState } from 'common/store/localStorage'
import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'
import { shouldSaveStepRegistrationExtraData } from 'components/system/LocalStorage/LocalStorage.functions'

const StepRegistrationStorageLoadable = loadable(() =>
  import('./StepRegistrationStorage')
)

export const LocalStorageManager: FC = () => {
  const { gdprBlocked, realStatusNeeded } = useShallowEqualSelector(
    ({ errorReducer: { gdprBlocked, realStatusNeeded } }) => ({
      gdprBlocked,
      realStatusNeeded,
    })
  )
  const [
    loadStepRegistrationStorage,
    setLoadStepRegistrationStorage,
  ] = useState(false)

  useEffect(() => {
    if (!gdprBlocked && !realStatusNeeded) {
      const partialState = loadLocalStorageState()
      // console.log('Can save data', partialState)
      if (shouldSaveStepRegistrationExtraData(partialState.stepRegistration!)) {
        setLoadStepRegistrationStorage(true)
      }
    } else {
      // console.log(`Can't save local storage data`)
    }
  }, [gdprBlocked, realStatusNeeded])

  const handleSetShouldSaveStepRegistrationData = useCallback(() => {
    setLoadStepRegistrationStorage(false)
  }, [])

  // В дальнейшем добавляем отдельными строками другие задачи
  return (
    <>
      {loadStepRegistrationStorage && (
        <StepRegistrationStorageLoadable
          onDispose={handleSetShouldSaveStepRegistrationData}
        />
      )}
    </>
  )
}
