import { AppDispatchNext } from 'actions/actions.typesNext'

export const SET_GEOLOCATION_ERROR_CODE = 'SET_GEOLOCATION_ERROR_CODE' as const

export const setGeolocationErrorCodeAction = (errorCode?: number) => (
  dispatch: AppDispatchNext
) => {
  /** https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code */
  switch (errorCode) {
    case 1: // GeolocationPositionError.PERMISSION_DENIED
    case 2: // GeolocationPositionError.POSITION_UNAVAILABLE
      dispatch(setGeolocationErrorCodePlainAction(errorCode))
      break
    case 3: // GeolocationPositionError.TIMEOUT
      // На таймаут пофиг, т.к. координаты могут быть получены на несколько секунд позже
      break
    default:
      dispatch(setGeolocationErrorCodePlainAction(GEOLOCATION_CUSTOM_ERROR))
  }
}

export const setGeolocationErrorCodePlainAction = (code: number) => ({
  type: SET_GEOLOCATION_ERROR_CODE,
  code,
})

export const GEOLOCATION_CUSTOM_ERROR = 99
