import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const PromoCodeLoadable = loadable(
  async () => (await import('./PromoCode')).PromoCode,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Promo code split error', error)
      }
      return <DefaultLoader />
    })(defaultLoadableProps),
  }
)
