import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { fetchProfilesMyLocationApi } from 'api/profile/fetchCurrentUserLocationApi'

export const FETCH_MY_LOCATION = 'FETCH_MY_LOCATION' as const

export const fetchProfilesMyLocationAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    systemReducer: { locale },
  } = getState()

  return dispatch(fetchProfilesMyLocationPlainAction(locale))
}

export const fetchProfilesMyLocationPlainAction = (locale: string) => ({
  type: FETCH_MY_LOCATION,
  promise: () => fetchProfilesMyLocationApi(locale),
})
