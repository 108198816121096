import { mergeAllUrls } from 'functions/mergeAllUrls'

export const eventListPath = '/event-list'
export const eventListFilterPath = ':filter'
export const eventListWithFilterPath = mergeAllUrls(
  eventListPath,
  eventListFilterPath
)

export enum EventListFilter {
  All = 'all',
  Guest = 'visits',
  Likes = 'likes',
  Favourites = 'favorites',
}

export interface EventListPathParams {
  filter: EventListFilter
}

export const EventListWithAdv: string[] = [
  EventListFilter.All,
  EventListFilter.Guest,
]
