import React, { FC } from 'react'
import { BOTTOM_SHEET_MOBILE_PORTAL_ID } from './BottomSheet.constants'
import { useRedesign } from 'hooks/useRedesign'

/** Нужно разместить там, где нужно, чтобы открывался BottomSheet */
export const BottomSheetPortal: FC = () => {
  const redesign = useRedesign()

  if (!redesign) {
    return null
  }

  return <div id={BOTTOM_SHEET_MOBILE_PORTAL_ID} />
}
