import { Api6Error } from 'api/api.types'
import { DeprecatedApi6Error } from 'api/types'
import { ApiResult } from 'api/fetchApi'

export interface ErrorState {
  error:
    | null
    | Api6Error
    | DeprecatedApi6Error
    | ApiResult<unknown>
    | {
        code: string
        message: string
        data: unknown
        humanMessage: null
      }
  showCaptcha: boolean
  realStatusNeeded: boolean
  profileRemoved: boolean
  profileBanned: boolean
  profilePersonalBanned: boolean
  ipBlocked: boolean
  trackBlocked: boolean
  gdprBlocked: boolean
  isErrorAuthorizedNeedLogout: boolean
  firstTimeRealStatus: boolean
  additionalRegistrationNeeded: boolean
  errorProfileData: {
    name?: string
  }
  isAuthorizedResetByServerNeedLogout: boolean
}

export const initialState: ErrorState = {
  error: null,
  showCaptcha: false,
  realStatusNeeded: false,
  profileRemoved: false,
  profileBanned: false,
  profilePersonalBanned: false,
  ipBlocked: false,
  trackBlocked: false,
  gdprBlocked: false,
  firstTimeRealStatus: false,
  additionalRegistrationNeeded: false,
  isErrorAuthorizedNeedLogout: false,
  errorProfileData: {},
  isAuthorizedResetByServerNeedLogout: false,
}
