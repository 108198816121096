import { fetchQueryApi } from 'api/fetchApi'
import { paths } from 'api/generated/promo_codes'

type Method = paths['/promocodes/aboutCode']['get']
type Result = Method['responses']['200']['schema']

export const fetchPromoCodeAboutApi = (promoCode: string) => {
  return fetchQueryApi<Result, Method['parameters']['query']>(
    '/promocodes/aboutCode',
    {
      promoCode,
    }
  )
}
