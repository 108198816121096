import { UniNoticeType } from './UniNotice.constants'

/**
 * Список id-шников:
 * https://developers.wamba.com/api/services/uni_notice_index/index.html
 */
export enum UniNoticeId {
  user_ignored_you = 'user-ignored-you',
  hit_list_first_limit_exceeded = 'hit-list-first-limit-exceeded',
  stop_chat_user_by_ignoring_notice_id = 'stop-chat-by-ignoring',
  viberVerification = 'profile-verification-via-viber',
  telegramVerification = 'profile-verification-via-telegram',
  whatsAppVerification = 'profile-verification-via-whatsapp',
  billing_payment_receipt = 'billing-payment-receipt',
  user_added_to_ignore = 'user-added-to-ignore',
  hitListSpecialPointsAvailablePoints = 'hit-list-special-points-available-points',
  hitListSpecialPointsPromo = 'hit-list-special-points-promo',
  hitListSpecialPointsDescribe = 'hit-list-special-points-describe',
  external_url_click_confirmation = 'external-url-click-confirmation',
  external_url_is_invalid = 'external-url-is-invalid',
  action_not_supported_by_browser = 'action-not-supported-by-browser',
  network_error = 'network-error',
  dating_profile_confirm_birthdate_change = 'dating-profile-confirm-birthdate-change',
  password_recovery_success = 'password-recovery-success',
  fill_profile_for_advanced_search = 'fill-profile-for-advanced-search',
  rating_advanced_filters_restricted = 'rating-advanced-filters-restricted', // https://youtrack.mamba.ru/issue/M-1969
  hide_advertisement = 'hide-advertisement', // https://youtrack.mamba.ru/issue/M-4013#focus=Comments-4-29061.0-0
  stopChatDatingFilterNoticeId = 'stop-chat-by-dating-filter',
  messagesOnlyFromVerifiedByPhotoAnnouncement = 'messages-only-from-verified-by-photo-announcement',
  photoUploadRules = 'photo-upload-rules',
  externalMessengerOpenForAuth = 'external-messenger-open-for-auth',
  userBanned = 'user-banned',
  user_leaves_otp_pay_page = 'user-leaves-otp-pay-page',
  stopChatByRequestForCommunicationPhotoRequired = 'stop-chat-by-request-for-communication-photo-required',
}

export enum NoticeShortCutList {
  moveFavoriteOneContact = 'contacts-moved-to-favorites-one',
  moveFavoriteManyContact = 'contacts-moved-to-favorites-many',
  moveCommonManyContact = 'contacts-moved-to-common-many',
  moveCommonOneContact = 'contacts-moved-to-common-one',
  moveIgnoreManyContact = 'contacts-moved-to-ignored-many',
  moveIgnoreOneContact = 'contacts-moved-to-ignored-one',
  deleteOneContact = 'contacts-deleted-one',
  deleteManyContact = 'contacts-deleted-many',
  deleteAllContactsFromIgnoreFolder = 'contacts-deleted-from-ignored-all',
  deleteCustomFolder = 'contacts-folders-deleted',
  userCoordinatesUpdate = 'user-coordinates-updated',
  confirmChangeEmailSuccess = 'confirm-change-email-success',
  confirmProfileSuccess = 'confirm-profile-success',
  vipActivateSuccess = 'vip-activate-success',
  restoreUserSuccess = 'restore-user-success',
  messagingNewMessage = 'messaging-new-message',
  phoneVerificationCodeInvalid = 'phone-verification-code-invalid',
}

export enum InlineNoticeTypes {
  authLinkIsInvalidOrExpiredId = 'auth-link-is-invalid-or-expired',
  passwordRecoveryLinkExpired = 'password-recovery-link-expired',
  passwordRecoverySuccess = 'password-recovery-success',
  tele2 = 'billing-subscription-tele2',
  promoCode = 'promo-code',
}

export const NoticeInlineRedirect = [
  InlineNoticeTypes.authLinkIsInvalidOrExpiredId,
  InlineNoticeTypes.passwordRecoveryLinkExpired,
  InlineNoticeTypes.passwordRecoverySuccess,
]

/** Список нотисов, которые открываются в BottomSheet без роутинга */
export const uniNoticesIdsListInBottomSheet = [
  UniNoticeId.telegramVerification,
  UniNoticeId.viberVerification,
  UniNoticeId.whatsAppVerification,
  UniNoticeId.externalMessengerOpenForAuth,
]

/** Проигнорированные нотисы согласно редизайну */
export const ignoredUniNoticesIdsList = [
  NoticeShortCutList.phoneVerificationCodeInvalid,
]

export const livenessNoticeIdsList = [
  UniNoticeType.liveness_verification_failed,
  UniNoticeType.liveness_verification_success,
]
