import { photoLineServiceType, giftsServiceType } from 'common/constants'

/**
 * Удалить экспорт ради экспорта
 * @deprecated
 */
export { photoLineServiceType, giftsServiceType }

export const incorrectPhoneType = 'app-link-sending-failed-incorrect-phone'
export const successPhoneType = 'app-link-was-sent-via-sms'
export const cascadeChangeProfileCompletedType =
  'cascade_change_profile_completed'

export enum NotificationType {
  // billing
  photoLine = 'photoline',
  gifts = 'gifts',
  makeTop = 'maketop',
  featuredPhotos = 'featured-photos',
  orderBankCardSuccess = 'order/bankcard-success',
  genericError = 'order/bankcard-fail',
  orderMobileSuccess = 'order/mobile-success',
  orderCoinsSuccess = 'order/coins-success',
  // other
  diamonds = 'diamonds',
  incorrectPhone = 'app-link-sending-failed-incorrect-phone',
  successPhone = 'app-link-was-sent-via-sms',
  informationSaveSuccess = 'information-save-success',
  cascadeChangeProfileCompleted = 'cascade_change_profile_completed',
  // message
  newMessageNoticeId = 'messaging-new-message',
  incorrectSizePhotoFormSupport = 'incorrect_size_photo_form_support',
  forbiddenRemoveProfile = 'forbidden_remove_profile',
  // push notifications
  pushNotificationsNotSupported = 'push-notifications-not-supported',
  // verification
  phoneFromSocialAccountAlreadyVerified = 'phone-from-social-account-already-verified',
}
