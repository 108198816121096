import {
  MAKE_DIRTY,
  RESET_FORM,
  TOGGLE_PASSWORD,
  TOGGLE_VALIDATE_BY_SUBMIT,
} from 'actions/formAction'
import { Reducer } from 'redux'

export const createPasswordKey = (formId: string, fieldName: string) =>
  `${formId}.${fieldName}`

export type AppForm = Record<string, boolean>
export type AppFormMap = Record<string, AppForm>

export interface FormState {
  form: AppFormMap
  password: Record<string, boolean>
  validation: Record<string, boolean>
}

export const formReducer: Reducer<FormState> = (
  state = {
    form: {},
    password: {},
    validation: {},
  },
  action
) => {
  const { formId, fieldName } = action
  switch (action.type) {
    case MAKE_DIRTY:
      if (state.form[formId] && state.form[formId][fieldName]) {
        return state
      }
      const newState = {
        ...state,
        form: {
          ...state.form,
        },
      }
      if (!newState.form[formId]) {
        newState.form[formId] = {}
      }
      newState.form[formId][fieldName] = true
      return newState

    case TOGGLE_PASSWORD:
      const passwordKey = createPasswordKey(formId, fieldName)
      return {
        ...state,
        password: {
          ...state.password,
          [passwordKey]: !state.password[passwordKey],
        },
      }

    case RESET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [formId]: {},
        },
      }

    case TOGGLE_VALIDATE_BY_SUBMIT:
      return {
        ...state,
        validation: {
          ...state.validation,
          [action.formId]: action.validateBySubmit,
        },
      }

    default:
      return state
  }
}
