export const smallPadding = 10

export enum FontSize {
  nano = 8,
  micro = 10,
  footer = 11,
  note = 12,
  text = 13,
  timePost = 14,
  titleSubGiftCategory = 15,
  header = 16,
  button = 18,
  titleName = 21,
  titleLarge = 30,
}

export enum PhotoSize {
  photoAvatar = 60,
  photoAvatarLarge = 100,
}

export enum BorderRadiusSize {
  photoStream = 10,
  chatMessage = 10,
  tooltip = 10,
  contactCard = 20,
  chatContactCard = 30,
}

export enum ToolTipSize {
  photoLine = 180,
  gift = 260,
}

export enum IconSize {
  commentIcon = 20,
}
