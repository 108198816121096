import { SocketReceiveDataAction } from 'actions/socket/socketReceiveDataAction'
import { PROFILE_UNBAN_CHANNEL } from 'functions/comet'
import { ErrorState } from './ErrorState'

export const reduceSocketReceiveData = (
  state: ErrorState,
  action: SocketReceiveDataAction
): ErrorState => {
  const channel = action.result?.[0]?.channel

  if (!channel) {
    return state
  }

  if (channel.startsWith(PROFILE_UNBAN_CHANNEL)) {
    return {
      ...state,
      profileBanned: false,
    }
  }

  return state
}
