import {
  API5_ERROR,
  API6_ERROR,
  CATCH_NETWORK_ERROR,
  MANUAL_ERROR,
} from 'api/constants'
import { Api6Error } from 'api/api.types'
import { Api5Error } from 'common-constants/api5.types'

export const apiResultHasErrors = (
  result: unknown
): result is Api5Error | Api6Error =>
  result! &&
  [API6_ERROR, API5_ERROR, CATCH_NETWORK_ERROR, MANUAL_ERROR].indexOf(
    result['code']
  ) > -1
