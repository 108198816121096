import { forceCaptchaAction, setErrorFromGQLAction } from 'actions/errorAction'
import { addUniNoticeFromGraphqlToReduxAction } from 'actions/uninotice/uniNoticeAction'
import { consoleLog } from 'common/consoleLog'
import { onError } from '@apollo/client/link/error'

/**
 * https://www.apollographql.com/docs/react/data/error-handling/
 */
export const errorLink = (store) =>
  onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    const noticeCount = graphQLErrors?.reduce((acc, { extensions }) => {
      if (extensions) {
        if (extensions.category === 'client' && extensions.code === 'captcha') {
          store.dispatch(forceCaptchaAction())
        }
        if (extensions.notice) {
          store.dispatch(
            addUniNoticeFromGraphqlToReduxAction(extensions.notice)
          )

          if (extensions.code) {
            store.dispatch(setErrorFromGQLAction(extensions.code as string))
          }

          return acc++
        }
      }
      return acc
    }, 0)
    if (noticeCount && noticeCount > 0) {
      // В теории можно будет сделать особые действия, если есть нотайсы
      // но Apollo на текущий момент не умеет передать в ответ дополнительные
      // данные
    }
    if (networkError) {
      consoleLog(`Network error: ${networkError}`)
    }
    // forward(operation)
  })
