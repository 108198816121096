import {
  fetchDeleteMyInterestApi,
  fetchMyInterestApi,
  fetchPopularInterestApi,
  saveInterestApi,
  fetchSearchInterestApi,
} from 'api/profileApi'
import { findApiLocale } from 'actions/actions.functions'
import { AsyncAction } from 'actions/actions.types'
import { Interest } from 'reducers/interests/interests.types'

export const UPDATE_INTEREST_FIELD_ACTION = 'UPDATE_INTEREST_FIELD_ACTION' as const
export const updateInterestFieldAction = (name, value) => ({
  type: UPDATE_INTEREST_FIELD_ACTION,
  name,
  value,
})

export const SAVE_INTEREST_FIELD_ACTION = 'SAVE_INTEREST_FIELD_ACTION'

interface SaveInterestFieldAction extends AsyncAction {
  type: typeof SAVE_INTEREST_FIELD_ACTION
}

export const saveInterestFieldAction = (value: string) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_INTEREST_FIELD_ACTION,
      promise: async () => {
        const json = await saveInterestApi(value)
        dispatch(fetchMyInterestAction())
        return json
      },
    })
  }
}

export const GET_INTEREST_LIST_ACTION = 'GET_INTEREST_LIST_ACTION'

interface SearchInterestListAction
  extends AsyncAction<{ id: number; text: string }[]> {
  type: typeof GET_INTEREST_LIST_ACTION
}

export const searchInterestListAction = (value) => ({
  type: GET_INTEREST_LIST_ACTION,
  promise: () => fetchSearchInterestApi(value),
})

export const GET_MY_INTEREST_LIST_ACTION = 'GET_MY_INTEREST_LIST_ACTION' as const

interface FetchMyInterestAction extends AsyncAction<Interest[]> {
  type: typeof GET_MY_INTEREST_LIST_ACTION
}

export const fetchMyInterestAction = () => {
  return {
    type: GET_MY_INTEREST_LIST_ACTION,
    promise: () => fetchMyInterestApi(),
  }
}

export const DELETE_MY_INTEREST_ACTION = 'DELETE_MY_INTEREST_ACTION'

interface DeleteMyInterestAction extends AsyncAction {
  type: typeof DELETE_MY_INTEREST_ACTION
}
export const deleteMyInterestAction = (id) => (dispatch) => {
  dispatch({
    type: DELETE_MY_INTEREST_ACTION,
    promise: async () => {
      const json = await fetchDeleteMyInterestApi(id)
      if (json.ok) {
        dispatch(fetchMyInterestAction())
      }
      return json
    },
  })
}

const popularExpiredMs = 10 * 1000

export const GET_POPULAR_INTEREST_LIST_ACTION = 'GET_POPULAR_INTEREST_LIST_ACTION' as const

interface FetchInterestPopularListAction
  extends AsyncAction<{ interests: Interest[] }> {
  type: typeof GET_POPULAR_INTEREST_LIST_ACTION
}

export const fetchInterestPopularListAction = () => (dispatch, getState) => {
  const state = getState()
  const { lastModified } = state.interestsReducer.interestPopularList
  const locale = findApiLocale(
    state.authorizationReducer.authorized,
    state.systemReducer.locale
  )
  if (lastModified + popularExpiredMs < new Date().getTime()) {
    dispatch({
      type: GET_POPULAR_INTEREST_LIST_ACTION,
      promise: () => fetchPopularInterestApi(locale),
    })
  }
}

export const RESET_FORM_INTEREST = 'RESET_FORM_INTEREST' as const

export const resetFormAction = () => ({
  type: RESET_FORM_INTEREST,
})

export type InterestsActionTypes =
  | ReturnType<typeof resetFormAction>
  | ReturnType<typeof updateInterestFieldAction>
  | FetchInterestPopularListAction
  | FetchMyInterestAction
  | SearchInterestListAction
  | SaveInterestFieldAction
  | DeleteMyInterestAction
