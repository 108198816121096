export enum AbTestPhotoUploadGroups {
  /** Обычная загрузка фоток */
  GroupA = 'Group A',
  /** Тоже обычная загрузка фоток */
  GroupB = 'Group B',
  /** Жёсткий блок после регистрации с требованием загрузить фотку */
  GroupC = 'Group C',
  /** То же, что C, но спустя 65,5 часов работы */
  GroupD = 'Group D',
  /** Постоянные напоминания о необходимости загрузки фотки */
  GroupE = 'Group E',
}

export enum AbTestFeaturedPhotosHits {
  GroupA = 'A',
  GroupB = 'B',
}

/**
 * Зачисление бонусов/випа при регистрации пользователя
 */
export enum AbTestPromoCodeReg {
  /** Не отображаем страницу /code-accepted, отображаем информацию о том, что код применён на экране пошаговой регистрации */
  GroupG1 = 'G1',
  /** Отображаем страницу /code-accepted, не отображаем информацию о том, что код применён на экране пошаговой регистрации */
  GroupG2 = 'G2',
  /** Отображаем страницу /code-accepted, отображаем информацию о том, что код применён на экране пошаговой регистрации */
  GroupG3 = 'G3',
}

/**
 * Список контактов без блока Новые контакты
 */
export enum AbTestNewContactsBlock {
  WithBlock = 'withblock',
  Withoutblock = 'withoutblock',
}

/**
 * Новый фильтр "Общаться только с понравившимися"
 */
export enum AbTestMessagesFromLiked {
  /** Группа у которой установлен старый фильтр  */
  OldFilter = 'A',
  /** Группа у которой установлен новый фильтр  */
  NewFilter = 'B',
}

/*
 * https://youtrack.mamba.ru/articles/M-A-387#gruppy-a-b-testa
 */
export enum AbTestHideAdvBlock {
  GroupA = 'A',
  GroupB = 'B',
  GroupC = 'C', // витрина vip c нужным slide
  GroupD = 'D', // notice
  GroupE = 'E',
}

/*
 * https://youtrack.mamba.ru/articles/M-A-359#gruppy-a-b-testa
 */
export enum AbTestGroupНitHiddenData {
  GroupA = 'A',
  GroupB = 'B',
  GroupC = 'C',
  GroupD = 'D',
}

/**
 * https://youtrack.mamba.ru/articles/M-A-421
 */
export enum AbTestGeolocationRequestGroup {
  GroupA = 'A',
  GroupB = 'B',
  GroupC = 'C',
  GroupD = 'D',
  GroupE = 'E',
  GroupF = 'F',
  GroupG = 'G',
  GroupH = 'H',
}
