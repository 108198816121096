import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const UseActivateVipMegafonSubscriptionLoadable = loadable(
  async () =>
    (await import('./UseActivateVipMegafonSubscription'))
      .UseActivateVipMegafonSubscription,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('UseActivateVipMegafonSubscription split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
