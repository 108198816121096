import { AsyncThunkAction } from 'actions/types'
import {
  ActionComet,
  MessageFromCometContent,
  MessageIgnoredFromCometContent,
  MessageReaderFromCometContent,
  MessageTypingFromCometContent,
  MessengerRequestStatus,
} from 'reducers/messenger/ChatMessengerState'
import { StorefrontService } from 'components/storefront/StoreFront.constants'
import { GQLHitGroupCounters } from 'generated-graphql/graphql'
import { AsyncAction } from 'actions/actions.types'
import { UniNotice } from 'api/uninotice/UniNotice'
import { PhotoRestrictionStatus } from 'components/page/Chat/Messenger/Messenger.constants'

export interface BaseRemoveMessage {
  contactUserId: number
  removedMessagesIds: number[]
}
export interface RemoveMessage extends BaseRemoveMessage {
  contactId: number // ?
}

export interface AttachmentVisibilityContactModified {
  contactId: number
  status: PhotoRestrictionStatus
}

export interface AttachmentVisibilityMessageModified {
  contactId: number
  messageId: number
  isVisible: boolean
}

interface ReactionMessage {
  id: number
  messageUuid: string
  userId: number
}

export interface AddReactionMessage extends ReactionMessage {
  createdAt: number
  type: string
  value: string
}

export interface ReplacedReactionMessage extends ReactionMessage {
  oldId: number
  type: string
  value: string
  createdAt: number
}

export interface RemovedReactionMessage extends ReactionMessage {}

export interface ReadReactionMessage extends ReactionMessage {
  readAt: number
}

interface MessageHitListGroup {
  groups: string[]
  newHitsCounters: GQLHitGroupCounters
}

export interface DeliveredOrderComet {
  orderId: string // Идентификатор заказа
  serviceId: StorefrontService // Идентификатор сервиса (string)
}

export interface RequestForCommunicationCountComet {
  count: number
  lastRequestId: number
}

export interface RequestForCommunicationStatusChangedComet {
  contactAnketaId: number
  status: MessengerRequestStatus
}

export interface PhotoLinePhotoComet {
  id: number
  user: {
    id: number
    sex: 'M' | 'W'
    age: number
    name: {
      orig: string
      translit: string
    }
    geo: { [geoId: string]: string }
    url: string
    photoCount: number
    isVip: boolean
    isOnline: boolean
    hasVerifiedPhoto: boolean
    themeId: number | undefined
  }
  photo: {
    id: number
    url: string
    urlSquare: string
    urlSquareSmall: string
  }
  num: number
  message: {
    orig: string
    translit: string
  }
  line_id: number
}

export interface VipStatusChangedNotification {
  status: boolean // Статус VIP
}

/**
 * https://redmine.mamba.ru/issues/113282#note-17
 */
export interface AccountBalanceNotification {
  oldBalance: number
  newBalance: number
  value: number
  updated: string
}

export interface MessengerMessagesCounterComet {
  count_all: number // Всего сообщений
  count_unread: number // Число непрочитанных сообщений
}

/** Пустые ответы от кометы */
export interface EmptyBody {}

/** Из канала mamba.gifts.user.${userId} */
export interface GiftsUserComet {
  to_user_id: boolean
}

export type LivenessSessionStatus = 'failed' | 'verified' | 'not-verified'

export interface LivenessComet {
  sessionStatus: LivenessSessionStatus
  sessionId: string
}

/**
 * https://developers.wamba.com/comet/
 */
export type SocketContent =
  | RemoveMessage
  | AddReactionMessage
  | ReplacedReactionMessage
  | RemovedReactionMessage
  | MessageReaderFromCometContent
  | MessageFromCometContent
  | MessageTypingFromCometContent
  | MessageIgnoredFromCometContent
  | MessageHitListGroup
  | DeliveredOrderComet
  | PhotoLinePhotoComet
  | VipStatusChangedNotification
  | AccountBalanceNotification
  | MessengerMessagesCounterComet
  | UniNotice
  | EmptyBody
  | GiftsUserComet

export interface SocketData<T extends SocketContent = {}> {
  channel: string
  action: ActionComet
  limit: number
  lifetime: number
  cursor: number
  identity: string
  key: number
  content: T
}

export const SOCKET_RECEIVE_DATA = 'SOCKET_RECEIVE_DATA' as const

export interface SocketReceiveDataAction extends AsyncAction<SocketData[]> {
  type: typeof SOCKET_RECEIVE_DATA
  userId: number
  chatSelectedProfileId: number
  pathname: string
  result: SocketData[]
}

export const socketReceiveDataAction = (
  result: SocketData[]
): AsyncThunkAction => (dispatch, getState) => {
  const {
    authorizationReducer: {
      profile: { id },
    },
    router: {
      location: { pathname },
    },
    chatMessengerReducer,
  } = getState()

  return dispatch({
    type: SOCKET_RECEIVE_DATA,
    result,
    userId: id,
    pathname,
    // TODO вынести из общего экшена, то что касается чата
    chatSelectedProfileId: chatMessengerReducer?.selectedProfileId || 0,
    // TODO удалить ready: true после того как уберем везде longPoling, так как в reducer у нас заявзка на запросе ajax от сервеа, поэтому надо передавать ready: true
    ready: true,
  })
}

export type SocketReceiveDataTypes = SocketReceiveDataAction
