export const clientThrottle = (
  callback: Function,
  ms: number,
  lastCall = false
): VoidFunction => {
  let isThrottled = false
  let lastCallTimer: number = 0

  return (...params: unknown[]) => {
    if (!isThrottled) {
      callback(...params)
      isThrottled = true
      clearTimeout(lastCallTimer)
      setTimeout(() => {
        isThrottled = false
      }, ms)
    } else if (lastCall) {
      clearTimeout(lastCallTimer)
      lastCallTimer = window.setTimeout(() => {
        callback(...params)
      }, ms)
    }
  }
}

export const debounce = (callback: Function, ms: number, immediate = false) => {
  let timer: number | null = null

  return (...params: unknown[]) => {
    if (timer) {
      clearTimeout(timer)
    } else if (!timer && immediate) {
      callback(...params)
    }

    timer = window.setTimeout(() => {
      callback(...params)
      timer = null
    }, ms)
  }
}
