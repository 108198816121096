const agreementPath = 'agreement'

export const agreementAndroidStandalonePath = `/${agreementPath}/android-agreement-standalone`
export const agreementIOSStandalonePath = `/${agreementPath}/ios-agreement-standalone`

export const legalHost = 'https://legal.wmbcdn.com'

/**
 * Назвал с *Folder на конце, т.к. поход на внешнюю статику:
 *
 * https://legal.wmbcdn.com/ru/agreement.html
 * https://legal.wmbcdn.com/ru/lovemailru/agreement.html
 * https://legal.wmbcdn.com/en/lite/agreement.html
 */
export const androidFolder = '/android'
export const iOsFolder = '/ios'
export const huaweiFolder = '/huawei'
export const loveMailRuFolder = '/lovemailru'
export const liteFolder = '/lite'
