import {
  CLEAR_LOCATION_SUGGEST_LIST,
  ClearLocationSuggestListAction,
} from 'actions/geolocation/clearLocationSuggestListAction'
import {
  FETCH_LOCATION_SUGGEST_LIST,
  FetchLocationSuggestListAction,
  LocationSuggestion,
} from 'actions/geolocation/fetchLocationSuggestListAction'
import {
  SET_GEOLOCATION_ERROR_CODE,
  setGeolocationErrorCodePlainAction,
} from 'actions/geolocation/setGeolocationErrorCodeAction'
import {
  UPDATE_GEOLOCATION_PERMISSION_STATE,
  updateGeolocationPermissionStateAction,
} from 'actions/geolocation/updateGeolocationPermissionStateAction'
import {
  UPDATE_GEOLOCATION_REQUEST_HIDDEN_DATE_TS,
  updateGeolocationRequestHiddenDateTsAction,
} from 'actions/geolocation/updateGeolocationRequestHiddenDateTsAction'
import {
  UPDATE_COORDINATES,
  updateCoordinatesPlainAction,
} from 'actions/user/updateCoordinatesAction'
import { AppPermissionState } from 'api/browser/fetchPermissionStatus'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { Coordinates } from './geolocationReducer.types'
import {
  SEND_GEOLOCATION_ANALYTICS,
  sendGeolocationAnalyticsAction,
} from 'actions/geolocation/sendGeolocationAnalyticsAction'

const INITIAL_STATE = {
  coordinates: null as null | Coordinates,
  geolocationErrorCode: null as null | number,
  geolocationRequestHiddenDateTs: 0 as number,
  permissionState: null as PermissionState | null | undefined,
  locationSuggestionList: null as null | LocationSuggestion[],
  fetchingLocationSuggestionList: false as boolean,
  analyticsGeolocationAbTestRestrictionShown: false as boolean,
  analyticsGeolocationAbTestPermissionGranted: false as boolean,
} as const

export const geolocationReducer = (
  state = INITIAL_STATE,
  action:
    | FetchLocationSuggestListAction
    | ClearLocationSuggestListAction
    | ReturnType<typeof updateCoordinatesPlainAction>
    | ReturnType<typeof updateGeolocationPermissionStateAction>
    | ReturnType<typeof updateGeolocationRequestHiddenDateTsAction>
    | ReturnType<typeof setGeolocationErrorCodePlainAction>
    | ReturnType<typeof sendGeolocationAnalyticsAction>
): GeolocationState => {
  switch (action.type) {
    case UPDATE_COORDINATES:
      return {
        ...state,
        coordinates: action.coordinates,
      }

    case SET_GEOLOCATION_ERROR_CODE:
      return {
        ...state,
        geolocationErrorCode: action.code,
      }

    case UPDATE_GEOLOCATION_PERMISSION_STATE:
      return {
        ...state,
        permissionState: action.permissionState,
        geolocationErrorCode:
          action.permissionState === AppPermissionState.Denied
            ? state.geolocationErrorCode
            : null,
      }

    case UPDATE_GEOLOCATION_REQUEST_HIDDEN_DATE_TS:
      return {
        ...state,
        geolocationRequestHiddenDateTs: action.geolocationRequestHiddenDateTs,
      }

    case FETCH_LOCATION_SUGGEST_LIST:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          fetchingLocationSuggestionList: true,
        }),
        (result) => ({
          locationSuggestionList: result,
          fetchingLocationSuggestionList: false,
        }),
        () => ({
          fetchingLocationSuggestionList: false,
        })
      )

    case CLEAR_LOCATION_SUGGEST_LIST:
      return {
        ...state,
        locationSuggestionList: null,
        fetchingLocationSuggestionList: false,
      }

    case SEND_GEOLOCATION_ANALYTICS:
      return defaultPromiseReducer(state, action, undefined, () => {
        switch (action.eventType) {
          case 'open':
            return { analyticsGeolocationAbTestRestrictionShown: true }
          case 'click':
            return { analyticsGeolocationAbTestPermissionGranted: true }
          default:
            return state
        }
      })

    default:
      return state
  }
}

export type GeolocationState = typeof INITIAL_STATE
