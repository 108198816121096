import {
  FETCH_ALBUMS,
  FetchAlbumsIfNeededAction,
} from 'actions/photo/photoAction'
import { defaultPromiseReducer } from '../defaultPromiseReducer'
import { Reducer } from 'react'
import { definitions } from 'api/generated/photo'

export interface PhotoReducerState {
  loading: boolean
  albums: definitions['PhotoAlbum'][]
}

export const photoReducer: Reducer<
  PhotoReducerState,
  FetchAlbumsIfNeededAction
> = (
  state = {
    loading: false,
    albums: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_ALBUMS:
      return defaultPromiseReducer(
        state,
        action,
        () => ({}),
        () => {
          return { albums: action.result.albums }
        }
      )
    default:
      return state
  }
}
