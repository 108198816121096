import { getCurrentPositionAction } from 'actions/geolocation/getCurrentPositionAction'
import { AppPermissionState } from 'api/browser/fetchPermissionStatus'
import { VoidHandler } from 'common/types'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { GeoPermissionDeniedContent } from './GeoPermissionDeniedContent'
import { GeoPermissionErrorContent } from './GeoPermissionErrorContent'
import { GeoPermissionRequestContent } from './GeoPermissionRequestContent'
import { GeoPermissionTitle } from './GeoPermissionTitle'
import { useGeolocationAbTestAnalytics } from './hooks/useGeolocationAbTestAnalytics'
import { GeolocationAbTestPageName } from './hooks/useGeolocationAbTestAnalytics.types'

export const GeoPermissionRequestSwitch: FC<{
  title?: ReactNode | null
  onSecondaryButtonClick?: VoidHandler
  onSuccess?: VoidHandler
  pageName: GeolocationAbTestPageName
}> = ({
  title = <GeoPermissionTitle />,
  onSecondaryButtonClick,
  onSuccess,
  pageName,
}) => {
  const dispatch = useAppDispatch()

  const { permissionState, geolocationErrorCode } = useShallowEqualSelector(
    ({ geolocation: { permissionState, geolocationErrorCode } }) => ({
      permissionState,
      geolocationErrorCode,
    })
  )

  const {
    handleGeoRequestSuccess,
    handleGeoRequestShow,
  } = useGeolocationAbTestAnalytics(pageName)

  useEffect(() => {
    handleGeoRequestShow()
  }, [handleGeoRequestShow])

  const [loading, setLoading] = useState(false)

  if (geolocationErrorCode) {
    return (
      <GeoPermissionErrorContent
        title={title}
        errorCode={geolocationErrorCode}
        onSecondaryButtonClick={onSecondaryButtonClick}
      />
    )
  }

  if (permissionState === AppPermissionState.Denied) {
    return (
      <GeoPermissionDeniedContent
        title={title}
        onSecondaryButtonClick={onSecondaryButtonClick}
      />
    )
  }

  const handleSuccess = () => {
    handleGeoRequestSuccess()
    onSuccess?.()
  }

  const handlePrimaryButtonClick = async () => {
    setLoading(true)
    await dispatch(getCurrentPositionAction(handleSuccess))
    setLoading(false)
  }

  return (
    <GeoPermissionRequestContent
      title={title}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      primaryButtonLoading={loading}
      onSecondaryButtonClick={onSecondaryButtonClick}
    />
  )
}
