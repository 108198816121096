import { FirebaseOptions } from '@firebase/app'

export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDcPUhCDNg561fanFeaQKFKC-Btji7DgYs',
  authDomain: 'wamba-android-app.firebaseapp.com',
  databaseURL: 'https://wamba-android-app.firebaseio.com',
  projectId: 'wamba-android-app',
  storageBucket: 'wamba-android-app.appspot.com',
  messagingSenderId: '16595498602',
  appId: '1:16595498602:web:036511c18ec4f99dc93c13',
  measurementId: 'G-KHR8FVYF20',
}
