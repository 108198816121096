import { authSocialApi } from 'api/socialUserApi'

export const FETCH_SOCIAL_DATA_USER = 'FETCH_SOCIAL_DATA_USER' as const

export const fetchSocialDataUserAction = () => ({
  type: FETCH_SOCIAL_DATA_USER,
  promise: () => authSocialApi(),
})

export type FetchSocialDataUserActionTypes = ReturnType<
  typeof fetchSocialDataUserAction
>
