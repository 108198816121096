import React, { FC } from 'react'
import { Route } from 'react-router'
import { AppStaticContext } from 'components/components.types'

export const OnlyServerRedirect: FC<{ to: string }> = ({ to }) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          console.info('OnlyServerRedirect', { to })
          ;(staticContext as AppStaticContext).url = to
        }

        return null
      }}
    />
  )
}
