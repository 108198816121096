import { postApi } from 'api/fetchApi'
import { paths as notifications_pushes } from 'api/generated/notifications_pushes'

type Paths = notifications_pushes['/notifications/subscribeOnPushes']
type Response = Paths['post']['responses']['204']
type Body = Paths['post']['parameters']['body']['body']

export const subscribeOnPushesApi = (
  subscribed: number[],
  unsubscribed: number[],
  headers
) => {
  return postApi<Response, Body>(
    '/notifications/subscribeOnPushes',
    {
      subscribed: subscribed,
      unsubscribed: unsubscribed,
    },
    { headers }
  )
}
