import { filterCometMessages } from 'reducers/functions'
import {
  FETCH_PROFILE_DETAILS,
  FETCH_USER_STATISTICS,
  FetchProfileEmailAction,
  FETCH_EMAIL_PROFILE,
  UserActionTypes,
} from 'actions/userAction'
import {
  createAccountBalanceChannel,
  createMessengerCounterChannel,
  createVipStatusChannel,
} from 'functions/comet'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { notEmptyArray } from 'functions/array'
import {
  AccountBalanceNotification,
  MessengerMessagesCounterComet,
  SOCKET_RECEIVE_DATA,
  SocketData,
  SocketReceiveDataTypes,
  VipStatusChangedNotification,
} from 'actions/socket/socketReceiveDataAction'
import {
  FETCH_MAIN_ALBUM,
  FetchProfileAlbumMainTypes,
} from 'actions/profile/fetchProfileAlbumMainAction'
import { Fields } from 'components/page/Settings/Fields'
import { UPDATE_MINI, UpdateMiniAction } from 'actions/system/updateMiniAction'
import {
  FETCH_MINI,
  FetchMiniIfNeededAction,
} from 'actions/user/fetchMiniIfNeededAction'
import { initialState, UserState } from 'reducers/user/UserState'
import { profileReducer } from 'reducers/user/profileReducer'
import { Reducer } from 'redux'
import {
  UPDATE_SETTING,
  UpdateSettingAction,
} from 'actions/updateSettingAction'
import { profileBannedCode } from 'api/index'
import { UPDATE_FIELD_ACTION } from 'actions/user/updateFieldAction'
import {
  DISABLE_INCOGNITO,
  DisableIncognitoAction,
} from 'actions/user/incognitoUserAction'
import { LOGOUT_ACTION, LogoutAction } from 'actions/authorization/logoutAction'
import {
  FETCH_EDNA_INFORMATION,
  RESET_EDNA_INFORMATION,
  fetchEdnaInformationAction,
  resetEdnaInformationAction,
} from 'actions/edna/fetchEdnaInformationAction'
import {
  TOGGLE_NEW_MESSAGE_SUPPORT_EDNA,
  toggleNewMessageSupportEdnaAction,
} from 'actions/edna/newMessageSupportEdnaAction'

export const userReducer: Reducer<
  UserState,
  | FetchProfileAlbumMainTypes
  | SocketReceiveDataTypes
  | DisableIncognitoAction
  | UpdateMiniAction
  | FetchMiniIfNeededAction
  | FetchProfileEmailAction
  | UserActionTypes
  | LogoutAction
  | UpdateSettingAction
  | ReturnType<typeof fetchEdnaInformationAction>
  | ReturnType<typeof resetEdnaInformationAction>
  | ReturnType<typeof toggleNewMessageSupportEdnaAction>
> = (state = initialState, action): UserState => {
  switch (action.type) {
    case UPDATE_MINI:
    case FETCH_MINI:
      return defaultPromiseReducer(
        state,
        action,
        undefined,
        (result) => {
          if (result) {
            return { ...profileReducer(result.anketa) }
          }
          return state
        },
        (result) => {
          // Забаненная анкета
          const anketa = result?.internalError?.anketa
          const profileBanned =
            result?.internalError?.errorCode === profileBannedCode

          if (anketa && profileBanned) {
            return { ...profileReducer(anketa) }
          }

          return state
        }
      )

    case SOCKET_RECEIVE_DATA:
      return defaultPromiseReducer(state, action, undefined, () => {
        const messageCount = filterCometMessages<MessengerMessagesCounterComet>(
          action.result as SocketData<MessengerMessagesCounterComet>[],
          createMessengerCounterChannel(action.userId)
        )

        const accountBalanceMessages = filterCometMessages<
          AccountBalanceNotification
        >(
          action.result as SocketData<AccountBalanceNotification>[],
          createAccountBalanceChannel(action.userId)
        )

        if (notEmptyArray(accountBalanceMessages)) {
          const { content } = accountBalanceMessages[
            accountBalanceMessages.length - 1
          ]
          return { accountBalance: content.newBalance }
        }

        const vipArray = filterCometMessages<VipStatusChangedNotification>(
          action.result as SocketData<VipStatusChangedNotification>[],
          createVipStatusChannel(action.userId)
        )

        if (notEmptyArray(vipArray)) {
          const { content } = vipArray[vipArray.length - 1]
          return {
            vip: content.status,
          }
        }

        if (notEmptyArray(messageCount)) {
          const { content } = messageCount[0]
          return {
            messagesCounter: content.count_unread,
          }
        }

        return null
      })

    case FETCH_EMAIL_PROFILE:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (result) {
          return {
            ...state,
            email: result.email,
          }
        }

        return null
      })

    case FETCH_PROFILE_DETAILS:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        return {
          userId: result.aid,
          accountBalance: result.balance,
          login: result.login,
          // TODO убрать location после того как в настройках поиска и объявлений выпилить location = 0_0_0_0
          // TODO проверить, работает ли код вообще
          location: ((result.details?.location as unknown) as {
            location: string
          }).location,
          details: {
            meetingGoalsExtended: result.details!.meetingGoalsExtended.map(
              (element) => element.key
            ),
            interests: result.details!.interests,
          },
        }
      })

    case FETCH_MAIN_ALBUM:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          albumIdMainLoading: true,
        }),
        (result) => ({
          albumIdMain: result.id,
          albumIdMainLoaded: true,
          albumIdMainLoading: false,
        }),
        () => ({
          albumIdMainLoaded: false,
          albumIdMainLoading: false,
        })
      )

    case FETCH_USER_STATISTICS:
      return defaultPromiseReducer(state, action, undefined, () => {
        if (action.result) {
          return {
            activityDayCounter: action.result.day,
            activityMonthCounter: action.result.month,
          }
        }

        return null
      })

    case LOGOUT_ACTION:
      return defaultPromiseReducer(state, action, undefined, () => ({
        userId: null,
        smallPhoto: null,
        messagesCounter: 0,
        accountBalance: 0,
        vip: false,
        login: null,
        incognito: false,
        notifications: 0,
        updateTitleNewMessage: false,
        hasDefaultPhoto: null,
        name: null,
        albumId: null,
        albumIdMain: null,
        albumIdMainLoaded: false,
        promoMtsAvailable: null,
        promoMtsStatusLoading: true,
        promoMtsStatus: null,
        supportHelpDeskEddy: null,
        supportHelpDeskEddyUserId: null,
      }))

    case DISABLE_INCOGNITO: {
      return defaultPromiseReducer(state, action, undefined, () => {
        return {
          incognito: false,
        }
      })
    }

    case UPDATE_SETTING:
      return action.field === Fields.ProfileVisibilityStatus
        ? {
            ...state,
            place: null,
          }
        : state

    case UPDATE_FIELD_ACTION:
      const { name, value } = action
      return {
        ...state,
        [name]: value,
      }

    case FETCH_EDNA_INFORMATION:
      return defaultPromiseReducer(
        state,
        action,
        undefined,
        (result) => ({
          supportEdnaInformation: result,
        }),
        () => ({
          supportEdnaInformation: null,
        })
      )

    case RESET_EDNA_INFORMATION:
      return {
        ...state,
        supportEdnaInformation: null,
      }

    case TOGGLE_NEW_MESSAGE_SUPPORT_EDNA:
      return {
        ...state,
        newMessageSupportEdna: action.value,
      }

    default:
      return state
  }
}
