import { AppRoute } from 'components/components.types'
import { TeamoModalLoadable } from 'components/page/PromoService/Promo/Teamo/TeamoModal/TeamoModalLoadable'
import { teamoModalCompabilityTypePath } from 'components/paths'

export const teamoModalRoutes: AppRoute[] = [
  {
    path: teamoModalCompabilityTypePath,
    component: TeamoModalLoadable,
  },
]
