import { StartScreen } from 'common/constantsStartScreen'
import { searchPath } from 'components/page/Search/Search.paths'
import { ratingPath } from 'components/paths'

export const defineStartScreenPath = (startScreen: StartScreen | null) => {
  switch (startScreen) {
    case StartScreen.search:
      return searchPath
    case StartScreen.voting:
    default:
      // Нельзя указывать indexPath, иначе будет 404 на главной для авторизованного
      return ratingPath
  }
}
