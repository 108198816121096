import React, { FC, useEffect, useMemo, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router'
import { History } from 'history'
import { ThemeProvider } from 'styled-components'
import { useApolloClient } from '@apollo/client'
import { Store } from 'redux'
import { ErrorBoundary } from '@sentry/react'
import { AppContext, AppContextType } from 'common/hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { isLoveMailru, isMamba } from 'common/constants'
import { LtrTheme, RtlTheme } from 'components/presentational/AppTheme'
import { CometProvider } from 'components/layout/MambaLayout/CometProvider'
import Analytics from 'components/system/Analytics'
import { RepeatableAction } from 'components/system/RepeatableAction'
import { checkBundleUpdateAction } from 'actions/system/checkBundleUpdateAction'
import { saveClientTimersAction } from 'actions/clientStatisticAction'
import { indexPath } from 'components/paths'
import { UpdateSlots } from 'components/system/UpdateSlots'
import { AppRedirect } from 'components/system/AppRedirect'
import { TimeRange } from 'common-constants/timeRange'
import { LOCALES_REGEXP } from 'common-constants/locale'
import { WatchPageVisibility } from 'components/system/view/WatchPageVisibility'
import { LocalStorageManager } from 'components/system/LocalStorage/LocalStorageManager'
import { MetaTags } from 'components/system/metaTags/MetaTags'
import {
  useAfterVipActivated,
  useDispatchAfterAuthorizationActions,
  useDispatchInitialActions,
  useSmoothScrollPolyfillForCheesyDesktopSafari,
  useUnregisterFirebaseMessagingServiceWorkerIfNeeded,
  useFetchMiniOnProfileRestored,
  useUpdateLastAuthMethod,
  useUpdateAnimationsDisabled,
} from './App.hooks'
import { useMobileView } from '../hooks/useMobileView'
import { YandexAdsLoadable } from 'components/banner/YandexAdsLoadable'
import { YandexMetrikaLoadable } from 'components/metric/YandexMetrikaLoadable'
import { useShowPhotoUploader } from 'hooks/useShowPhotoUploader/useShowPhotoUploader'
import { NoticeShortCut } from './notice/noticeShortCut'
import { usePromoCodeActivate } from './page/PromoCode/hooks/usePromoCodeActivate'
import { usePrevious } from 'hooks/usePrevious'
import { ShellLoadable } from 'components/page/Pwa/ShellLoadable'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { pwaOfflinePath, pwaSettingsPath } from 'components/routes/pwa.path'
import { shellPath } from 'components/page/Pwa/Pwa.paths'
import { PwaOfflineLoadable } from 'components/page/Pwa/OfflinePageLoadable'
import { PwaSettingsLoadable } from 'components/page/Pwa/PwaSettingsLoadable'
import { PwaCommunicationLoadable } from 'components/system/pwa/PwaCommunicationLoadable'
import { useCounterDown } from 'reducers/utility/counterDown/useCounterDown'
import { RouteSwitch } from 'components/system/route/RouteSwitch'
import { ActivateVipMegafonSubscription } from './page/VipMegafon/ActivateVipMegafonSubscription'
import { ActivateVipBeelineSubscription } from './page/VipBeeline/ActivateVipBeelineSubscription'
import { InstallAppBanner } from './banner/InstallAppBanner/InstallAppBanner'
import { FirebaseCommunication } from './system/firebase/FirebaseCommunication'
import { LoveMailRuTokenLoadable } from 'components/system/authorization/LoveMailRuTokenLoadable'
import { mailRuTokenParam } from 'components/page/Boarding/Boarding.constants'
import { useLinkUserToMnogoCard } from 'hooks/useLinkUserToMnogoCard'
import { useIsAuthorizedResetByServer } from 'hooks/useIsAuthorizedResetByServer'
import { useCheckAuthorization } from 'components/useCheckAuthorization'
import { useAuthorizeBySecretQueryParams } from 'components/useAuthorizeBySecretQueryParams'
import { getSafeAreaTop } from 'functions/layout/getSafeAreaTop'
import { useUrlHasNoticeInQueryParameters } from 'hooks/app/useUrlHasNoticeInQueryParameters'
import { useRedirectFromOldMobileDomain } from 'hooks/app/useRedirectFromOldMobileDomain'
import loadable from '@loadable/component'
import { SentryRoute } from 'components/system/SentryRoute'
import { useWatchGeolocationPermissionState } from 'hooks/useWatchGeolocationPermissionState'
import { useWatchGeolocationPosition } from 'hooks/useWatchGeolocationPosition'
import { getWindowHeight } from '../../client/functions/getWindowHeight'
import { isPureDevMode } from 'functions/isPureDevMode'
import { ErrorBoundaryWithRedirect } from 'components/system/ErrorBoundaryWithRedirect'
import { BottomSheetPortal } from './designSystem/BottomSheet/BottomSheetPortal'
import { SplashScreen } from './designSystem/SplashScreen/SplashScreen'
import { useSupportEdna } from 'hooks/useSupportEdna'
import { RotatePhoneOverlay } from './block/RotatePhoneOverlay/RotatePhoneOverlay'
import { livenessRedirectPath } from './page/Liveness/Liveness.paths'
import { LivenessRedirectLoadable } from './page/Liveness/LivenessRedirectLoadable'
import { useSupportLiveness } from 'hooks/useSupportLiveness'
import { WatchProfileBanned } from './system/profile/WatchProfileBanned'

const SupportEdnaLoadable = loadable(
  async () => (await import('./block/SupportEdna/SupportEdna')).SupportEdna
)

const LivenessSupport = loadable(
  async () =>
    (await import('components/page/Liveness/LivenessSupport')).LivenessSupport
)

const canShowRoutes = (partnerId: number, hasMailRuToken: string): boolean => {
  /**
   * Если love.mail.ru и есть токен, ждем проверки на токен.
   * Иначе будет мелькать интерфейс, авторизован или нет.
   */
  if (isLoveMailru(partnerId) && hasMailRuToken) {
    return false
  } else {
    return true
  }
}

const AuthorizedAppLoadable = loadable(() =>
  import('components/system/AuthorizedApp')
)

export const App: FC<{
  store: Store
  history: History
}> = ({ store, history }) => {
  const {
    rtl,
    partnerId,
    authorized,
    javaScriptEnabled,
    hasMailRuToken,
  } = useShallowEqualSelector(
    ({
      systemReducer: { rtl, partnerId, hostname, javaScriptEnabled, query },
      authorizationReducer: { authorized },
    }) => ({
      rtl,
      partnerId,
      authorized,
      hostname,
      javaScriptEnabled,
      hasMailRuToken: query[mailRuTokenParam],
    })
  )
  const apolloClient = useApolloClient()
  const location = useLocation()

  const previousAuthorized = usePrevious(authorized)

  useMobileView()
  useUpdateAnimationsDisabled()
  const hasSupportEdna = useSupportEdna()
  const hasSupportLiveness = useSupportLiveness()

  useRedirectFromOldMobileDomain()
  useShowPhotoUploader()
  useAuthorizeBySecretQueryParams()
  usePromoCodeActivate()
  useCounterDown()
  /**
   * TODO: Пока просто закомментил так как есть в AuthorizedApp
   * Потом надо убрать
   */
  // useProfileNoticeBanned()
  useUrlHasNoticeInQueryParameters()
  useDispatchInitialActions()
  useDispatchAfterAuthorizationActions()
  useWatchGeolocationPermissionState()
  useWatchGeolocationPosition()
  useSmoothScrollPolyfillForCheesyDesktopSafari()
  useAfterVipActivated()
  useUnregisterFirebaseMessagingServiceWorkerIfNeeded()
  useFetchMiniOnProfileRestored()

  /**
   * Пока просто комментриую на будущее
   * Пояснение
   * https://redmine.mamba.ru/issues/121027
   * При старте pwa, клиент не ходит на сервер за данными
   * Мы начинаем использовать флаги пользователя,
   * до того как придут ответы от асинронных запросов.
   * Это не связанная проблема именно с этим. Это глобальная проблем
   * Предложение пока такое. Перенести все проверки на state пользователя
   * на какой-то из запросов, до старта pwa.
   * Все делать через dispatch, чтобы положить в state.
   */
  // usePromoMtsCheckAvailability()
  useIsAuthorizedResetByServer()
  useLinkUserToMnogoCard()
  // useUpdateShellIfNewYear()
  useUpdateLastAuthMethod()

  useEffect(() => {
    const calculateDocumentHeight = () => {
      const slot = document.getElementById('slot')
      const slotHeight = slot ? slot.offsetHeight : 0
      const safeAreaTop = getSafeAreaTop()
      const vh = (getWindowHeight() - slotHeight - safeAreaTop) * 0.01

      document.documentElement.style.setProperty('--vh', `${vh}px`)
      document.documentElement.style.setProperty(
        '--scrollbar-width',
        `${window.innerWidth - document.documentElement.clientWidth}px`
      )
    }
    calculateDocumentHeight()

    console.info(
      'production',
      process.env.production,
      process.env.VERSION_HUMAN_DATE,
      'PWA',
      isPwaSupported()
    )

    if (window.visualViewport) {
      /**
       * Данная конструкция срабатывает каждый раз когда меняется viewport
       * https://github.com/WICG/visual-viewport/issues/79
       * Вообще с 13 safari работает
       */
      window.visualViewport.addEventListener('resize', calculateDocumentHeight)
    } else {
      window.addEventListener('resize', calculateDocumentHeight)
    }
  }, [])

  useEffect(() => {
    if (
      (authorized && !previousAuthorized) ||
      (!authorized && previousAuthorized)
    ) {
      apolloClient.resetStore().catch(console.error)
    }
  }, [apolloClient, authorized, previousAuthorized])

  /** Обновление шелл после авторизации */
  useCheckAuthorization()

  const theme = useMemo(() => (rtl ? RtlTheme : LtrTheme), [rtl])
  const value = useMemo<AppContextType>(
    () => ({ store, history } as AppContextType),
    [history, store]
  )

  const [showRoutes, setShowRoutes] = useState(
    canShowRoutes(partnerId, hasMailRuToken)
  )

  const [showAds, setShowAds] = useState(false)
  useEffect(() => {
    setShowAds(!isPureDevMode())
  }, [])

  /** Нужно для того, чтобы игнорировать языки в урле для анонимных юзеров */
  const doNotUseLocale = useMemo(() => {
    return [
      shellPath,
      pwaOfflinePath,
      pwaSettingsPath,
      livenessRedirectPath,
    ].includes(location.pathname)
  }, [location.pathname])

  if (doNotUseLocale) {
    return (
      <AppContext.Provider value={value}>
        <ThemeProvider theme={{ ...theme, partnerId, javaScriptEnabled }}>
          <Switch>
            <Route path={shellPath}>
              <ShellLoadable />
            </Route>
            <Route path={pwaOfflinePath}>
              <PwaOfflineLoadable />
            </Route>
            <Route path={pwaSettingsPath}>
              <PwaSettingsLoadable />
            </Route>
            <Route path={livenessRedirectPath}>
              <LivenessRedirectLoadable />
            </Route>
          </Switch>
          <NoticeShortCut />
        </ThemeProvider>
      </AppContext.Provider>
    )
  }

  return (
    <ErrorBoundaryWithRedirect uid="app">
      <AppContext.Provider value={value}>
        <ThemeProvider theme={{ ...theme, partnerId, javaScriptEnabled }}>
          <SplashScreen>
            <WatchPageVisibility />
            {authorized && <AuthorizedAppLoadable />}
            {/* Ассинхронно включаем, чтобы не было вечного лоадера https://redmine.mamba.ru/issues/120868 */}
            {authorized && showAds && (
              <ErrorBoundary>
                <YandexAdsLoadable />
              </ErrorBoundary>
            )}
            <FirebaseCommunication />

            {showRoutes && (
              <Switch>
                <SentryRoute
                  path={`/:locale(${LOCALES_REGEXP})`}
                  component={RouteSwitch}
                />
                <SentryRoute path={indexPath} component={RouteSwitch} />
              </Switch>
            )}

            <CometProvider />

            <Analytics />

            {isLoveMailru(partnerId) && (
              <>
                <UpdateSlots />
                <LoveMailRuTokenLoadable onFinished={setShowRoutes} />
              </>
            )}

            <RepeatableAction
              action={checkBundleUpdateAction}
              timeout={15 * TimeRange.minute}
            />
            <RepeatableAction
              action={saveClientTimersAction}
              timeout={60 * TimeRange.second}
            />
            <MetaTags />

            <LocalStorageManager />
            <AppRedirect />
            {isMamba(partnerId) && process.env.production && (
              <YandexMetrikaLoadable />
            )}
            <NoticeShortCut />
            <ActivateVipMegafonSubscription />
            <ActivateVipBeelineSubscription />
            <InstallAppBanner />

            {isPwaSupported() && <PwaCommunicationLoadable />}

            <BottomSheetPortal />
            <RotatePhoneOverlay />
          </SplashScreen>

          {authorized && hasSupportEdna && <SupportEdnaLoadable />}

          {authorized && hasSupportLiveness && <LivenessSupport />}

          <WatchProfileBanned />
        </ThemeProvider>
      </AppContext.Provider>
    </ErrorBoundaryWithRedirect>
  )
}
