import {
  additionalRegistrationNeededCode,
  errorCodeEquals,
  partnerRegistrationCode,
} from 'api/index'
import { DeprecatedApi6Error } from 'api/types'
import { ApiResult } from 'api/fetchApi'
import { Api6Error } from 'api/api.types'

export const isAdditionalRegistrationNeeded = (
  result: DeprecatedApi6Error | ApiResult<unknown>
) => {
  const error = (result as unknown) as Api6Error

  return (
    errorCodeEquals(error, additionalRegistrationNeededCode) ||
    errorCodeEquals(error, partnerRegistrationCode)
  )
}
