import { STATE } from 'common/constants'
import { mutatePartialRootStateWithVipBeelineState } from 'components/page/VipBeeline/mutatePartialRootStateWithVipBeelineState'
import { mutatePartialRootStateWithVipMegafonState } from 'components/page/VipMegafon/mutatePartialRootStateWithVipMegafonState'
import { mutatePartialRootStateWithMnogoCardIdState } from 'hooks/useLinkUserToMnogoCard'
import { loadStateFromGivenStorage } from './functions'
import { PartialRootState } from './store.types'
import { RootState } from 'reducers/RootState'
import { mutatePartialRootStateWithLocationsHistoryState } from './session/mutatePartialRootStateWithLocationsHistoryState'

export type SessionHandler = (
  state: RootState,
  partialState: PartialRootState
) => void

export const loadSessionStorageState = () => {
  return loadStateFromGivenStorage(sessionStorage)
}

export const saveStateToSessionStorage = (state: RootState) => {
  try {
    const partialState: PartialRootState = loadSessionStorageState()

    mutatePartialRootStateWithVipMegafonState(state, partialState)
    mutatePartialRootStateWithVipBeelineState(state, partialState)
    mutatePartialRootStateWithMnogoCardIdState(state, partialState)
    mutatePartialRootStateWithLocationsHistoryState(state, partialState)

    const serializedState = JSON.stringify(partialState)
    sessionStorage.setItem(STATE, serializedState)
  } catch (error) {
    // prettier-ignore
    console.error('Can\'t save state to session storage')
  }
}
