import { POSITION_OPTIONS } from './constants'

export const watchGeolocationPositionBrowserApi = (
  onSuccess: PositionCallback,
  onError?: PositionErrorCallback
) => {
  return navigator.geolocation.watchPosition(
    onSuccess,
    onError,
    POSITION_OPTIONS
  )
}

export const clearWatchingGeolocationPositionApi = (subscriptionId: number) => {
  return navigator.geolocation?.clearWatch(subscriptionId)
}
