import { fetchProfileAlbumMainAction } from 'actions/profile/fetchProfileAlbumMainAction'
import { noToken } from 'common-constants/facebook'
import { profilePath } from 'components/paths'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { replace } from 'functions/router'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveFacebookTokenAndMambaUserIdToLocalStorage } from './facebook/functions'
import {
  accessDeniedPath,
  albumPath,
  providerNameFacebookPath,
  providerPath,
  uploadPhotosPath,
} from './Uploader.paths'

export const ProviderFacebookRedirect: FC<{
  location: { search: string }
}> = ({ location: { search } }) => {
  const dispatch = useDispatch()
  const parsedQueryString = new URLSearchParams(search)
  const token = parsedQueryString.get('token')
  const location = parsedQueryString.get('location')

  const { userId, albumId, albumIdMain } = useShallowEqualSelector(
    ({ userReducer: { userId, albumId, albumIdMain } }) => ({
      userId,
      albumId,
      albumIdMain,
    })
  )

  useEffect(() => {
    if (!albumIdMain) {
      dispatch(fetchProfileAlbumMainAction())
    }
  }, [albumIdMain, dispatch])

  useEffect(() => {
    if (!parsedQueryString.has('token') || !albumIdMain) {
      return
    }

    const tokenReceived = token !== noToken

    if (tokenReceived) {
      saveFacebookTokenAndMambaUserIdToLocalStorage(token!, userId!)
    }

    // Передали локацию в state, чтобы вернуться именно на ту страницу, с которой мы ушли в facebook
    if (location) {
      dispatch(
        replace(
          mergeAllUrls(
            decodeURIComponent(location),
            tokenReceived ? '' : accessDeniedPath
          )
        )
      )
      return
    }

    dispatch(
      replace(
        mergeAllUrls(
          profilePath,
          userId!,
          uploadPhotosPath,
          albumPath,
          albumId ?? albumIdMain,
          providerPath,
          providerNameFacebookPath,
          tokenReceived ? '' : accessDeniedPath
        )
      )
    )
  }, [
    albumId,
    albumIdMain,
    dispatch,
    location,
    parsedQueryString,
    token,
    userId,
  ])

  return <DefaultLoader />
}
