import { fetchApi } from './index'
import { definitions, paths } from 'api/generated/photoline'
import { fetchQueryApi } from './fetchApi'

export function fetchPhotoLineIdApi() {
  return fetchApi('/photoline/id')
}

export type PhotolinePosts = definitions['PhotolinePosts']
export type PhotolinePostWithAuthor = definitions['PhotolinePostWithAuthor']
type Method = paths['/photoline/main']
type Query = Method['get']['parameters']['query']

export function fetchInitialPhotoLineApi(limit?: number) {
  return fetchQueryApi<PhotolinePosts, Query>('/photoline/main', {
    statusNames: 'hasVerifiedPhoto,online',
    limit,
  })
}
