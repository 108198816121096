import { fetchProfileMyApi } from 'api/profile/fetchProfileMyApi'
import { getKeyValue, setKeyValue } from 'functions/localStorage'
import {
  getSessionKeyValue,
  setSessionKeyValue,
} from 'functions/sessionStorage'
import { TimeRange } from 'common-constants/timeRange'
import { matchPath } from 'react-router'
import { AbTestPhotoUploadGroups } from 'reducers/system/abTestReducer.constants'
import { INCLUDED_PATHS_IN_AB_TEST } from 'hooks/useShowPhotoUploader/useShowPhotoUploader.constants'
import { findMatchByPath } from 'functions/findMatch'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import {
  albumPath,
  uploadPhotosPath,
} from 'components/page/UploaderPhotos/Uploader.paths'
import { vipBeelinePath } from 'components/page/VipBeeline/VipBeeline.paths'
import { noticePath } from 'components/uninotice/UniNotice.paths'
import { definitions } from 'api/generated/profile'

export const isTargetGroup = (groupName: AbTestPhotoUploadGroups) => {
  return [
    AbTestPhotoUploadGroups.GroupC,
    AbTestPhotoUploadGroups.GroupD,
    AbTestPhotoUploadGroups.GroupE,
  ].includes(groupName)
}

/** Map { userId: created } */
const userCreatedMap = new Map<number, number>()

export const shouldShowPhotoUploaderForGroupD = async (userId: number) => {
  let userCreated = userCreatedMap.get(userId)

  if (!userCreated) {
    const user = ((await fetchProfileMyApi()) as unknown) as definitions['ProfileActive']
    userCreated = user.created * 1000
    userCreatedMap.set(userId, userCreated)
  }

  /** 65.5 часов */
  const timeGap = 65.5 * 60 * 60 * 1000

  return userCreated + timeGap < Date.now()
}

export const isRouteIncludedInAbTest = (currentPath: string) => {
  // Исключения
  if (
    currentPath.includes(noticePath) || // Чтобы не перетереть нотис попрошайкой
    currentPath.includes(vipBeelinePath) // Если открыт модал активации VIP по промокоду через билайн
  ) {
    return false
  }

  for (const path of INCLUDED_PATHS_IN_AB_TEST) {
    const match = matchPath(currentPath, {
      exact: true,
      path,
    })

    if (match) {
      return true
    }
  }

  return false
}

const photoUploadedDateAbTestKey = 'photoUploadedDateAbTest'

export const onPhotoUploaded = () => {
  setSessionKeyValue(photoUploadedDateAbTestKey, String(Date.now()))
}

export const isPhotoJustUploaded = () => {
  try {
    const record = getSessionKeyValue(photoUploadedDateAbTestKey)

    if (!record) {
      return
    }

    const dateMs = Number(record)

    if (isNaN(dateMs)) {
      return
    }

    return Boolean(dateMs + TimeRange.minute > Date.now())
  } catch (error) {}
}

export const nextTimeShowPhotouploaderKey = 'nextTimeShowPhotouploader'

export const setNextTimeToShowPhotoUploader = (
  userId: number,
  delayInSeconds: number
) => {
  setKeyValue(
    nextTimeShowPhotouploaderKey,
    JSON.stringify({
      userId,
      nextShowTime: Date.now() + delayInSeconds * TimeRange.second,
    })
  )
}

export const getNextTimeToShowPhotoUploader = (userId: number) => {
  try {
    const record = getKeyValue(nextTimeShowPhotouploaderKey)

    if (!record) {
      return
    }

    const { userId: storedUserId, nextShowTime } = JSON.parse(
      JSON.parse(record)
    ) as {
      nextShowTime: number
      userId: number
    }

    if (userId !== storedUserId) {
      return
    }

    return nextShowTime
  } catch (error) {}
}

export const isToSoonToShowPhotoUploader = (userId: number) => {
  const nextShowTime = getNextTimeToShowPhotoUploader(userId)

  if (!nextShowTime) {
    return false
  }

  return nextShowTime > Date.now()
}

export const findMatchUploader = (pathname: string, baseUrl: string) => {
  return findMatchByPath(
    mergeAllUrls(baseUrl, uploadPhotosPath, albumPath),
    pathname,
    false
  )
}
