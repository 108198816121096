import React, { FC, ReactNode, RefObject, useEffect, useRef } from 'react'
import { goBackAction, goBackToBaseUrlAction } from 'actions/route/routeAction'
import { StyledComponentsStyles } from 'common/types'
import {
  genericLayoutContextId,
  mambaLayoutContextId,
} from 'components/layout/MambaLayout/Context'
import { ModalCss } from 'components/layout/ModalLayout/ModalLayout.styled'
import { logoutPath } from 'components/paths'
import { media } from 'components/presentational'
import {
  addStyleOverflowToBody,
  removeStyleOverflowFromBody,
} from 'functions/client'
import { fullStopPropagation } from 'functions/fullStopPropagation'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { ModalElementContext } from 'hooks/useModalElementGetter'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ModalLayoutContent } from './ModalLayoutContent'
import {
  ModalLayoutContextForChildren,
  useModalLayoutContext,
} from './ModalLayoutIndex.hooks'

const defaultRenderContent = (
  children: ReactNode,
  refGetter: () => RefObject<HTMLElement>,
  styles?: StyledComponentsStyles
) => (
  <Modal $styles={styles}>
    <ModalElementContext.Provider value={refGetter}>
      {children}
    </ModalElementContext.Provider>
  </Modal>
)

export const ModalLayoutIndex: FC<{
  onBackClick?: (event?: unknown) => void
  backUrl?: string
  closeDisabled?: boolean
  fade?: boolean
  renderContent?: (children: ReactNode) => ReactNode
  children?: ReactNode
  modalStyles?: StyledComponentsStyles
}> = (props) => {
  const {
    backUrl,
    closeDisabled = false,
    fade = true,
    renderContent = defaultRenderContent,
    children,
    modalStyles,
    ...rest
  } = props

  const {
    overlayClickHandler,
    contextValueForChildren,
  } = useModalLayoutContext()

  const dispatch = useDispatch()
  const { baseUrl, id } = useLayoutContext()
  const modalElementRef = useRef<HTMLDivElement>(null)
  const { isWindowsXp, isIpad } = useShallowEqualSelector(
    ({ systemReducer: { isWindowsXp, isIpad } }) => ({
      isWindowsXp,
      isIpad,
    })
  )

  const onOverlayClick = overlayClickHandler || props.onBackClick

  useEffect(() => {
    const handleEscape = ({ keyCode }: KeyboardEvent) => {
      if (keyCode === 27) {
        if (closeDisabled) {
          dispatch(push(mergeAllUrls(baseUrl, logoutPath)))
        } else if (onOverlayClick) {
          onOverlayClick()
        } else if (backUrl) {
          dispatch(push(backUrl))
        } else {
          dispatch(goBackAction())
        }
      }
    }
    document.addEventListener('keyup', handleEscape)
    addStyleOverflowToBody()
    return () => {
      document.removeEventListener('keyup', handleEscape)
      removeStyleOverflowFromBody()
    }
  }, [dispatch, baseUrl])

  const routeToBackUrl = () => {
    if (backUrl) {
      dispatch(push(backUrl))
    }
  }

  const handleOverlayClick = () => {
    dispatch(goBackToBaseUrlAction())
  }

  let internalFade = fade
  if (id === mambaLayoutContextId) {
    internalFade = true
  }

  if (id === genericLayoutContextId) {
    internalFade = false
  }

  const properties = {
    ...rest,
    isModalHack: isWindowsXp || isIpad,
    ref: modalElementRef,
    fade: internalFade,
  }

  const content = renderContent(children, () => modalElementRef, modalStyles)

  const getOverlayClick = () => {
    if (closeDisabled) return fullStopPropagation
    if (onOverlayClick) return onOverlayClick
    if (backUrl) return routeToBackUrl
    return handleOverlayClick
  }

  return (
    <ModalLayoutContent {...properties} onOverlayClick={getOverlayClick()}>
      <ModalLayoutContextForChildren.Provider value={contextValueForChildren}>
        {content}
      </ModalLayoutContextForChildren.Provider>
    </ModalLayoutContent>
  )
}

export const Modal = styled.div<{ $styles?: StyledComponentsStyles }>`
  ${ModalCss};

  ${media.phone`
    margin: 0;    
    border-radius: 0;
    width: 100%;
    position: relative;
  `};

  ${({ $styles }) => $styles};
`
