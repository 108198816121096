import { matchPath } from 'react-router-dom'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { LOCALES_REGEXP } from 'common-constants/locale'

function findCertainMath(pathname, path, exact = false, strict = false) {
  return matchPath(pathname, {
    path,
    exact,
    strict,
  })
}

/**
 * @deprecated
 */
export function findMatch(
  path,
  router,
  strictError = true,
  exact = false,
  strict = false
) {
  const routerPath = router.location.pathname
  return findMatchUrl(routerPath, path, exact, strict, strictError)
}

export const findMatchByPath = (
  path,
  pathname,
  strictError = true,
  exact = false,
  strict = false
) => findMatchUrl(pathname, path, exact, strict, strictError)

export function findMatchUrl(
  pathname,
  path,
  exact = false,
  strict = false,
  strictError = true
) {
  const match = findCertainMath(pathname, path, exact, strict)
  if (match) {
    return match
  }

  const matchWithLocale = findCertainMath(
    pathname,
    mergeAllUrls(`:locale(${LOCALES_REGEXP})`, path),
    exact,
    strict
  )

  if (matchWithLocale) {
    return matchWithLocale
  }

  if (strictError) {
    throw new Error(`Match not found "${pathname}" for path "${path}"`)
  } else {
    return null
  }
}
