import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'

export const SWITCH_OFF_UPDATE_TITLE_NEW_MESSAGE = 'SWITCH_OFF_UPDATE_TITLE_NEW_MESSAGE' as const

export const switchOfUpdateTitleNewMessageAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  if (getState().metaData.updateTitleNewMessage) {
    return dispatch(toggleSwitchTitleNewMessagePlainAction())
  }
}

export const toggleSwitchTitleNewMessagePlainAction = () => ({
  type: SWITCH_OFF_UPDATE_TITLE_NEW_MESSAGE,
})
