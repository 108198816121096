import { useEffect } from 'react'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useRedesign } from 'hooks/useRedesign'
import { updateIsMobileViewAction } from 'actions/system/updateIsMobileViewAction'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { debounce } from 'functions/performance'

export const useMobileView = () => {
  const dispatch = useAppDispatch()
  const redesign = useRedesign()

  useEffect(() => {
    if (!redesign) {
      return
    }

    const updateIsMobileView = debounce(() => {
      dispatch(updateIsMobileViewAction(window.innerWidth < breakpoints.mobile))
    }, 100)

    updateIsMobileView()

    window.addEventListener('resize', updateIsMobileView)

    return () => {
      window.removeEventListener('resize', updateIsMobileView)
    }
  }, [dispatch, redesign])
}
