import { authorizeByTokenAtMailruApi } from 'api/authorization/authorizeByTokenAtMailruApi'
import { NodeHeaders } from 'api/NodeHeaders'

export const AUTHORIZE_MAIL_RU_TOKEN = 'AUTHORIZE_MAIL_RU_TOKEN' as const

/**
 * @param token
 * @param headers
 */
export const authorizeByTokenAtMailruAction = (
  token: string,
  headers?: NodeHeaders
) => ({
  type: AUTHORIZE_MAIL_RU_TOKEN,
  promise: () => authorizeByTokenAtMailruApi(token, headers),
})

export type AuthorizeByTokenAtMailruAction = ReturnType<
  typeof authorizeByTokenAtMailruAction
>
