export const onlineStatus = 'online'
export const realStatus = 'real'
export const verifiedPhotoStatus = 'hasVerifiedPhoto'
export const geoDistanceStatus = 'geoDistance'
export const geoCoordsStatus = 'geoCoords'
export const spaceTimeLocationStatus = 'spaceTimeLocation'
export const vipStatus = 'vip'
export const winkStatus = 'wink'
export const favouritesStatus = 'inMyFavorites'

export enum ProfileStatus {
  Online = 'online',
  Real = 'real',
  VerifiedPhoto = 'hasVerifiedPhoto',
  GeoDistance = 'geoDistance',
  GeoCoords = 'geoCoords',
  SpaceTimeLocation = 'spaceTimeLocation',
  Vip = 'vip',
  Wink = 'wink',
  Favourites = 'inMyFavorites',
}

export const statusNamesParameter = 'statusNames'
