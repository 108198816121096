import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { RestoreInitialStateAction } from 'actions/system/restoreInitialStateAction'
import {
  BannersAvailableResult,
  fetchBannersAvailableApi,
} from 'api/banners/fetchBannersAvailableApi'
import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'

export const BANNERS_AVAILABLE = 'BANNERS_AVAILABLE' as const
interface FetchBannerAvailableAction
  extends AsyncAction<BannersAvailableResult> {
  type: typeof BANNERS_AVAILABLE
}

export const fetchBannersAvailableAction = (
  headers?: Api6NodeHeaders
): AsyncThunkAction => (dispatch, getState) => {
  const state = getState()
  const {
    systemReducer: { partnerId },
  } = state

  /**
   * Убрал оптимизацию.
   * Срабатывает, только когда меняется основной шаблон, и
   * только для авторизованных.
   * Вызывается только если меняется vip или authorized
   */
  return dispatch({
    type: BANNERS_AVAILABLE,
    /**
     * Решили удалить со Степаном и показывать на всех партнеров
     */
    promise: () =>
      fetchBannersAvailableApi(state.authorizationReducer.authorized, headers),
  })
}

export type BannerActionTypes =
  | FetchBannerAvailableAction
  | RestoreInitialStateAction
