import React from 'react'
import loadable from '@loadable/component'

import { DefaultLoader } from '../../presentational/AnimatedWatch/AnimatedWatch'

export const DiamondsModalLoadable = loadable(() => import('./DiamondsModal'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('DiamondInfo split error', error)
    }
    return <DefaultLoader />
  })({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
})
