export const CLEAR_AUTHORIZATION_ERROR = 'CLEAR_AUTHORIZATION_ERROR' as const

export const clearAuthorizationErrorAction = () =>
  ({
    type: CLEAR_AUTHORIZATION_ERROR,
  } as const)

export type ClearAuthorizationTypes = ReturnType<
  typeof clearAuthorizationErrorAction
>
