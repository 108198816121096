/**
 * TODO Каким-то образом попадает в стартовую сборку.
 */
export enum Fields {
  Email = 'email',
  Password = 'password',
  DefaultPhoto = 'defaultPhoto',
  ProfileVisibilityStatus = 'profileVisibilityStatus',
  LanguageId = 'languageId',
  Message = 'message',
  Photo = 'photo',
  HiddenAge = 'hidden-age',
  HiddenOnline = 'hidden-online',
  HiddenVisits = 'hidden-visits',
  Personal = 'personal',
  Location = 'location',
  AgeFilterFrom = 'ageFilterFrom',
  AgeFilterTo = 'ageFilterTo',
  MessageSound = 'messageSound',
  ContactListBlocked = 'contact-list-blocked',
}

export enum SingleSelectFieldsSettings {
  InvisibleMode = 'invisibleMode',
  HiddenAge = 'hiddenAge',
  HiddenLastAccessTime = 'hiddenLastAccessTime',
  Incognito = 'incognito',
}
