export const mambaColor = '#ed6a0b'
export const balloonColor = 'rgb(224,96,101)'
export enum Colors {
  warning = '#e06065',
  warningHover = '#ca565b',
  warningActive = '#b34d51',
  link = '#46aae9',
  location = '#b2b2b2',
  white = 'white',
  black = 'black',
  invisibleSvgBackground = '#2e3d49',
  yandexButton = 'rgb(255, 51, 51)',
  appleButton = 'rgb(30, 30, 30)',
  vkontakte = 'rgb(70, 128, 194)',
  vkConnect = 'rgb(39, 135, 245)',
  mailRu = 'rgb(2, 95, 249)',
  odnoklassniki = 'rgb(239, 131, 8)',
  specialGray = '#7e8fa2',
  darkGrey = '#8d8d8f',
  darkGreyText = 'rgb(46, 61, 73)',
  hoverColor = '#ffffff',
  linkHover = '#0076c0',
  linkSelected = '#f56323',
  backgroundLight = '#ecf0f6',
  backgroundHoverLight = '#d7e0ee',
  defaultAction = '#46aae9',
  dark = '#2e3d49',
  checkboxActive = '#0065a4',
  note = '#9d9d9d',
  noteIcon = '#cdcccc',
  transparentSmallWhite = 'rgba(255, 255, 255, 0.26)',
  transparentFullWhite = 'rgba(255, 255, 255, 0)',
  navigationUserPanelBgColor = '#f4f8fd',
  noUserPhotoBgColor = '#7E8FA2',
  transparentFull = 'transparent',
  specialFeaturedPhotosNote = '#4ecfa8',
  informationNote = 'rgb(254, 213, 118)',
  iconBackgroundColorAquamarine = '#4ecfa8',
  iconBackgroundStartOnline = '#7af0cc',
  errorColor = '#bb1515',
  borderPhoneColor = '#e5e5e5',
  specialNotFoundGray = '#b1b9c3',
  backgroundSelfMessage = '#6679bf',
  textChatWillBeDeletedMessage = '#2e3d49',
  backgroundChatWillBeDeletedMessage = '#fceff0',
  lockedTagDefaultColor = '#f88b5e',
  lockedTagHoverColor = '#e87343',
  lockedTagActiveColor = '#e2622d',
  simpleTagWithActionActiveColor = '#cfd8e8',
  simpleTagWithActionFocusBoxShadow = '#c3daff',
  favouriteActive = '#fed576',
  editorBorder = '#f2eeee',
  disabledIcon = '#B1B9C3',
  noteColor = '#999999',
  locationAutoCompleteColor = '#8b999c',
  inputPlaceholderColor = '#757575',
  disabledCheckBoxWithChecked = '#d5d5d5',
  disabledCheckBoxWithCheckedColor = '#b1b9c3',
  checkedUserRegisteredInMamba = '#4ecfa8',
  replyMessage = '#9ab6cd',
  contactListEmpty = '#9ab6cd',
  tooltipInnerBorder = '#dddada',
  backgroundColorBannerDeleting = '#f5f7fa',
  lightBlue = '#7CD5F6',
  blackTransparentForty = 'rgba(0, 0, 0, .4)',
  blackTransparentSixty = 'rgba(0, 0, 0, .6)',
  blackTransparentSeventyFive = 'rgba(0, 0, 0, .75)',
  hoverProviderIconUploader = '#E4ECF5',
  endaNewMessage = 'red',
  moderation = '#FFD768',
  decline = '#E06065',
}
