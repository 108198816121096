import { locationField } from 'common/constants'
import { SettingsForm } from 'reducers/settings/SettingsReducerState'

export const createFormBuilderSettings = (
  field: string,
  form: SettingsForm
) => {
  const {
    name,
    birth,
    location,
    defaultPhoto,
    email,
    password,
    passwordCheck,
    passwordNewCheck,
    genderFilter,
    likedFilter,
    vipFilter,
  } = form
  switch (field) {
    case 'name':
    case 'defaultPhoto':
    case locationField:
      return {
        settings: {
          personal: { name, birth, location, defaultPhoto },
        },
        tag: 'personal',
      }
    case 'email':
      return {
        settings: {
          email: { email, password: passwordCheck },
        },
        tag: 'email',
      }
    case 'password':
      return {
        settings: {
          password: {
            newPassword: password,
            confirmPassword: passwordNewCheck,
            password: passwordCheck,
          },
        },
        tag: 'password',
      }
    case 'genderFilter':
    case 'likedFilter':
    case 'vipFilter':
      return {
        settings: {
          filterIncoming: {
            filterEnabled: true,
            genderFilter,
            likedFilter,
            vipFilter,
          },
        },
        tag: 'filterIncoming',
      }
  }
  throw new Error(`Unexpected form builder field "${field}"`)
}
