import { definitions } from 'api/generated/photo'

/**
 * меняем фото default местами c текущем выбранным фото.
 * @param photos
 * @param photoId
 */
export const changeDefaultPhoto = (
  photos: definitions['OmniAlbumPhoto'][],
  photoId: number
) => {
  return photos.map((item) => {
    return {
      ...item,
      main: Number(photoId) === item.photoId,
    }
  })
}
