import { AppRoute } from 'components/components.types'
import { AutoDetectLoadable } from 'components/page/Settings/Location/AutoDetect/AutoDetectLoadable'
import {
  autoDetectPath,
  locationPath,
  manualDetectPath,
} from 'components/page/Settings/Location/Location.paths'
import { ManualDetectLoadable } from 'components/page/Settings/Location/ManualDetect/ManualDetectLoadable'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const locationRoutes: AppRoute[] = [
  {
    path: mergeAllUrls(locationPath, autoDetectPath),
    component: AutoDetectLoadable,
    authorizedOnly: true,
  },
  {
    path: mergeAllUrls(locationPath, manualDetectPath),
    component: ManualDetectLoadable,
    authorizedOnly: true,
  },
]
