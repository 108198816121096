import React, { FC } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { isMamba } from 'common/constants'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { MambaPreloaderSvg } from 'components/presentational/AnimatedWatch/MambaPreloaderSvg'
import { useRedesign } from 'hooks/useRedesign'
import { Loader } from 'components/designSystem/Loader/Loader'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const rotate360AnimationRule = css`
  ${rotate360} 10s infinite linear;
`

const rotate360AnimationMinuteRule = css`
  ${rotate360} 5s infinite linear;
`

const Watch = styled.div<{ size: number; color: string }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  position: relative;
  border: solid 2px ${(props) => props.color};
`

const Hour = styled.div`
  position: absolute;
  top: 45%;
  width: 100%;
  height: 10%;
  animation: ${rotate360AnimationRule};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 45%;
    width: 30%;
    height: 100%;
    background-color: ${(props) => props.color};
  }
`

const Minute = styled.div`
  position: absolute;
  left: 45%;
  width: 10%;
  height: 100%;
  animation: ${rotate360AnimationMinuteRule};

  &::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    height: 40%;
    background-color: ${(props) => props.color};
  }
`

// TODO документацию по использованию прелоудера смотри в файле src/common/components/presentational/AnimatedWatch/DoNotImportDefaultPreloader.js
export const AnimatedWatch: FC<{
  size?: number
  color?: string
  colorMambaPreloader?: string
  className?: string
}> = ({
  size = 70,
  color = '#0079c0',
  colorMambaPreloader = '#ed6b0b',
  className,
}) => {
  const { isEdge, isIE, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { isEdge, isIE, partnerId } }) => ({
      isEdge,
      isIE,
      partnerId,
    })
  )

  if (!isIE && !isEdge && isMamba(partnerId)) {
    return (
      <MambaPreloaderSvg
        fill={colorMambaPreloader}
        size={size}
        className={className}
      />
    )
  }

  return (
    <Watch color={color} size={size} className={className}>
      <Hour color={color} />
      <Minute color={color} />
    </Watch>
  )
}

export const Loading = styled.div<{ $flexGrow?: number }>`
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: ${(props) => props.$flexGrow};
  height: inherit;
  box-sizing: border-box;
`

Loading.defaultProps = {
  $flexGrow: 1,
}

export const DefaultLoader: FC<{ className?: string }> = ({ className }) => {
  const redesign = useRedesign()

  if (redesign) {
    return <Loader />
  }

  return (
    <Loading className={className}>
      <AnimatedWatch />
    </Loading>
  )
}
