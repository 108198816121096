import { fetchTextApi } from 'api/textApi'
import { AsyncAction } from './actions.types'
import { AppDispatchNext, AppGetState } from './actions.typesNext'
import { createLegalPath } from 'components/page/Legal/createLegalPath'
import { OsTypes } from 'common-constants/userAgent'
import { DEFAULT_LOCALE } from 'common/constants'
import { INTERNATIONAL_LOCALE, resolveLocale } from 'functions/locale'
import { isRussia } from 'functions/country/country.functions'

const resolveLocaleByCountryId = (countryId: number) =>
  isRussia(countryId) ? DEFAULT_LOCALE : INTERNATIONAL_LOCALE

export const FETCH_TEXT = 'FETCH_TEXT'

export interface CompleteTextAction extends AsyncAction<string> {
  type: typeof FETCH_TEXT
}

export enum LegalDocument {
  agreement = 'agreement.html',
  confidentiality = 'confidentiality.html',
  vip = 'vip.html',
}

export const completeTextAction = (name: LegalDocument, forceOS?: OsTypes) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    systemReducer: { locale, partnerId, osType },
    userReducer,
  } = getState()

  const documentUrl = createLegalPath(
    userReducer?.countryId
      ? resolveLocaleByCountryId(userReducer.countryId)
      : resolveLocale(locale),
    partnerId,
    name,
    forceOS || osType
  )

  return dispatch({
    type: FETCH_TEXT,
    promise: () => fetchTextApi(documentUrl),
  })
}
