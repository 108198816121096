import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { fetchCurrentLocationApi } from 'api/location/fetchCurrentLocationApi'
import { NodeHeaders } from 'api/NodeHeaders'

export const FETCH_CURRENT_LOCATION = 'FETCH_CURRENT_LOCATION' as const

/**
 * Работает только если установить
 * https://staff.mamba.ru/test/ip_conf.phtml
 * на заруливалке. Если смотреть в бой то не работает.
 */
export const fetchCurrentLocationAction = (headers?: NodeHeaders) => async (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    location: { currentLocationUpdating, currentLocationServerErrorLoaded },
  } = getState()

  if (!currentLocationUpdating && !currentLocationServerErrorLoaded) {
    return dispatch(fetchCurrentLocationPlainAction(headers))
  }
}

export const fetchCurrentLocationPlainAction = (headers?: NodeHeaders) => ({
  type: FETCH_CURRENT_LOCATION,
  promise: () => fetchCurrentLocationApi(headers),
})
