/** https://stackoverflow.com/a/21623206/7635654 */
export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const r = 6371 // km
  const p = Math.PI / 180

  const a =
    0.5 -
    Math.cos((lat2 - lat1) * p) / 2 +
    (Math.cos(lat1 * p) *
      Math.cos(lat2 * p) *
      (1 - Math.cos((lon2 - lon1) * p))) /
      2

  return 2 * r * Math.asin(Math.sqrt(a))
}

export const hasLocationChangedBy100Meters = (
  oldLat: number,
  oldLon: number,
  newLat: number,
  newLon: number
) => {
  const distance = calculateDistance(oldLat, oldLon, newLat, newLon)
  return distance > 0.1
}
