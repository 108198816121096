import { isLoveMailru, isMamba, isWamba, isYonja } from 'common/constants'
import { sharedHost } from 'components/system/third-party/vk/vk.constants'
import { isServiceWorkerSupported } from 'functions/pwa/isServiceWorkerSupported'

/** Для дев по-идее не нужен, только в редких случаях */
export const isPwaSupported = () => {
  if (process.env.browser) {
    const { partnerId } = window.__INITIAL_STATE__.systemReducer

    return (
      process.env.production &&
      (isYonja(partnerId) ||
        isWamba(partnerId) ||
        isMamba(partnerId) ||
        isLoveMailru(partnerId)) &&
      location.host !== sharedHost &&
      isServiceWorkerSupported()
    )
  }

  return false
}
