import { setMainPhotoApi } from 'api/profileApi'
import { AsyncThunkAction } from 'actions/types'
import { fetchUserPhotosApi } from 'api/photosProfileApi'
import { fetchIncognitoRelationStatusApi } from 'api/incognito/fetchIncognitoRelationStatusApi'
import { fetchIncognitoStatusApi } from 'api/incognito/fetchIncognitoStatusApi'
import { fetchUserProfileApi } from 'api/profile/fetchUserProfileApi'
import { ChatMessagePhoto } from 'reducers/messenger/chatMessage.types'
import { NodeHeaders } from 'api/NodeHeaders'
import { deletePhotoApi } from 'api/profile/deletePhotoApi'

export const PHOTOS_LOADING = 'PHOTOS_LOADING' as const

export const setPhotosLoadingAction = (value: boolean) => ({
  type: PHOTOS_LOADING,
  value,
})

export const fetchUserPhotosAction = (
  userId: number,
  headers?: NodeHeaders
): AsyncThunkAction => async (dispatch, getState) => {
  const {
    authorizationReducer: { authorized },
  } = getState()
  dispatch(setPhotosLoadingAction(true))

  const promises: unknown[] = [
    dispatch(fetchPhotosViewerPhotosPlainAction(userId, headers)),
    dispatch(fetchPhotosProfilePlainAction(userId, headers)),
  ]

  if (authorized) {
    promises.push(dispatch(fetchIncognitoRelationPlainAction(userId, headers)))
    promises.push(dispatch(fetchIncognitoStatusPlainAction(userId, headers)))
  }

  await Promise.all(promises)
  dispatch(setPhotosLoadingAction(false))
}

export const FETCH_PHOTO_VIEWER_PHOTOS = 'FETCH_PHOTO_VIEWER_PHOTOS' as const

export const fetchPhotosViewerPhotosPlainAction = (
  userId: number,
  headers?: NodeHeaders
) => ({
  type: FETCH_PHOTO_VIEWER_PHOTOS,
  promise: () => fetchUserPhotosApi(userId, undefined, undefined, headers),
  userId,
})

export const FETCH_PHOTOS_PROFILE = 'FETCH_PHOTOS_PROFILE' as const

export const fetchPhotosProfilePlainAction = (
  userId: number,
  headers?: NodeHeaders
) => ({
  type: FETCH_PHOTOS_PROFILE,
  promise: () => fetchUserProfileApi(userId, undefined, headers),
})

export const FETCH_INCOGNITO_RELATION = 'FETCH_INCOGNITO_RELATION' as const

export const fetchIncognitoRelationPlainAction = (
  userId: number,
  headers?: NodeHeaders
) => ({
  type: FETCH_INCOGNITO_RELATION,
  promise: () => fetchIncognitoRelationStatusApi(userId, headers),
})

export const FETCH_INCOGNITO_STATUS = 'FETCH_INCOGNITO_STATUS' as const

export const fetchIncognitoStatusPlainAction = (
  userId: number,
  headers?: NodeHeaders
) => ({
  type: FETCH_INCOGNITO_STATUS,
  promise: () => fetchIncognitoStatusApi(userId, headers),
})

export const SET_NEW_MAIN_PHOTO = 'SET_NEW_MAIN_PHOTO' as const

export const setMainPhotoAction = (photoId: number) => ({
  type: SET_NEW_MAIN_PHOTO,
  photoId,
  promise: () => setMainPhotoApi(photoId),
})

export const DELETE_PHOTO = 'DELETE_PHOTO' as const

export const deletePhotoAction = (photosIds: number[]) => ({
  type: DELETE_PHOTO,
  removedPhotosIds: photosIds,
  promise: () => deletePhotoApi(photosIds),
})

export const FETCH_ATTACH_PHOTOS = 'FETCH_ATTACH_PHOTOS' as const

interface FetchAttachPhotosAction {
  type: typeof FETCH_ATTACH_PHOTOS
  result: {
    photos: ChatMessagePhoto[]
  }
}

export const fetchAttachPhotosAction = (photos: ChatMessagePhoto[]) => ({
  type: FETCH_ATTACH_PHOTOS,
  result: { photos },
})

export const RESET_PHOTO_VIEWER = 'RESET_PHOTO_VIEWER' as const

export const resetPhotoViewerAction = () => ({
  type: RESET_PHOTO_VIEWER,
})

export type PhotoViewerActionTypes =
  | ReturnType<typeof resetPhotoViewerAction>
  | ReturnType<typeof fetchAttachPhotosAction>
  | ReturnType<typeof deletePhotoAction>
  | ReturnType<typeof setMainPhotoAction>
  | ReturnType<typeof fetchPhotosViewerPhotosPlainAction>
  | ReturnType<typeof fetchPhotosProfilePlainAction>
  | ReturnType<typeof fetchIncognitoRelationPlainAction>
  | ReturnType<typeof fetchIncognitoStatusPlainAction>
  | ReturnType<typeof setPhotosLoadingAction>
