import { AppRoute } from 'components/components.types'
import { settingsModalPath } from 'components/paths'
import SettingsLoadable from 'components/page/Settings/SettingsLoadable'
import { webViewRoutes } from 'components/page/WebView/WebView.routes'

/**
 * Роуты с подложкой без основного лейаута.
 * Например, забаненных юзеров, лишние запросы из лейаута
 * будут делать редиректы.
 * https://redmine.mamba.ru/issues/114849
 */
export const backgroundOnlyRoutes: AppRoute[] = [
  {
    path: settingsModalPath,
    component: SettingsLoadable,
    authorizedOnly: true,
  },
  ...webViewRoutes,
]
