import {
  FETCH_CURRENT_LOCATION,
  fetchCurrentLocationPlainAction,
} from 'actions/location/fetchCurrentLocationAction'
import { definitions } from 'api/generated/location'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

export interface LocationState {
  currentLocationUpdating: boolean
  currentLocation: definitions['NamedLocation'] | null
  currentLocationServerErrorLoaded: boolean
}

const initialState: LocationState = {
  currentLocationUpdating: false,
  currentLocation: null,
  currentLocationServerErrorLoaded: false,
}

export const locationReducer = (
  state = initialState,
  action: ReturnType<typeof fetchCurrentLocationPlainAction>
): LocationState => {
  switch (action.type) {
    case FETCH_CURRENT_LOCATION:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          currentLocationUpdating: true,
        }),
        (result) => ({
          currentLocationUpdating: false,
          currentLocation: result,
        }),
        () => ({
          currentLocationUpdating: false,
          currentLocation: null,
          currentLocationServerErrorLoaded: true,
        })
      )

    default:
      return state
  }
}
