import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import React from 'react'

export const VipMegafonRedirectLoadable = loadable(
  async () => (await import('./VipMegafonRedirect')).VipMegafonRedirect,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('VipMegafonRedirect split error', error)
      }
      return <DefaultLoader />
    })({
      pastDelay: true,
      error: false,
      timedOut: false,
    }),
  }
)
