import { PhotoCommentPathParams, profilePath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import React, { FC } from 'react'
import RedirectWithStatus from './RedirectWithStatus'

/**
 * Редиректит со старой структуры url на новую https://redmine.mamba.ru/issues/115724
 */
export const PhotoCommentOldPathRedirect: FC<{
  match: {
    params: PhotoCommentPathParams
  }
}> = ({
  match: {
    params: { profileId, photoId, comment },
  },
}) => {
  const newUrl = mergeAllUrls(
    profilePath,
    profileId,
    'photo',
    photoId,
    `comment-${comment}`
  )

  return (
    <RedirectWithStatus
      to={newUrl}
      status={301}
      uid="PhotoCommentOldPathRedirect"
    />
  )
}
