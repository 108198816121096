/**
 * @deprecated fetchQueryApi
 * @param firstKey
 * @param params
 */
export const createComplexParams = (
  firstKey: string,
  params: Record<string, string[]>
): Record<string, string> =>
  Object.keys(params).reduce<Record<string, string>>((acc, key) => {
    acc[`data[${firstKey}][${key}]`] = params[key].join(',')
    return acc
  }, {})
