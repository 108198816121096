export const internalApiPath = '/node/api'

export interface FetchVkPhotosParams {
  token: string
}
export const vkPhotosPath = '/vk/photos'
export const checkAwayPath = '/check-away'

export const facebookPhotosPath = '/facebook/photos'

export const supportHelpDeskEddyInternalPath = '/support-help-desk-eddy-id'

export const sessionInitInternalPath = '/session-init-node'

export type InternalPath =
  | typeof vkPhotosPath
  | typeof supportHelpDeskEddyInternalPath
  | typeof sessionInitInternalPath
