import { AsyncThunkAction } from 'actions/types'

export const MAKE_DIRTY = 'MAKE_DIRTY'
export const TOGGLE_PASSWORD = 'TOGGLE_PASSWORD'
export const TOGGLE_VALIDATE_BY_SUBMIT = 'TOGGLE_VALIDATE_BY_SUBMIT'

export const makeDirtyAction = (
  formId: string,
  fieldName: string
): AsyncThunkAction => (dispatch, getState) => {
  const {
    formReducer: { form, validation },
  } = getState()

  /**
   * Не помечает dirty если валидация по сабмиту
   * Либо уже dirty
   */
  if (validation[formId] || (form[formId] && form[formId][fieldName])) {
    return
  }

  dispatch({
    type: MAKE_DIRTY,
    formId,
    fieldName,
  })
}

export const togglePasswordAction = (formId: string, fieldName: string) => ({
  type: TOGGLE_PASSWORD,
  formId,
  fieldName,
})

export const RESET_FORM = 'RESET_FORM'

export interface ResetFormAction {
  type: typeof RESET_FORM
  formId: string
}

export const resetFormAction = (formId: string) => ({
  type: RESET_FORM,
  formId,
})

export const toggleValidateOnSubmitAction = (
  formId: string,
  validateBySubmit: boolean
): AsyncThunkAction => (dispatch, getState) => {
  if (getState().formReducer.validation?.[formId] !== validateBySubmit) {
    return dispatch({
      type: TOGGLE_VALIDATE_BY_SUBMIT,
      formId,
      validateBySubmit,
    })
  }
}
