import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/coubstat'

type Post = paths['/cubstat/statisticsFromClient/not_authorized']['post']
type Response = Post['responses']['204']
type Body = Post['parameters']['body']['body']

export const sendStatisticsNotAuthorizedApi = (
  type: string,
  eventId: string,
  fromId: string
) => {
  return postApi<Response, Body>(
    '/cubstat/statisticsFromClient/not_authorized',
    {
      type,
      eventId,
      fromId,
      // Серверсайд попросил, чтобы эти поля приходили, но значения им не важны
      gender: 'M',
      age: 18,
    }
  )
}
