import React, { SVGProps } from 'react'

export const CloseSvg = ({
  stroke = '#0079C0',
  width = 24,
  height = 24,
  ...rest
}: SVGProps<SVGSVGElement> & {
  stroke?: string
}) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      d="M4 3l16.73 17.207M20.73 3L4 20.207"
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth="2.364"
    />
  </svg>
)
