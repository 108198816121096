import {
  go as _go,
  push as _push,
  replace as _replace,
} from 'redux-first-history'

import { tryScroll } from 'functions/tryTopScroll'
import { goBackAction } from 'actions/route/routeAction'
import { removeAllSPosts } from 'common-constants/cookie'
import { AsyncThunkAction2 } from 'actions/actions.types'
import {
  extractLocaleFromPath,
  removeLocaleFromPath2,
} from 'functions/language'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { fullPageReload } from './fullPageReload'
import { AppDispatchNext } from 'actions/actions.typesNext'
import { noticePath } from 'components/uninotice/UniNotice.paths'

// TODO переместить в экшены
export const push = (
  url: string,
  shouldScrollToTop?: boolean,
  state?: Partial<LocationState>
): AsyncThunkAction2 => async (dispatch, getState) => {
  const locale = extractLocaleFromPath(url)

  const {
    authorizationReducer: { authorized, profile },
    systemReducer: { language, shouldReloadOnNextPush },
  } = getState()

  // console.log('Push', { authorized })

  if (locale && authorized) {
    url = removeLocaleFromPath2(url)
    console.info('Push: remove locale', url)
  }

  if (!locale && !authorized && language && `/${language.code}` !== url) {
    url = mergeAllUrls(language.code, url)
    console.info('Push: add locale', url)
  }

  if (
    shouldReloadOnNextPush &&
    /** Чтобы не "моргало" на этапе регистрации и верификации */
    authorized &&
    profile?.isReal &&
    /**
     * Нотайсы могут появится с произвольным запросом.
     * Поэтому не нужно тут же перезагружать страницу.
     *
     * https://youtrack.mamba.ru/issue/M-8806
     */
    !url.includes(noticePath)
  ) {
    removeAllSPosts()
    return fullPageReload(url)
  }

  if (shouldScrollToTop) {
    tryScroll()
  }

  if (window.location.pathname !== url) {
    return dispatch(_push(url, state))
  } else {
    console.error(new Error(`Duplicated push ${url}`))
  }
}

export const replace = (
  url: string,
  shouldScrollToTop = false,
  uid?: string,
  state?: LocationState
): AsyncThunkAction2 => (dispatch) => {
  if (shouldScrollToTop) {
    tryScroll()
  }

  if (uid) {
    console.info('Redirect', uid, url, 'false')
  }

  if (window.location.pathname !== url) {
    return dispatch(_replace(url, state))
  } else {
    console.error(new Error(`Duplicated replace ${url}`))
  }
}

export const go = (count: number, shouldScrollToTop = false) => {
  if (shouldScrollToTop) {
    tryScroll()
  }
  return _go(count)
}

/**
 * @deprecated используем @see goBackAction
 */
export const goBack = (shouldScrollToTop = false) => (
  dispatch: AppDispatchNext
) => {
  if (shouldScrollToTop) {
    tryScroll()
  }
  return dispatch(goBackAction())
}

export interface LocationState {}
