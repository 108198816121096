import {
  updateFeaturedPhotosX3PopupShownDatePlainAction,
  UPDATE_FEATURED_PHOTOS_X3_POPUP_SHOWN_DATE,
} from 'actions/promo/updateFeaturedPhotosX3PopupShownDateAction'
import { Reducer } from 'redux'

const INITIAL_STATE = {
  popupFeaturedPhotosX3ShownDate: null as null | number,
} as const

export type PromoReducerState = typeof INITIAL_STATE

/** Здесь должны храниться данные касающиеся внутренней рекламы */
export const promoReducer: Reducer<
  PromoReducerState,
  ReturnType<typeof updateFeaturedPhotosX3PopupShownDatePlainAction>
> = (state = INITIAL_STATE, action): PromoReducerState => {
  switch (action.type) {
    case UPDATE_FEATURED_PHOTOS_X3_POPUP_SHOWN_DATE:
      return {
        ...state,
        popupFeaturedPhotosX3ShownDate: action.value,
      }

    default:
      return state
  }
}
