import React, { createContext, useContext } from 'react'
import { History } from 'history'

import { RootReducers } from 'reducers/index'
import { combineStaticReducers } from 'common/store/configureStore'
import { loadStorageState } from 'common/store/loadStorageState'
import { restorePartialStateAction } from 'actions/system/localStorageAction'
import { restoreInitialStateAction } from 'actions/system/restoreInitialStateAction'
import { AppStore, PartialRootState } from 'common/store/store.types'
import { createReduxHistoryContext } from 'redux-first-history'

export type AppContextType = {
  history: History
  store: AppStore
}
export const AppContext = createContext<AppContextType>({} as AppContextType)

const addAsyncReducer = (
  store: AppStore,
  history: History,
  name: string,
  reducer: Function
) => {
  if (!store.asyncReducers) {
    store.asyncReducers = {}
  }

  if (store.asyncReducers[name]) {
    // console.warn(`Reducer ${name} has been inserted already.`)
  } else {
    const { routerReducer } = createReduxHistoryContext({
      history: history,
    })

    store.asyncReducers[name] = reducer
    store.replaceReducer(
      combineStaticReducers({
        routerReducer: routerReducer,
        asyncReducers: store.asyncReducers,
      })
    )
    if (process.env.browser && window.__INITIAL_STATE__[name]) {
      store.dispatch(restoreInitialStateAction(name, window.__INITIAL_STATE__))
    }

    if (process.env.browser) {
      const partialState = loadStorageState()
      if (partialState[name]) {
        store.dispatch(
          restorePartialStateAction(
            partialState,
            name as keyof PartialRootState
          )
        )
      }
    }
  }
}

export const insertReducers = (
  store: AppStore,
  history: History,
  reducersObject: RootReducers
) => {
  const reducerNames = Object.keys(reducersObject)
  reducerNames.forEach((reducerName) => {
    // console.log('local storage add reducer', stateName)
    addAsyncReducer(store, history, reducerName, reducersObject[reducerName])
  })
}

export const useReducersInsert = (reducersObject: RootReducers) => {
  const { store, history } = useContext(AppContext)
  insertReducers(store, history, reducersObject)
}

/**
 * @deprecated
 */
export const withReducersInsert = (reducersObject: RootReducers) => (
  WrappedComponent
) => {
  return (props) => {
    useReducersInsert(reducersObject)
    return <WrappedComponent {...props} />
  }
}
