import { AppRoute } from 'components/components.types'
import { AccountRemovalLoadable } from 'components/system/redirect/NoticeEmail/AccountRemoval/AccountRemovalLoadable'
import { NoticeEmails } from 'components/system/redirect/NoticeEmail/constants'
import { createNoticeEmailsClientPath } from 'components/system/redirect/NoticeEmail/function'
import { noticePath } from 'components/system/redirect/NoticeEmail/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

/**
 * Ссылки из email
 */
export const emailRoutes: AppRoute[] = [
  {
    path: mergeAllUrls(
      noticePath,
      createNoticeEmailsClientPath(NoticeEmails.AccountRemoval)
    ),
    component: AccountRemovalLoadable,
    exact: true,
    authorizedOnly: false,
  },
]
