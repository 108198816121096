import { SocketData } from 'actions/socket/socketReceiveDataAction'
import { ToastUniNotice } from 'api/uninotice/uninotice.types'
import { NoticeShortCutList } from 'components/uninotice/uninotice.types'

/**
 * Если с кем-то открыт чат, то уведомление о новом сообщений
 * с этим пользователем, не надо показывать.
 *
 * Комета ничего не знает о том с кем ты переписываешь
 * и в канал notice приходит все.
 *
 * @param channelUniNotice - каналы
 * @param openedChatUserId - id пользователя, чат с которым открыт
 * убираем notice из массива если чат открыт с openedChatUserId
 */
export const filterToastUniNoticeByOpenedChatUserId = (
  channelUniNotice: SocketData<ToastUniNotice>[],
  openedChatUserId?: number
): SocketData<ToastUniNotice>[] => {
  if (!openedChatUserId) {
    return channelUniNotice
  }

  return channelUniNotice.filter((messageNotice) => {
    const {
      content: { payload },
    } = messageNotice

    if (
      payload?.noticeId === NoticeShortCutList.messagingNewMessage &&
      payload.action.data &&
      payload.action.data.userId === Number(openedChatUserId)
    ) {
      return false
    }

    return true
  })
}
