/* eslint-disable max-lines */
import { VERIFY_CAPTCHA } from 'actions/captchaAction'
import {
  FORCE_CAPTCHA,
  RESET_BLOCKED_PERSONAL,
  RESET_BLOCKED_PROFILE,
  RESET_BLOCKED_TRACK,
  RESET_ERROR,
  RESET_FIRST_TIME_REAL,
  RESET_PROFILE_ERROR_DATA,
  RESET_REAL_STATUS,
  SET_ERROR_FROM_GQL,
} from 'actions/errorAction'
import {
  errorCodeEquals,
  gdprCode,
  profileBannedCode,
  profileRemovedCode,
  realStatusNeededCode,
  realStatusRequiredCode,
} from 'api/index'
import { ACCEPT_GDPR, REMOVE_GDPR, RESET_STATUS_GDPR } from 'actions/gdprAction'
import { REGISTER_ACTION } from 'actions/authorization/registrationAction'

import {
  isActionBlockedGdpr,
  isActionBlockedIp,
  isActionBlockedTrack,
  isActionPersonalBlocked,
} from '../functions'
import { DELETE_PROFILE_SEND_CODE_SMS } from 'actions/profile/profileDeleteAction'
import { RESET_REGISTRATION_NEEDED } from 'actions/authorization/resetRegistrationNeededAction'
import { isAdditionalRegistrationNeeded } from 'api/function/isAdditionalRegistrationNeeded'
import { Reducer } from 'redux'
import { UPDATE_MINI } from 'actions/system/updateMiniAction'
import { FETCH_MINI } from 'actions/user/fetchMiniIfNeededAction'
import { Api6Error } from 'api/api.types'
import { AsyncAction } from 'actions/actions.types'
import { DELETE_ACCOUNT } from 'actions/settings/deleteAccountAction'
import { REMOVE_ACCOUNT } from 'actions/settings/removeAccountAction'
import { ApiResult } from 'api/fetchApi'
import { DeprecatedApi6Error } from 'api/types'
import { Api5Error } from 'common-constants/api5.types'
import { ErrorState, initialState } from 'reducers/error/ErrorState'
import { LOGIN_ACTION } from 'actions/authorization/loginAction'
import { LOGOUT_ACTION } from 'actions/authorization/logoutAction'
import {
  SOCKET_RECEIVE_DATA,
  SocketReceiveDataAction,
} from 'actions/socket/socketReceiveDataAction'
import { AllErrorActionTypes, ServerError } from 'reducers/error/error.types'
import { reduceSocketReceiveData } from 'reducers/error/errorReducer.socketMessage'
import { RESTORE_ACCOUNT } from 'actions/settings/restoreAccountAction'
import { isActionCaptchaNeeded } from 'reducers/isActionCaptchaNeeded'
import {
  actionHasErrors,
  isActionBlockedTrackLegacy,
  isActionWithoutErrors,
} from 'reducers/isActionWithoutErrors'

export const errorReducer: Reducer<ErrorState, AllErrorActionTypes> = (
  state = initialState,
  action
): ErrorState => {
  /** Возможно есть более красивый способ типизации */
  const asyncAction = action as AsyncAction<ApiResult>
  const api5Action = action as AsyncAction<Api5Error>
  /**
   * В старом фетче в какой-то момент ошибка стала приходить в result.
   * В новой типизированной реализации, приходит в error
   */
  const asyncResult = (asyncAction.error || asyncAction.result) as
    | DeprecatedApi6Error
    | ApiResult

  const asyncError = asyncResult as DeprecatedApi6Error | Api6Error | undefined

  if (action.type === FORCE_CAPTCHA && !state.showCaptcha) {
    return { ...state, showCaptcha: true }
  }

  if (action.type === LOGOUT_ACTION && asyncAction.ready) {
    return {
      ...state,
      showCaptcha: false,
      realStatusNeeded: false,
      profileRemoved: false,
      profileBanned: false,
      profilePersonalBanned: false,
      gdprBlocked: false,
      isErrorAuthorizedNeedLogout: false,
    }
  }

  if (action.type === RESET_FIRST_TIME_REAL) {
    return { ...state, firstTimeRealStatus: false }
  }

  if (
    action.type === REMOVE_GDPR &&
    asyncAction.ready &&
    (asyncResult as ApiResult).ok
  ) {
    return { ...state, gdprBlocked: false, realStatusNeeded: false }
  }

  if (action.type === RESET_ERROR && state.error !== null) {
    return {
      ...state,
      error: null,
    }
  }

  if (action.type === RESET_BLOCKED_TRACK) {
    return {
      ...state,
      trackBlocked: false,
    }
  }

  if (action.type === RESET_STATUS_GDPR) {
    return {
      ...state,
      gdprBlocked: false,
    }
  }

  if (action.type === RESET_BLOCKED_PERSONAL) {
    return {
      ...state,
      profilePersonalBanned: false,
    }
  }

  if (action.type === RESET_BLOCKED_PROFILE) {
    return {
      ...state,
      profileBanned: false,
    }
  }

  if (action.type === RESET_PROFILE_ERROR_DATA) {
    return {
      ...state,
      errorProfileData: {},
    }
  }

  if (action.type === RESTORE_ACCOUNT && asyncAction.ready) {
    return { ...state, profileRemoved: false }
  }

  if (action.type === DELETE_ACCOUNT && asyncAction.ready) {
    return { ...state, profileRemoved: true }
  }

  if (
    action.type === REMOVE_ACCOUNT &&
    asyncAction.ready &&
    !asyncAction.error
  ) {
    return { ...state, profileRemoved: true }
  }

  if (action.type === RESET_REGISTRATION_NEEDED) {
    return { ...state, additionalRegistrationNeeded: Boolean(action.value) }
  }

  if (action.type === RESET_REAL_STATUS) {
    return { ...state, realStatusNeeded: false }
  }

  if (asyncAction.ready) {
    if (
      (errorCodeEquals(asyncError, realStatusRequiredCode) ||
        realStatusNeededCode(asyncResult)) &&
      !state.realStatusNeeded
    ) {
      return { ...state, realStatusNeeded: true }
    }

    if (errorCodeEquals(asyncError, gdprCode) && !state.gdprBlocked) {
      return {
        ...state,
        gdprBlocked: true,
      }
    }

    if (
      errorCodeEquals(asyncError, profileBannedCode) &&
      !state.profileBanned
    ) {
      return { ...state, profileBanned: true }
    }

    if (
      action.type === DELETE_PROFILE_SEND_CODE_SMS &&
      isActionWithoutErrors(asyncAction)
    ) {
      return { ...state, profileRemoved: true }
    }

    if (action.type === VERIFY_CAPTCHA && isActionWithoutErrors(asyncAction)) {
      return {
        ...state,
        showCaptcha: false,
      }
    }

    if (action.type === REGISTER_ACTION && isActionWithoutErrors(asyncAction)) {
      return {
        ...state,
        additionalRegistrationNeeded: false,
      }
    }

    if (isAdditionalRegistrationNeeded(asyncResult)) {
      return {
        ...state,
        additionalRegistrationNeeded: true,
      }
    }

    if (action.type === FETCH_MINI || action.type === UPDATE_MINI) {
      if (
        errorCodeEquals(asyncError, profileRemovedCode) &&
        !state.profileRemoved
      ) {
        return { ...state, profileRemoved: true }
      }

      if (
        errorCodeEquals(asyncError, profileBannedCode) &&
        !state.profileBanned
      ) {
        return { ...state, profileBanned: true }
      }

      if (isActionWithoutErrors(asyncAction)) {
        if (state.realStatusNeeded) {
          return {
            ...state,
            realStatusNeeded: false,
            firstTimeRealStatus: true,
          }
        }
        if (state.profileRemoved) {
          return { ...state, profileRemoved: false }
        }
        if (state.profileBanned) {
          return { ...state, profileBanned: false }
        }
        if (state.profilePersonalBanned) {
          return { ...state, profilePersonalBanned: false }
        }
      }
    }
    if (action.type === ACCEPT_GDPR) {
      return { ...state, error: null, gdprBlocked: false }
    }
  }

  if (asyncAction.ready && actionHasErrors(asyncAction)) {
    // TODO switch который разрулит каждую конкретную ошибку,
    // минорные ошибки должны отображаться тостом

    /**
     * https://redmine.mamba.ru/issues/115264
     * Ошибка c кодом profileRemovedCode падает сразу из 5 апи,
     * при авторизации.
     * Поэтому проверяем сразу и апи5 и апи6
     *
     */
    if (
      (errorCodeEquals(asyncAction.error, 'user_deleted') ||
        errorCodeEquals(asyncError, profileRemovedCode) ||
        errorCodeEquals(asyncError, 'user_deleted')) &&
      !state.profileRemoved
    ) {
      return {
        ...state,
        profileRemoved: true,
      }
    }

    if (
      errorCodeEquals(asyncError, ServerError.userBanned) &&
      !state.profileBanned
    ) {
      return {
        ...state,
        profileBanned: true,
      }
    }

    if (isActionPersonalBlocked(asyncAction)) {
      return {
        ...state,
        profilePersonalBanned: true,
        errorProfileData: api5Action?.result?.internalError?.anketa
          ? api5Action.result.internalError.anketa
          : state.errorProfileData,
      }
    }

    if (
      isActionBlockedTrack((asyncAction as unknown) as ApiResult<Api6Error>) ||
      isActionBlockedTrackLegacy(asyncAction.result)
    ) {
      return {
        ...state,
        error: asyncAction.error ?? null,
        trackBlocked: true,
      }
    }

    if (isActionBlockedIp(asyncAction)) {
      return {
        ...state,
        error: asyncAction.error ?? null,
        ipBlocked: true,
      }
    }

    if (
      (isActionBlockedGdpr(asyncAction) ||
        errorCodeEquals(asyncAction.error, 'user_account_was_deactivated')) &&
      !state.gdprBlocked
    ) {
      return {
        ...state,
        gdprBlocked: true,
      }
    }

    if (isActionCaptchaNeeded(asyncAction)) {
      return {
        ...state,
        error: asyncResult ?? null,
        showCaptcha: true,
      }
    }

    /**
     * TODO: Слишком опасно
     * https://redmine.mamba.ru/issues/112548
     * общая ошибка которая где угодно может случится.
     * Но в случае если пользователь авторизован и сессия куда то пропала,
     * то нужно сбросить ошибки.
     */
    if (
      errorCodeEquals((asyncResult as unknown) as Api6Error, 'auth') &&
      // Исключения для случая ввода неверного логина/пароля
      LOGIN_ACTION !== action.type
    ) {
      return {
        ...state,
        isAuthorizedResetByServerNeedLogout: true,
      }
    }

    /**
     * https://redmine.mamba.ru/issues/115264
     * Так как для многих страниц нету seo нет смысла
     * добавлять ошибку в reducer
     */
    if (errorCodeEquals(asyncAction.error, 'seo_page_meta_not_found')) {
      return state
    }

    /**
     * A/B чаще не работают чем, работают и засирают errorLog
     * Поэтому не стоит регать ошибку в store
     */
    if (errorCodeEquals(asyncAction.error, 'user_not_engaged_in_ab_test')) {
      return state
    }

    if (errorCodeEquals(asyncAction.error, 'incorrect_or_outdated_link')) {
      return {
        ...state,
        error: asyncResult,
      }
    }

    if (errorCodeEquals(asyncAction.error, ''))
      if (!state.error && asyncAction.critical !== false) {
        return {
          ...state,
          error: asyncResult ?? null,
        }
      }
  } else if (asyncAction.error) {
    return {
      ...state,
      error: null,
    }
  }

  if (action.type === SET_ERROR_FROM_GQL) {
    if (action.value === ServerError.userBanned && !state.profileBanned) {
      return {
        ...state,
        profileBanned: true,
      }
    }

    return state
  }

  if (action.type === SOCKET_RECEIVE_DATA) {
    return reduceSocketReceiveData(state, action as SocketReceiveDataAction)
  }

  return state
}
