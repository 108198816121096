import React, { FC } from 'react'
import { CreateBaseUrl } from 'components/layout/MambaLayout/CreateContext'
import { UniNoticeLoadable } from 'components/uninotice/UniNoticeLoadable'
import { indexPath } from 'components/paths'
import { genericLayoutContextId } from 'components/layout/MambaLayout/Context'

/**
 * Используется для показов нотисов с общим контекстом.
 * Чтобы была видна подложка, без фейда.
 * @constructor
 */
export const GenericUniNotice: FC = () => {
  return (
    <CreateBaseUrl
      baseUrl={indexPath}
      basePath={indexPath}
      contextId={genericLayoutContextId}
    >
      <UniNoticeLoadable />
    </CreateBaseUrl>
  )
}
