import { VendorSocialList } from 'common-constants/vendorSocialList'

export const LAST_CLICKED_AUTH_VENDOR = 'LAST_CLICKED_AUTH_VENDOR' as const

/**
 * Последний вендор, которым юзер пытался авторизоваться.
 * Решает следующую проблему:
 * юзер при клике на вендора(например VK), перенаправляется на VK, затем на наш сервер PHP
 * и после всех этих редиректов мы не знаем, как авторизовался пользователь.
 *
 * Поэтому кладем в кэш вендора, которым пользователь пытался авторизоваться,
 * чтобы после авторизации понять, что это был, например, VK.
 */
export const updateLastClickedAuthVendor = (vendor: VendorSocialList) => ({
  type: LAST_CLICKED_AUTH_VENDOR,
  vendor,
})
