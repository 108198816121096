import { fetchQueryApi } from 'api/fetchApi'

import { paths } from 'api/generated/profile'

type Response = paths['/profiles/my/location']['get']['responses']['200']['schema']
type Parameters = paths['/profiles/my/location']['get']['parameters']

export const fetchProfilesMyLocationApi = (locale: string) => {
  return fetchQueryApi<Response, Parameters>('/profiles/my/location', {
    '_loc[locale]': locale,
  })
}
