import { indexPath } from 'components/paths'

import { RootState } from 'reducers/RootState'

export const modalOpenAttribute = 'data-modal-open'
export const modalServerDummy = process.env.browser ? '/' : '/dummy-dummy/'

export const dummyRegExp: RegExp = new RegExp(modalServerDummy, 'g')

const replaceDummy = (html: string, baseUrl: string) =>
  html.replace(dummyRegExp, baseUrl + indexPath)

const replaceAttribute = (html: string, modalOpen: boolean) => {
  const from = `${modalOpenAttribute}="${JSON.stringify(!modalOpen)}"`
  const to = `${modalOpenAttribute}="${JSON.stringify(modalOpen)}"`
  return html.replace(from, to)
}

export const isModalOpenFromState = (state: RootState) => {
  const {
    systemReducer: { baseUrl, statusCode },
    router: {
      location: { pathname },
    },
  } = state

  return isModalOpen(baseUrl, pathname, statusCode)
}

export const isModalOpen = (
  baseUrl: string,
  pathname: string,
  statusCode: number
) => {
  if (statusCode === 404) {
    return false
  }
  // Оптимизация: нужна при переходе с одного baseUrl на другой
  // чтобы не было modalOpen = true с перерисовкой и белым экраном
  const sameRoot = pathname.substring(0, baseUrl.length)
  // console.log('isModalOpen', { baseUrl, sameRoot }, sameRoot !== pathname)
  if (sameRoot !== baseUrl) {
    return false
  }
  // console.log('isModalOpen', { baseUrl, pathname }, baseUrl !== pathname)
  return baseUrl !== pathname
}

export const checkStaticOpenModal = (
  html: string,
  baseUrl: string,
  pathname: string,
  statusCode: number
) => {
  const modalOpen = isModalOpen(baseUrl, pathname, statusCode)
  // console.log('checkStaticOpenModal', {
  //   baseUrl,
  //   pathname,
  //   isModalOpen: modalOpen,
  // })
  const htmlWithReplacedModalOpened = replaceAttribute(html, modalOpen)
  return replaceDummy(htmlWithReplacedModalOpened, baseUrl)
}
