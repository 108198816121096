import { DEFAULT_LOCALE } from 'common/constants'
import { INTERNATIONAL_LOCALE } from 'functions/locale'

/**
 * Использовать src/common/functions/mergeAllUrls.js:7
 * @deprecated
 */
export function mergeUrl(baseUrl = '', relativeUrl = '') {
  return '/' + [...splitUrl(baseUrl), ...splitUrl(relativeUrl)].join('/')
}

function splitUrl(url) {
  return String(url)
    .split('/')
    .filter((part) => part)
}

export function handleWithPreventDefault(event) {
  event.preventDefault()
}

export function parseSearch(query) {
  return query
    .substring(1)
    .split('&')
    .map((param) => {
      const [name, value] = param.split('=')
      return { name, value }
    })
    .reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {})
}

/**
 * @deprecated src/common/functions/capitalizeFirstLetter.ts
 */
export function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.substr(1)
}

export function resolveOsType(userAgentRaw = '') {
  const userAgent = userAgentRaw.toLowerCase()
  if (userAgent.indexOf('android') > -1) {
    return 'android'
  }
  if (/ipad|iphone|ipod/.test(userAgent)) {
    return 'ios'
  }
  return 'default'
}

const windowsXpRegExp = new RegExp(/^Mozilla\/.*Windows NT 5\.[12](.)*/)

export const resolveIfWindowsXp = (userAgentRaw = '') =>
  windowsXpRegExp.test(userAgentRaw)

export function findGiftById(gifts, id) {
  return concatGifts(gifts).find((gift) => gift.giftId === id)
}

function concatGifts(gifts) {
  return gifts.reduce((result, category) => [...result, ...category.gifts], [])
}

export const findChoosenGiftCategoryInfo = (categories, giftActiveId) =>
  categories.find((category) =>
    category.gifts.find((gift) => gift.giftId === giftActiveId)
  )

export async function sendApiBtp(promise, api, url, options) {
  if (!process.env.browser) {
    // TODO все равно не смотрим этот график + переведем на Сентри мониторинг запросов
    // return (await import('../../server/btp')).sendBtpApiHttpRequest(
    //   promise,
    //   api,
    //   url,
    //   options
    // )
  }
  return promise
}

export function checkIE(userAgentRaw = '') {
  const userAgent = userAgentRaw.toLowerCase()
  const msie = userAgent.indexOf('msie')
  return msie > 0 || !!userAgent.match(/trident.*rv\:11\./)
}

/**
 * Добавляет к числу пробел:
 * 1
 * 10
 * 100
 * 1 000
 * 10 000
 * 100 000
 * 1 000 000
 * 10 000 000
 * 100 000 000
 * 1 000 000 000
 * @param number
 * @returns {string}
 */
export function numberWithSpaces(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const findCorporateSiteLocale = (locale: string): string =>
  locale === DEFAULT_LOCALE ? locale : INTERNATIONAL_LOCALE
