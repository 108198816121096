export const bowserMobilePlatformType = 'mobile'
export const bowserDesktopPlatformType = 'desktop'
export const bowserTabletPlatformType = 'tablet'
// https://github.com/lancedikson/bowser/blob/master/src/constants.js
export const bowserWindowsOsName = 'Windows'
export const bowserChromeName = 'Chrome'
export const bowserIEName = 'Internet Explorer'
export const bowserFireFoxName = 'Firefox'
export const bowserEdgeName = 'Microsoft Edge'
export const bowserMacOsName = 'macOS'
export const bowserLinuxOsName = 'Linux'
export const bowserIosOsName = 'iOS'
export const bowserAndroidOsName = 'Android'

export enum OperationSystem {
  Windows = 'Windows',
  MacOs = 'macOS',
  Linux = 'Linux',
  Ios = 'iOS',
  Android = 'Android',
}

export enum PlatformType {
  Mobile = 'mobile',
  Desktop = 'desktop',
  Tablet = 'tablet',
}

export enum BrowserList {
  Chrome = 'Chrome',
  Safari = 'Safari',
  IE = 'Internet Explorer',
  FireFox = 'Firefox',
  Edge = 'Microsoft Edge',
  Opera = 'Opera',
  YandexBrowser = 'Yandex Browser',
}

export const SupportEmojiDisplayMobilePlatform = [
  OperationSystem.Android,
  OperationSystem.Ios,
]

export const SupportEmojiDisplayBrowserList = [
  BrowserList.FireFox,
  BrowserList.Edge,
  BrowserList.Chrome,
  BrowserList.Opera,
  BrowserList.YandexBrowser,
]

/**
 * https://support.google.com/chrome/thread/185534985/sunsetting-support-for-windows-7-8-8-1-and-windows-server-2012-and-2012-r2-in-early-2023?hl=en#:~:text=Chrome%20109%20is%20the%20last,requires%20Windows%2010%20or%20later.
 */
export const LastSupportChromeMajorVersionWindows78 = 109

/**
 * https://youtrack.mamba.ru/issue/M-5792
 */
export const chromeVersionWithoutBug = 85
