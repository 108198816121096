import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const YandexMetrikaLoadable = loadable(() => import('./YandexMetrika'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('YandexMetrika split error', error)
    }
    return undefined
  })(defaultLoadableProps),
})
