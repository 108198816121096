import { DEFAULT_LOCALE } from 'common/constants'
import cookie from 'js-cookie'
import { fetchApi5 } from 'api/index'
import { Gender } from 'common-constants/api6.types'
import { addZeroBeforeTimeUnit } from 'functions/number/addZeroBeforeTimeUnit'
import { FormBuilder } from 'api/FormBuilder'
import { Api5AuthResponse } from 'common-constants/api5.types'

export interface Birthday {
  year: string
  month: string
  day: string
}

export const registrationApi = (
  email: string,
  name: string,
  gender: Gender | string,
  lookFor: Gender | string,
  location: string,
  birth: Birthday,
  locale = DEFAULT_LOCALE
): Promise<Api5AuthResponse> => {
  const { year, month, day } = birth
  const s_post = cookie.get('s_post')
  return fetchApi5(`/registration/?lang_id=${locale}`, {
    method: 'POST',
    body: JSON.stringify({
      registration: {
        email,
        name,
        gender,
        lookFor,
        location,
        birth: `${year}-${addZeroBeforeTimeUnit(
          Number(month)
        )}-${addZeroBeforeTimeUnit(Number(day))}`,
      },
      s_post,
    }),
  })
}

export interface RegistrationApiResult {
  formBuilder: FormBuilder
}
