import { AppDispatch } from 'actions/actions.types'
import { AppGetState } from 'actions/actions.typesNext'

export const UPDATE_FEATURED_PHOTOS_X3_POPUP_SHOWN_DATE = 'UPDATE_FEATURED_PHOTOS_X3_POPUP_SHOWN_DATE' as const

/** Обновляет дату показанного промо гарантированных показов х3 */
export const updateFeaturedPhotosX3PopupShownDateAction = (
  value: number | null
) => (dispatch: AppDispatch, getState: AppGetState) => {
  const {
    promo: { popupFeaturedPhotosX3ShownDate },
  } = getState()

  if (popupFeaturedPhotosX3ShownDate === value) {
    return
  }

  dispatch(updateFeaturedPhotosX3PopupShownDatePlainAction(value))
}

export const updateFeaturedPhotosX3PopupShownDatePlainAction = (
  value: number | null
) => ({
  type: UPDATE_FEATURED_PHOTOS_X3_POPUP_SHOWN_DATE,
  value,
})
