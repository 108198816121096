import React, { FC } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { chatPath, contactPath } from 'components/paths'
import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { SupportRedirect } from 'components/system/redirect/SupportRedirect'

/**
 * LegacyMessengerRedirect
 * /my/message.phtml?oid=123
 * /my/messages.phtml?id=123
 * http://localhost:8080/my/message.phtml?oid=1460318970
 * https://www.mamba.ru/my/message.phtml?uid=1759660473
 * @constructor
 */
export const LegacyMessengerRedirect: FC = () => {
  const { id, authorized } = useShallowEqualSelector(
    ({ systemReducer: { query }, authorizationReducer: { authorized } }) => ({
      id: query.oid || query.uid || query.id,
      authorized,
    })
  )
  if (authorized && id) {
    return (
      <Replace
        to={mergeAllUrls(chatPath, id, contactPath)}
        uid="LegacyMessengerRedirect"
      />
    )
  }
  return <SupportRedirect />
}
