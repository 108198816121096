import { FormContext } from 'components/presentational/form'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useContext } from 'react'
import { AppFormMap } from 'reducers/formReducer'

const isFormDirty = (form: AppFormMap, formId: string, name: string) => {
  const formDirty = form[formId] || {}

  return formDirty[name]
}

export const useIsFormDirty = (name: string) => {
  const formId = useContext<string>(FormContext)
  const { form } = useShallowEqualSelector(({ formReducer: { form } }) => ({
    form,
  }))

  return isFormDirty(form, formId, name)
}
