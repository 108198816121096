import { ratingPath } from 'components/paths'

export const photoRatingMatchPath = '/match'
export const photoRatingSettingsPath = '/rating-settings'
export const photoRatingSettingsFullPath = `${ratingPath}/rating-settings`

export const countPhotosInStack = 4
export const maxVisibleNumberOfCountries = 8
export const photoRatingLimit = 30
export const minPhotoQueueLength = 6

export const limitExceededErrorCode = 'limit_exceeded'
export const vipLimitExceededErrorCode = 'vip_limit_exceeded'

export const ratingAdvancedFiltersRestricted =
  'rating_advanced_filters_restricted'

export const triggerVelocity = 0.2
export const triggerDistance = 80

export const draggableThreshold = 540

export const tutorialShowFrequency = 10
export const gapBetweenTutorials = 5

export const flippingPhotosTutorialType = 'flipping'
export const swipingCardsTutorialType = 'swiping'

export const flippingPhotosKey = 'flipping_photos_learned'
export const swipingCardsKey = 'swiping_cards_learned'

export const maxDescriptionTextLength = 300

export const smallDotClassName = 'dot-small'
export const activeDotClassName = 'dot-active'
export const numberPhotoToChangePaginationPhotoRating = 10
export const numberDotsShowCarouselPhotoRating = 10
