import React, { SVGProps } from 'react'

import { Colors } from 'common-constants/colors'
import { useTheme } from 'styled-components'

export const BackSvg = ({
  color = Colors.link,
}: SVGProps<SVGElement> & { color?: Colors }) => {
  const theme = useTheme()
  const transform =
    theme?.multiplier > 0 ? 'rotate(0 12 12)' : 'rotate(180 12 12)'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform={transform}
        viewBox="0 0 24 24"
      >
        <g stroke={color} strokeWidth="3">
          <g>
            <g>
              <path
                d="M7.587 0L.039 7.484 7.587 15.003M0 7.5L16.342 7.5"
                transform="translate(-1002 -262) translate(1002 262) translate(4 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
