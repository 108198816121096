import {
  appAgreementIndexPath,
  appConfidentialityPath,
  chatPath,
  commonFolderContactPath,
  contactListPath,
  contactRequestPath,
  contactWithoutContactIdPath,
  hitListPath,
  indexPath,
  redirectChatPath,
} from 'components/paths'
import { ChatMainLoadable } from 'components/page/Chat/ChatMainLoadable'
import { ContactListMobilePageLoadable } from 'components/page/ContactList/ContactListMobilePage/ContactListMobilePageLoadable'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { AuthorizedRedirect } from 'components/system/AuthorizedRedirect'
import React from 'react'
import { commonRoutes } from 'components/layout/commonRoutes'
import { HitListRedirectLoadable } from 'components/page/EventList/Redirect/HitListRedirectLoadable'
import { eventListRoutes } from 'components/page/EventList/eventList.routes'
import { promoCodeAuthorizedRoutes } from 'components/page/PromoCode/promoCode.routes'
import { ChatRedirectEntryLoadable } from 'components/page/Chat/ChatRedirectEntryLoadable'
import { AppRoute } from 'components/components.types'
import { vipMegafonPath } from 'components/page/VipMegafon/VipMegafon.paths'
import { VipMegafonRedirectLoadable } from 'components/page/VipMegafon/VipMegafonRedirectLoadable'
import { vipBeelineSubscriptionPath } from 'components/page/VipBeeline/VipBeeline.paths'
import { VipBeelineRedirectLoadable } from 'components/page/VipBeeline/VipBeelineRedirectLoadable'
import { AgreementPage } from 'components/page/Legal/AgreementPage'
import { MessengerRequestsLoadable } from 'components/page/MessengerRequests/MessengerRequestsLoadable'
import { vipCancelFromEmailPath } from 'components/page/VipCancelFromEmail/VipCancelFromEmail.paths'
import { VipCancelFromEmailLoadable } from 'components/page/VipCancelFromEmail/VipCancelFromEmailLoadable'
import { ConfidentialityPage } from 'components/page/Legal/Confidentiality/ConfidentialityLoadable'
import { selectGenderPath } from 'components/page/Onboarding/paths'

export const authorizedRoutes: AppRoute[] = [
  ...commonRoutes,
  ...promoCodeAuthorizedRoutes,
  ...eventListRoutes,
  /** https://youtrack.mamba.ru/issue/M-8350 */
  {
    path: appConfidentialityPath,
    component: ConfidentialityPage,
  },
  {
    path: vipMegafonPath,
    component: VipMegafonRedirectLoadable,
    exact: false,
  },
  {
    path: vipBeelineSubscriptionPath,
    component: VipBeelineRedirectLoadable,
    exact: true,
  },
  {
    path: vipCancelFromEmailPath,
    component: VipCancelFromEmailLoadable,
    exact: true,
  },
  {
    path: hitListPath,
    component: HitListRedirectLoadable,
    authorizedOnly: true,
    exact: false,
  },
  {
    path: appAgreementIndexPath,
    component: AgreementPage,
  },
  {
    path: commonFolderContactPath,
    component: ChatMainLoadable,
    authorizedOnly: true,
    exact: false,
  },
  {
    path: contactWithoutContactIdPath,
    component: ChatMainLoadable,
    authorizedOnly: true,
    exact: false,
  },
  {
    path: contactListPath,
    component: ContactListMobilePageLoadable,
    authorizedOnly: true,
    exact: false,
  },
  {
    path: contactRequestPath,
    component: MessengerRequestsLoadable,
    authorizedOnly: true,
    exact: false,
  },
  {
    path: redirectChatPath,
    component: ChatRedirectEntryLoadable,
    authorizedOnly: true,
    exact: true,
  },
  {
    path: chatPath,
    component: () => (
      <RedirectWithStatus
        to={redirectChatPath}
        status={301}
        uid="authorizedRoutes-chatPath"
      />
    ),
    authorizedOnly: true,
    exact: true,
  },
  // Можем попасть сюда после редиректа с /register и в процессе авторизоваться.
  {
    path: selectGenderPath,
    component: () => <AuthorizedRedirect uid="select-gender" />,
  },
  {
    path: indexPath,
    shouldCheck: true,
    component: () => <AuthorizedRedirect uid={'index'} />,
    exact: true,
  },
]
